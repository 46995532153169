<div id="container" class="h-100" [ngClass]="{ 'px-2 pb-4': isDesktop }">
  <div id="content" class="rounded-4" [ngClass]="{ 'm-3': !isDesktop, 'p-4 h-100': isDesktop }">
    <div id="body" class="p-4 h-100">
      @switch (activeSection) {
        @case ("detalhes") {
          <section-detalhes-produto [produtoForm]="form" />
        }
        @case ("info-tipo") {
          <section-info-tipo-produto [produtoForm]="form" />
        }
        @case ("aviso-tipo") {
          <section-aviso-tipo-produto [formProduto]="form" />
        }
        @case ("sobre-ultima-compra") {
          <section-sobre-ultima-compra-produto [produtoForm]="form" />
        }
        @case ("medida") {
          <section-medida-produto
            [produtoForm]="form"
            [idCompraProduto]="idCompraProduto"
            [unidadesMedidaEquivalente]="form.controls.tipo.value === TipoProdutoEnum.insumo ? unidadesMedidaEquivalente : null"
            [unidadesMedidaRendimento]="form.controls.tipo.value === TipoProdutoEnum.composicao ? unidadesMedidaRendimento : null"
            (removeUnidadeMedida)="removeUnidadeMedida($event)"
            (updateUnidadeRendimentoEquivalente)="setUnidadeRendimentoEquivalente($event)"
          />
        }
        @case ("quantidade-medida") {
          <section-quantidade-medida-produto
            [produtoForm]="form"
            [unidadesMedidaEquivalente]="form.controls.tipo.value === TipoProdutoEnum.insumo ? unidadesMedidaEquivalente : null"
            [unidadesMedidaRendimento]="form.controls.tipo.value === TipoProdutoEnum.composicao ? unidadesMedidaRendimento : null"
          />
        }
        @case ("valor-venda") {
          <section-valor-venda-produto [produtoID]="produtoID" [produtoForm]="form" />
        }
        @case ("valor-unidade-venda") {
          <section-valor-unidade-venda-produto
            [produtoForm]="form"
            [produtoID]="produtoID"
            [unidadesMedidaEquivalente]="form.controls.tipo.value === TipoProdutoEnum.insumo ? unidadesMedidaEquivalente : null"
            [unidadesMedidaRendimento]="form.controls.tipo.value === TipoProdutoEnum.composicao ? unidadesMedidaRendimento : null"
          />
        }
        @case ("produtos-composicao") {
          <section-produtos-composicao
            [produtoForm]="form"
            [produtoID]="produtoID"
            [produtosComposicao]="produtosComposicao"
            [operationInProgress$]="operationProdutosComposicaoInProgress$"
            [unidadesMedidaEquivalente]="form.controls.tipo.value === TipoProdutoEnum.insumo ? unidadesMedidaEquivalente : null"
            [unidadesMedidaRendimento]="form.controls.tipo.value === TipoProdutoEnum.composicao ? unidadesMedidaRendimento : null"
            [paginatorOpts]="paginatorProdutosComposicaoOpts"
            (produtoComposicaoAdicionado)="produtoComposicaoAdicionado($event)"
            (navigatePage)="navigatePageProdutosComposicao($event)"
            (updatePageSize)="updatePageSizeProdutosComposicao($event)"
          />
        }
      }
    </div>
  </div>

  <div
    id="actions"
    class="d-flex position-absolute p-4 rounded-top-4"
    [ngClass]="{
      'mx-4 gap-3 align-items-center': isDesktop,
      'justify-content-between': isDesktop && produtoID,
      'justify-content-end': isDesktop && !produtoID,
      'mx-3': !isDesktop,
    }"
  >
    @if (produtoID) {
      <button type="button" color="danger" class="btn" [ngClass]="{ 'flex-grow-1': !isDesktop }" (click)="excluirProduto()">
        <span> Excluir </span>
      </button>
    }

    <div [ngClass]="{ 'd-contents': !isDesktop, 'd-flex gap-3': isDesktop }">
      <button type="button" color="apoio" class="btn" [ngClass]="{ 'flex-grow-1': !isDesktop }" (click)="passoAnterior()">
        <span> {{ backButtonText }} </span>
      </button>

      <button type="button" class="btn" [ngClass]="{ 'flex-grow-1': !isDesktop }" [color]="form.controls.tipo.value | colorTipoProduto" (click)="proximoPasso()">
        <span> {{ nextButtonText }} </span>
      </button>
    </div>
  </div>
</div>
