<div id="container" class="h-100">
  <div id="content" class="mx-auto d-flex flex-column align-items-start justify-content-start h-100">
    @if (novoProduto) {
      <small-label [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ produtoForm.controls.nome.value }} </small-label>
    }

    <h1 class="fw-bold mt-3" color="dark">Produtos que compõe</h1>

    <p>Para fazer essa composição de produto, precisamos utilizar outros. Adicione abaixo os insumos ou composições que o compõe.</p>

    <div class="d-flex flex-column gap-4 w-100" id="produtos">
      <small-label class="fw-bold"> Adicionar </small-label>

      <div class="d-flex" id="add-buttons">
        <button type="button" class="btn gap-2 align-items-center" [ngClass]="{ 'btn-outline': !isDesktop }" [color]="produtoForm.controls.tipo.value | colorTipoProduto" (click)="adicionarEditarProduto(TipoProdutoEnum.insumo)">
          <ng-mat-icon name="add_box" [color]="isDesktop ? 'white' : (produtoForm.controls.tipo.value | colorTipoProduto)" />
          <span> Insumo </span>
        </button>
        <button
          type="button"
          class="btn gap-2 align-items-center"
          [ngClass]="{ 'btn-outline': !isDesktop }"
          [color]="produtoForm.controls.tipo.value | colorTipoProduto"
          (click)="adicionarEditarProduto(TipoProdutoEnum.composicao)"
        >
          <ng-mat-icon name="add_box" [color]="isDesktop ? 'white' : (produtoForm.controls.tipo.value | colorTipoProduto)" />
          <span> Composição </span>
        </button>
      </div>

      <div id="produtos-list" class="pb-3">
        @if (!produtosComposicao) {
          <operation-in-progress />
        } @else if (produtosComposicao?.length === 0) {
          <no-results>
            <h3 color="dark" class="fw-bold text-center">Você ainda não informou os produtos</h3>
          </no-results>
        } @else {
          <div id="header" class="rounded-top-4 py-2 text-center">
            <span class="fw-bold"> Produto </span>
          </div>

          <div id="produtos-list-content">
            @for (produtoComposicao of produtosComposicao; track $index) {
              <div class="d-flex flex-column produto rounded-4 p-3 gap-2" color="dark">
                <div class="d-flex justify-content-between align-items-center">
                  <small-label class="fw-bold"> {{ produtoComposicao.nome }} </small-label>

                  <div class="actions d-flex align-items-center justify-content-center">
                    <button type="button" class="btn p-0" (click)="removeProdutoComposicao(produtoComposicao)">
                      <ng-mat-icon name="delete" [color]="produtoForm.controls.tipo.value | colorTipoProduto" />
                    </button>

                    <button type="button" class="btn p-0" (click)="adicionarEditarProduto(null, produtoComposicao)">
                      <ng-mat-icon name="edit" [color]="produtoForm.controls.tipo.value | colorTipoProduto" />
                    </button>
                  </div>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                  <small-label>
                    <span class="fw-bold"> {{ produtoComposicao.quantidadeUsada }} </span>
                    &nbsp;
                    <span> {{ produtoComposicao.nomeUnidade }} </span>
                  </small-label>

                  <small-label class="fw-bold"> {{ produtoComposicao.valor | currency }} </small-label>
                </div>
              </div>
            }
          </div>

          @if (paginatorOpts) {
            <paginator
              color="info"
              [showLabels]="false"
              [page]="paginatorOpts?.page"
              [pages]="paginatorOpts.pages"
              [pageSize]="paginatorOpts.pageSize"
              [rowsCount]="paginatorOpts.rowsCount"
              [isDisabled]="(operationInProgress$ | async) || !produtosComposicao?.length"
              (updatePageSize)="updatePageSize.emit($event)"
              (navigatePage)="navigatePage.emit($event)"
            />
          }
        }
      </div>
    </div>
  </div>
</div>
