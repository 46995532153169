<div id="container" class="d-flex gap-5 pt-4" [ngClass]="{ 'flex-column': !isDesktop }">
  <div id="left" class="p-2 pe-4">
    <div class="d-flex flex-column gap-4">
      <h2 class="fw-bolder" color="titulo">{{ produto.nome }}</h2>
      <small-label class="py-2"> Foto do produto </small-label>

      <div id="image-detalhes-produto">
        @if (!produto.imagem) {
          <div class="d-flex flex-column align-items-center">
            <ng-mat-icon name="image" [color]="produto.tipoProduto | colorTipoProduto" />
          </div>
        } @else {
          <img class="img-thumbnail avatar" [src]="produto.imagem" [alt]="produto.nome" (error)="produto.imagem = null" />
        }
      </div>
    </div>
  </div>

  <div id="right" class="p-2 ps-4">
    <div class="d-flex flex-column gap-4">
      @if (produto.categoria) {
        <div id="categoria" class="d-flex align-items-center gap-3">
          <icon-in-ellipse [matIcon]="produto.categoria?.icone" [bgColor]="produto.categoria?.cor" />
          <span class="fw-600" color="titulo">{{ produto.categoria?.nome }}</span>
        </div>
      }

      <div class="d-flex flex-column gap-2">
        <small-label> Código </small-label>
        <span class="fw-600" color="titulo">{{ produto.codigoBarras || "-" }}</span>
      </div>

      <div class="d-flex gap-5">
        @if (produto?.classificacao === ClassificacaoProduto.vendido) {
          <div class="d-flex flex-column gap-2">
            <small-label> Valor de venda </small-label>
            <h4 class="fw-bolder" [color]="produto.tipoProduto | colorTipoProduto">{{ (produto.valorVenda | currency) || "-" }}</h4>
          </div>
        }

        <div class="d-flex flex-column gap-2">
          <small-label> Preço de custo </small-label>
          <h4 class="fw-bolder" color="titulo">{{ produto.valorCusto | currency }}</h4>
        </div>
      </div>

      <div class="d-flex flex-column gap-2">
        <small-label> Produto ativo ou inativo </small-label>
        <span class="fw-bolder" color="titulo">{{ produto.ativo != undefined ? (produto.ativo ? "Ativo" : "Inativo") : "-" }}</span>
      </div>

      <div class="d-flex flex-column gap-2">
        <small-label> Esse produto vai ser vendido </small-label>
        <span class="fw-bolder" color="titulo">{{ produto.classificacao != undefined ? (produto.classificacao === ClassificacaoProduto.vendido ? "Sim" : "Não") : "-" }}</span>
      </div>
    </div>
  </div>
</div>
