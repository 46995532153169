<div id="container" class="d-flex flex-column gap-4 pt-4">
  <h2 class="fw-bolder mb-0" color="dark">Histórico de compras</h2>

  <small-label> Registro de todas as compras feitas deste produto </small-label>

  <div id="content" class="w-100 flex-grow-1 d-flex gap-5" [ngClass]="{ 'flex-column': !isDesktop }">
    <div id="grafico">
      <chart-historio-vendas-compras
        #chartComprasRef
        drawColor="#F05929"
        [title]="'Total de compras'"
        [isDesktop]="isDesktop"
        [dadosGrafico]="dadosGrafico"
        [operationInProgress$]="operationHistoricoInProgress$"
        [dataInicio]="dataInicio"
        [dataFim]="dataFim"
        [tipoVisualizacao]="TipoVisualizacaoEnum.periodo"
      />

      <div class="d-flex gap-2 flex-wrap periodos-chart-select">
        @for (periodo of periodosChart; track $index) {
          <button class="btn rounded-pill" color="roxo" [ngClass]="{ 'btn-outline': periodoSelecionado?.label !== periodo.label }" (click)="selecionarPeriodo(periodo)" [disabled]="operationChartInProgress$ | async">
            {{ periodo.label }}
          </button>
        }
        @if (periodoHistoricoControl?.value) {
          <button class="btn rounded-pill" color="roxo">{{ periodoHistoricoControl.value.label }}</button>
        }
      </div>
    </div>

    <div id="compras" class="d-flex flex-column gap-2" ngbAccordion [closeOthers]="true">
      <div class="form-group d-flex flex-column gap-2 input-icon-end position-relative">
        <dropdown
          label="Período Exibido"
          placeholder="Selecione o período"
          iconColor="laranja-forte"
          [inline]="false"
          [classes]="['lh-2']"
          [options]="periodosHistorico"
          [displayField]="'label'"
          [control]="periodoHistoricoControl"
          (optionSelected)="periodoHistoricoComprasChanged($event)"
        />
      </div>

      <div class="header-compras rounded-top-4 d-flex justify-content-center p-3">
        <small-label class="fw-bold">Compras</small-label>
      </div>

      <div class="position-relative d-flex flex-column gap-2 py-2">
        @if (!compras || (operationHistoricoInProgress$ | async)) {
          <operation-in-progress [absolute]="true" />
        }

        @if (compras?.length === 0) {
          <no-results message="Nenhuma compra encontrada para os filtros selecionados." />
        }

        @for (compra of compras; track compra) {
          <div #accordionRef="ngbAccordionItem" ngbAccordionItem class="compra d-flex flex-column gap-3 p-3 rounded-4 position-relative" [class.collapsed]="accordionRef.collapsed">
            <div class="detalhes flex-grow-1 d-flex align-items-center" (click)="accordionRef.toggle()" [ngClass]="{ 'justify-content-start': isDesktop, 'justify-content-between': !isDesktop }">
              <div class="d-grid grid-two-columns w-100">
                <div class="d-flex flex-column gap-2">
                  <small-label color="cinza">Data</small-label>
                  <small-label class="fw-bold">{{ compra.dataUltimaCompra | date: "dd/MM/yyyy" }}</small-label>
                </div>

                <div class="d-flex flex-column gap-2">
                  <small-label color="cinza">R$ por unidade</small-label>
                  <small-label class="fw-bold">{{ compra.valorComprado | currency: "BRL" }}</small-label>
                </div>
              </div>
            </div>

            <div class="cell acoes d-flex justify-content-center position-absolute right-0 top-0">
              <button class="btn rounded-4 py-0 px-1" (click)="accordionRef.toggle()">
                <ng-mat-icon name="expand_more" color="laranja-forte" />
              </button>
            </div>

            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="p-1">
                <ng-template>
                  <div class="d-grid grid-two-columns w-100">
                    <div class="d-flex flex-column gap-2">
                      <small-label color="cinza">Marca</small-label>
                      <small-label class="fw-bold">{{ compra.marca || "-" }}</small-label>
                    </div>

                    <div class="d-flex flex-column gap-2">
                      <small-label color="cinza">Onde comprei</small-label>
                      <small-label class="fw-bold">{{ compra.localCompra || "-" }}</small-label>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
