import { Component, EventEmitter } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { SidenavComponent } from './nav/sidenav/sidenav.component';
import { ToolbarComponent } from './nav/toolbar/toolbar.component';
import { TabbarComponent } from './nav/tabbar/tabbar.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { Menu } from '../model/menu.model';
import { BaseClass } from '../globals/base-class';
import { AppPages, PageApp } from './nav/app-pages';
import { session$ } from '../globals/globals';
import { UsuarioService } from '../services/usuario.service';

const COMPONENTS = [SidenavComponent, ToolbarComponent, TabbarComponent];
const MODULES = [RouterModule, NgbToastModule];
const PIPES = [AsyncPipe, NgClass];

export const updateSession: EventEmitter<void> = new EventEmitter<void>();

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MODULES, COMPONENTS, PIPES],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent extends BaseClass() {
  mainMenu: Menu = new Menu();
  currentPage: PageApp;

  session = session$;

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
  ) {
    super();

    const sub = this.router.events.subscribe({
      next: (ev) => {
        if (ev instanceof NavigationEnd) {
          const page = AppPages.find(k => k.path === ev.url?.split('/')[2]);
          if (page) this.currentPage = page;

          if (this.mainMenu?.opened) this.mainMenu.close();
        }
      }
    });
    this.appendSubscription(sub);

    const sub2 = updateSession.pipe().subscribe({
      next: () => {
        if (!session$.value?.usuario?.id) return;
        this.usuarioService.getUserById(session$.value.usuario.id).then((res) => {
          if (!res.status) return;

          session$.next({
            ...session$.value, usuario: {
              email: res.email,
              id: res.id,
              nome: res.nome,
              quantidadeCreditos: res.quantidadeCreditos,
            }
          });
        });
      }
    });
    this.appendSubscription(sub2);
  }
}
