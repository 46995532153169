<div id="container" class="position-relative">
  <div id="content" class="p-4 d-flex flex-column gap-4 rounded-4 flex-grow-1" [ngClass]="{ 'align-items-center': isDesktop }">
    <h2 class="fw-bold" color="dark">Suas informações</h2>
    <small-label class="small justify-content-center" color="roxo"> Preencha suas informações para o cadastro </small-label>

    <div id="form" [formGroup]="form" class="d-grid gap-4">
      <div class="form-group d-flex flex-column gap-2 flex-grow-1">
        <small-label class="required"> Nome do empreendedor </small-label>
        <input type="text" class="form-control lh-2" placeholder="Ex: João da Silva" formControlName="nome" />
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label class="required"> Telefone </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <ng-mat-icon name="phone" color="white" />
          </div>
          <input mask="(00)0000-0000||(00)00000-0000" type="text" class="form-control lh-2" placeholder="Ex: (11) 99999-9999" formControlName="telefone" />
        </div>
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Rede social (site, Instagram, etc) </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <ng-mat-icon name="alternate_email" color="white" />
          </div>
          <input type="text" class="form-control lh-2" placeholder="www.site.com.br" formControlName="redeSocial" />
        </div>
      </div>
    </div>
  </div>

  <div id="footer" class="d-flex w-100 rounded-4 p-4 gap-4" [ngClass]="{ 'justify-content-center': isDesktop, 'position-absolute left-0 bottom-0': !isDesktop }">
    <button class="btn" color="apoio" (click)="dismiss()" [ngClass]="{ 'px-5': isDesktop, 'flex-grow-1': !isDesktop }">
      <span [class.px-5]="isDesktop"> Cancelar </span>
    </button>

    <button class="btn" color="titulo" (click)="salvar()" [ngClass]="{ 'px-5': isDesktop, 'flex-grow-1': !isDesktop }">
      <span [class.px-5]="isDesktop"> Salvar </span>
    </button>
  </div>
</div>
