<div id="container" class="pt-4">
  <div class="d-flex flex-column gap-4">
    <h2 class="fw-bolder" color="dark">Quando for <ng-container *ngTemplateOutlet="classificacaoText" />, usarei as medidas</h2>

    <div id="unidades-grid" [ngClass]="{ 'd-grid grid-two-columns gap-4 insumo': formEdit.controls.tipo.value === TipoProduto.insumo }">
      @if (formEdit.controls.tipo.value === TipoProduto.insumo) {
        <div class="d-flex flex-column align-items-center gap-3">
          <div id="quantidade-comprada" class="form-group w-100 d-flex flex-column gap-2">
            <small-label> Unidade de compra? </small-label>

            <div class="input-group input-icon-end">
              <unidade-medida-autocomplete class="flex-grow-1" [control]="formEdit.controls.unidadeMedidaCompra" [tipoProduto]="formEdit.controls.tipo.value" />
            </div>
          </div>
        </div>
      }

      <div class="d-flex flex-column align-items-center gap-3">
        <div id="quantidade-comprada" class="form-group w-100 d-flex flex-column gap-2">
          <small-label> Em quais medidas vou <ng-container *ngTemplateOutlet="classificacaoText" />? </small-label>

          <div class="input-group input-icon-end">
            <unidade-medida-autocomplete class="flex-grow-1" [control]="unidadeSelect" [tipoProduto]="formEdit.controls.tipo.value" />
          </div>
        </div>

        <list-medidas-checkboxes class="w-100" [unidadesMedida]="unidadesMedida" [tipoProduto]="formEdit.controls.tipo.value" [disabled]="true" />

        <div class="w-100 d-flex flex-column gap-4 mt-3">
          @for (unidadeMedida of unidadesMedida; track unidadeMedida) {
            @if (unidadeMedida.checked) {
              <div class="quantidade-unidade form-group d-flex flex-column gap-2 w-100 input-with-tag">
                <small-label>
                  <span class="text-wrap">
                    @if (formEdit.controls.tipo.value === TipoProduto.insumo) {
                      Quantas <span [color]="formEdit.controls.tipo.value | colorTipoProduto"> {{ unidadeMedida?.nome }}</span> tem em 1
                      <span [color]="formEdit.controls.tipo.value | colorTipoProduto"> {{ formEdit.controls.unidadeMedidaCompra.value?.nome }}</span
                      >?
                    } @else {
                      Essa composição rende quantas <span [color]="formEdit.controls.tipo.value | colorTipoProduto"> {{ unidadeMedida?.nome }}</span
                      >?
                    }
                  </span>
                </small-label>

                <div class="input-group">
                  <div class="input-group-text">
                    <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="10" [(ngModel)]="unidadeMedida.quantidade" />
                  </div>
                  <input type="text" class="medida form-control lh-2" placeholder="Ex: Quilos, Litros, Caixas" [value]="unidadeMedida?.nome" readonly />
                </div>
              </div>
            }
          }
        </div>
      </div>
    </div>
  </div>

  <ng-template #classificacaoText>{{ formEdit.controls.classificacao.value === ClassificacaoProduto.vendido ? "vender" : "usar" }}</ng-template>
</div>
