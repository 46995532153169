<div id="container" class="w-100 d-flex flex-column gap-2">
  @if (unidadesMedida?.length === 0) {
    <small-label> Nenhuma unidade selecionada </small-label>
  }
  @for (unidadeMedida of unidadesMedida; track unidadeMedida) {
    <checkbox
      [label]="unidadeMedida.nome + (unidadeMedida.sigla ? ' (' + unidadeMedida.sigla + ')' : '')"
      [color]="tipoProduto | colorTipoProduto"
      [value]="unidadeMedida.checked"
      [disabled]="disabled"
      (valueChange)="unidadeMedida.checked = $event"
    />
  }
</div>
