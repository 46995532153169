<div id="container" class="h-100">
  <div id="content" class="h-100 d-flex flex-column align-items-center justify-content-center" [ngClass]="{ 'mx-5 px-5': isDesktop }">
    <h1 class="fw-bold" color="dark">
      Quando for {{ produtoForm.controls.classificacao.value === ClassificacaoProduto.vendido ? "vender" : "usar" }}
      <span [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ produtoForm.controls.nome.value || "" }}</span
      >, vai ser em que medida?
      <!-- <div class="d-flex mt-3" [ngClass]="{ 'justify-content-center': isDesktop }">
        <small-label color="roxo"> Não sei o que responder, preciso de ajuda! </small-label>
      </div> -->
    </h1>

    <div id="quantidade-vendida" class="form-group w-100 d-flex flex-column gap-2 mt-5">
      <small-label> Qual a unidade de medida de venda? </small-label>

      <unidade-medida-autocomplete [control]="control" [tipoProduto]="produtoForm.controls.tipo.value" />
    </div>

    <!-- <div id="medidas" class="w-100">
      @for (unidade of unidadesMedidaEquivalente || unidadesMedidaRendimento; track unidade) {
        <div class="form-group d-flex flex-column gap-2 flex-grow-1 mt-4">
          <div class="input-group input-icon-end position-relative">
            <input type="text" class="medida form-control lh-2" placeholder="Ex: Quilos, Litros, Caixas" [value]="unidade.nome" readonly />
            <ng-mat-icon class="cursor-pointer" slot="end" name="delete" color="vermelho" (click)="removeUnidadeMedida.emit(unidade)" />
          </div>
        </div>
      }
    </div> -->
  </div>
</div>
