export const environment = {
  production: false,
  api: {
    url: 'https://smartis-composicao-custo-api-homologacao.azurewebsites.net/v1',
    // url: 'https://5221-187-79-180-131.ngrok-free.app/v1',
    endpoints: {
      categorias: '/categorias',
      cidades: '/cidades',
      empresas: '/empresas',
      estados: '/estados',
      negocioempresa: '/negocioempresa',

      produtos: '/produtos',
      calcularPrecoIdeal: '/produtos/{idProduto}/calcularPrecoIdeal',

      composicoes: '/{idProduto}/composicoes',
      unidadesMedidaComposicao: '/{idProduto}/composicoes/unidadesmedida',

      produtocomposicao: '/produtocomposicao',
      ramonegocios: '/ramonegocios',

      tokens: '/tokens',
      unidadesMedida: '/unidadesmedida',
      usuarios: '/usuarios',

      compraprodutos: '/compraprodutos',
      comprashistorico: '/compraprodutos/historico',
      comprasdashboard: '/compraprodutos/dashboard',
      produtoscompra: '/compraprodutos/{id}/produtos',

      vendas: '/vendas',
      vendashistorico: '/vendas/historico',
      vendasdashboard: '/vendas/dashboard',
      produtosvenda: '/vendas/{id}/produtos',
    }
  }
};











