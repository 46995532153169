<div id="container" class="rounded-3 p-3">
  <div id="content" class="h-100 rounded-4 p-4 d-grid gap-4">
    <div id="produtos">
      <produtos-pendentes (editarProduto)="editProduto($event)" [refreshList]="refreshListProdutos$.asObservable()" />
    </div>
    <!-- <div id="nfes">
      <nfes-pendentes (editarProduto)="editProduto($event)" [refreshList]="refreshListNfes$.asObservable()" />
    </div> -->
  </div>
</div>
