import { Component, Input } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import { Produto } from '../../../../model/produto.model';
import { CompraService } from '../../../../services/compra.service';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { AsyncPipe, CurrencyPipe, DatePipe, NgClass, TitleCasePipe } from '@angular/common';
import { Compras } from '../../../../model/compra.model';
import { ApiConstraintGetCompras } from '../../../../model/api.model';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { BehaviorSubject } from 'rxjs';
import { ChartHistorioVendasComprasComponent, PeriodoChartHistoricoVendaCompra, PeriodoHistoricoVendaCompra, TipoVisualizacaoChartHistoricoVendasComprasEnum } from '../../../../custom-components/chart-historio-vendas-compras/chart-historio-vendas-compras.component';
import { ChartSeriesData } from '../../../../model/custom-types';
import { DropdownComponent } from '../../../../custom-components/dropdown/dropdown.component';
import { getPeriodosChartHistoricoVendasCompras, showApiErrorMessages } from '../../../../globals/utils';
import { FormControl } from '@angular/forms';
import { DefaultPageSize, periodosChartHistoricoVendaCompra } from '../../../../globals/globals';
import { PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../../services/storage.service';
import { ToastService } from '../../../../services/toast.service';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../../../custom-components/no-results/no-results.component';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'section-compras-produto',
  standalone: true,
  imports: [
    SmallLabelComponent,
    ColorDirective,
    NgClass,
    CurrencyPipe,
    DatePipe,
    NgbAccordionModule,
    NgMatIconComponent,
    ChartHistorioVendasComprasComponent,
    DropdownComponent,
    AsyncPipe,
    OperationInProgressComponent,
    NoResultsComponent,
  ],
  providers: [TitleCasePipe],
  templateUrl: './section-compras-produto.component.html',
  styleUrl: './section-compras-produto.component.scss'
})
export class SectionComprasProdutoComponent extends BaseClass() {

  @Input() produto: Produto;

  compras: Compras;
  dadosGrafico: Array<ChartSeriesData>;
  operationChartInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  operationHistoricoInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  TipoVisualizacaoEnum = TipoVisualizacaoChartHistoricoVendasComprasEnum;

  dataInicio: Date;
  dataFim: Date;
  paginatorOpts: PaginatorOpts;

  periodosChart: Array<PeriodoChartHistoricoVendaCompra> = periodosChartHistoricoVendaCompra;
  periodosHistorico: Array<PeriodoHistoricoVendaCompra> = getPeriodosChartHistoricoVendasCompras(this.titleCasePipe);
  periodoHistoricoControl: FormControl<PeriodoHistoricoVendaCompra> = new FormControl<PeriodoHistoricoVendaCompra>(null);

  periodoSelecionado: PeriodoChartHistoricoVendaCompra = this.periodosChart[0];

  constructor(
    private titleCasePipe: TitleCasePipe,
    private compraService: CompraService,
    private storageService: StorageService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.setDates();
    this.getCompras();
    this.getComprasGrafico();
  }

  private getConstrains(): ApiConstraintGetCompras {
    return {
      DataInicio: this.dataInicio.toISOString(),
      DataFim: this.dataFim.toISOString(),
      IdProduto: this.produto.id,
    }
  }

  async getCompras() {
    try {
      this.operationHistoricoInProgress$.next(true);

      const constraints = this.getConstrains();

      const constraintsPagination: ApiConstraintGetCompras = {
        Page: this.paginatorOpts?.page,
        PageSize: this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_HISTORICO_COMPRAS') || DefaultPageSize.compras,
      };

      const res = await this.compraService.getCompras({ ...constraints, ...constraintsPagination });
      const { page, pages, pageSize, rowsCount, data } = res;
      this.compras = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar categorias', color: 'danger' });
    } finally {
      this.operationHistoricoInProgress$.next(false);
    }
  }

  getComprasGrafico() {
    this.operationChartInProgress$.next(true);

    const constraints = this.getConstrains();
    this.compraService.getDashboardCompras({ ...constraints, TipoVisualizacao: TipoVisualizacaoChartHistoricoVendasComprasEnum.periodo }).then((dadosGrafico) => {
      this.dadosGrafico = dadosGrafico;
    }).catch((err) => {
      console.error(err);
      if (!showApiErrorMessages(this.modalService, err)) this.toastService.show({ body: 'Erro ao buscar dados para o gráfico', color: 'danger' });
    }).finally(() => {
      this.operationChartInProgress$.next(false);
    });
  }

  private setDates() {
    if (this.periodoHistoricoControl.value) {
      const { mes, ano } = this.periodoHistoricoControl.value;
      this.dataFim = new Date(ano, mes, 0);
      this.dataInicio = new Date(ano, mes - 1, 1);
      return;
    }

    const hoje = new Date();
    this.dataFim = new Date(hoje);
    this.dataInicio = new Date(hoje.getFullYear(), hoje.getMonth() - (this.periodoSelecionado.mesesAntes || 0), hoje.getDate() - (this.periodoSelecionado.diasAntes || 0));

    console.log(this.dataInicio, this.dataFim);
  }

  selecionarPeriodo(periodo: PeriodoChartHistoricoVendaCompra) {
    this.operationChartInProgress$.next(true);
    this.periodoSelecionado = periodo;
    this.periodoHistoricoControl.setValue(null);
    this.setDates();
    this.getCompras();
    this.getComprasGrafico();
  }

  periodoHistoricoComprasChanged(periodo: PeriodoHistoricoVendaCompra) {
    this.periodoSelecionado = null;
    this.setDates();
    this.getCompras();
    this.getComprasGrafico();
  }

}

