import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { AsyncPipe, CurrencyPipe, DatePipe, NgClass } from '@angular/common';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../directives/color.directive';
import { DebounceDirective } from '../../../directives/debounce.directive';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ClassificacaoProdutoEnum, Produto, ProdutoNovaVenda, Produtos } from '../../../model/produto.model';
import { ModalService } from '../../../services/modal.service';
import { ModalClose, OmitId } from '../../../model/custom-types';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { defaultDebounceInput, transitionDuration } from '../../../globals/globals';
import { dateDDMMYYYYIsInvalid, displayWith, horaHHMMIsInvalid, showApiErrorMessages } from '../../../globals/utils';
import { NgxMaskDirective } from 'ngx-mask';
import { NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
import { IconInEllipseComponent } from '../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { VendaService } from '../../../services/venda.service';
import { Venda, VendaHistoricoProduto, VendaInsert } from '../../../model/venda.model';
import { format, parse } from 'date-fns';
import { ApiResponseError } from '../../../model/api.model';
import { ActionFeedbackComponent, ModalCloseActionFeedbackData } from '../../../custom-components/action-feedback/action-feedback.component';
import { ProdutoAutocompleteComponent } from '../../../custom-components/autocomplete/produto-autocomplete/produto-autocomplete.component';

type TForm = {
  produto: FormControl<Produto>;
  data: FormControl<string>;
  hora: FormControl<string>;
}

@Component({
  selector: 'produtos-venda',
  standalone: true,
  imports: [
    NgClass,
    SmallLabelComponent,
    ColorDirective,
    DebounceDirective,
    ReactiveFormsModule,
    AsyncPipe,
    NgMatIconComponent,
    DatePipe,
    CurrencyPipe,
    MatAutocompleteModule,
    NgxMaskDirective,
    NgbDatepickerModule,
    FormsModule,
    IconInEllipseComponent,
    ProdutoAutocompleteComponent,
  ],
  templateUrl: './produtos-venda.component.html',
  styleUrl: './produtos-venda.component.scss'
})
export class ProdutosVendaComponent extends BaseClass() {

  @Input({ required: true }) venda: Venda;
  @Input() produtosSelecionados: Array<ProdutoNovaVenda>;
  @Input() date: Date;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() vendaAtualizada: EventEmitter<Venda> = new EventEmitter<Venda>();

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchTerm: string;

  datetime: Date;
  total: number = 0;

  searchingProdutos: boolean = false;
  debounce: number = defaultDebounceInput;
  displayWith = (value: Produto) => displayWith(value, 'nome');

  form: FormGroup<TForm> = new FormGroup({
    produto: new FormControl({ value: null, disabled: true }),
    data: new FormControl(null),
    hora: new FormControl(null),
  });

  editDate: boolean = false;
  maxDate: NgbDateStruct = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  }

  ClassificacaoProdutoEnum = ClassificacaoProdutoEnum;

  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private vendaService: VendaService,
  ) {
    super();
  }

  ngOnInit() {
    const now = this.date ? new Date(this.date) : new Date();
    this.datetime = now;
    this.form.controls.data.setValue(format(now, 'dd/MM/yyyy'));
    this.form.controls.hora.setValue(format(now, 'HH:mm'));
    this.updateTotal();
  }

  async finalizarVenda() {
    const { data, hora } = this.form.value;

    const dataValida = !dateDDMMYYYYIsInvalid(data);
    const horaValida = !horaHHMMIsInvalid(hora);

    if (!dataValida) return this.toastService.show({ body: 'Data inválida', color: 'danger' });
    if (!horaValida) return this.toastService.show({ body: 'Hora inválida', color: 'danger' });

    const [day, month, year] = data.split('/');
    const [hour, minute] = hora.split(':');
    const date = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute));

    const venda: OmitId<VendaInsert> = {
      dataVenda: new Date(date).toISOString(),
      vendaProdutos: this.produtosSelecionados.map(p => ({
        idProduto: p.id,
        quantidade: p.quantidade,
        valorVenda: p.valorVenda
      })),
    }

    const loading = this.modalService.presentLoading("Salvando venda...");
    this.operationInProgress$.next(true);

    try {
      const res = await (this.venda?.id ? this.vendaService.updateVenda({ ...venda, id: this.venda.id }) : this.vendaService.insertVenda(venda));
      if (!res.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Ocorreu um erro ao salvar a venda, tente novamente', color: 'danger' });
        return;
      }

      if (this.venda?.id) {
        this.toastService.show({ body: 'Venda atualizada com sucesso', color: 'success' });
        this.vendaAtualizada.emit(res.data);
        return;
      }

      this.produtosSelecionados = null;
      // this.datetime = new Date();
      // this.form.controls.data.setValue(format(this.datetime, 'dd/MM/yyyy'));
      // this.form.controls.hora.setValue(format(this.datetime, 'HH:mm'));
      this.total = 0;
      const dataModal: ModalCloseActionFeedbackData = {
        title: "Nova venda registrada com sucesso!",
        color: 'verde',
        icon: 'verified',
      }
      this.modalService.presentModal(ActionFeedbackComponent, "slide-bottom", dataModal, { keyboard: false, backdrop: 'static' });
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Ocorreu um erro ao salvar a venda, tente novamente', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
      loading.dismiss();
    }
  }

  limparLista() {
    const confirm = this.modalService.presentConfirm("Limpar lista", "Deseja realmente limpar a lista de produtos selecionados?");
    confirm.closed.subscribe({
      next: (res: ModalClose<boolean>) => {
        if (!res.data) return;

        this.produtosSelecionados = null;
      }
    });
  }

  async cancelarEdicao() {
    // const confirm = this.modalService.presentConfirm("Cancelar edição", "Deseja realmente cancelar a edição da venda?");
    // const result: ModalClose<boolean> = await confirm.result;
    // if (!result.data) return;

    this.cancel.emit();
  }

  produtoSelecionado(produto: Produto) {
    const idsSelecionados = this.produtosSelecionados?.map(p => p.id) || [];
    if (idsSelecionados.includes(produto.id)) {
      const produtoCardEl = document.querySelector(`#p-${produto.id}.produto-selecionado`);
      produtoCardEl?.classList.add('duplicated');
      setTimeout(() => produtoCardEl?.classList.remove('duplicated'), transitionDuration * 4);
      return this.toastService.show({ body: 'Produto já adicionado', color: 'danger' });
    }

    this.produtosSelecionados = [this.produtosSelecionados || [], { ...produto, quantidade: 1 }].flat();
    this.form.controls.produto.setValue(null, { emitEvent: false });
    this.searchTerm = null;
    this.updateTotal();
  }

  private updateTotal() {
    this.total = this.produtosSelecionados?.reduce((acc, p) => acc + (p.valorVenda || 0) * (p.quantidade || 1), 0) || 0;
  }

  atualizarDatetime() {
    const { data, hora } = this.form.value;
    if (!data || !hora) return this.toastService.show({ body: 'Data e hora são obrigatórios', color: 'danger' });
    if (dateDDMMYYYYIsInvalid(data)) return this.toastService.show({ body: 'Data inválida', color: 'danger' });
    if (horaHHMMIsInvalid(hora)) return this.toastService.show({ body: 'Hora inválida', color: 'danger' });

    const [day, month, year] = data.split('/');
    const [hour, minute] = hora.split(':');
    this.datetime = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute));
    this.editDate = false;
  }

  private setDate(date: Date) {
    const hora = this.datetime.getHours();
    const minuto = this.datetime.getMinutes();
    const ano = date.getFullYear();
    const mes = date.getMonth();
    const dia = date.getDate();

    this.datetime = new Date(ano, mes, dia, hora, minuto, 0);
  }

  dateSelected(inputEl: HTMLInputElement) {
    inputEl.value = this.form.controls.data.value;
    const date = parse(this.form.controls.data.value, 'dd/MM/yyyy', new Date());
    this.setDate(date);
  }

  focusOutInputDate(el: HTMLInputElement) {
    if (el.value?.length === 10 && dateDDMMYYYYIsInvalid(el.value)) {
      el.value = null;
      this.form.controls.data.setValue(null);
      this.toastService.show({ body: 'Data inválida', color: "danger" });
      return;
    }
    const date = parse(el.value, 'dd/MM/yyyy', new Date());
    this.setDate(date);
  }

  focusOutInputHora(el: HTMLInputElement) {
    if (el.value?.length === 5 && horaHHMMIsInvalid(el.value)) {
      el.value = null;
      this.form.controls.hora.setValue(null);
      this.toastService.show({ body: 'Hora inválida', color: "danger" });
    }
  }

  inputOnData(inputEl: HTMLInputElement) {
    const timeInvalid = horaHHMMIsInvalid(inputEl.value)?.timeInvalid;
    if (timeInvalid) return;

    const time = parse(inputEl.value, 'HH:mm', new Date());

    const hora = time.getHours();
    const minuto = time.getMinutes();
    const ano = this.datetime.getFullYear();
    const mes = this.datetime.getMonth();
    const dia = this.datetime.getDate();
    this.datetime = new Date(ano, mes, dia, hora, minuto, 0);
  }

  quantidadeProdutoAdd(index: number) {
    this.produtosSelecionados[index].quantidade++;
    this.updateTotal();
  }

  quantidadeProdutoSub(index: number) {
    if (this.produtosSelecionados[index].quantidade === 1) return;
    this.produtosSelecionados[index].quantidade--;
    this.updateTotal();
  }

  focusOutInputQuantidadeProduto(index: number, el: HTMLInputElement) {
    this.produtosSelecionados[index].quantidade = (!el.value || isNaN(Number(el.value))) ? 1 : Number(el.value);
    this.updateTotal();
  }

  removerProduto(index: number) {
    this.produtosSelecionados.splice(index, 1);
    this.updateTotal();
  }
}
