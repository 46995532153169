import { Component, Input, TemplateRef } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { PageApp } from '../app-pages';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { Usuario } from '../../../model/usuario.model';
import { Menu } from '../../../model/menu.model';
import { CreditosButtonComponent } from '../../../custom-components/creditos-button/creditos-button.component';
import { SugestaoPrecoComponent } from '../../../custom-components/sugestao-preco/sugestao-preco.component';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'toolbar',
  standalone: true,
  imports: [
    NgbDropdownModule,
    NgClass,
    DatePipe,
    AsyncPipe,
    IconComponent,
    CreditosButtonComponent,
    NgbPopoverModule,
    SugestaoPrecoComponent,
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent extends BaseClass() {

  @Input({ required: true }) currentPage: Partial<PageApp>;
  @Input({ required: true }) usuario: Partial<Usuario>;
  @Input({ required: true }) menu: Menu;

  constructor(
    private modalService: ModalService,
  ) {
    super();
  }

  showModalSugestaoPreco() {
    this.modalService.presentModal(SugestaoPrecoComponent, 'slide-right', { textCloseButton: 'Ok' }, {
      windowClass: 'modal-sugestao-preco',
      backdropClass: 'opacity-0'
    });
  }
}
