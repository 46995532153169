import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: 'produtos-wrapper',
  imports: [
    RouterOutlet,
  ],
  standalone: true,
  templateUrl: './produtos-wrapper.component.html',
})
export class ProdutoWrapperComponent {}
