import { Component, Input } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import { Produto, TipoProdutoEnum } from '../../../../model/produto.model';
import { ColorDirective } from '../../../../directives/color.directive';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'section-medidas-produto',
  standalone: true,
  imports: [
    ColorDirective,
    SmallLabelComponent,
    NgTemplateOutlet,
  ],
  templateUrl: './section-medidas-produto.component.html',
  styleUrl: './section-medidas-produto.component.scss'
})
export class SectionMedidasProdutoComponent extends BaseClass() {

  @Input() produto: Produto;

  TipoProduto = TipoProdutoEnum;

}
