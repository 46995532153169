import { Component, Input } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import { ClassificacaoProdutoEnum, Produto } from '../../../../model/produto.model';
import { ColorDirective } from '../../../../directives/color.directive';
import { IconInEllipseComponent } from '../../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { SmallLabelComponent } from "../../../../custom-components/small-label/small-label.component";
import { CurrencyPipe, NgClass } from '@angular/common';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';

@Component({
  selector: 'section-detalhes-produto',
  standalone: true,
  imports: [
    ColorDirective,
    IconInEllipseComponent,
    SmallLabelComponent,
    CurrencyPipe,
    ColorTipoProdutoPipe,
    NgMatIconComponent,
    NgClass,
  ],
  templateUrl: './section-detalhes-produto.component.html',
  styleUrl: './section-detalhes-produto.component.scss'
})
export class SectionDetalhesProdutoComponent extends BaseClass() {

  @Input({ required: true }) produto: Produto;

  ClassificacaoProduto = ClassificacaoProdutoEnum;

}

