<div id="container" class="d-flex gap-5 pt-4">
  <div class="d-flex flex-column gap-4">
    <h2 class="fw-bolder" color="dark">Quando for vender, usarei as seguintes medidas</h2>

    @if (produto.tipoProduto === TipoProduto.insumo) {
      <div class="d-flex flex-column gap-2">
        <small-label> Medidas/Unidade de compra </small-label>
        <span class="fw-600" color="titulo">{{ produto.nomeUnidadeMedidaCompra || "-" }}</span>
      </div>
    }

    <div class="d-flex flex-column gap-2">
      <small-label> Medidas/Unidades de venda e uso </small-label>
      @if ((produto.tipoProduto === TipoProduto.insumo && !produto.unidadesMedidaEquivalentes?.length) || (produto.tipoProduto !== TipoProduto.insumo && !produto.unidadesMedidaRendimentos?.length)) {
        <span class="fw-600" color="titulo"> Nenhuma medida cadastrada </span>
      }
      @for (medida of produto.tipoProduto === TipoProduto.insumo ? produto.unidadesMedidaEquivalentes : produto.unidadesMedidaRendimentos; track $index) {
        <span class="fw-600" color="titulo">
          @if (produto.tipoProduto === TipoProduto.composicao) {
            <ng-container *ngTemplateOutlet="unidadeMedidaRendimentoQtde; context: { $implicit: medida }" />
            x
          }
          {{ medida.nomeUnidadeMedida }}
        </span>
      }
    </div>

    @if (produto.tipoProduto === TipoProduto.insumo && produto.unidadesMedidaEquivalentes?.length) {
      <div class="d-flex flex-column gap-2">
        <small-label> Conversões </small-label>
        @for (medida of produto.unidadesMedidaEquivalentes; track $index) {
          <span class="fw-600" color="titulo">1 {{ produto.nomeUnidadeMedidaCompra }} = {{ medida.quantidadeEquivalente }} {{ medida.nomeUnidadeMedida }} </span>
        }
      </div>
    }
  </div>

  <ng-template #unidadeMedidaRendimentoQtde let-medida> {{ medida.quantidadeRendimento }} </ng-template>
</div>
