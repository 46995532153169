<div id="container" class="position-relative">
  <div id="content" class="p-4 d-flex flex-column gap-4 rounded-4 flex-grow-1" [ngClass]="{ 'align-items-center': isDesktop }">
    <h2 class="fw-bold" color="dark">Algumas informações sobre o seu negócio</h2>
    <small-label class="small justify-content-center" color="roxo"> Através desses dados, conseguimos orientar melhor sua experiência dentro da nossa solução. </small-label>

    <div id="form" [formGroup]="form" class="gap-4" [ngClass]="{ 'd-grid': !data.apenasDadosFinanceiros, 'd-flex flex-column w-100': data.apenasDadosFinanceiros }">
      @if (!data.apenasDadosFinanceiros) {
        <div class="form-group d-flex flex-column gap-2 flex-grow-1">
          <small-label class="required"> Nome da empresa </small-label>
          <input type="text" class="form-control lh-2" placeholder="Digite o nome da sua empresa" formControlName="nomeEmpresa" />
        </div>

        <dropdown
          label="Ramo de negócio"
          placeholder="Selecione o ramo de negócio"
          iconColor="titulo"
          [inline]="false"
          [classes]="['lh-2']"
          [options]="ramosNegocio"
          [displayField]="'descricao'"
          [control]="form.controls.ramoNegocio"
        />

        <div id="data-criacao" class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
          <small-label> Em que data a empresa foi criada? </small-label>
          <div class="input-group">
            <div class="input-group-text" color="titulo">
              <ng-mat-icon name="calendar_month" />
            </div>

            <input [readonly]="true" formControlName="dataCriacao" [maxDate]="now" ngbDatepicker #dataCriacaoDP="ngbDatepicker" id="input-dp-data-criacao" (dateSelect)="mainInput.value = form.controls.dataCriacao.value" />
            <input
              #mainInput
              mask="00/00/0000"
              [dropSpecialCharacters]="false"
              class="form-control date lh-2"
              placeholder="22/08/2024"
              formControlName="dataCriacao"
              (click)="dataCriacaoDP.toggle()"
              (blur)="focusOutInputDate(mainInput)"
              (ngModelChange)="dataCriacaoDP.writeValue(form.controls.dataCriacao.value)"
            />
          </div>
        </div>
      }

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Qual o seu custo fixo </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <span class="fw-bold"> R$ </span>
          </div>
          <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="custoFixo" />
        </div>
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Qual o valor das suas despesas de venda? </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <span class="fw-bold"> R$ </span>
          </div>
          <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="despesasVenda" />
        </div>
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Qual a margem de lucro desejada? </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <span class="fw-bold"> % </span>
          </div>
          <input mask="percent" suffix="%" type="text" class="form-control lh-2" placeholder="Ex: 10%" formControlName="percentualMargemLucro" />
        </div>
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Qual o seu faturamento médio? </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <span class="fw-bold"> R$ </span>
          </div>
          <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="faturamentoMedio" />
        </div>
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Qual multiplicador você utiliza para precificar seus produtos? </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <ng-mat-icon name="close" color="white" />
          </div>
          <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 1,5" formControlName="multiplicadorPreco" />
        </div>
      </div>
    </div>
  </div>

  <div id="footer" class="d-flex w-100 rounded-4 p-4 gap-4" [ngClass]="{ 'justify-content-center': isDesktop, 'position-absolute left-0 bottom-0': !isDesktop }">
    <button class="btn" color="apoio" (click)="dismiss()" [ngClass]="{ 'px-5': isDesktop, 'flex-grow-1': !isDesktop }">
      <span [class.px-5]="isDesktop"> Cancelar </span>
    </button>

    <button class="btn" color="titulo" (click)="salvar()" [ngClass]="{ 'px-5': isDesktop, 'flex-grow-1': !isDesktop }">
      <span [class.px-5]="isDesktop"> Salvar </span>
    </button>
  </div>
</div>
