<div [color]="color" id="container" class="modal-feedback-action gap-4 h-100 d-flex flex-column align-items-center justify-content-center">
  @if (icon) {
    <ng-mat-icon [name]="icon" />
  }

  @if (title) {
    <h3 class="text-center">{{ title }}</h3>
  }

  @if (message) {
    <p class="text-center">{{ message }}</p>
  }

  @if (textCloseButton) {
    <button class="btn" [color]="colorCloseButton" (click)="dismiss()">{{ textCloseButton }}</button>
  }
</div>
