import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { ApiConstraintGetHistoricoVendas, ApiConstraintGetVendas, ApiResponseGetHistoricoVendaProduto } from '../model/api.model';
import { makeParamsGet } from '../globals/utils';
import { environment } from '../../environments/environment.dev';
import { ProdutoVenda, Venda, VendaInsert } from '../model/venda.model';
import { updateSession } from '../home/home.component';

@Injectable({
  providedIn: 'root'
})
export class VendaService extends BaseHttpService {

  getVendas(constraints: ApiConstraintGetVendas = null) {
    const params = makeParamsGet(constraints, ['IdProduto', 'DataInicio', 'DataFim', 'TipoVisualizacao']);
    return this.get<Venda>(environment.api.endpoints.vendas, params);
  }

  getVendaById(id: string) {
    return this.getById<Venda>(`${environment.api.endpoints.vendas}`, id);
  }

  getProdutosVenda(id: string) {
    return this.get<ProdutoVenda>(environment.api.endpoints.produtosvenda.replace('{id}', id));
  }

  getHistoricoVendas(constraints: ApiConstraintGetHistoricoVendas = null) {
    const params = makeParamsGet(constraints, ['DataFim', 'DataInicio', 'IdProduto', 'TipoVisualizacao']);
    return this.get<Venda>(environment.api.endpoints.vendashistorico, params) as Promise<ApiResponseGetHistoricoVendaProduto>;
  }

  getDashboardVendas(constraints: ApiConstraintGetHistoricoVendas = null) {
    const params = makeParamsGet(constraints, ['DataFim', 'DataInicio', 'IdProduto', 'TipoVisualizacao']);
    return this.get<{ eixoX: string, eixoY: number }>(environment.api.endpoints.vendasdashboard, params) as Promise<Array<{ eixoX: string, eixoY: number }>>;
  }

  async insertVenda(venda: Partial<VendaInsert>) {
    const res = await this.post<Venda>(environment.api.endpoints.vendas, venda);
    if (res.success) updateSession.emit();
    return res;
  }

  updateVenda(venda: Partial<VendaInsert>) {
    return this.put<VendaInsert>(`${environment.api.endpoints.vendas}/${venda.id}`, venda);
  }

  deleteVenda(id: string) {
    return this.delete<Venda>(environment.api.endpoints.vendas, id);
  }


}
