<div id="form" class="d-grid row-gap-4 pb-4 gap-4" [ngClass]="{ 'grid-two-columns column-gap-3': isDesktop }" [formGroup]="form">
  <div id="valor-compra" class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <small-label> Por qual valor foi comprado? </small-label>

    <div class="input-group">
      <div class="input-group-text" [color]="form.controls.tipo.value | colorTipoProduto">
        <span class="fw-bold"> R$ </span>
      </div>
      <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="valorUltimaCompra" />
    </div>
  </div>

  <div id="data-compra" class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <small-label> Em que data a compra foi feita? </small-label>
    <div class="input-group">
      <div class="input-group-text" [color]="form.controls.tipo.value | colorTipoProduto">
        <ng-mat-icon name="calendar_month" />
      </div>

      <input
        [readonly]="true"
        formControlName="dataUltimaCompra"
        [maxDate]="maxDate"
        ngbDatepicker
        #ultimaCompraDP="ngbDatepicker"
        id="input-dp-data-ultima-compra"
        (dateSelect)="mainInput.value = form.controls.dataUltimaCompra.value"
      />
      <input
        #mainInput
        mask="00/00/0000"
        [dropSpecialCharacters]="false"
        class="form-control date"
        placeholder="22/08/2024"
        formControlName="dataUltimaCompra"
        (click)="ultimaCompraDP.toggle()"
        (blur)="focusOutInputDate(mainInput)"
        (ngModelChange)="ultimaCompraDP.writeValue(form.controls.dataUltimaCompra.value)"
      />

      <button class="btn btn-outline" color="neutro" (click)="ultimaCompraDP.toggle()" type="button">
        <ng-mat-icon name="edit_calendar" [color]="form.controls.tipo.value | colorTipoProduto" />
      </button>
    </div>
  </div>

  <div id="quantidade-comprada" class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <small-label> Qual a quantidade comprada? </small-label>

    <div class="input-group input-icon-end">
      <div class="input-group-text">
        <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="10" formControlName="quantidadeUnidadeMedidaUltimaCompra" />
      </div>
      <unidade-medida-autocomplete class="flex-grow-1" [control]="form.controls.unidadeMedidaUltimaCompra" [tipoProduto]="form.controls.tipo.value" />
    </div>
  </div>

  <div id="marca" class="form-group w-100 d-flex flex-column gap-2">
    <small-label> Qual foi a marca comprada? </small-label>
    <input type="text" class="form-control lh-2" placeholder="Ex: Nestlé, Dona Benta" formControlName="marcaUltimaCompra" />
  </div>

  <div id="local-compra" class="form-group w-100 d-flex flex-column gap-2">
    <small-label> Onde esse produto foi comprado? </small-label>
    <input type="text" class="form-control lh-2" placeholder="Ex: Supermercado Carrefour" formControlName="localUltimaCompra" />
  </div>

  @if (showTagCalculoCusto) {
    <div id="checkbox" class="form-check d-flex align-items-center justify-content-center w-100 gap-3 ps-0">
      <input id="mostrar-tag-calculo-custo" type="checkbox" class="form-check-input my-0" formControlName="tagCalculoCusto" />
      <label class="fw-400 form-check-label cursor-pointer no-selection" for="mostrar-tag-calculo-custo"> Usar para cálculo de custo </label>
    </div>
  }
</div>
