import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { ApiConstraintGetProdutos, ApiConstraintGetProdutosComposicao, ApiResponseGetProdutos } from '../model/api.model';
import { makeParamsGet } from '../globals/utils';
import { environment } from '../../environments/environment.dev';
import {
  ComposicaoInsert,
  InsumoInsert,
  Produto,
  ProdutoCommonInsert,
  ProdutoComposicao,
  ProdutoComposicaoInsert,
  ResponseInsertProdutoComposicao,
  TipoProdutoEnum,
} from '../model/produto.model';
import { UnidadeMedida } from '../model/unidade-medida.model';
import { updateSession } from '../home/home.component';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService extends BaseHttpService {

  getProdutos(constraints: ApiConstraintGetProdutos = null) {
    const params = makeParamsGet(constraints, ['IdCategoria', 'IdEmpresa', 'Classificacao', 'TipoProduto', 'TagCadastroCompleto']);
    return this.get<ApiResponseGetProdutos>(environment.api.endpoints.produtos, params);
  }

  getProdutoById(id: string) {
    return this.getById<Produto>(environment.api.endpoints.produtos, id);
  }

  getProdutosComposicao(idProduto: string, constraints: ApiConstraintGetProdutosComposicao = null) {
    const params = makeParamsGet(constraints);
    return this.get<ProdutoComposicao>(environment.api.endpoints.composicoes.replace('{idProduto}', idProduto), params);
  }

  getUnidadesMedidaComposicao(idProduto: string) {
    return this.get<UnidadeMedida>(environment.api.endpoints.unidadesMedidaComposicao.replace('{idProduto}', idProduto));
  }

  async calcularPrecoIdeal(idProduto: string) {
    const res = await this.post<number>(environment.api.endpoints.calcularPrecoIdeal.replace('{idProduto}', idProduto), null);
    if (res.success) updateSession.emit();
    return res;
  }

  async insertProduto<T = TipoProdutoEnum>(produto: Partial<T extends TipoProdutoEnum ? InsumoInsert : ComposicaoInsert>) {
    const res = await this.post<Produto>(environment.api.endpoints.produtos, produto);
    if (res.success) updateSession.emit();
    return res;
  }

  insertProdutoComposicao(produto: ProdutoComposicaoInsert) {
    return this.post<ResponseInsertProdutoComposicao>(environment.api.endpoints.composicoes.replace('{idProduto}', produto.idProdutoComposto), produto);
  }

  updateProduto(produto: Partial<ProdutoCommonInsert>) {
    return this.put<Produto>(environment.api.endpoints.produtos + '/' + produto.id, produto);
  }

  updateProdutoComposicao(produto: ProdutoComposicaoInsert & { id: string }) {
    return this.put<ProdutoComposicao>(environment.api.endpoints.composicoes.replace('{idProduto}', produto.idProdutoComposto), produto);
  }

  deleteProduto(id: string) {
    return this.delete<Produto>(environment.api.endpoints.produtos, id);
  }

  deleteProdutoComposicao(idProduto: string, id: string) {
    return this.delete(environment.api.endpoints.composicoes.replace('{idProduto}', idProduto), id);
  }

}
