import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';
import { BaseClass } from '../../globals/base-class';

@Component({
  selector: 'button-group',
  standalone: true,
  imports: [ColorDirective],
  templateUrl: './button-group.component.html',
  styleUrl: './button-group.component.scss'
})
export class ButtonGroupComponent<T> extends BaseClass() {

  @Input({ required: true }) buttons: ButtonGroup<T>;

  @Input() color: AppColor = 'primary';
  @Input() disabled: boolean = false;
  @Input() value: T;

  @Output() activeButtonChange: EventEmitter<OptionButtonGroup<T>> = new EventEmitter();


  ngOnInit() {
    this.value = this.value ?? this.buttons[0].value;
  }

  activateButton(button: OptionButtonGroup<T>) {
    if (this.disabled || button.disabled) return;
    if (button.value === this.value) return;
    this.value = button.value;
    this.activeButtonChange.emit(button);
  }
}

export type OptionButtonGroup<Value = string> = {
  label: string;
  value: Value;
  disabled?: boolean;
}

export type ButtonGroup<T> = Array<OptionButtonGroup<T>>;
