<div id="container" class="rounded-3 p-3">
  <div id="content" class="h-100 rounded-4 p-4 d-flex flex-column gap-3">
    <segments [isNavigation]="false" [segments]="segments" color="titulo" [activeSegment]="activeSegment" (onSegmentChange)="activeSegment = $event" />

    <h4 color="titulo" class="fw-bold">{{ activeSegment.title }}</h4>

    <div id="body" class="d-grid" [ngClass]="{ 'gap-5': isDesktop, 'gap-3': !isDesktop }">
      @switch (activeSegment.path[0]) {
        @case ("suas-info") {
          <div class="form-group d-flex flex-column gap-2">
            <small-label color="cinza"> Nome do empreendedor </small-label>
            @if (!usuario) {
              <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
            } @else {
              @if (usuario?.nome) {
                <small-label color="titulo" class="fw-bold"> {{ usuario.nome }} </small-label>
              } @else {
                <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
              }
            }
          </div>

          <div class="form-group d-flex flex-column gap-2">
            <small-label color="cinza"> Telefones </small-label>
            @if (!usuario) {
              <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
            } @else {
              @if (usuario?.telefone) {
                <small-label color="titulo" class="fw-bold"> {{ usuario.telefone }} </small-label>
              } @else {
                <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
              }
            }
          </div>

          <div class="form-group d-flex flex-column gap-2">
            <small-label color="cinza"> Redes sociais (site, Instagram, etc) </small-label>
            @if (!usuario) {
              <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
            } @else {
              @if (usuario?.redeSocial) {
                <small-label color="titulo" class="fw-bold"> {{ usuario?.redeSocial }} </small-label>
              } @else {
                <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
              }
            }
          </div>
        }
        @case ("dados-login") {
          <div class="form-group d-flex flex-column gap-2">
            <small-label color="cinza"> Email </small-label>
            @if (!usuario) {
              <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
            } @else {
              @if (usuario?.email) {
                <small-label color="titulo" class="fw-bold"> {{ usuario.email }} </small-label>
              } @else {
                <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
              }
            }
          </div>

          <div class="form-group d-flex flex-column gap-2">
            <small-label color="cinza"> Senha </small-label>
            @if (!usuario) {
              <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
            } @else {
              <small-label color="titulo" class="fw-bold">
                @for (i of minLengthPassword; track $index) {
                  *
                }
              </small-label>
            }
          </div>
        }
      }
    </div>

    @if (usuario) {
      <div id="actions" class="d-flex gap-5 mt-5">
        <button class="btn px-5" color="titulo" [ngClass]="{ 'w-100': !isDesktop }" (click)="edit()">
          <span class="mx-5"> {{ activeSegment.path[0] === "suas-info" ? "Editar" : "Alterar senha" }} </span>
        </button>
      </div>
    }
  </div>
</div>
