import { Component, Input } from '@angular/core';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TFormCriarConta } from '../criar-conta.component';
import { ColorDirective } from '../../../directives/color.directive';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'section-dados-pessoais',
  standalone: true,
  imports: [
    SmallLabelComponent,
    NgMatIconComponent,
    ReactiveFormsModule,
    ColorDirective,
    NgxMaskDirective,
  ],
  templateUrl: './section-dados-pessoais.component.html',
  styleUrl: './section-dados-pessoais.component.scss'
})
export class SectionDadosPessoaisComponent {

  @Input() form: FormGroup<TFormCriarConta>;

}
