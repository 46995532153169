<div id="container" class="d-flex gap-5 pt-4" [ngClass]="{ 'flex-column': !isDesktop }">
  <div id="left" class="p-2 pe-4">
    <div class="d-flex flex-column gap-4">
      <div class="form-group d-flex flex-column gap-2" id="e-vendido">
        <small-label> Esse {{ formEdit.controls.tipo.value === TipoProduto.insumo ? "produto" : "composição" }} está</small-label>

        <div class="btn-group" role="group">
          <button class="btn" [color]="formEdit.controls.tipo.value | colorTipoProduto" [class]="'btn-' + (formEdit.controls.ativo?.value === true ? '' : 'outline')" (click)="setAtivoProduto(true)">Ativo</button>
          <button class="btn" [color]="formEdit.controls.tipo.value | colorTipoProduto" [class]="'btn-' + (formEdit.controls.ativo?.value === false ? '' : 'outline')" (click)="setAtivoProduto(false)">Inativo</button>
        </div>
      </div>

      <div id="selecao-foto" class="d-flex flex-column gap-4">
        <small-label> Foto do produto </small-label>
        <button-select-imagem-produto [imagemControl]="formEdit.controls.imagem" [imagemFileControl]="formEdit.controls.imagemFile" [tipoProduto]="formEdit.controls.tipo.value" />
      </div>
    </div>
  </div>

  <div id="right" class="p-2 ps-4 flex-grow-1">
    <div class="d-flex flex-column gap-4" [formGroup]="formEdit">
      <div [ngClass]="{ 'd-contents': !isDesktop, 'd-grid grid-two-columns gap-4': isDesktop }">
        <div class="form-group d-flex flex-column gap-2">
          <small-label> Código de barras </small-label>
          <input type="text" class="form-control lh-2" id="codigo-barras" placeholder="Insira o código de barras" formControlName="codigoBarras" />
        </div>

        <div class="form-group d-flex flex-column gap-2">
          <small-label> Nome do Insumo </small-label>
          <input type="text" class="form-control lh-2" id="nome" placeholder="Insira o nome" formControlName="nome" />
        </div>
      </div>

      <div [ngClass]="{ 'd-contents': !isDesktop, 'd-flex gap-4': isDesktop }">
        @if (formEdit.controls.classificacao.value === ClassificacaoProduto.vendido) {
          <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag valor-venda">
            <small-label> Qual o valor de venda? </small-label>

            <div class="input-group">
              <div class="input-group-text" [color]="formEdit.controls.tipo.value | colorTipoProduto">
                <span class="fw-bold"> R$ </span>
              </div>
              <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="valorVenda" />
            </div>
          </div>
        }

        <div class="d-flex gap-3 w-100">
          <div class="form-group col px-0 d-flex flex-column gap-2">
            <small-label> Categoria </small-label>

            <categoria-autocomplete [control]="formEdit.controls.categoria" [tipoProduto]="formEdit?.controls.tipo.value" />
          </div>
        </div>
      </div>

      <div class="form-group d-flex flex-column gap-2" [ngClass]="{ 'w-fit-content': isDesktop }" id="e-vendido">
        <small-label> Esse {{ formEdit.controls.tipo.value === TipoProduto.insumo ? "produto" : "composição" }} vai ser vendido?</small-label>

        <div class="btn-group" role="group">
          <button
            type="button"
            class="btn px-5"
            [color]="formEdit.controls.tipo.value | colorTipoProduto"
            [class]="'btn-' + (formEdit.controls.classificacao?.value === ClassificacaoProduto.vendido ? '' : 'outline')"
            (click)="setClassificacaoProduto(ClassificacaoProduto.vendido)"
          >
            Sim
          </button>
          <button
            type="button"
            class="btn px-5"
            [color]="formEdit.controls.tipo.value | colorTipoProduto"
            [class]="'btn-' + (formEdit.controls.classificacao?.value === ClassificacaoProduto.naoVendido ? '' : 'outline')"
            (click)="setClassificacaoProduto(ClassificacaoProduto.naoVendido)"
          >
            Não
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
