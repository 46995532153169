import { Component } from '@angular/core';
import { ColorDirective } from '../../directives/color.directive';
import { SmallLabelComponent } from '../../custom-components/small-label/small-label.component';
import { BaseClass } from '../../globals/base-class';
import { EmpresaService } from '../../services/empresa.service';
import { StorageService } from '../../services/storage.service';
import { Empresa } from '../../model/empresa.model';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../custom-components/no-results/no-results.component';
import { CurrencyPipe, NgClass, PercentPipe } from '@angular/common';
import { EditarMeuNegocioComponent } from './editar-meu-negocio/editar-meu-negocio.component';
import { ModalService } from '../../services/modal.service';
import { defaultClassModal, showApiErrorMessages } from '../../globals/utils';
import { ModalClose } from '../../model/custom-types';
import { TimeDiffPipe } from '../../pipes/time-diff.pipe';
import { ApiResponseError } from '../../model/api.model';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-meu-negocio',
  standalone: true,
  imports: [
    ColorDirective,
    SmallLabelComponent,
    OperationInProgressComponent,
    NoResultsComponent,
    CurrencyPipe,
    PercentPipe,
    NgClass,
    EditarMeuNegocioComponent,
    TimeDiffPipe,
  ],
  templateUrl: './meu-negocio.component.html',
  styleUrl: './meu-negocio.component.scss'
})
export class MeuNegocioComponent extends BaseClass() {

  empresa: Empresa;

  constructor(
    private empresaService: EmpresaService,
    private storageService: StorageService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getDadosNegocio();
  }

  getDadosNegocio() {
    const session = this.storageService.getSession();
    this.empresaService.getEmpresaById(session.empresa).then((res) => {
      if (!res) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Erro ao buscar dados do negócio', color: 'danger' });
        return;
      }
      this.empresa = res;
    }).catch((err) => {
      if (!showApiErrorMessages(this.modalService, err as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Erro ao buscar dados do negócio', color: 'danger' });
      console.log(err);
    })
  }

  async edit() {
    const dataModal = { empresa: this.empresa }
    const modal = this.modalService.presentModal(EditarMeuNegocioComponent, defaultClassModal(this), dataModal, { windowClass: 'modal-fullscreen' });
    const result: ModalClose<boolean> = await modal.result;
    if (result?.reason === "saved") this.getDadosNegocio();
  }


}
