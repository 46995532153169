<div id="dropdown" ngbDropdown class="d-flex" [ngClass]="{ 'flex-column': !inline, 'w-100 justify-content-between': !isDesktop, 'align-items-center': inline, 'gap-2': !inline, 'gap-4': inline && label }">
  <small-label> {{ label }} </small-label>
  <div>
    <button [disabled]="isDisabled" type="button" class="btn dropdown w-100 d-flex justify-content-between" [class.btn-outline-dark]="isDesktop" id="dropdownTrigger" ngbDropdownToggle [class]="classes">
      @if (control?.value) {
        <span class="button-value text-ellipsis">{{ displayField ? control?.value?.[displayField] : control?.value }}</span>
      } @else {
        <span class="button-placeholder text-ellipsis">{{ placeholder }}</span>
      }
      <ng-mat-icon [name]="icon" [color]="iconColor" [classes]="'ms-2'" />
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownTrigger" class="options-list">
      @for (option of options; track $index) {
        <button ngbDropdownItem (click)="isDisabled ? null : selectOption(option)">{{ displayField ? option[displayField] : option }}</button>
      }
      @if (!options?.length) {
        <button ngbDropdownItem disabled>Nenhum item disponível</button>
      }
    </div>
  </div>
</div>
