import { Component, Inject, Input } from '@angular/core';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { ColorTipoProdutoPipe } from '../../directives/color-tipo-produto.pipe';
import { ColorDirective } from '../../directives/color.directive';
import { TFormProdutoValue } from '../../home/produtos/novo-produto/novo-produto.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';

@Component({
  selector: 'sugestao-preco',
  standalone: true,
  imports: [
    SmallLabelComponent,
    ColorDirective,
    ColorTipoProdutoPipe,
    NgClass,
  ],
  templateUrl: './sugestao-preco.component.html',
  styleUrl: './sugestao-preco.component.scss'
})
export class SugestaoPrecoComponent {

  @Input() produto: TFormProdutoValue;
  textCloseButton: string;
  modalCloseRequested: boolean = false;

  constructor(
    @Inject("data") protected data: { textCloseButton: string },
    private ngbActiveModal: NgbActiveModal,
  ) {
    if (this.data?.textCloseButton) {
      this.textCloseButton = this.data.textCloseButton;
      setTimeout(() => this.close(), 5000);
    }
  }

  close() {
    if (this.modalCloseRequested) return;
    this.modalCloseRequested = true;
    this.ngbActiveModal.close();
  }

}
