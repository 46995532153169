import { Component, EventEmitter } from '@angular/core';
import { Location, NgClass } from '@angular/common';
import { BaseClass } from '../../globals/base-class';
import { ProdutosPendentesComponent } from './produtos-pendentes/produtos-pendentes.component';
import { NfesPendentesComponent } from './nfes-pendentes/nfes-pendentes.component';
import { ClassificacaoProdutoEnum, Produto, TipoProdutoEnum } from '../../model/produto.model';
import { ModalService } from '../../services/modal.service';
import { DataModalNovoProduto, NovoProdutoComponent, SectionNovoProduto } from '../produtos/novo-produto/novo-produto.component';
import { updateUrl } from '../../globals/utils';
import { ModalClose, ModalCloseReason } from '../../model/custom-types';
import { ProdutoService } from '../../services/produto.service';
import { ApiResponseGetProdutos } from '../../model/api.model';

@Component({
  selector: 'app-pendencias',
  standalone: true,
  imports: [
    NgClass,
    ProdutosPendentesComponent,
    NfesPendentesComponent,
  ],
  templateUrl: './pendencias.component.html',
  styleUrl: './pendencias.component.scss'
})
export class PendenciasComponent extends BaseClass() {

  refreshListProdutos$: EventEmitter<void> = new EventEmitter<void>();
  refreshListNfes$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: ModalService,
    private produtoService: ProdutoService,
    private location: Location,
  ) {
    super();
  }

  async editProduto(_produto: Produto | ApiResponseGetProdutos) {
    const loading = this.modalService.presentLoading('Carregando produto...');

    try {
      const produto = await this.produtoService.getProdutoById(_produto.id);
      const section: SectionNovoProduto = 'detalhes';
      const evendido: boolean = produto.classificacao === ClassificacaoProdutoEnum.vendido;

      updateUrl(this.location, {
        produtoID: produto.id,
        section,
        action: 'editar',
        evendido,
      });

      const data: DataModalNovoProduto = {
        TipoProduto: produto.tipoProduto,
        Classificacao: produto.classificacao,
        section,
        evendido,
        produto,
      };
      const modal = this.modalService.presentModal(NovoProdutoComponent, "slide-bottom", data, { keyboard: false, windowClass: 'modal-full-content', backdrop: false })
      modal.closed.subscribe({
        next: (res: ModalClose<Produto, 'produto'>) => {
          produto.tipoProduto === TipoProdutoEnum.composicao ? this.refreshListNfes$.emit() : this.refreshListProdutos$.emit();
          if ((['saved', 'deleted'] as Array<ModalCloseReason>).includes(res.reason)) {
          }
          updateUrl(this.location, {});
        }
      });
    } finally {
      loading.dismiss();
    }
  }

}
