<div id="form-dados-login" class="d-flex flex-column mx-auto gap-3 align-items-center" [formGroup]="form">
  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <small-label> Email </small-label>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <ng-mat-icon name="alternate_email" color="white" />
      </div>
      <input type="text" class="form-control lh-2" placeholder="exemplo@dominio.com" formControlName="email" />
    </div>
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <small-label> Senha </small-label>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <ng-mat-icon name="lock" color="white" />
      </div>
      <input [type]="hidePassword ? 'password' : 'text'" class="form-control lh-2" placeholder="********" formControlName="senha" />
    </div>

    <ng-mat-icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="hidePassword ? 'visibility' : 'visibility_off'" color="dark" />
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <small-label> Confirmar senha </small-label>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <ng-mat-icon name="lock" color="white" />
      </div>
      <input [type]="hidePassword ? 'password' : 'text'" class="form-control lh-2" placeholder="********" formControlName="confirmarSenha" />
    </div>

    <ng-mat-icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="hidePassword ? 'visibility' : 'visibility_off'" color="dark" />
  </div>

  <div class="w-100 d-flex flex-column gap-1">
    @for (v of passwordValidations; track $index) {
      <div #parent class="d-flex align-items-center gap-2" [ariaValueNow]="v.validation(this.form.controls.senha?.value, this.form.controls.confirmarSenha?.value)">
        <ng-mat-icon [name]="parent.ariaValueNow === 'true' ? 'check' : 'close'" [color]="parent.ariaValueNow === 'true' ? 'success' : 'danger'" />
        <small [color]="parent.ariaValueNow === 'true' ? 'success' : 'danger'"> {{ v.label }} </small>
      </div>
    }
  </div>
</div>
