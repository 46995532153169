<div class="w-100 d-flex flex-column gap-3 mt-5 pb-4">
  <small-label class="fw-bold"> Saiba o preço ideal de venda dos seus produtos: </small-label>

  <div class="d-flex align-items-center" [ngClass]="{ 'justify-content-between': precoSugerido }">
    <h2 [color]="precoSugerido ? (produtoForm.value?.tipo | colorTipoProduto) : 'neutro'" class="fw-bolder m-0">{{ precoSugerido || 0 | currency }}</h2>

    @if (precoSugerido) {
      <div class="d-flex flex-column align-items-center justify-content-center gap-1">
        <small-label color="roxo"> Você ainda possui </small-label>
        <creditos-button [quantidadeCreditos]="(session | async)?.usuario?.quantidadeCreditos" />
      </div>
    }
  </div>

  @if ((idProduto && !precoSugerido) || !(session | async)?.usuario?.quantidadeCreditos) {
    <sugestao-preco [produto]="(session | async)?.usuario?.quantidadeCreditos ? produtoForm.value : null" (click)="confirmarDadosNegocio()" />
  }
</div>
