import { Component, Input } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import { TFormProduto } from '../novo-produto.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { ColorDirective } from '../../../../directives/color.directive';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { TipoProdutoEnum } from '../../../../model/produto.model';
import { UnidadesMedidaInsert } from '../../../../model/unidade-medida.model';
import { CalculoPrecoComponent } from '../calculo-preco/calculo-preco.component';

@Component({
  selector: 'section-valor-unidade-venda-produto',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    ColorDirective,
    SmallLabelComponent,
    ColorTipoProdutoPipe,
    CalculoPrecoComponent,
  ],
  templateUrl: './section-valor-unidade-venda-produto.component.html',
  styleUrl: './section-valor-unidade-venda-produto.component.scss'
})
export class SectionValorUnidadeVendaProdutoComponent extends BaseClass() {

  @Input() produtoID: string;
  @Input() produtoForm: FormGroup<TFormProduto>;
  @Input() unidadesMedidaRendimento: UnidadesMedidaInsert<TipoProdutoEnum.composicao>;
  @Input() unidadesMedidaEquivalente: UnidadesMedidaInsert<TipoProdutoEnum.insumo>;

}
