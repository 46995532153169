<div id="container" class="rounded-3 p-3">
  <div id="content" class="h-100 rounded-4 p-4">
    <div [ngClass]="{ 'align-items-end': isDesktop, 'flex-column-reverse': !isDesktop }" class="d-flex gap-5 mb-5" id="header">
      <button [disabled]="operationInProgress$ | async" id="btn-novo" type="button" color="roxo" class="btn gap-3" (click)="addEdit()">
        <ng-mat-icon name="local_offer" color="white" />
        <span> Nova categoria </span>
      </button>

      <div class="form-group d-flex flex-column gap-2 flex-grow-1 mt-4" id="searchbox">
        <div id="searchbox" class="input-group input-icon-end position-relative">
          <input
            type="text"
            class="form-control lh-2 pe-5"
            id="search-input"
            placeholder="Busque pelo nome da categoria"
            [debounce]="500"
            [operationObs$]="operationInProgress$"
            [enterOnly]="false"
            (onChange)="inputOnSearch()"
            [disabled]="operationInProgress$ | async"
            [(ngModel)]="searchTerm"
          />
          <ng-mat-icon class="cursor-pointer" slot="end" name="search" color="roxo" />
        </div>
      </div>
    </div>

    <div id="body" class="mt-3 mb-5">
      @if (operationInProgress$ | async) {
        <operation-in-progress />
      } @else {
        @if (categorias?.length > 0) {
          <div id="list-categorias">
            @for (categoria of categorias; track $index) {
              <div class="card-categoria py-3 px-4 rounded-4" [ngClass]="{ 'overflow-auto': !isDesktop }">
                <div class="card-content d-flex flex-column gap-2">
                  <div class="d-flex gap-2 justify-content-between">
                    <div class="d-flex gap-2 align-items-center">
                      <icon-in-ellipse class="h-100" [matIcon]="categoria.icone" [bgColor]="categoria.cor ? categoria.cor : null" [fitParent]="false" />
                      <h6 class="m-0 fw-bold">{{ categoria.nome }}</h6>
                    </div>

                    <ng-mat-icon name="edit" color="roxo" (click)="addEdit(categoria)" />
                  </div>

                  <div>
                    <p class="m-0">
                      Quantidade de produtos <span class="fw-bold"> {{ categoria.quantidadeProdutosRelacionados }} </span>
                    </p>
                  </div>
                </div>
              </div>
            }
          </div>
        } @else {
          <no-results [classes]="['text-center']">
            <h2 color="black" class="fw-bold">Nenhuma categoria encontrada</h2>
          </no-results>
        }
      }
    </div>

    @if (paginatorOpts) {
      <paginator
        [isDisabled]="operationInProgress$.value"
        color="roxo"
        [showLabels]="false"
        [page]="paginatorOpts?.page"
        [pages]="paginatorOpts.pages"
        [pageSize]="paginatorOpts.pageSize"
        [rowsCount]="paginatorOpts.rowsCount"
        [isDisabled]="operationInProgress$.value || !categorias?.length"
        (updatePageSize)="updatePageSize($event)"
        (navigatePage)="navigatePage($event)"
      />
    }
  </div>
</div>
