import { Categoria } from "./categoria.model";
import { UnidadeMedida, UnidadesMedidaInsert } from "./unidade-medida.model";

export type Produto = {
  id: string;
  nome: string;
  paguei: number;
  valorCusto: number;
  quantidade: number;
  medidaId: string;
  medidaNome: string
  equivalencia: number;
  medidaEquivalenciaId: string;
  medidaEquivalenciaNome: string;
  marca: string;
  ultimaCompra: Date;
  localCompra: string;
  ativo: boolean;
  imagem: string;
  classificacao: ClassificacaoProdutoEnum;
  valorVenda: number;
  idCategoria: string;
  categoriaNome: string;
  codigoBarras: string;
  codigo: string;
  quantidadeMediaVendidaMensal: number;
  tipoProduto: TipoProdutoEnum;
  valorUnidadeMedidaVenda: number;
  valorComprado: number;
  quantidadeComprada: number;
  dataUltimaCompra: string;
  idUnidadeMedidaCompra: string;
  idCompraProduto?: string;
  nomeUnidadeMedidaCompra: string,
  siglaUnidadeMedidaCompra: string,
  tagCadastroCompleto?: boolean;

  categoria: Categoria;
  unidadesMedidaEquivalentes: Array<UnidadeMedidaProdutoEquivalentRendimento<TipoProdutoEnum.insumo>>;
  unidadesMedidaRendimentos: Array<UnidadeMedidaProdutoEquivalentRendimento<TipoProdutoEnum.composicao>>;
  imageBase64?: string;
}
export type Produtos = Array<Produto>;

export type ProdutoNovaVenda = Pick<Produto, 'id' | 'quantidade' | 'valorVenda' | 'nome' | 'imagem'>

export type UnidadeMedidaProdutoEquivalentRendimento<T = TipoProdutoEnum> = {
  idCompraProduto: string;
  idUnidadeMedida: string;
  nomeUnidadeMedida: string;
  siglaUnidadeMedida: string;
} & (T extends TipoProdutoEnum.insumo ? {
  idUnidadeMedidaCompraEquivalente: string;
  quantidadeEquivalente: number;
} : {
  idProduto: string;
  quantidadeRendimento: number;
  idUnidadeMedidaRendimento: string;
});

// TipoProduto
// 1 - Insumo
// 2 - Composição
export enum TipoProdutoEnum {
  insumo = 1,
  composicao = 2,
}

// Classificação
// 1 - Vendido
// 2 - NaoVendido
export enum ClassificacaoProdutoEnum {
  vendido = 1,
  naoVendido = 2,
}

export type ProdutoCommon = Pick<
  Produto,
  'id' |
  'nome' |
  'medidaId' |
  'medidaNome' |
  'ativo' |
  'imagem' |
  'classificacao' |
  'idCategoria' |
  'categoriaNome' |
  'codigoBarras' |
  'quantidade' |
  'codigo' |
  'quantidadeMediaVendidaMensal' |
  'tipoProduto' |
  'valorVenda' |
  'valorUnidadeMedidaVenda' |
  'unidadesMedidaEquivalentes' |
  'idCompraProduto' |
  'imageBase64' |
  'tagCadastroCompleto'
>;
export type ProdutoCommonInsert = Pick<
  ProdutoCommon,
  'id' |
  'idCategoria' |
  'nome' |
  'codigoBarras' |
  'quantidadeMediaVendidaMensal' |
  'classificacao' |
  'tipoProduto' |
  'valorVenda' |
  'valorUnidadeMedidaVenda' |
  'idCompraProduto' |
  'imageBase64'
> & Partial<Pick<ProdutoCommon, 'ativo'>>;

export type Insumo = ProdutoCommon & Pick<
  Produto,
  'valorComprado' |
  'quantidadeComprada' |
  'marca' |
  'localCompra' |
  'idUnidadeMedidaCompra'
>;
export type Insumos = Array<Insumo>;
export type InsumoInsert = ProdutoCommonInsert & Pick<
  Insumo,
  'valorComprado' |
  'quantidadeComprada' |
  'marca' |
  'localCompra' |
  'idUnidadeMedidaCompra'
> &
  Pick<
    Produto,
    'dataUltimaCompra' |
    'quantidadeComprada' |
    'idUnidadeMedidaCompra'
  > & {
    unidadesMedidaEquivalentes: UnidadesMedidaInsert<TipoProdutoEnum.insumo>;
  };

export type Composicao = ProdutoCommon & Pick<
  Produto,
  'unidadesMedidaRendimentos'
>;
export type Composicoes = Array<Composicao>;
export type ComposicaoInsert = ProdutoCommonInsert & Pick<
  Composicao,
  never
> & {
  unidadesMedidaRendimentos: UnidadesMedidaInsert<TipoProdutoEnum.composicao>;
};

export type ProdutoComposicao = {
  id: string;

  idProduto: string;
  idUnidadeMedida: string;
  nome: string;
  nomeUnidade: string;
  quantidadeUsada: number;
  valor: number;
}
export type ProdutosComposicao = Array<ProdutoComposicao>;
export type ResponseInsertProdutoComposicao = ProdutoComposicao & {
  idProdutoComposto: string;
  idCompraProduto: string;
}

export type ProdutoComposicaoForm = Partial<ProdutoComposicao>;
export type ProdutosComposicaoForm = Array<ProdutoComposicaoForm>;
export type ProdutoComposicaoInsert = {
  idProdutoComposto: string;
  idProduto: string;
  idUnidadeMedida: string;
  quantidadeUsada: number;
}
