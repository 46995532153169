<div id="container">
  <div id="content" class="m-4">
    <div id="header">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">{{ unidadeMedida?.id ? "Editar" : "Nova" }} Unidade de Medida</h3>
        <button class="btn" (click)="dismiss()">
          <ng-mat-icon name="close" color="dark" />
        </button>
      </div>
    </div>

    <div id="form" class="mt-4">
      <div class="d-flex flex-column w-100" [formGroup]="form">
        <div class="form-group mt-3 d-flex flex-column gap-2">
          <small-label> Nome da unidade </small-label>
          <input type="text" class="form-control lh-2" placeholder="Insira o nome da unidade de medida" formControlName="nome" />
        </div>

        <div class="form-group mt-3 d-flex flex-column gap-2">
          <small-label> Sigla </small-label>
          <input type="text" class="form-control lh-2" placeholder="Insira a sigla da unidade de medida" formControlName="sigla" />
        </div>
      </div>
    </div>

    <div id="buttons" class="d-flex justify-content-end mt-4 gap-3">
      @if (unidadeMedida?.id) {
        <button type="button" class="px-4 btn flex-grow-1 btn-outline" color="danger" (click)="delete()">Excluir</button>
      }

      <button type="button" class="px-4 btn btn-outline" color="vermelho" (click)="dismiss()">Cancelar</button>

      <button type="button" class="px-4 btn" color="vermelho" (click)="salvar()">
        Salvar
        @if (isDesktop) {
          Unidade de Medida
        }
      </button>
    </div>
  </div>
</div>
