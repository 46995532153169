import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { Empresa, EmpresaInsert, EmpresaUpdate, NegocioEmpresa } from '../model/empresa.model';
import { environment } from '../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends BaseHttpService {

  getEmpresaById(id: string) {
    return this.getById<Empresa>(environment.api.endpoints.empresas, id);
  }

  insertEmpresa(empresa: Partial<EmpresaInsert>) {
    return this.post<Empresa>(`${environment.api.endpoints.empresas}`, empresa);
  }

  updateEmpresa(empresa: EmpresaUpdate) {
    return this.put<Empresa>(`${environment.api.endpoints.empresas}/${empresa.id}`, empresa);
  }

  updateNegocioEmpresa(negocioEmpresa: Partial<NegocioEmpresa>) {
    return this.put<NegocioEmpresa>(environment.api.endpoints.negocioempresa, negocioEmpresa);
  }

}
