import { Component, Inject } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { modalFeedbackDuration } from '../../globals/globals';
import { NgClass } from '@angular/common';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';
import { MaterialIcon } from 'material-icons';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';

export type ModalCloseActionFeedbackData = {
  title: string;
  message?: string;
  color?: AppColor;
  icon?: MaterialIcon;
  textCloseButton?: string;
  colorCloseButton?: AppColor;
}

@Component({
  selector: 'action-feedback',
  standalone: true,
  imports: [
    NgClass,
    ColorDirective,
    NgMatIconComponent,
  ],
  templateUrl: './action-feedback.component.html',
  styleUrl: './action-feedback.component.scss'
})
export class ActionFeedbackComponent extends BaseClass() {

  title: string;
  message: string;
  color: AppColor;
  icon: MaterialIcon;
  textCloseButton: string;
  colorCloseButton: AppColor;

  constructor(
    @Inject("data") protected data: ModalCloseActionFeedbackData,
    private ngbActiveModal: NgbActiveModal,
  ) {
    super();

    this.color = data.color || "primary";
    this.title = data.title;
    this.message = data.message;
    this.icon = data.icon;
    this.textCloseButton = data.textCloseButton;
    this.colorCloseButton = data.colorCloseButton || "apoio";

    if (!this.textCloseButton) {
      setTimeout(() => {
        this.ngbActiveModal.close();
      }, modalFeedbackDuration);
    }
  }

  dismiss() {
    this.ngbActiveModal.close();
  }

}
