<div id="container">
  <input
    #inputProdutoRef
    #autocompleteTrigger="matAutocompleteTrigger"
    type="text"
    class="produto form-control lh-2"
    [ngClass]="inputClasses"
    id="produto-input"
    placeholder="Ex: Nestlé, Dona Benta"
    [formControl]="control"
    [matAutocomplete]="autocompleteProduto"
    [debounce]="debounce"
    (onChange)="filter($event)"
  />
  <ng-mat-icon class="cursor-pointer" slot="end" [name]="dropdownIcon" [color]="highlightColor" (click)="inputProdutoRef.focus()" />
  <mat-autocomplete #autocompleteProduto="matAutocomplete" [displayWith]="displayWith" requireSelection (optionSelected)="selectProduto($event)">
    @if (searching) {
      <mat-option disabled>Carregando produtos...</mat-option>
    } @else {
      @if (showAllOption) {
        <mat-option [value]="null">Todos os produtos</mat-option>
      }
      @if (produtos.length === 0) {
        <mat-option disabled>Nenhum produto encontrado</mat-option>
      }
      @for (produto of produtos; track produto) {
        <mat-option [value]="produto">
          <div class="d-flex gap-3 align-items-center">
            <icon-in-ellipse [matIcon]="'tag'" color="white" [bgColor]="'var(--bs-' + highlightColor + ', var(--color-' + highlightColor + '))'" />
            <span>{{ produto.nome }}</span>
          </div>
        </mat-option>
      }
    }
  </mat-autocomplete>
</div>
