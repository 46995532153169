<div id="container" class="d-flex flex-column gap-4 pt-4">
  <div class="d-flex flex-column gap-4" id="header">
    <h2 class="fw-bolder mb-0" color="dark">Histórico de compras</h2>

    <div class="local-grid d-grid gap-3">
      <div class="chart">
        <div class="form-group d-flex flex-column gap-2">
          <small-label> Defina a visualização </small-label>

          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn"
              color="laranja-forte"
              [class]="'btn-' + (tipoVisualizacao === TipoVisualizacaoEnum.periodo ? '' : 'outline')"
              (click)="selecionarTipoVisualizacao(TipoVisualizacaoEnum.periodo)"
              [disabled]="operationChartInProgress$ | async"
            >
              Período
            </button>
            <button
              type="button"
              class="btn"
              color="laranja-forte"
              [class]="'btn-' + (tipoVisualizacao === TipoVisualizacaoEnum.produto ? '' : 'outline')"
              (click)="selecionarTipoVisualizacao(TipoVisualizacaoEnum.produto)"
              [disabled]="operationChartInProgress$ | async"
            >
              Produto
            </button>
          </div>
        </div>
      </div>

      <div class="gap-2" [ngClass]="{ 'd-grid grid-two-columns px-2': isDesktop, 'd-flex flex-column': !isDesktop }">
        <div class="form-group d-flex flex-column gap-2 input-icon-end position-relative">
          <dropdown
            label="Período Exibido"
            placeholder="Selecione o período"
            iconColor="laranja-forte"
            [inline]="false"
            [classes]="['lh-2']"
            [options]="periodosHistorico"
            [displayField]="'label'"
            [control]="periodoHistoricoControl"
            (optionSelected)="periodoHistoricoComprasChanged($event)"
          />
        </div>

        <div class="form-group d-flex flex-column gap-2 input-icon-end position-relative">
          <small-label> Produto Exibido </small-label>

          <produto-autocomplete [tipoProduto]="TipoProdutoEnum.insumo" [inputClasses]="['fw-bold']" [control]="produtoControl" [showAllOption]="true" highlightColor="laranja-forte" (produtoSelecionado)="produtoChanged()" />
        </div>
      </div>
    </div>
  </div>

  <div id="content" class="w-100 local-grid h-100 d-grid gap-3">
    <div id="grafico" class="chart d-flex flex-column gap-3">
      <chart-historio-vendas-compras
        #chartComprasRef
        drawColor="#F05929"
        [title]="'Total de compras'"
        [isDesktop]="isDesktop"
        [dadosGrafico]="dadosGrafico"
        [operationInProgress$]="operationChartInProgress$"
        [dataInicio]="dataInicio"
        [dataFim]="dataFim"
        [tipoVisualizacao]="tipoVisualizacao"
      />

      <div class="d-flex gap-2 flex-wrap periodos-chart-select">
        @for (periodo of periodosChart; track $index) {
          <button class="btn rounded-pill" color="roxo" [ngClass]="{ 'btn-outline': periodoSelecionado?.label !== periodo.label }" (click)="selecionarPeriodo(periodo)" [disabled]="operationChartInProgress$ | async">
            {{ periodo.label }}
          </button>
        }
        @if (periodoHistoricoControl?.value) {
          <button class="btn rounded-pill" color="roxo">{{ periodoHistoricoControl.value.label }}</button>
        }
      </div>
    </div>

    <div id="compras" class="d-flex flex-column gap-2 px-2">
      @if (valorTotalCompras) {
        <div class="d-flex flex-column gap-2">
          <small-label class="fw-bold" color="cinza"> Valor total </small-label>
          <h4 color="laranja-forte" class="m-0 fw-bold">{{ valorTotalCompras | currency }}</h4>
        </div>
      }

      <div class="rounded-top-4 d-flex justify-content-center p-3 header-compras">
        <small-label class="fw-bold">Compras</small-label>
      </div>

      <div class="position-relative d-flex flex-column gap-2 py-2">
        @if (!compras || (operationHistoricoInProgress$ | async)) {
          <operation-in-progress [absolute]="true" />
        }

        @if (compras?.length === 0) {
          <no-results message="Nenhuma compra encontrada para os filtros selecionados." />
        }

        @for (compra of compras; track compra) {
          <div class="compra d-flex flex-column gap-3 p-3 rounded-4 position-relative">
            <div class="detalhes flex-grow-1 d-flex align-items-center" [ngClass]="{ 'justify-content-start': isDesktop, 'justify-content-between': !isDesktop }">
              <div class="d-flex flex-column w-100">
                <div class="header d-flex justify-content-between">
                  <small-label class="fw-bolder mb-0">{{ compra.nomeProduto }}</small-label>
                  <button class="btn rounded-4 py-0 px-1" (click)="editarCompra(compra)">
                    <ng-mat-icon name="edit" color="laranja-forte" />
                  </button>
                </div>

                <div class="body d-grid grid-two-columns w-100">
                  <div class="d-flex gap-2">
                    <small-label color="cinza">Quantidade</small-label>
                    <small-label class="fw-bold">{{ compra.quantidade }}</small-label>
                  </div>

                  <div class="d-flex gap-2">
                    <small-label color="cinza">Preço</small-label>
                    <small-label class="fw-bold">{{ compra.preco | currency: "BRL" }}</small-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      @if (paginatorOpts?.rowsCount) {
        <paginator
          [isDisabled]="operationHistoricoInProgress$ | async"
          color="laranja-forte"
          [showLabels]="false"
          [page]="paginatorOpts?.page"
          [pages]="paginatorOpts.pages"
          [pageSize]="paginatorOpts.pageSize"
          [rowsCount]="paginatorOpts.rowsCount"
          [isDisabled]="(operationHistoricoInProgress$ | async) || !compras?.length"
          (updatePageSize)="updatePageSize($event)"
          (navigatePage)="navigatePage($event)"
        />
      }
    </div>
  </div>
</div>
