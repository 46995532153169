<div id="container" class="d-flex w-100 pt-4">
  <div class="d-flex flex-column gap-4 w-100">
    <h2 class="fw-bolder px-1" color="dark">Produtos que compõe</h2>

    <small-label class="px-1">Para fazer essa composição de produto, são utilizados os produtos abaixo.</small-label>

    <div id="table" class="w-100">
      <div id="header" class="d-flex list-row align-items-center top-0 px-1 rounded-top-4">
        <div class="cell text-center fw-bold py-2 flex-grow-1 detalhes">Produto</div>
      </div>

      <div id="table-content" class="px-1 pb-1 d-flex flex-column gap-2 py-2" ngbAccordion [closeOthers]="true">
        @if (!produtosComposicao) {
          <operation-in-progress />
        }

        @if (produtosComposicao?.length === 0) {
          <no-results message="Nenhum produto encontrado" />
        }

        @for (produto of produtosComposicao; track $index) {
          <div #accordionRef="ngbAccordionItem" ngbAccordionItem class="produto d-flex flex-column gap-3 p-3 rounded-4 position-relative" [ngClass]="{ collapsed: accordionRef.collapsed }">
            <div class="detalhes flex-grow-1" [ngClass]="{ 'd-flex flex-column': !isDesktop, 'd-grid grid-auto-columns align-items-center': isDesktop }" (click)="isDesktop ? null : accordionRef.toggle()">
              <div class="d-flex gap-2">
                @if (isDesktop) {
                  <small-label color="cinza">Produto</small-label>
                }
                <small-label color="dark" class="fw-bold" [class.mb-2]="!isDesktop && accordionRef.collapsed">{{ produto.nome }}</small-label>
              </div>

              @if (isDesktop || accordionRef.collapsed) {
                <div class="d-flex gap-2">
                  @if (isDesktop) {
                    <small-label color="cinza">Quantidade</small-label>
                  }
                  <ng-container *ngTemplateOutlet="quantidadeMedidaBlock; context: { $implicit: produto }" />
                </div>
              }

              @if (isDesktop) {
                <div class="d-flex gap-2">
                  <ng-container *ngTemplateOutlet="custoReceitaBlock; context: { $implicit: produto }" />
                </div>

                <div class="d-flex gap-2">
                  <ng-container *ngTemplateOutlet="custoUnidadeBlock; context: { $implicit: produto }" />
                </div>
              }
            </div>

            @if (!isDesktop) {
              <div class="cell acoes d-flex justify-content-center position-absolute right-0 top-0">
                <button class="btn rounded-4 py-0 px-1" (click)="accordionRef.toggle()">
                  <ng-mat-icon name="expand_more" color="info" />
                </button>
              </div>
            }

            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="p-0 d-flex flex-column gap-4">
                <div class="d-grid grid-two-columns w-100">
                  <div class="d-flex flex-column gap-2">
                    <small-label color="cinza">Quantidade</small-label>
                    <ng-container *ngTemplateOutlet="quantidadeMedidaBlock; context: { $implicit: produto }" />
                  </div>

                  <div class="d-flex flex-column gap-2">
                    <ng-container *ngTemplateOutlet="custoReceitaBlock; context: { $implicit: produto }" />
                  </div>
                </div>

                <div class="d-grid grid-two-columns w-100">
                  <div class="d-flex flex-column gap-2">
                    <ng-container *ngTemplateOutlet="custoUnidadeBlock; context: { $implicit: produto }" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      @if (paginatorOpts) {
        <paginator
          color="info"
          [showLabels]="false"
          [page]="paginatorOpts?.page"
          [pages]="paginatorOpts.pages"
          [pageSize]="paginatorOpts.pageSize"
          [rowsCount]="paginatorOpts.rowsCount"
          [isDisabled]="(operationInProgress$ | async) || !produtosComposicao?.length"
          (updatePageSize)="updatePageSize($event)"
          (navigatePage)="navigatePage($event)"
        />
      }
    </div>
  </div>
</div>

<ng-template #quantidadeMedidaBlock let-produto>
  <small-label>
    <span class="fw-bold">{{ produto?.["quantidadeUsada"] }}</span>
    <span class="ms-2"> {{ produto?.["nomeUnidade"] }} </span>
  </small-label>
</ng-template>

<ng-template #custoReceitaBlock let-produto>
  <small-label color="cinza">Custo na receita</small-label>
  <small-label class="fw-bold">{{ produto?.["valor"] | currency }}</small-label>
</ng-template>

<ng-template #custoUnidadeBlock let-produto>
  <small-label color="cinza">Custo por unidade</small-label>
  @if (unidadeRendimento) {
    <small-label class="fw-bold">{{ produto?.["valor"] / unidadeRendimento.quantidadeRendimento | currency }}</small-label>
  } @else {
    <small-label class="fw-bold">-</small-label>
  }
</ng-template>
