<div id="container" class="h-100">
  <div id="content" class="mx-auto d-flex flex-column align-items-center justify-content-center h-100" [ngClass]="{ 'px-5': isDesktop }">
    @if (produtoForm.controls.tipo.value === TipoProdutoEnum.composicao) {
      <h1 class="fw-bold" color="dark">
        Essa composição rende quantos(as)
        <span [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ (unidadesMedidaEquivalente || unidadesMedidaRendimento)?.[0]?.nome }}(s) </span> de
        <span [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ produtoForm.controls.nome.value }}</span
        >?
        <!-- <div class="d-flex mt-3" [ngClass]="{ 'justify-content-center': isDesktop }">
          <small-label color="roxo"> Não sei o que responder, preciso de ajuda! </small-label>
        </div> -->
      </h1>
    } @else {
      <h1 class="fw-bold" color="dark">
        Quantos(as)
        <span [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ (unidadesMedidaEquivalente || unidadesMedidaRendimento)?.[0]?.nome }} </span>
        tem em 1
        <span [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ produtoForm.controls.unidadeMedidaUltimaCompra.value?.nome }}</span> de
        <span [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ produtoForm.controls.nome.value }}</span
        >?
        <!-- <div class="d-flex mt-3" [ngClass]="{ 'justify-content-center': isDesktop }">
          <small-label color="roxo"> Não sei o que responder, preciso de ajuda! </small-label>
        </div> -->
      </h1>

      <div class="w-100 mt-5 d-flex flex-column gap-2">
        <small-label class="fw-bold">Exemplo:</small-label>
        <small-label color="dark" class="fw-bold">1 quilo equivale à 7,5 xícaras</small-label>
      </div>
    }

    <div class="d-flex flex-column gap-3 w-100">
      @for (unidade of unidadesMedidaEquivalente || unidadesMedidaRendimento; track unidade) {
        <div class="quantidade-unidade form-group w-100 d-flex flex-column gap-2 input-with-tag mb-3 mt-5">
          <small-label> Quantas {{ unidade.nome }}? </small-label>

          <div class="input-group">
            <div class="input-group-text">
              <!-- <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="10" [(ngModel)]="unidade.quantidadeEquivalente" /> -->
              @if (produtoForm.controls.tipo.value === TipoProdutoEnum.insumo) {
                <ng-container *ngTemplateOutlet="unidadesEquivalentInput; context: { $implicit: unidade }" />
              } @else {
                <ng-container *ngTemplateOutlet="unidadesRendimentoInput; context: { $implicit: unidade }" />
              }
            </div>
            <input type="text" class="medida form-control lh-2" placeholder="Ex: Quilos, Litros, Caixas" [value]="unidade.nome" readonly />
          </div>
        </div>
      }
    </div>
  </div>
</div>

<ng-template #unidadesEquivalentInput let-unidade>
  <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="10" [(ngModel)]="unidade.quantidadeEquivalente" />
</ng-template>

<ng-template #unidadesRendimentoInput let-unidade>
  <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="10" [(ngModel)]="unidade.quantidadeRendimento" />
</ng-template>
