import { Component } from '@angular/core';
import { AppPages, PagesApp, routerLinkActiveOptions } from '../../app-pages';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IconComponent } from '../../../../custom-components/icon/icon.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { NgClass } from '@angular/common';
import { PAGES_SIDENAV } from '../sidenav.component';
import { BaseClass } from '../../../../globals/base-class';
import { closeModal } from '../../../../globals/utils';

@Component({
  selector: 'app-extra-options',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, IconComponent, ColorDirective, NgClass],
  templateUrl: './extra-options.component.html',
  styleUrl: './extra-options.component.scss'
})
export class ExtraOptionsComponent extends BaseClass() {

  pages: PagesApp = [];
  routerLinkActiveOptions = routerLinkActiveOptions;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();

    const sub = PAGES_SIDENAV.subscribe({
      next: (pages) => {
        this.pages = AppPages.filter(page => !pages?.map(x => x.path).includes(page.path));
        if (!this.pages || this.pages.length === 0) closeModal(this.activeModal, "cancel");
      }
    });
    this.appendSubscription(sub);
  }

  isActiveChange(isActive: boolean = true) {
    if (!isActive) closeModal(this.activeModal, "cancel");
  }
}
