<div id="container" class="position-relative">
  <div id="content" class="p-4 d-flex flex-column gap-4 rounded-4 flex-grow-1" [ngClass]="{ 'align-items-center': isDesktop }">
    <h2 class="fw-bold" color="dark">Dados de login</h2>

    <div id="form" [formGroup]="form" class="d-grid gap-4">
      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Email </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <ng-mat-icon name="alternate_email" color="white" />
          </div>
          <input type="email" class="form-control lh-2" placeholder="Ex: email@domimion.com.br" formControlName="email" />
        </div>
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Senha atual </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <ng-mat-icon name="lock" color="white" />
          </div>
          <input type="password" class="form-control lh-2" placeholder="Digite sua senha atual" formControlName="senhaAtual" />
        </div>
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Nova senha </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <ng-mat-icon name="lock" color="white" />
          </div>
          <input type="password" class="form-control lh-2" placeholder="Digite sua nova senha" formControlName="novaSenha" />
        </div>
      </div>

      <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
        <small-label> Confirmar nova senha </small-label>

        <div class="input-group">
          <div class="input-group-text" color="titulo">
            <ng-mat-icon name="lock" color="white" />
          </div>
          <input type="password" class="form-control lh-2" placeholder="Confirme sua nova senha" formControlName="confirmarNovaSenha" />
        </div>
      </div>
    </div>
  </div>

  <div id="footer" class="d-flex w-100 rounded-4 p-4 gap-4" [ngClass]="{ 'justify-content-center': isDesktop, 'position-absolute left-0 bottom-0': !isDesktop }">
    <button class="btn" color="apoio" (click)="dismiss()" [ngClass]="{ 'px-5': isDesktop, 'flex-grow-1': !isDesktop }">
      <span [class.px-5]="isDesktop"> Cancelar </span>
    </button>

    <button class="btn" color="titulo" (click)="salvar()" [ngClass]="{ 'px-5': isDesktop, 'flex-grow-1': !isDesktop }">
      <span [class.px-5]="isDesktop"> Salvar </span>
    </button>
  </div>
</div>
