import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TFormCriarConta } from '../criar-conta.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../../globals/base-class';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'section-financas-negocio',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SmallLabelComponent,
    NgMatIconComponent,
    ColorDirective,
    NgClass,
    NgxMaskDirective,
  ],
  templateUrl: './section-financas-negocio.component.html',
  styleUrl: './section-financas-negocio.component.scss'
})
export class SectionFinancasNegocioComponent extends BaseClass() {
  @Input() form: FormGroup<TFormCriarConta>;
}
