import { Component, Input } from '@angular/core';
import { TFormProduto } from '../novo-produto.component';
import { FormGroup } from '@angular/forms';
import { ColorDirective } from '../../../../directives/color.directive';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../../../globals/base-class';
import { FormCompraProdutoComponent } from '../../../../custom-components/form-compra-produto/form-compra-produto.component';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';

@Component({
  selector: 'section-sobre-ultima-compra-produto',
  standalone: true,
  imports: [
    ColorDirective,
    NgClass,
    FormCompraProdutoComponent,
    ColorTipoProdutoPipe,
  ],
  templateUrl: './section-sobre-ultima-compra-produto.component.html',
  styleUrl: './section-sobre-ultima-compra-produto.component.scss'
})
export class SectionSobreUltimaCompraProdutoComponent extends BaseClass() {

  @Input() produtoForm: FormGroup<TFormProduto>;

}
