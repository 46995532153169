import { Component } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { ColorDirective } from '../../directives/color.directive';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { IconComponent } from '../../custom-components/icon/icon.component';
import { Contato } from '../../globals/globals';

@Component({
  selector: 'app-ajuda',
  standalone: true,
  imports: [
    ColorDirective,
    NgMatIconComponent,
    IconComponent,
  ],
  templateUrl: './ajuda.component.html',
  styleUrl: './ajuda.component.scss'
})
export class AjudaComponent extends BaseClass() {

  sendMail() {
    window.open(`mailto:${Contato.emailSuporte}`, '_blank');
  }

  sendWhatsapp() {
    window.open(`https://wa.me/${Contato.whatsappSuporte}`, '_blank');
  }

}
