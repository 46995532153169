@for (toast of toastService.toasts; track toast) {
<ngb-toast [header]="toast.header" [autohide]="true" [delay]="toast.delay || 4000" (hidden)="toastService.remove(toast)" [ngClass]="{
  'v-top': toast.alignVertical === 'top',
  'v-bottom': toast.alignVertical === 'bottom',
  'v-center': toast.alignVertical === 'center',
  'h-left': toast.alignHorizontal === 'left',
  'h-right': toast.alignHorizontal === 'right',
  'h-center': toast.alignHorizontal === 'center',

  'color-primary': toast.color === 'primary',
  'color-secondary': toast.color === 'secondary',
  'color-success': toast.color === 'success',
  'color-info': toast.color === 'info',
  'color-warning': toast.color === 'warning',
  'color-danger': toast.color === 'danger',
  'color-light': toast.color === 'light',
  'color-dark': toast.color === 'dark',
  }">
  <div class="d-flex">
    {{ toast.body }}
    @if (!toast.header) {
    <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
    }
  </div>
</ngb-toast>
}
