import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TFormEditProduto } from '../detalhes-produto.component';
import { ClassificacaoProdutoEnum, TipoProdutoEnum } from '../../../../model/produto.model';
import { BaseClass } from '../../../../globals/base-class';
import { NgClass } from '@angular/common';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { CategoriaAutocompleteComponent } from '../../../../custom-components/autocomplete/categoria-autocomplete/categoria-autocomplete.component';
import { NgxMaskDirective } from 'ngx-mask';
import { ButtonSelectImagemProdutoComponent } from '../../../../custom-components/button-select-imagem-produto/button-select-imagem-produto.component';

@Component({
  selector: 'section-detalhes-produto-editar',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    SmallLabelComponent,
    ColorDirective,
    ColorTipoProdutoPipe,
    CategoriaAutocompleteComponent,
    NgxMaskDirective,
    ButtonSelectImagemProdutoComponent,
  ],
  templateUrl: './section-detalhes-produto-editar.component.html',
  styleUrl: './section-detalhes-produto-editar.component.scss'
})
export class SectionDetalhesProdutoEditarComponent extends BaseClass() {

  @Input() formEdit: FormGroup<TFormEditProduto>;

  ClassificacaoProduto = ClassificacaoProdutoEnum;
  TipoProduto = TipoProdutoEnum;

  setClassificacaoProduto(classification: ClassificacaoProdutoEnum) {
    const control = this.formEdit.controls.classificacao;
    control.setValue(classification, { emitEvent: false });
    control.markAsDirty();
  }

  setAtivoProduto(ativo: boolean) {
    const control = this.formEdit.controls.ativo;
    control.setValue(ativo, { emitEvent: false });
    control.markAsDirty();
  }
}
