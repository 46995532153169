import { Component, Input } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { MatInputModule } from '@angular/material/input';
import { NgClass } from '@angular/common';
import { TFormProduto } from '../novo-produto.component';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { ClassificacaoProdutoEnum } from '../../../../model/produto.model';
import { CategoriaAutocompleteComponent } from '../../../../custom-components/autocomplete/categoria-autocomplete/categoria-autocomplete.component';
import { ButtonSelectImagemProdutoComponent } from '../../../../custom-components/button-select-imagem-produto/button-select-imagem-produto.component';

@Component({
  selector: 'section-detalhes-produto',
  standalone: true,
  imports: [
    MatInputModule,
    SmallLabelComponent,
    ColorDirective,
    ReactiveFormsModule,
    NgMatIconComponent,
    NgClass,
    ColorTipoProdutoPipe,
    CategoriaAutocompleteComponent,
    ButtonSelectImagemProdutoComponent,
  ],
  templateUrl: './section-detalhes-produto.component.html',
  styleUrl: './section-detalhes-produto.component.scss'
})
export class SectionDetalhesProdutoComponent extends BaseClass() {

  @Input() produtoForm: FormGroup<TFormProduto>;

  ClassificacaoProduto = ClassificacaoProdutoEnum;

}
