<div id="form-informacoes-pessoais" class="d-flex flex-column mx-auto gap-3 align-items-center" [formGroup]="form">
  <div class="form-group w-100 d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-between">
      <small-label> Nome do empreendedor <span class="ms-1"> * </span> </small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <input type="text" class="form-control lh-2" placeholder="Nome do empreendedor" formControlName="nome" />
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <div class="d-flex align-items-center justify-content-between">
      <small-label> Telefone <span class="ms-1"> * </span> </small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>

    <div class="input-group">
      <div class="input-group-text" color="primary">
        <ng-mat-icon name="smartphone" color="white" />
      </div>
      <input mask="(00) 0000-0000||(00) 00000-0000" type="text" class="form-control lh-2" placeholder="(00) 90000-0000" formControlName="telefone" />
    </div>
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <small-label> Redes sociais (site, instagram, etc) </small-label>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <ng-mat-icon name="alternate_email" color="white" />
      </div>
      <input type="text" class="form-control lh-2" placeholder="www.site.com" formControlName="social" />
    </div>
  </div>
</div>
