import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';
import { minLengthPassword, session$ } from '../../globals/globals';
import { UsuarioSession } from '../../model/usuario.model';
import { LogoHeaderComponent } from '../../custom-components/logo-header/logo-header.component';
import { IconComponent } from '../../custom-components/icon/icon.component';
import { UsuarioService } from '../../services/usuario.service';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { ApiResponseError } from '../../model/api.model';
import { closeModal } from '../../globals/utils';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [ReactiveFormsModule, LogoHeaderComponent, IconComponent, OperationInProgressComponent, NgMatIconComponent],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {

  user: UsuarioSession;

  form: FormGroup<{
    currentPassword: FormControl<string>,
    newPassword: FormControl<string>,
    confirmPassword: FormControl<string>
  }> = new FormGroup({
    currentPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(minLengthPassword)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(minLengthPassword)]),
  });

  hidePassword: boolean = true;
  operationInProgress: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private toastService: ToastService,
  ) {
    session$.subscribe({
      next: (session) => {
        this.user = session.usuario;
      }
    });
  }

  confirm() {
    const formValue = this.form.value;

    if (!formValue.currentPassword || !formValue.newPassword || !formValue.confirmPassword)
      return this.presentErrorToast('Preencha todos os campos');

    if (formValue.newPassword.length < minLengthPassword || formValue.confirmPassword.length < minLengthPassword)
      return this.presentErrorToast(`A senha deve ter no mínimo ${minLengthPassword} caracteres`);

    if (formValue.newPassword !== formValue.confirmPassword)
      return this.presentErrorToast('As senha não conferem');

    if (formValue.currentPassword === formValue.newPassword)
      return this.presentErrorToast('A nova senha não pode ser igual a senha atual');

    this.operationInProgress = true;
    this.form.disable();

    this.usuarioService.changePassword(formValue.currentPassword, formValue.newPassword).then((res) => {
      if (res.errors) return this.presentErrorToast(res.errors.validations?.map(x => x.message)?.join('\n') || 'Ocorreu um erro ao alterar a senha');

      this.toastService.show({ body: 'Senha alterada com sucesso', color: 'success' });
      closeModal<CloseModalDataChangePassword>(this.activeModal, "saved", { user: this.user, success: true });
    }).catch((err: ApiResponseError<'validation'>) => {
      console.log(err);
      this.presentErrorToast(err.error.validations?.map(x => x.message)?.join('\n') || 'Ocorreu um erro ao alterar a senha');
    }).finally(() => {
      this.operationInProgress = false;
      this.form.enable();
    });
  }

  presentErrorToast(message: string) {
    this.toastService.show({ body: message, color: 'danger' });
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}

export type CloseModalDataChangePassword = { user: UsuarioSession, success: boolean };
