import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TFormProduto } from '../novo-produto.component';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../../../globals/base-class';
import { NgxMaskDirective } from 'ngx-mask';
import { CalculoPrecoComponent } from '../calculo-preco/calculo-preco.component';

@Component({
  selector: 'section-valor-venda-produto',
  standalone: true,
  imports: [
    SmallLabelComponent,
    ReactiveFormsModule,
    ColorDirective,
    ColorTipoProdutoPipe,
    NgClass,
    NgxMaskDirective,
    CalculoPrecoComponent,
  ],
  templateUrl: './section-valor-venda-produto.component.html',
  styleUrl: './section-valor-venda-produto.component.scss'
})
export class SectionValorVendaProdutoComponent extends BaseClass() {

  @Input() produtoID: string;
  @Input() produtoForm: FormGroup<TFormProduto>;

}
