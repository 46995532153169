<div class="p-5 h-100 d-flex flex-column">
  @for (page of pages; track $index) {
    <input [style.display]="'none'" />

    <div
      role="button"
      color="titulo"
      class="d-flex align-items-center gap-3 py-3"
      routerLinkActive="active"
      #route="routerLinkActive"
      [routerLink]="'/home/' + page.path"
      [queryParams]="page.queryParams || {}"
      [routerLinkActiveOptions]="routerLinkActiveOptions"
      (isActiveChange)="isActiveChange($event)"
    >
      <icon [name]="route.isActive ? page.icon + (page.color ? '_highlight' : '') : page.icon" />
      <span [color]="route.isActive ? page.color : 'cinza'" class="fw-600"> {{ page.title }} </span>
    </div>
  }

  <a role="button" target="_blank" [href]="'https://google.com'" class="mt-auto d-flex align-items-center gap-3 py-3">
    <img class="logo" src="assets/logo-pequena.png" alt="Smartis Logo Pequena" height="24" />
    <span color="cinza" class="fw-600"> Conheça nossa solução completa </span>
  </a>
</div>
