<div class="card-preco">
  <div class="card-content" [ngClass]="{ 'd-flex align-items-center justify-content-between gap-5': textCloseButton, 'cursor-pointer': produto }">
    <small-label>
      <div class="lh-2">
        @if (produto) {
          <h6>
            Clique aqui e receba agora uma sugestão de preço para
            <span class="fw-bold">{{ produto.nome }}</span>
          </h6>
        } @else {
          <h6 class="text-justify">Cadastre novos produtos, registre vendas ou compras para ganhar créditos e receber sugestão de preços para seus produtos</h6>
        }
      </div>
    </small-label>

    @if (textCloseButton) {
      <button class="btn btn-outline px-3" color="white" (click)="close()">{{ textCloseButton }}</button>
    }
  </div>
</div>
