import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective } from 'ngx-mask';
import { ToastService } from '../../services/toast.service';
import { EmpresaInsert, TipoEmpresaEnum } from '../../model/empresa.model';
import { BaseClass } from '../../globals/base-class';
import { LogoHeaderComponent } from '../../custom-components/logo-header/logo-header.component';
import { ModalService } from '../../services/modal.service';
import { EmpresaService } from '../../services/empresa.service';
import { ColorDirective } from '../../directives/color.directive';
import { ToolbarComponent } from '../../home/nav/toolbar/toolbar.component';
import { minLengthPassword, session$ } from '../../globals/globals';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { SmallLabelComponent } from "../../custom-components/small-label/small-label.component";
import { NgMatIconComponent } from "../../custom-components/ng-mat-icon/ng-mat-icon.component";
import { SectionDadosPessoaisComponent } from './section-dados-pessoais/section-dados-pessoais.component';
import { SectionDadosNegocioComponent } from './section-dados-negocio/section-dados-negocio.component';
import { SectionFinancasNegocioComponent } from './section-financas-negocio/section-financas-negocio.component';
import { SectionDadosLoginComponent } from './section-dados-login/section-dados-login.component';
import { RamoNegocio } from '../../model/ramo-negocio.model';
import { Cidade, Estado } from '../../model/endereco.model';
import { closeModal, showApiErrorMessages } from '../../globals/utils';
import { parse } from 'date-fns';
import { ApiResponseError } from '../../model/api.model';

export type TFormCriarConta = {
  nome: FormControl<string>;
  telefone: FormControl<string>;
  social: FormControl<string>;

  nomeEmpresa: FormControl<string>;
  ramoNegocio: FormControl<RamoNegocio>;
  dataCriacao: FormControl<string>;
  cnpj: FormControl<string>;
  estado: FormControl<Estado>;
  cidade: FormControl<Cidade>;

  custoFixo: FormControl<number>;
  despesasVendas: FormControl<number>;
  margemLucro: FormControl<number>;
  faturamentoMedio: FormControl<number>;
  multiplicador: FormControl<number>;

  email: FormControl<string>;
  senha: FormControl<string>;
  confirmarSenha: FormControl<string>;
};
export type TFormCriarContaValue = {
  [key in keyof TFormCriarConta]: TFormCriarConta[key]['value'];
};

export const LabelsFieldsFormCriarContaMap: { [key in keyof TFormCriarConta]: string } = {
  nome: "Nome",
  telefone: "Telefone",
  social: "Rede social",
  nomeEmpresa: "Nome da empresa",
  ramoNegocio: "Ramo de negócio",
  dataCriacao: "Data de fundação",
  cnpj: "CNPJ",
  estado: "Estado",
  cidade: "Cidade",
  custoFixo: "Custo fixo",
  despesasVendas: "Despesas de vendas",
  margemLucro: "Margem de lucro",
  faturamentoMedio: "Faturamento médio",
  multiplicador: "Multiplicador",
  email: "Email",
  senha: "Senha",
  confirmarSenha: "Confirmar senha",
};

const SECTIONS = [SectionDadosPessoaisComponent, SectionDadosNegocioComponent, SectionFinancasNegocioComponent, SectionDadosLoginComponent];

@Component({
  selector: 'app-criar-conta',
  standalone: true,
  imports: [
    SECTIONS,
    ReactiveFormsModule,
    NgxMaskDirective,
    LogoHeaderComponent,
    ColorDirective,
    ToolbarComponent,
    AsyncPipe,
    SmallLabelComponent,
    NgTemplateOutlet,
    NgMatIconComponent,
    NgClass,
  ],
  templateUrl: './criar-conta.component.html',
  styleUrl: './criar-conta.component.scss'
})
export class CriarContaComponent extends BaseClass() {

  session = session$;

  form: FormGroup<TFormCriarConta> = new FormGroup({
    nome: new FormControl(null, [Validators.required]),
    telefone: new FormControl(null, [Validators.required]),
    social: new FormControl(null, []),

    nomeEmpresa: new FormControl(null, [Validators.required, Validators.minLength(3)]),
    ramoNegocio: new FormControl(null, [Validators.required]),
    dataCriacao: new FormControl(null, [Validators.required]),
    cnpj: new FormControl(null, [Validators.minLength(14)]),
    estado: new FormControl(null, []),
    cidade: new FormControl({ value: null, disabled: true }, []),

    custoFixo: new FormControl(null, [Validators.min(0.01)]),
    despesasVendas: new FormControl(null, [Validators.min(0.01)]),
    margemLucro: new FormControl(null, [Validators.min(0.01)]),
    faturamentoMedio: new FormControl(null, [Validators.min(0.01)]),
    multiplicador: new FormControl(null, [Validators.min(0.01)]),

    email: new FormControl(null, [Validators.required, Validators.email]),
    senha: new FormControl(null, [Validators.required, Validators.minLength(minLengthPassword)]),
    confirmarSenha: new FormControl(null, [Validators.required, Validators.minLength(minLengthPassword)]),
  });

  sections: Array<Section> = Sections;
  activeSection: Section = this.sections[0];

  constructor(
    private empresaService: EmpresaService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  previous() {
    if (this.activeSection === this.sections[0]) return this.activeModal.dismiss();
    this.activeSection = this.sections[this.sections.indexOf(this.activeSection) - 1];
  }

  next() {
    const invalidFields = this.invalidFieldsForm(this.activeSection);
    if (invalidFields.length) {
      this.modalService.presentAlert("Os seguintes campos forão preenchidos incorretamente", invalidFields.map((field) => LabelsFieldsFormCriarContaMap[field]).join("\n"));
      return;
    }
    if (this.activeSection === this.sections[this.sections.length - 1]) return this.salvar();
    this.activeSection = this.sections[this.sections.indexOf(this.activeSection) + 1];
  }

  private invalidFieldsForm(section: Section): Array<keyof TFormCriarConta> {
    let fields: Array<keyof TFormCriarConta> = [];

    switch (section) {
      case 'info-pessoal':
        fields = ['nome', 'telefone', 'social'];
        break;

      case 'info-negocio':
        fields = ['nomeEmpresa', 'ramoNegocio', 'dataCriacao', 'cnpj', 'estado', 'cidade'];
        break;

      case 'financas-negocio':
        fields = ['custoFixo', 'despesasVendas', 'margemLucro', 'faturamentoMedio', 'multiplicador'];
        break;

      case 'dados-login':
        fields = ['email', 'senha', 'confirmarSenha'];
        break;
    }

    return fields.filter((field) => this.form.controls[field].invalid);
  }

  async salvar() {
    const validationSection = this.sections.map((section) => this.invalidFieldsForm(section)).flat();
    if (validationSection.length) {
      this.modalService.presentAlert("Os seguintes campos forão preenchidos incorretamente", validationSection.map((field) => LabelsFieldsFormCriarContaMap[field]).join("\n"));
      return;
    }

    const empresa: Partial<EmpresaInsert> = {
      nome: this.form.controls.nomeEmpresa.value || null,
      cpfCnpj: this.form.controls.cnpj.value || null,
      tipo: TipoEmpresaEnum._1 || null,
      idRamoNegocio: this.form.controls.ramoNegocio.value.id || null,
      idEstado: this.form.controls.estado.value.id || null,
      idCidade: this.form.controls.cidade.value.id || null,
      dataCriacao: this.form.controls.dataCriacao.value ? parse(this.form.controls.dataCriacao.value, 'dd/MM/yyyy', new Date())?.toISOString() : null,

      usuario: {
        nome: this.form.controls.nome.value || null,
        email: this.form.controls.email.value || null,
        telefone: this.form.controls.telefone.value || null,
        senha: this.form.controls.senha.value || null,
        redeSocial: this.form.controls.social.value || null,
      },
      negocioEmpresa: {
        custoFixo: this.form.controls.custoFixo.value || null,
        despesasVenda: this.form.controls.despesasVendas.value || null,
        percentualMargemLucro: this.form.controls.margemLucro.value || null,
        faturamentoMedio: this.form.controls.faturamentoMedio.value || null,
        multiplicadorPreco: this.form.controls.multiplicador.value || null,
      },

    };

    const loading = this.modalService.presentLoading("Salvando conta...", true);

    try {
      const resEmpresa = await this.empresaService.insertEmpresa(empresa);
      if (!resEmpresa.success) {
        if (!showApiErrorMessages(this.modalService, resEmpresa as ApiResponseError<'validation'>)) this.presentErrorToast('Erro ao salvar conta');
        return;
      }

      this.toastService.show({ body: `Conta salva com sucesso`, color: 'success' });
      closeModal(this.activeModal, 'saved');
    } catch (error) {
      console.log(error);
      if (!showApiErrorMessages(this.modalService, error as ApiResponseError<'validation'>)) this.presentErrorToast('Erro ao salvar conta');
    } finally {
      loading.dismiss();
    }
  }

  private presentErrorToast(body: string) {
    this.toastService.show({ body, color: 'danger' });
  }
}

type Section = "info-pessoal" | "info-negocio" | "financas-negocio" | "dados-login";
const Sections: Array<Section> = ["info-pessoal", "info-negocio", "financas-negocio", "dados-login"];
