import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseClass } from '../../globals/base-class';
import { ColorDirective } from '../../directives/color.directive';
import { closeModal } from '../../globals/utils';
import { NgMatIconComponent } from "../ng-mat-icon/ng-mat-icon.component";
import { AppColor } from '../../model/custom-types';

@Component({
  selector: 'modal-confirm',
  standalone: true,
  imports: [ColorDirective, NgMatIconComponent],
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.scss'
})
export class ConfirmComponent extends BaseClass() {
  header: string;
  message: string;
  noText: string;
  yesText: string;

  noButtonColor: AppColor = 'apoio';
  yesButtonColor: AppColor = 'titulo';

  constructor(
    @Inject('data') private data: Record<string, any>,
    private activeModal: NgbActiveModal,
  ) {
    super();
    this.header = this.data?.['header'] || 'Confirmar';
    this.message = this.data?.['message'];

    const config = this.data?.['config'];

    this.noText = config?.['noText'] || 'Não';
    this.yesText = config?.['yesText'] || 'Sim';
    this.noButtonColor = config?.['noButtonColor'] || 'apoio';
    this.yesButtonColor = config?.['yesButtonColor'] || 'titulo';
  }

  close(confirmed: boolean = null) {
    closeModal<boolean>(this.activeModal, "cancel", confirmed);
  }

}
