<div id="container" class="d-flex gap-5 w-100 pb-4" [ngClass]="{ 'flex-column': !isDesktop }" [formGroup]="produtoForm">
  <div id="start" class="flex-grow-1">
    <div class="d-flex flex-column gap-5">
      <div id="selecao-foto" class="d-flex flex-column gap-4">
        <small-label> Foto do produto </small-label>

        <button-select-imagem-produto [imagemControl]="produtoForm.controls.imagem" [imagemFileControl]="produtoForm.controls.imagemFile" [tipoProduto]="produtoForm.controls.tipo.value" />
      </div>

      <div class="form-group d-flex flex-column gap-2" id="e-vendido">
        <small-label> Esse produto vai ser vendido?</small-label>

        <div class="btn-group" role="group">
          <button
            type="button"
            class="btn"
            [color]="produtoForm.controls.tipo.value | colorTipoProduto"
            [class]="'btn-' + (produtoForm.controls.classificacao?.value === ClassificacaoProduto.vendido ? '' : 'outline')"
            (click)="produtoForm.controls.classificacao.setValue(ClassificacaoProduto.vendido)"
          >
            Sim
          </button>
          <button
            type="button"
            class="btn"
            [color]="produtoForm.controls.tipo.value | colorTipoProduto"
            [class]="'btn-' + (produtoForm.controls.classificacao?.value === ClassificacaoProduto.naoVendido ? '' : 'outline')"
            (click)="produtoForm.controls.classificacao.setValue(ClassificacaoProduto.naoVendido)"
          >
            Não
          </button>
        </div>
      </div>

      @if (produtoForm.controls.classificacao.value === ClassificacaoProduto.vendido) {
        <div class="form-group d-flex flex-column gap-2" id="quantidade">
          <small-label> Qual a quantidade média de vendas desse produto no mês? </small-label>
          <div class="d-flex align-self-center align-items-center gap-2">
            <button
              [disabled]="produtoForm.controls.quantidadeMediaVendasMensal.value <= 0"
              type="button"
              class="round-btn-icon btn"
              [color]="produtoForm.controls.tipo.value | colorTipoProduto"
              (click)="produtoForm.controls.quantidadeMediaVendasMensal.setValue((produtoForm.controls.quantidadeMediaVendasMensal.value || 0) - 1)"
            >
              <ng-mat-icon name="remove" color="white" />
            </button>
            <input type="number" class="form-control lh-2" placeholder="99" formControlName="quantidadeMediaVendasMensal" [min]="0" />
            <button
              type="button"
              class="round-btn-icon btn"
              [color]="produtoForm.controls.tipo.value | colorTipoProduto"
              (click)="produtoForm.controls.quantidadeMediaVendasMensal.setValue((produtoForm.controls.quantidadeMediaVendasMensal.value || 0) + 1)"
            >
              <ng-mat-icon name="add" color="white" />
            </button>
          </div>
        </div>
      }
    </div>
  </div>

  <div id="end" class="flex-grow-1">
    <div class="d-flex flex-column gap-5">
      <div class="form-group mt-3 d-flex flex-column gap-2">
        <small-label> Nome do Insumo </small-label>
        <input type="text" class="form-control lh-2" id="nome" placeholder="Insira o nome do produto" formControlName="nome" />
      </div>

      <div class="form-group d-flex flex-column gap-2">
        <small-label> Código de barras </small-label>
        <input type="text" class="form-control lh-2" id="codigo-barras" placeholder="Insira o código de barras" formControlName="codigoBarras" />
      </div>

      <div class="form-group col px-0 d-flex flex-column gap-2">
        <small-label> Categoria da composição </small-label>

        <categoria-autocomplete [control]="produtoForm.controls.categoria" [tipoProduto]="produtoForm?.controls.tipo.value" />
      </div>
    </div>
  </div>
</div>
