<div id="container" class="h-100 d-flex flex-column justify-content-center position-relative mx-auto text-justify" [formGroup]="formProduto">
  <h1 color="dark" class="text-center fw-bold mb-5">Produtos {{ formProduto.controls.tipo.value === TipoProdutoEnum.insumo ? "Comprados" : "Produzidos" }}</h1>

  <p>Em nosso sistema os produtos comprados são chamados pelo nome de <span [color]="DefaultColorTipoProduto[TipoProdutoEnum.insumo]">"Insumos"</span> e são caracterizados pela cor laranja.</p>

  <p>
    Os insumos podem ser comprados e revendidos ou comprados e utilizados para formar outros produtos, aos quais chamamos de
    <span [color]="DefaultColorTipoProduto[TipoProdutoEnum.composicao]"> "Composições" </span> e são caracterizados pelo cor azul.
  </p>

  <div id="checkbox" class="form-check d-flex align-items-center justify-content-center w-100 gap-3 mt-3">
    <input id="ocultar-aviso-classificacao" type="checkbox" class="form-check-input my-0" formControlName="ocultarAvisoTipoProduto" />
    <label class="fw-400 form-check-label cursor-pointer no-selection" for="ocultar-aviso-classificacao"> Não visualizar novamente </label>
  </div>
</div>
