<div id="container" class="h-100">
  <div id="content" class="h-100 p-3 d-flex gap-3 flex-column">
    <h3 color="dark" class="fw-bold" [ngClass]="{ 'text-center': !isDesktop }">Completar cadastro</h3>

    <small-label> Você precisa completar o cadastro para saber mais informações desse produto, tais como o preço de custo. </small-label>

    <small-label> É possível completar o cadastro depois ou continuar agora </small-label>

    <small-label class="mt-2 fw-bold"> Qual o tipo de criação deseja fazer? </small-label>

    <div class="form-check rounded-3 p-0">
      <input class="form-check-input m-3" type="radio" name="completarCadastro" id="completarAgora" value="agora" [formControl]="control" />
      <label class="form-check-label ms-3 d-flex align-items-center" for="completarAgora"> Completar o cadastro agora </label>
    </div>
    <div class="form-check rounded-3 p-0">
      <input class="form-check-input m-3" type="radio" name="completarCadastro" id="completarDepois" value="depois" [formControl]="control" />
      <label class="form-check-label ms-3 d-flex align-items-center" for="completarDepois"> Completar o cadastro depois </label>
    </div>

    <div id="actions" class="position-absolute d-flex">
      <button class="btn flex-grow-1" color="apoio" (click)="cancel()">Cancelar</button>
      <button class="btn flex-grow-1" [color]="tipoProduto | colorTipoProduto" (click)="confirm()">Continuar</button>
    </div>
  </div>
</div>
