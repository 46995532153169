import { Component, Input } from '@angular/core';
import { TFormCriarConta } from '../criar-conta.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../../globals/base-class';
import { ColorDirective } from '../../../directives/color.directive';
import { minLengthPassword } from '../../../globals/globals';
import { passwordValidations } from '../../../globals/utils';

@Component({
  selector: 'section-dados-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SmallLabelComponent,
    NgMatIconComponent,
    NgClass,
    ColorDirective,
  ],
  templateUrl: './section-dados-login.component.html',
  styleUrl: './section-dados-login.component.scss'
})
export class SectionDadosLoginComponent extends BaseClass() {

  @Input() form: FormGroup<TFormCriarConta>;

  hidePassword: boolean = true;

  passwordValidations = passwordValidations;


  constructor() {
    super();
  }


}
