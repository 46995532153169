import { Component, Inject } from '@angular/core';
import {
  Composicao,
  Produto,
  ProdutoComposicaoForm,
  ProdutoComposicaoInsert,
  Produtos,
  TipoProdutoEnum,
} from '../../model/produto.model';
import { BaseClass } from '../../globals/base-class';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { ColorDirective } from '../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../directives/color-tipo-produto.pipe';
import { NgClass } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnidadeMedida, UnidadesMedida, UnidadesMedidaInsert } from '../../model/unidade-medida.model';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { closeModal, showApiErrorMessages } from '../../globals/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';
import { SelectProdutoComponent } from '../select-produto/select-produto.component';
import { UnidadeMedidaAutocompleteComponent } from '../autocomplete/unidade-medida-autocomplete/unidade-medida-autocomplete.component';
import { ProdutoService } from '../../services/produto.service';
import { ModalService } from '../../services/modal.service';
import { ApiResponseError } from '../../model/api.model';
import { UnidadeMedidaService } from '../../services/unidade-medida.service';

type TForm = {
  produto: FormControl<Produto>;
  quantidadeUnidadeMedida: FormControl<number>;
  unidadeMedida: FormControl<UnidadeMedida>;
}

export type ComposicaoModalAddProdutoComposicao = Pick<Composicao, 'quantidade' | 'nome' | 'tipoProduto' | 'id'>;

@Component({
  selector: 'selecionar-produto-composicao',
  standalone: true,
  imports: [
    SmallLabelComponent,
    ColorDirective,
    ColorTipoProdutoPipe,
    NgClass,
    ReactiveFormsModule,
    NgMatIconComponent,
    SelectProdutoComponent,
    UnidadeMedidaAutocompleteComponent,
  ],
  templateUrl: './selecionar-produto-composicao.component.html',
  styleUrl: './selecionar-produto-composicao.component.scss'
})
export class SelecionarProdutoComposicaoComponent extends BaseClass() {

  composicao: ComposicaoModalAddProdutoComposicao;
  tipo: TipoProdutoEnum;

  TipoProdutoEnum = TipoProdutoEnum;

  searchingUnidadesMedida: boolean = false;

  produtos: Produtos;
  unidadesMedida: UnidadesMedida;

  form: FormGroup<TForm> = new FormGroup({
    produto: new FormControl({ value: null, disabled: true }),
    quantidadeUnidadeMedida: new FormControl({ value: null, disabled: true }),
    unidadeMedida: new FormControl({ value: null, disabled: true }),
  });

  produtosJaAdicionados: Produtos;
  unidadesMedidaRendimento: UnidadesMedidaInsert<TipoProdutoEnum.composicao> = null;
  unidadesMedidaEquivalente: UnidadesMedidaInsert<TipoProdutoEnum.insumo> = null;

  constructor(
    @Inject('data') protected data: {
      tipo: TipoProdutoEnum,
      composicao: ComposicaoModalAddProdutoComposicao,
      produtosJaAdicionados: Produtos,
      produtoComposicao: ProdutoComposicaoForm,
      unidadesMedidaRendimento: UnidadesMedidaInsert<TipoProdutoEnum.composicao>,
      unidadesMedidaEquivalente: UnidadesMedidaInsert<TipoProdutoEnum.insumo>,
    },
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private modalService: ModalService,
    private produtoService: ProdutoService,
    private unidadeMedidaService: UnidadeMedidaService,
  ) {
    super();
    const sub = this.form.controls.produto.valueChanges.subscribe({
      next: (res) => {
        if (!res?.id) {
          this.form.controls.quantidadeUnidadeMedida.disable();
          this.form.controls.unidadeMedida.disable();
          return;
        }

        this.form.controls.quantidadeUnidadeMedida.enable();
        this.form.controls.unidadeMedida.enable();

      }
    })
    this.appendSubscription(sub);
  }

  ngOnInit() {
    this.composicao = this.data.composicao;
    this.tipo = this.data.tipo;
    this.produtosJaAdicionados = this.data.produtosJaAdicionados;
    this.unidadesMedidaRendimento = this.data.unidadesMedidaRendimento;
    this.unidadesMedidaEquivalente = this.data.unidadesMedidaEquivalente;
    if (this.data.produtoComposicao) this.setProduto();
  }

  private async setProduto() {
    if (!this.data?.produtoComposicao?.id) return;
    try {
      const unidadeMedida = (await this.unidadeMedidaService.getUnidadesMedida({ Termo: this.data.produtoComposicao.nomeUnidade }))?.data?.at(0);

      const produto = await this.produtoService.getProdutoById(this.data.produtoComposicao.idProduto);
      if (!produto) {
        this.toastService.show({ body: 'Produto não encontrado', color: 'danger' });
        this.voltar();
        return;
      }
      this.form.controls.produto.enable();
      this.form.controls.produto.setValue(produto);
      this.form.controls.quantidadeUnidadeMedida.setValue(this.data.produtoComposicao.quantidadeUsada);
      this.form.controls.unidadeMedida.setValue(unidadeMedida);
    } catch (err) {
      console.log(err);
      if (!showApiErrorMessages(this.modalService, err as ApiResponseError<'validation'>)) {
        this.toastService.show({ body: 'Erro ao buscar produto', color: 'danger' });
      }
    }
  }

  async confirm() {
    const { produto, quantidadeUnidadeMedida, unidadeMedida } = this.form.value;
    if (!produto || !quantidadeUnidadeMedida || !unidadeMedida) return this.toastService.show({ body: 'Preencha todos os campos', color: 'danger' });

    if (this.produtosJaAdicionados?.find(p => p.id === produto.id)) return this.toastService.show({ body: 'Produto já adicionado à composição', color: 'danger' });

    const produtoComposicaoInsert: ProdutoComposicaoInsert = {
      idProduto: produto.id,
      idProdutoComposto: this.composicao.id,
      idUnidadeMedida: unidadeMedida.id,
      quantidadeUsada: quantidadeUnidadeMedida,
    }
    const loading = this.modalService.presentLoading(this.data?.produtoComposicao?.id ? 'Editando produto na composição' : 'Adicionando produto à composição', true);

    try {
      const res = await (this.data?.produtoComposicao?.id ?
        this.produtoService.updateProdutoComposicao({ ...produtoComposicaoInsert, id: this.data?.produtoComposicao?.id }) :
        this.produtoService.insertProdutoComposicao(produtoComposicaoInsert)
      );

      if (!res?.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) {
          this.toastService.show({ body: this.data?.produtoComposicao?.id ? 'Erro ao editar produto na composição' : 'Erro ao adicionar produto à composição', color: "danger" });
        }
        return;
      }

      this.toastService.show({ body: this.data?.produtoComposicao?.id ? 'Produto editado com sucesso' : 'Produto adicionado à composição', color: 'success' });
      closeModal(this.activeModal, "saved", { ...produto, ...res.data }, 'produto');
    } catch (error) {
      console.log(error);
      if (!showApiErrorMessages(this.modalService, error as ApiResponseError<'validation'>)) {
        this.toastService.show({ body: this.data?.produtoComposicao?.id ? 'Erro ao editar produto na composição' : 'Erro ao adicionar produto à composição', color: "danger" });
      }
    } finally {
      loading.dismiss();
    }
  }

  voltar() {
    closeModal(this.activeModal, "cancel");
  }
}
