<div id="container" class="h-100">
  <toolbar class="w-100" [currentPage]="currentPage" [usuario]="(session | async).usuario" [menu]="mainMenu" />

  @if ((!isDesktop && mainMenu.opened) || isDesktop) {
    <sidenav class="w-100 h-100" [menu]="mainMenu" [usuario]="(session | async).usuario" />
  }

  @if (!isDesktop) {
    <tabbar class="w-100" />
  }

  <div id="content" class="w-100 position-relative overflow-x-hidden overflow-y-auto">
    <router-outlet />
  </div>
</div>
