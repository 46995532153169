import { Component, Input } from '@angular/core';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { ColorDirective } from '../../directives/color.directive';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { ColorTipoProdutoPipe } from '../../directives/color-tipo-produto.pipe';
import { TipoProdutoEnum } from '../../model/produto.model';
import { UnidadeMedidaFormEditar } from '../../home/produtos/detalhes-produto/detalhes-produto.component';

@Component({
  selector: 'list-medidas-checkboxes',
  standalone: true,
  imports: [
    SmallLabelComponent,
    ColorDirective,
    CheckboxComponent,
    ColorTipoProdutoPipe,
  ],
  templateUrl: './list-medidas-checkboxes.component.html',
  styleUrl: './list-medidas-checkboxes.component.scss'
})
export class ListMedidasCheckboxesComponent {

  @Input({ required: true }) unidadesMedida: Array<UnidadeMedidaFormEditar>;
  @Input({ required: true }) tipoProduto: TipoProdutoEnum;
  @Input() disabled: boolean = false;

}
