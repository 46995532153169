import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';

@Component({
  selector: 'operation-in-progress',
  standalone: true,
  imports: [NgClass, SpinnerComponent, ColorDirective],
  templateUrl: './operation-in-progress.component.html',
  styleUrl: './operation-in-progress.component.scss'
})
export class OperationInProgressComponent {
  @Input() message: string = 'Carregando...';
  @Input() absolute: boolean = false;
  @Input() h100: boolean = true;
  @Input() messageColor: AppColor = 'dark';
}
