import { Component, Input, SimpleChanges } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DebounceDirective } from '../../../directives/debounce.directive';
import { NgMatIconComponent } from '../../ng-mat-icon/ng-mat-icon.component';
import { ColorTipoProdutoPipe } from '../../../directives/color-tipo-produto.pipe';
import { ColorDirective } from '../../../directives/color.directive';
import { NovaUnidadeOptionComponent } from '../../options-autocomplete/nova-unidade-option/nova-unidade-option.component';
import { defaultDebounceInput } from '../../../globals/globals';
import { BaseClass } from '../../../globals/base-class';
import { UnidadeMedidaService } from '../../../services/unidade-medida.service';
import { UnidadeMedida, UnidadesMedida } from '../../../model/unidade-medida.model';
import { displayWith, showApiErrorMessages } from '../../../globals/utils';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TipoProdutoEnum } from '../../../model/produto.model';
import { ProdutoService } from '../../../services/produto.service';
import { ModalService } from '../../../services/modal.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'unidade-medida-autocomplete',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    DebounceDirective,
    NgMatIconComponent,
    ColorTipoProdutoPipe,
    ColorDirective,
    ReactiveFormsModule,

    NovaUnidadeOptionComponent,
  ],
  templateUrl: './unidade-medida-autocomplete.component.html',
  styleUrl: './unidade-medida-autocomplete.component.scss'
})
export class UnidadeMedidaAutocompleteComponent extends BaseClass() {

  @Input({ required: true }) control: FormControl<UnidadeMedida>;
  @Input({ required: true }) tipoProduto: TipoProdutoEnum;
  @Input() disabled: boolean = false;
  @Input() defaultSelect: 'unique' | 'first' = null;
  @Input() idProduto: string;

  searching: boolean = false;

  unidadesMedida: UnidadesMedida;

  debounce: number = defaultDebounceInput;

  displayWith = (value: UnidadeMedida) => displayWith(value, 'nome');

  constructor(
    private unidadeMedidaService: UnidadeMedidaService,
    private produtoService: ProdutoService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.idProduto === undefined) this.getUnidadesMedida();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['disabled']?.currentValue === true) this.control.disable({ emitEvent: false });
    else if (changes?.['disabled']?.currentValue === false && !this.searching) this.control.enable({ emitEvent: false });

    if (changes?.['idProduto']?.currentValue && changes?.['idProduto']?.currentValue !== changes?.['idProduto']?.previousValue) {
      this.control.setValue(null);
      this.control.disable();
      this.getUnidadesMedida();
    }
  }

  filter(el: HTMLInputElement) {
    el.disabled = false;
    el.focus();
    this.getUnidadesMedida(el.value);
  }

  async getUnidadesMedida(Termo: string = null) {
    if (this.searching) return;

    try {
      this.searching = true;
      const res = await (this.idProduto ? this.produtoService.getUnidadesMedidaComposicao(this.idProduto) : this.unidadeMedidaService.getUnidadesMedida({ Termo }));
      this.unidadesMedida = res instanceof Array ? res : res.data;
      if (this.control.disabled && !this.disabled) this.control.enable();

      switch (this.defaultSelect) {
        case 'first': this.control.setValue(this.unidadesMedida[0]); break;
        case 'unique': if (this.unidadesMedida.length === 1) this.control.setValue(this.unidadesMedida[0]); break;
      }
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar unidades de medida', color: "danger" });
    } finally {
      this.searching = false;
    }
  }

}
