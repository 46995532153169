import { Component, Inject } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { NgClass } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColorDirective } from '../../../directives/color.directive';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SectionDetalhesProdutoComponent } from './section-detalhes-produto/section-detalhes-produto.component';
import { SectionInfoTipoProdutoComponent } from './section-info-tipo-produto/section-info-tipo-produto.component';
import { SectionSobreUltimaCompraProdutoComponent } from './section-sobre-ultima-compra-produto/section-sobre-ultima-compra-produto.component';
import { SectionValorVendaProdutoComponent } from './section-valor-venda-produto/section-valor-venda-produto.component';
import { Location as NgLocation } from '@angular/common';
import { closeModal, dateDDMMYYYYIsInvalid, defaultClassModal, fileToBase64, showApiErrorMessages, updateUrl } from '../../../globals/utils';
import {
  ClassificacaoProdutoEnum,
  ComposicaoInsert,
  InsumoInsert,
  Produto,
  ProdutoCommonInsert,
  ProdutoComposicaoForm,
  TipoProdutoEnum,
  UnidadeMedidaProdutoEquivalentRendimento,
} from '../../../model/produto.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Categoria } from '../../../model/categoria.model';
import { SectionAvisoTipoProdutoComponent } from "./section-aviso-tipo-produto/section-aviso-tipo-produto.component";
import { StorageService } from '../../../services/storage.service';
import { ModalService } from '../../../services/modal.service';
import { ModalClose, OmitId } from '../../../model/custom-types';
import { UnidadeMedida, UnidadeMedidaInsert, UnidadesMedidaInsert } from '../../../model/unidade-medida.model';
import { SectionMedidaProdutoComponent } from './section-medida-produto/section-medida-produto.component';
import { SectionQuantidadeMedidaProdutoComponent } from './section-quantidade-medida-produto/section-quantidade-medida-produto.component';
import { parse } from 'date-fns';
import { ProdutoService } from '../../../services/produto.service';
import { ColorTipoProdutoPipe } from '../../../directives/color-tipo-produto.pipe';
import { SectionValorUnidadeVendaProdutoComponent } from './section-valor-unidade-venda-produto/section-valor-unidade-venda-produto.component';
import { ApiConstraintGetProdutos, ApiConstraintGetProdutosComposicao, ApiResponseError } from '../../../model/api.model';
import { TQueryParams } from '../produtos.component';
import { ToastService } from '../../../services/toast.service';
import { SectionProdutosComposicaoComponent } from './section-produtos-composicao/section-produtos-composicao.component';
import { ConfirmCompletarCadastroProdutoComponent, ModalCloseConfirmCompletarCadastroProduto } from '../../../custom-components/confirm-completar-cadastro-produto/confirm-completar-cadastro-produto.component';
import { ActionFeedbackComponent, ModalCloseActionFeedbackData } from '../../../custom-components/action-feedback/action-feedback.component';
import { CategoriaService } from '../../../services/categoria.service';
import { UnidadeMedidaService } from '../../../services/unidade-medida.service';
import { PaginatorOpts } from '../../../custom-components/paginator/paginator.component';
import { BehaviorSubject } from 'rxjs';

export type DataModalNovoProduto = ApiConstraintGetProdutos & {
  section?: TSection,
  evendido?: boolean,
  produto?: Produto,
};

export type TFormProduto = Partial<{
  classificacao: FormControl<ClassificacaoProdutoEnum>,
  quantidadeMediaVendasMensal: FormControl<number>,
  nome: FormControl<string>,
  codigoBarras: FormControl<string>,
  categoria: FormControl<Categoria>;
  imagem: FormControl<string>;
  imagemFile: FormControl<File>;

  tipo: FormControl<TipoProdutoEnum>;

  ocultarAvisoTipoProduto: FormControl<boolean>;

  valorUltimaCompra: FormControl<number>;
  quantidadeUnidadeMedidaUltimaCompra: FormControl<number>;
  unidadeMedidaUltimaCompra: FormControl<UnidadeMedida>;
  marcaUltimaCompra: FormControl<string>;
  localUltimaCompra: FormControl<string>;
  dataUltimaCompra: FormControl<string>;

  quantidadeUnidadeMedidaVenda: FormControl<number>;

  valorUnidadeMedidaVenda: FormControl<number>;

  valorVenda: FormControl<number>;

  tagCalculoCusto: FormControl<boolean>;
}>

export type TFormProdutoValue = {
  [key in keyof TFormProduto]?: TFormProduto[key]['value'];
}

const CHILDREN = [
  SectionDetalhesProdutoComponent,
  SectionInfoTipoProdutoComponent,
  SectionAvisoTipoProdutoComponent,
  SectionSobreUltimaCompraProdutoComponent,
  SectionMedidaProdutoComponent,
  SectionQuantidadeMedidaProdutoComponent,
  SectionValorVendaProdutoComponent,
  SectionValorUnidadeVendaProdutoComponent,
  SectionProdutosComposicaoComponent,
];

@Component({
  selector: 'app-novo-produto',
  standalone: true,
  imports: [
    CHILDREN,
    NgClass,
    ColorDirective,
    ColorTipoProdutoPipe,
  ],
  templateUrl: './novo-produto.component.html',
  styleUrls: ['../common/common-styles-modal-full-content-produto.scss', './novo-produto.component.scss']
})
export class NovoProdutoComponent extends BaseClass() {

  backButtonText: string = 'Cancelar';
  nextButtonText: string = 'Próximo';

  SECTIONS = () => this.form.controls.tipo.value === TipoProdutoEnum.composicao ? SECTIONS_COMPOSICAO : SECTIONS_INSUMO;

  activeSection: TSection = 'detalhes';

  form: FormGroup<TFormProduto> = new FormGroup<TFormProduto>({
    codigoBarras: new FormControl(null),
    classificacao: new FormControl(null),
    nome: new FormControl(null),
    quantidadeMediaVendasMensal: new FormControl(null),
    categoria: new FormControl({ value: null, disabled: true }),
    imagem: new FormControl(null),
    imagemFile: new FormControl(null),

    tipo: new FormControl(null),

    ocultarAvisoTipoProduto: new FormControl(this.storageService.get<boolean>('OCULTAR_AVISO_TIPO_PRODUTO') || false),

    valorUltimaCompra: new FormControl(null),
    quantidadeUnidadeMedidaUltimaCompra: new FormControl(null),
    unidadeMedidaUltimaCompra: new FormControl(null),
    marcaUltimaCompra: new FormControl(null),
    dataUltimaCompra: new FormControl(null),
    localUltimaCompra: new FormControl(null),

    quantidadeUnidadeMedidaVenda: new FormControl(null),

    valorUnidadeMedidaVenda: new FormControl(null),

    valorVenda: new FormControl(null),

    tagCalculoCusto: new FormControl(null),
  });

  produtosComposicao: Array<ProdutoComposicaoForm> = [];
  unidadesMedidaRendimento: UnidadesMedidaInsert<TipoProdutoEnum.composicao> = null;
  unidadesMedidaEquivalente: UnidadesMedidaInsert<TipoProdutoEnum.insumo> = null;

  produtoID: string = null;
  idCompraProduto: string = null;
  lastInsert: Produto = null;

  routerUpdatedLocally: boolean = false;

  TipoProdutoEnum = TipoProdutoEnum;

  paginatorProdutosComposicaoOpts: PaginatorOpts;
  operationProdutosComposicaoInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    @Inject('data') private data: DataModalNovoProduto,
    private router: Router,
    private route: ActivatedRoute,
    private ngbActiveModal: NgbActiveModal,
    private ngLocation: NgLocation,
    private storageService: StorageService,
    private modalService: ModalService,
    private produtoService: ProdutoService,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
    private unidadeMedidaService: UnidadeMedidaService,
  ) {
    super();
  }

  ngOnInit() {
    const sub = this.router.events.subscribe({
      next: (ev) => {
        if (ev instanceof NavigationEnd && !this.routerUpdatedLocally) this.voltar();
      }
    });
    this.appendSubscription(sub);

    const sub2 = this.form.valueChanges.subscribe({
      next: (value) => {
        if ([true, false].includes(value.ocultarAvisoTipoProduto)) {
          this.storageService.set('OCULTAR_AVISO_TIPO_PRODUTO', value.ocultarAvisoTipoProduto);
        }
      }
    });
    this.appendSubscription(sub2);

    if (!this.data?.produto) {
      this.form.patchValue({
        tipo: this.data.TipoProduto || null,
        classificacao: this.data.evendido === true ? ClassificacaoProdutoEnum.vendido : ClassificacaoProdutoEnum.naoVendido,
      });
    } else {
      const produto = this.data.produto;

      this.form.patchValue({
        categoria: produto.categoria,
        classificacao: produto.classificacao,
        codigoBarras: produto.codigoBarras,
        dataUltimaCompra: produto.dataUltimaCompra ? new Date(produto.dataUltimaCompra).toLocaleDateString('pt-BR') : null,
        imagem: produto.imagem,
        localUltimaCompra: produto.localCompra,
        marcaUltimaCompra: produto.marca,
        nome: produto.nome,
        quantidadeMediaVendasMensal: produto.quantidadeMediaVendidaMensal,
        quantidadeUnidadeMedidaUltimaCompra: produto.quantidadeComprada,
        tipo: produto.tipoProduto,
        unidadeMedidaUltimaCompra: {
          id: produto.idUnidadeMedidaCompra,
          nome: produto.nomeUnidadeMedidaCompra,
          sigla: produto.siglaUnidadeMedidaCompra,
        },
        valorUltimaCompra: produto.valorComprado,
        valorUnidadeMedidaVenda: produto.valorUnidadeMedidaVenda,
        valorVenda: produto.valorVenda,
      });

      this.setUnidadesRendimentoEquivalente(produto);

      this.getProdutosComposicao(produto.id);
    }

    const queryParams = this.route.snapshot?.queryParams;
    const section: TSection = this.data.section || queryParams?.['section'] || 'detalhes';
    this.produtoID = queryParams?.['produtoID'] || this.data?.produto?.id || null;

    if (this.produtoID) this.getProduto(section);

    if (!this.secoesValidas(section, false)) return;

    this.setActiveSection(Math.max(0, this.SECTIONS().indexOf(section)));
  }

  private setUnidadesRendimentoEquivalente(produto: Produto) {
    const _sanitizeUnidadeMedida = <T = TipoProdutoEnum>(u: UnidadeMedidaProdutoEquivalentRendimento<T>) => {
      return {
        id: u.idUnidadeMedida,
        nome: u.nomeUnidadeMedida,
        idUnidadeMedida: u.idUnidadeMedida,
        idCompraProduto: u.idCompraProduto,
        tipo: produto.tipoProduto,
      } as Pick<UnidadeMedidaInsert<T>, 'id' | 'idCompraProduto' | 'idUnidadeMedida' | 'nome' | 'tipo'>;
    }

    if (produto.tipoProduto === TipoProdutoEnum.composicao)
      this.unidadesMedidaRendimento = produto.unidadesMedidaRendimentos?.map((x) => ({ ..._sanitizeUnidadeMedida(x), quantidadeRendimento: x.quantidadeRendimento })) || [];
    if (produto.tipoProduto === TipoProdutoEnum.insumo)
      this.unidadesMedidaEquivalente = produto.unidadesMedidaEquivalentes?.map((x) => ({ ..._sanitizeUnidadeMedida(x), quantidadeEquivalente: x.quantidadeEquivalente })) || [];
  }

  private async getProduto(sectionToActive: TSection) {
    const loading = this.modalService.presentLoading('Carregando produto...');
    try {
      const produto = await this.produtoService.getProdutoById(this.produtoID);
      if (!produto) {
        if (showApiErrorMessages(this.modalService, produto as ApiResponseError<'validation'>)) return;
        this.toastService.show({ body: 'Erro ao carregar produto', color: 'danger' });
        return;
      }

      this.idCompraProduto = produto.idCompraProduto;
      this.produtoID = produto.id;

      this.form.patchValue({
        classificacao: produto.classificacao,
        codigoBarras: produto.codigoBarras,
        dataUltimaCompra: produto.dataUltimaCompra ? new Date(produto.dataUltimaCompra).toLocaleDateString('pt-BR') : null,
        imagem: produto.imagem,
        localUltimaCompra: produto.localCompra,
        marcaUltimaCompra: produto.marca,
        nome: produto.nome,
        quantidadeMediaVendasMensal: produto.quantidadeMediaVendidaMensal,
        quantidadeUnidadeMedidaUltimaCompra: produto.quantidadeComprada,
        tipo: produto.tipoProduto,
        valorUltimaCompra: produto.valorComprado,
        valorUnidadeMedidaVenda: produto.valorUnidadeMedidaVenda,
        valorVenda: produto.valorVenda,
      });

      const [categoria, unidadeMedidaCompra] = await Promise.all([
        produto.idCategoria ? this.categoriaService.getCategoriaById(produto.idCategoria) : null,
        produto.idUnidadeMedidaCompra ? this.unidadeMedidaService.getUnidadeMedidaById(produto.idUnidadeMedidaCompra) : null,
      ]);

      if (categoria) this.form.controls.categoria.setValue(categoria);
      if (unidadeMedidaCompra) this.form.controls.unidadeMedidaUltimaCompra.setValue(unidadeMedidaCompra);

      if (sectionToActive) this.setActiveSection(this.SECTIONS().indexOf(sectionToActive));

      this.setUnidadesRendimentoEquivalente(produto);
      this.getProdutosComposicao();
    } catch (err) {
      if (showApiErrorMessages(this.modalService, err)) return;
      this.toastService.show({ body: 'Erro ao carregar produto', color: 'danger' });
    } finally {
      loading.dismiss();
    }
  }

  async getProdutosComposicao(idProduto: string = null) {
    try {
      this.operationProdutosComposicaoInProgress$.next(true);

      const PageSize = this.paginatorProdutosComposicaoOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_PRODUTOS_COMPOSICAO')
      const constraints: ApiConstraintGetProdutosComposicao = {
        Page: this.paginatorProdutosComposicaoOpts?.page,
        PageSize,
      };
      const res = await this.produtoService.getProdutosComposicao(this.produtoID || idProduto, constraints);
      if (res.errors) return this.toastService.show({ body: 'Erro ao buscar produtos da composição', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data } = res;
      this.produtosComposicao = data;
      this.paginatorProdutosComposicaoOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar produtos da composição', color: 'danger' });
    } finally {
      this.operationProdutosComposicaoInProgress$.next(false);
    }
  }

  updatePageSizeProdutosComposicao(size: number) {
    this.paginatorProdutosComposicaoOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PRODUTOS_COMPOSICAO', size);
    this.getProdutosComposicao();
  }

  navigatePageProdutosComposicao(page: number) {
    this.paginatorProdutosComposicaoOpts.page = page;
    this.getProdutosComposicao();
  }

  private podeAvancarProximoPasso(passoAtual: TSection, showAlert: boolean = true): boolean {
    let messages: Array<string> = [];
    const {
      // seção detalhes
      nome, codigoBarras, categoria, imagem, imagemFile, quantidadeMediaVendasMensal, classificacao,

      // seção info-tipo
      tipo,

      // seção sobre-ultima-compra
      valorUltimaCompra, dataUltimaCompra, quantidadeUnidadeMedidaUltimaCompra, unidadeMedidaUltimaCompra, marcaUltimaCompra, localUltimaCompra,

      // seção quantidade-medida
      quantidadeUnidadeMedidaVenda,

      // seção valor-unidade-venda
      valorUnidadeMedidaVenda,

      // seção valor-venda
      valorVenda,
    } = showAlert ? this.form.value : this.form.getRawValue();

    switch (passoAtual) {
      case "detalhes":
        if (!nome) messages.push("Nome do produto é obrigatório");
        if (!categoria) messages.push("Categoria é obrigatória");
        if (classificacao === ClassificacaoProdutoEnum.vendido && !quantidadeMediaVendasMensal) messages.push("Quantidade é obrigatória");

        if (messages.length > 0) break;

        if (classificacao === ClassificacaoProdutoEnum.naoVendido) this.form.controls.quantidadeMediaVendasMensal.setValue(null);
        if (!this.form.controls.tipo.value) this.form.controls.tipo.setValue(TipoProdutoEnum.insumo);
        break;


      case "info-tipo":
        if (!tipo) messages.push("Classificação do produto é obrigatória");

        break;

      case "sobre-ultima-compra":
        if (!valorUltimaCompra) messages.push("Valor da última compra é obrigatório");
        if (!dataUltimaCompra) messages.push("Data da última compra é obrigatória");
        if (dataUltimaCompra && dateDDMMYYYYIsInvalid(dataUltimaCompra)) messages.push("Data da última compra é inválida");
        if (!quantidadeUnidadeMedidaUltimaCompra) messages.push("Quantidade da última compra é obrigatória");
        if (!unidadeMedidaUltimaCompra) messages.push("Unidade de medida da última compra é obrigatória");

        if (tipo !== TipoProdutoEnum.insumo) break;

        // if (!marcaUltimaCompra) messages.push("Marca da última compra é obrigatória");
        // if (!localUltimaCompra) messages.push("Local da última compra é obrigatório");
        break;

      case "medida":
        if (!((tipo === TipoProdutoEnum.composicao ? this.unidadesMedidaRendimento : this.unidadesMedidaEquivalente)?.length >= 1)) messages.push("Unidade de medida de venda/uso é obrigatória");
        break;

      case "quantidade-medida":
        if (
          tipo === TipoProdutoEnum.insumo && this.unidadesMedidaEquivalente.some(x => !x.quantidadeEquivalente) ||
          tipo === TipoProdutoEnum.composicao && this.unidadesMedidaRendimento.some(x => !x.quantidadeRendimento)
        )
          messages.push("Quantidade de medida de venda/uso é obrigatória");
        break;

      case "valor-unidade-venda":
        if (!(valorVenda > 0)) messages.push("Valor de venda é obrigatório e deve ser maior que zero");
        break;

      // case "valor-venda":
      //   if (!(valorVenda > 0)) messages.push("Valor de venda é obrigatório e deve ser maior que zero");
      //   break;

      case "produtos-composicao":
        if (!(this.produtosComposicao.length >= 1)) messages.push("Adicione ao menos um produto na composição");
        break;
    }

    if (messages.length > 0) {
      if (showAlert) this.modalService.presentAlert("Atenção", messages.join("\n"));
      return false;
    }

    return true;
  }

  async proximoPasso() {
    let index = this.SECTIONS().indexOf(this.activeSection);

    if (index < 0) return;

    const eComposicao = this.form.controls.tipo.value === TipoProdutoEnum.composicao;
    const eInsumo = this.form.controls.tipo.value === TipoProdutoEnum.insumo;

    const insumoComUnidadeCompraIgualUnidadeUso = eInsumo && this.form.controls.unidadeMedidaUltimaCompra.value?.id === this.unidadesMedidaEquivalente?.[0]?.idUnidadeMedida;

    let forceClose: boolean = false;

    if (insumoComUnidadeCompraIgualUnidadeUso && !!this.unidadesMedidaEquivalente?.[0]?.idUnidadeMedida && this.SECTIONS()[index] === 'medida') {
      this.unidadesMedidaEquivalente[0].quantidadeEquivalente = 1;
      if (this.form.controls.classificacao.value === ClassificacaoProdutoEnum.vendido) index++;
      else forceClose = true;
    }

    if (!this.podeAvancarProximoPasso(this.activeSection)) return;

    if (this.SECTIONS()[index + 1] === 'aviso-tipo' && this.storageService.get<boolean>('OCULTAR_AVISO_TIPO_PRODUTO')) index++;

    const stepCompletarCadastroComposicao: boolean = eComposicao && this.SECTIONS()[index + 1] === 'medida';

    const stepAntesSelecaoProdutoComposicao: boolean = this.activeSection === 'quantidade-medida' && eComposicao;

    const stepAntesMedidas: boolean = this.SECTIONS()[index + 1] === 'medida';

    if (
      (this.activeSection === this.SECTIONS().slice(-1)[0]) ||
      (this.activeSection === 'quantidade-medida' && eInsumo && this.form.controls.classificacao.value === ClassificacaoProdutoEnum.naoVendido) ||
      (this.activeSection === 'produtos-composicao' && eComposicao && this.form.controls.classificacao.value === ClassificacaoProdutoEnum.naoVendido) ||
      stepCompletarCadastroComposicao ||
      stepAntesSelecaoProdutoComposicao ||
      stepAntesMedidas ||
      forceClose
    ) {
      this.lastInsert = await this.salvarProduto(!stepCompletarCadastroComposicao && !stepAntesSelecaoProdutoComposicao && !stepAntesMedidas);
      if (!this.lastInsert) return;
      if (!stepCompletarCadastroComposicao && !stepAntesSelecaoProdutoComposicao && !stepAntesMedidas) return;
    }

    if (stepCompletarCadastroComposicao) {
      const salvarAgora = await this.confirmSalvarAgora();
      if (!salvarAgora) return;

      if (salvarAgora === "depois") {
        const dataModal: ModalCloseActionFeedbackData = {
          title: "Produto criado! Complete o cadastro em outro momento.",
          message: "É necessário completar o cadastro dos produtos depois!",
          color: 'verde',
          icon: 'verified',
          textCloseButton: 'Fechar',
        }
        this.modalService.presentModal(ActionFeedbackComponent, "slide-bottom", dataModal, { keyboard: false, backdrop: 'static' });
        updateUrl(this.ngLocation, {});
        this.navigateRouter();
        return closeModal<Partial<Produto>, 'produto'>(this.ngbActiveModal, "saved", this.lastInsert, 'produto');
      }
    }

    this.setActiveSection(index + ((stepCompletarCadastroComposicao && this.form.controls.classificacao.value === ClassificacaoProdutoEnum.naoVendido) ? 2 : 1));
  }

  private async confirmSalvarAgora() {
    try {
      const confirm = this.modalService.presentModal(ConfirmCompletarCadastroProdutoComponent, defaultClassModal(this), { tipo: this.form.controls.tipo.value }, { windowClass: 'blur-backdrop' });
      const res: ModalClose<ModalCloseConfirmCompletarCadastroProduto> = await confirm.result;
      return res.data;
    } catch (err) {
      console.log(err)
      return null;
    }
  }

  async passoAnterior() {
    if (this.activeSection === this.SECTIONS()[0] && (await this.confirmarVoltar())) return this.voltar();
    let index = this.SECTIONS().indexOf(this.activeSection);

    if (this.SECTIONS()[index - 1] === 'valor-venda') index--;
    if (this.SECTIONS()[index - 1] === 'aviso-tipo') index--;

    if (index > 0) this.setActiveSection(index - 1);
  }

  private async confirmarVoltar(): Promise<boolean> {
    if (this.form.pristine) return true;
    const confirm = this.modalService.presentConfirm("Deseja realmente cancelar?", "Os dados preenchidos serão perdidos.");
    const res: ModalClose<boolean> = await confirm.result;
    return res.data;
  }

  private setActiveSection(index: number) {
    this.activeSection = this.SECTIONS()[Math.max(0, index)];

    const queryParams: TQueryParams = Object.fromEntries(Object.entries({
      section: this.activeSection,
      action: 'novo',
      evendido: this.data.evendido === false ? false : true,
      produtoID: this.produtoID,
    }).filter(([k, v]) => v !== null));
    updateUrl(this.ngLocation, queryParams);

    this.backButtonText = (this.activeSection === this.SECTIONS()[0]) ? 'Cancelar' : 'Voltar';

    const eInsumo = this.form.controls.tipo.value === TipoProdutoEnum.insumo;
    const eComposicao = this.form.controls.tipo.value === TipoProdutoEnum.composicao;
    const naoVendido = this.form.controls.classificacao.value === ClassificacaoProdutoEnum.naoVendido;

    this.nextButtonText = (
      (this.activeSection === this.SECTIONS().slice(-1)[0]) ||
      (this.activeSection === 'quantidade-medida' && eInsumo && naoVendido) ||
      (this.activeSection === 'produtos-composicao' && eComposicao && naoVendido) ||
      (this.activeSection === 'medida' && eInsumo && naoVendido && this.unidadesMedidaEquivalente?.at(0)?.idUnidadeMedida === this.form.controls.unidadeMedidaUltimaCompra.value?.id)
    ) ? 'Salvar Produto' : 'Próximo';
  }

  private secoesValidas(secaoAtual: TSection, showAlert: boolean = true): boolean {
    const secoesParaValidar = this.SECTIONS().slice(0, this.SECTIONS().indexOf(secaoAtual) + 1);
    for (let i = 0; i < secoesParaValidar.length; i++) {
      if (!this.podeAvancarProximoPasso(secoesParaValidar[i], showAlert)) {
        this.setActiveSection(i);
        return false;
      }
    }

    return true;
  }

  async salvarProduto(dismissModal: boolean = true) {
    const formValue: TFormProdutoValue = this.form.value;
    const produto = await this.makeProdutoInsert(formValue);

    if (!this.activeSection) return;

    const loading = this.modalService.presentLoading('Salvando produto...', true);

    try {
      const res = await (this.produtoID ? this.produtoService.updateProduto({ ...produto, id: this.produtoID }) : this.produtoService.insertProduto({ ...produto, ativo: true }));
      if (!res.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Erro ao salvar produto', color: 'danger' });
        return res?.data;
      }

      if (res.data?.id) {
        this.produtoID = res.data.id;
        this.idCompraProduto = res.data.idCompraProduto;

        this.setUnidadesRendimentoEquivalente(res.data);

        const queryParams: TQueryParams = {
          section: this.activeSection,
          action: 'novo',
          evendido: res.data?.classificacao === ClassificacaoProdutoEnum.vendido ? true : false,
          produtoID: res.data.id,
        }
        updateUrl(this.ngLocation, queryParams);
      }

      this.toastService.show({ body: `${produto.tipoProduto === TipoProdutoEnum.composicao ? 'Composição salva' : 'Produto salvo'} com sucesso`, color: 'success' });
      updateUrl(this.ngLocation, {});
      this.navigateRouter();

      if (dismissModal) {
        const dataModal: ModalCloseActionFeedbackData = {
          title: "Produto criado com sucesso!",
          color: 'verde',
          icon: 'verified',
        }
        this.modalService.presentModal(ActionFeedbackComponent, "slide-bottom", dataModal, { keyboard: false, backdrop: 'static' });

        closeModal<Partial<Produto>, 'produto'>(this.ngbActiveModal, "saved", res.data, 'produto');
      }
      return res?.data;
    } catch (err) {
      console.log(err);
      if (!showApiErrorMessages(this.modalService, err)) this.toastService.show({ body: 'Erro ao salvar produto', color: 'danger' });
    } finally {
      loading.dismiss();
    }
  }

  private async makeProdutoInsert<T = TipoProdutoEnum>(formValue: TFormProdutoValue): Promise<(InsumoInsert | ComposicaoInsert)> {
    const idCategoria = formValue.categoria?.id;
    const nome = formValue.nome;
    const codigoBarras = formValue.codigoBarras || null;
    const quantidadeMediaVendidaMensal = formValue.quantidadeMediaVendasMensal || null;
    const classificacao = formValue.classificacao;
    const tipoProduto = formValue.tipo;
    const valorVenda = formValue.valorVenda || null;
    const valorComprado = formValue.valorUltimaCompra || null;
    const quantidadeComprada = formValue.quantidadeUnidadeMedidaUltimaCompra || null;
    const marca = formValue.marcaUltimaCompra || null;
    const localCompra = formValue.localUltimaCompra || null;
    const dataUltimaCompra = formValue.dataUltimaCompra ? parse(formValue.dataUltimaCompra, 'dd/MM/yyyy', new Date())?.toISOString() : null;
    const idUnidadeMedidaCompra = formValue.unidadeMedidaUltimaCompra?.id;
    const valorUnidadeMedidaVenda = formValue.valorUnidadeMedidaVenda || null;
    const imagem = formValue.imagem || null;
    const imagemFile = formValue.imagemFile || null;

    const common: OmitId<ProdutoCommonInsert> = {
      idCategoria,
      nome,
      codigoBarras,
      quantidadeMediaVendidaMensal,
      classificacao,
      tipoProduto,
      valorVenda,
      valorUnidadeMedidaVenda,
      idCompraProduto: this.idCompraProduto,
      ativo: true,
    }

    if (imagemFile) {
      const base64Image = await fileToBase64(imagemFile);
      if (base64Image) common.imageBase64 = base64Image;
    }

    const camposInsumo: Omit<InsumoInsert, keyof ProdutoCommonInsert> = {
      valorComprado,
      quantidadeComprada,
      marca,
      localCompra,
      dataUltimaCompra,
      idUnidadeMedidaCompra,
      unidadesMedidaEquivalentes: this.unidadesMedidaEquivalente,
    }

    const camposComposicao: Omit<ComposicaoInsert, keyof ProdutoCommonInsert> = {
      unidadesMedidaRendimentos: this.unidadesMedidaRendimento,
    }

    const produto: T extends TipoProdutoEnum.insumo ? InsumoInsert : ComposicaoInsert = {
      ...common,
      ...(tipoProduto === TipoProdutoEnum.insumo ? camposInsumo : camposComposicao),
    } as T extends TipoProdutoEnum.insumo ? InsumoInsert : ComposicaoInsert;

    return produto;
  }

  setUnidadeRendimentoEquivalente(unidade: Pick<UnidadeMedidaInsert, 'idCompraProduto' | 'idUnidadeMedida' | 'nome'>) {
    const commonFields: Pick<UnidadeMedidaInsert, 'idCompraProduto' | 'idUnidadeMedida' | 'nome'> = {
      idCompraProduto: this.idCompraProduto,
      idUnidadeMedida: unidade.idUnidadeMedida,
      nome: unidade.nome,
    }

    if (this.form.controls.tipo.value === TipoProdutoEnum.insumo) {
      if (!this.unidadesMedidaEquivalente.find(x => x.idUnidadeMedida === unidade.idUnidadeMedida)) {
        this.unidadesMedidaEquivalente = [{
          ...commonFields,
          quantidadeEquivalente: null,
        }];
      }
      return;
    }

    if (!this.unidadesMedidaRendimento.find(x => x.idUnidadeMedida === unidade.idUnidadeMedida)) {
      this.unidadesMedidaRendimento = [{
        ...commonFields,
        quantidadeRendimento: null,
      }];
    }
  }

  removeUnidadeMedida(unidade: UnidadeMedidaInsert) {
    if (this.form.controls.tipo.value === TipoProdutoEnum.insumo) {
      this.unidadesMedidaEquivalente = this.unidadesMedidaEquivalente.filter(x => x.idUnidadeMedida !== unidade.idUnidadeMedida);
      return;
    }

    this.unidadesMedidaRendimento = this.unidadesMedidaRendimento.filter(x => x.idUnidadeMedida !== unidade.idUnidadeMedida);
  }

  produtoComposicaoAdicionado(prod: ProdutoComposicaoForm) {
    this.getProdutosComposicao();
  }

  private navigateRouter() {
    this.routerUpdatedLocally = true;
    this.router.navigate([], { queryParams: {} }).then(() => {
      this.routerUpdatedLocally = false;
    })
  }

  async excluirProduto() {
    const confirm = this.modalService.presentConfirm("Deseja realmente excluir?", "Essa ação não poderá ser desfeita.", true);
    const res: ModalClose<boolean> = await confirm.result;
    if (!res.data) return;

    const loading = this.modalService.presentLoading('Excluindo produto...');
    try {
      const res = await this.produtoService.deleteProduto(this.produtoID);
      if (!res.success) {
        if (showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) return;
        this.toastService.show({ body: 'Erro ao excluir produto', color: 'danger' });
        return;
      }

      this.toastService.show({ body: 'Produto excluído com sucesso', color: 'success' });
      updateUrl(this.ngLocation, {});
      this.navigateRouter();
      closeModal(this.ngbActiveModal, "deleted");
    } catch (err) {
      if (showApiErrorMessages(this.modalService, err)) return;
      this.toastService.show({ body: 'Erro ao excluir produto', color: 'danger' });
    } finally {
      loading.dismiss();
    }
  }

  voltar() {
    updateUrl(this.ngLocation, {});
    this.navigateRouter();
    closeModal(this.ngbActiveModal, "cancel");
  }

}

type TSection = 'detalhes' | 'info-tipo' | 'aviso-tipo' | 'sobre-ultima-compra' | 'medida' | 'quantidade-medida' | 'valor-venda' | 'produtos-composicao' | 'valor-unidade-venda';
export { TSection as SectionNovoProduto };
export const SECTIONS_INSUMO: Array<TSection> = ['detalhes', 'info-tipo', 'aviso-tipo', 'sobre-ultima-compra', 'medida', 'quantidade-medida', 'valor-unidade-venda'];
export const SECTIONS_COMPOSICAO: Array<TSection> = ['detalhes', 'info-tipo', 'aviso-tipo', 'medida', 'quantidade-medida', 'produtos-composicao', 'valor-unidade-venda'];
