import { Injectable } from '@angular/core';
import { Estado } from '../model/endereco.model';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../environments/environment.dev';
import { ApiConstraintGetCidades, ApiConstraintGetEstados } from '../model/api.model';
import { makeParamsGet } from '../globals/utils';

@Injectable({
  providedIn: 'root'
})
export class EnderecoService extends BaseHttpService {

  getEstadosBrasil(constraints?: ApiConstraintGetEstados) {
    const params = makeParamsGet(constraints);
    return this.get<Estado>(environment.api.endpoints.estados, params);
  }

  getCidadesEstado(constraints?: ApiConstraintGetCidades) {
    const params = makeParamsGet(constraints, ['SiglaEstado']);
    return this.get<Estado>(`${environment.api.endpoints.cidades}`, params);
  }
}
