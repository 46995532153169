<div id="container" [ngClass]="{ 'edit-produto': formEdit, 'h-100': !formEdit }">
  <div #content id="content" class="rounded-4 py-4 px-3" [ngClass]="{ 'h-100': formEdit }">
    <segments [isNavigation]="false" [activeSegment]="activeSegment" [segments]="segments" [color]="produto?.tipoProduto | colorTipoProduto" [disabled]="operationInProgress$ | async" (onSegmentChange)="segmentChanged($event)" />

    <div id="body">
      @if (operationInProgress$ | async) {
        <operation-in-progress />
      } @else {
        @switch (activeSegment?.path?.[0]) {
          @case ("detalhes") {
            @if (formEdit) {
              <section-detalhes-produto-editar [formEdit]="formEdit" />
            } @else {
              <section-detalhes-produto [produto]="produto" />
            }
          }
          @case ("medidas") {
            @if (formEdit) {
              <section-medidas-produto-editar [formEdit]="formEdit" [unidadesMedida]="unidadesMedida" (unidadeMedidaChange)="unidadeMedidaChanged($event)" />
            } @else {
              <section-medidas-produto [produto]="produto" />
            }
          }
          @case ("composicao") {
            @if (formEdit) {
              <section-composicao-produto-editar [composicao]="produto" />
            } @else {
              <section-composicao-produto [produto]="produto" />
            }
          }
          @case ("vendas") {
            <section-vendas-produto [produto]="produto" />
          }
          @case ("compras") {
            <section-compras-produto [produto]="produto" />
          }
        }
      }
    </div>
  </div>

  <div id="actions" class="position-absolute p-4 rounded-top-4" [ngClass]="{ 'd-flex gap-3 align-items-center justify-content-end': isDesktop }">
    @if (produto?.id && !formEdit) {
      <button [disabled]="operationInProgress$ | async" type="button" color="danger" class="btn btn-outline" (click)="delete()">
        <span> Excluir </span>
      </button>
    }

    <button [disabled]="operationInProgress$ | async" type="button" color="apoio" class="btn" (click)="close()">
      <span> {{ formEdit ? "Cancelar" : "Voltar" }} </span>
    </button>

    <button [disabled]="operationInProgress$ | async" type="button" class="btn" [color]="produto?.tipoProduto | colorTipoProduto" (click)="editar()">
      <span> {{ formEdit ? "Concluir edição" : "Editar" }} </span>
    </button>
  </div>
</div>
