import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../environments/environment.dev';
import { RamoNegocio } from '../model/ramo-negocio.model';
import { ApiConstraintGetRamosNegocio } from '../model/api.model';
import { makeParamsGet } from '../globals/utils';

@Injectable({
  providedIn: 'root'
})
export class RamoNegocioService extends BaseHttpService {

  getRamosNegocio(constraints?: ApiConstraintGetRamosNegocio) {
    const params = makeParamsGet(constraints);
    return this.get<RamoNegocio>(environment.api.endpoints.ramonegocios, params);
  }

}
