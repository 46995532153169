import { AsyncPipe, CurrencyPipe, NgClass } from '@angular/common';
import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexPlotOptions,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ApexStroke,
  ChartComponent,
  NgApexchartsModule,
  ApexLegend,
} from 'ng-apexcharts';
import { ColorDirective } from '../../directives/color.directive';
import { OperationInProgressComponent } from '../operation-in-progress/operation-in-progress.component';
import { ChartYAxisRedudancyFactor, RGBColors } from '../../globals/globals';
import { BehaviorSubject } from 'rxjs';
import { ChartSeriesData } from '../../model/custom-types';

export type ApexChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  colors: Array<string>;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
}

@Component({
  selector: 'chart-historio-vendas-compras',
  standalone: true,
  imports: [
    NgApexchartsModule,
    NgClass,
    ColorDirective,
    OperationInProgressComponent,
    CurrencyPipe,
    AsyncPipe,
  ],
  templateUrl: './chart-historio-vendas-compras.component.html',
  styleUrl: './chart-historio-vendas-compras.component.scss'
})
export class ChartHistorioVendasComprasComponent {

  @ViewChild('chartRef') chartRef: ChartComponent;

  @Input() operationInProgress$: BehaviorSubject<boolean>;
  @Input({ required: true }) dadosGrafico: Array<ChartSeriesData>;
  @Input({ required: true }) tipoVisualizacao: TipoVisualizacaoChartHistoricoVendasComprasEnum;
  @Input({ required: true }) title: string = "";
  @Input({ required: true }) isDesktop: boolean;
  @Input({ required: true }) dataInicio: Date;
  @Input({ required: true }) dataFim: Date;

  @Input() drawColor: string = '#54afa6';

  chartOptions: Partial<ApexChartOptions> = {
    tooltip: { enabled: true },
    colors: [this.drawColor],
    stroke: {
      width: 0,
      show: false,
    },
    chart: {
      height: 400,
      type: "bar",
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: { enabled: true },
      zoom: { enabled: true, type: 'xy' },
    },
    yaxis: {},
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    series: [{ name: this.title, data: [] }],
    fill: {
      type: 'solid',
    },
    xaxis: {},
    plotOptions: {
      bar: {
        borderRadius: 1,
        columnWidth: '100%',
        distributed: false,
        dataLabels: {
          position: 'top',
        },
      }
    }
  };

  private visualizacaoPeriodo: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if ((changes?.['dadosGrafico']?.currentValue) && this.dadosGrafico) {
      this.visualizacaoPeriodo = this.tipoVisualizacao === TipoVisualizacaoChartHistoricoVendasComprasEnum.periodo;

      let diasAntes = Math.floor((this.dataFim.getTime() - this.dataInicio.getTime()) / 86400000);

      const seriesData = this.visualizacaoPeriodo ? [{
        data: Array.from({ length: diasAntes }, (_, i) => i).map((_, i) => {
          const data = new Date(this.dataInicio);
          data.setDate(data.getDate() + (i + 1));
          const dados = this.dadosGrafico.filter(d => format(data, 'yyyy-MM-dd') === format(new Date(d.eixoX), 'yyyy-MM-dd'));
          return {
            x: format(data, `dd' de 'MMMM' de 'yyyy`, { locale: ptBR }),
            y: dados.reduce((acc, curr) => acc + curr.eixoY, 0),
          }
        })
      }] : [{
        data: this.dadosGrafico.map(x => x.eixoY),
      }];

      const yaxis: ApexYAxis = this.visualizacaoPeriodo ? {
        // min: Math.min(...seriesData.at(0).data.map(x => x.y), 0) * ChartYAxisRedudancyFactor,
        min: 0,
        max: Math.max(...seriesData.at(0).data.map(x => x.y), 0) * ChartYAxisRedudancyFactor,
        labels: {
          formatter: (value) => {
            return new CurrencyPipe('pt-BR').transform(value, 'BRL', 'symbol', '1.2-2');
          }
        },
        forceNiceScale: true,
      } : {
        labels: {
          formatter: (value) => {
            return new CurrencyPipe('pt-BR').transform(value, 'BRL', 'symbol', '1.2-2');
          }
        },
      }
      const xaxis: ApexXAxis = this.visualizacaoPeriodo ? {
        categories: Array.from({ length: diasAntes }, (_, i) => i).map((_, i) => {
          const data = new Date(this.dataInicio);
          data.setDate(data.getDate() + (i + 1));
          return format(data, 'dd MMM', { locale: ptBR });
        }),
        tickAmount: this.visualizacaoPeriodo ? (this.isDesktop ? 20 : 10) : null,
        axisTicks: { show: true },
        tooltip: { enabled: false },
        labels: {
          show: true,
          rotate: -75,
          rotateAlways: true,
        }
      } : {
        categories: this.dadosGrafico.map(x => x.eixoX),
        tickAmount: this.isDesktop ? 10 : 5,
        axisTicks: { show: false },
        tooltip: { enabled: false },
        labels: {
          show: true,
        }
      }

      this.updateChart(seriesData, yaxis, xaxis);
    }
  }

  updateChart(series: ApexAxisChartSeries, yaxis: ApexYAxis, xaxis: ApexXAxis) {
    // this.chartRef.updateSeries(series, true);
    // const tooltip: ApexTooltip = this.visualizacaoPeriodo ? {
    //   x: {
    //     formatter: (value) => {
    //       const data = new Date(this.dataInicio);
    //       data.setDate(data.getDate() + value);
    //       return format(data, 'dd \'de\' MMMM \'de\' yyyy', { locale: ptBR });
    //     },
    //   },
    // } : {
    //   y: {
    //     formatter: (value) => {
    //       return new CurrencyPipe('pt-BR').transform(value, 'BRL', 'symbol', '1.2-2');
    //     },
    //   },
    //   x: {
    //     formatter: (value, opt) => {
    //       const data = series[0].data?.[opt?.['dataPointIndex']];
    //       if (typeof data !== 'number' && !(data instanceof Array)) return data.x;
    //       return value;
    //     },
    //   }
    // };
    const tooltip: ApexTooltip = {
      y: {
        formatter: (value) => {
          return new CurrencyPipe('pt-BR').transform(value, 'BRL', 'symbol', '1.2-2');
        },
      },
      x: {
        show: true,
        formatter: (value, opt) => {
          const data = series[0].data?.[opt?.['dataPointIndex']];
          if (typeof data !== 'number' && !(data instanceof Array)) return data.x;
          return value;
        },
      }
    };
    // const colors = RGBColors.slice(0, series[0].data.length).map(x => `rgb(${x})`);
    const colors = [this.drawColor];
    this.chartRef.updateOptions({
      series,
      yaxis,
      xaxis,
      tooltip,
      colors,
    } as ApexChartOptions, true, true);
  }
}

export type PeriodoHistoricoVendaCompra = {
  label: string;
  mes: number;
  ano: number;
}

export type PeriodoChartHistoricoVendaCompra = {
  label: string;
  diasAntes?: number;
  mesesAntes?: number;
}

export enum TipoVisualizacaoChartHistoricoVendasComprasEnum {
  periodo = 1,
  produto = 2,
}
