import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Segment, SegmentsComponent } from '../../custom-components/segments/segments.component';

@Component({
  selector: 'app-vendas-wrapper',
  standalone: true,
  imports: [SegmentsComponent, RouterOutlet],
  templateUrl: './vendas-wrapper.component.html',
  styleUrl: './vendas-wrapper.component.scss'
})
export class VendasWrapperComponent {
  segments: Array<Segment> = [
    { path: ['/home', 'vendas', 'produtos'], title: 'Produtos para venda' },
    { path: ['/home', 'vendas', 'historico'], title: 'Histórico de vendas' },
  ];

  activeSegment: Segment;
}
