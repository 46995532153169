import { Routes } from "@angular/router";
import { SectionDetalhesProdutoComponent } from './section-detalhes-produto/section-detalhes-produto.component';
import { SectionMedidasProdutoComponent } from './section-medidas-produto/section-medidas-produto.component';
import { SectionComposicaoProdutoComponent } from './section-composicao-produto/section-composicao-produto.component';
import { SectionVendasProdutoComponent } from './section-vendas-produto/section-vendas-produto.component';
import { SectionComprasProdutoComponent } from "./section-compras-produto/section-compras-produto.component";

export const DetalhesProdutoRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'detalhes', component: SectionDetalhesProdutoComponent, },
      { path: 'medidas', component: SectionMedidasProdutoComponent, },
      { path: 'composicao', component: SectionComposicaoProdutoComponent, },
      { path: 'vendas', component: SectionVendasProdutoComponent, },
      { path: 'compras', component: SectionComprasProdutoComponent, },
      { path: '', redirectTo: 'detalhes', pathMatch: 'full' },
    ],
  },
];
