import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TFormCriarConta } from '../criar-conta.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { AsyncPipe, NgClass } from '@angular/common';
import { BaseClass } from '../../../globals/base-class';
import { RamoNegocioService } from '../../../services/ramo-negocio.service';
import { NgxMaskDirective } from 'ngx-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DebounceDirective } from '../../../directives/debounce.directive';
import { defaultDebounceInput } from '../../../globals/globals';
import { RamoNegocio, RamosNegocio } from '../../../model/ramo-negocio.model';
import { map, Observable, startWith } from 'rxjs';
import { Cidade, Cidades, Estado, Estados } from '../../../model/endereco.model';
import { EnderecoService } from '../../../services/endereco.service';
import { dateDDMMYYYYIsInvalid, showApiErrorMessages, stringIncludesSubstring } from '../../../globals/utils';
import { NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
import { SiglaEstado } from '../../../services/ibge.service';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'section-dados-negocio',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SmallLabelComponent,
    NgMatIconComponent,
    ColorDirective,
    NgClass,
    NgxMaskDirective,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    DebounceDirective,
    AsyncPipe,
    NgbDatepickerModule,
  ],
  templateUrl: './section-dados-negocio.component.html',
  styleUrl: './section-dados-negocio.component.scss'
})
export class SectionDadosNegocioComponent extends BaseClass() {
  @Input() form: FormGroup<TFormCriarConta>;

  debounce = defaultDebounceInput;
  searchingRamosNegocio: boolean = false;
  searchingEstados: boolean = false;
  searchingCidades: boolean = false;

  ramosNegocio: RamosNegocio;
  filteredRamosNegocio: Observable<RamosNegocio>;

  estados: Estados;
  filteredEstados: Observable<Estados>;

  cidades: Cidades;
  filteredCidades: Observable<Cidades>;

  now: NgbDateStruct = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  constructor(
    private ramoNegocioService: RamoNegocioService,
    private enderecoService: EnderecoService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.getRamosNegocio().then(() => {
      this.filteredRamosNegocio = this.form.controls.ramoNegocio.valueChanges.pipe(
        startWith(''),
        map(ramo => (ramo ? this._filterRamosNegocio(ramo) : (this.ramosNegocio || [])?.slice())),
      );
    });

    this.getEstados().then(() => {
      this.filteredEstados = this.form.controls.estado.valueChanges.pipe(
        startWith(''),
        map(estado => this._filterEstados(estado || '')),
      );
    });

    this.getCidades().then(() => {
      this.filteredCidades = this.form.controls.cidade.valueChanges.pipe(
        startWith(''),
        map(cidade => this._filterCidades(cidade || '')),
      );
    });

    this.form.controls.estado.valueChanges.subscribe({
      next: (x) => {
        this.cidades = null;
        this.form.controls.cidade.setValue(null, { emitEvent: false });
        this.form.controls.cidade.disable({ emitEvent: false });

        if (x) {
          this.form.controls.cidade.enable({ emitEvent: false });
          this.getCidades(null, x.siglaEstado);
        }
      }
    });
  }

  _filter(el: HTMLInputElement, cb?: (value: string) => Promise<void> | void) {
    el.disabled = false;
    el.focus();
    if (cb) cb.bind(this)(el.value);
  }

  private _filterRamosNegocio(value: string | RamoNegocio): RamosNegocio {
    const filterValue = (typeof value === 'string' ? value : value?.descricao)?.toLowerCase();
    return this.ramosNegocio?.filter(ramo => ramo.descricao.toLowerCase().includes(filterValue));
  }

  private _filterEstados(value: string | Estado): Estados {
    const filterValueNome = (typeof value === 'string' ? value : value?.nome)?.toLowerCase();
    const filterValueSigla = (typeof value === 'string' ? value : value?.siglaEstado)?.toLowerCase();

    return this.estados?.filter((estado) => {
      return (filterValueNome ? stringIncludesSubstring(estado.nome, filterValueNome) : true) || (filterValueNome ? stringIncludesSubstring(estado.siglaEstado, filterValueSigla) : true);
    });
  }

  private _filterCidades(value: string | Cidade): Cidades {
    const filterValue = (typeof value === 'string' ? value : value?.nome)?.toLowerCase();

    return this.cidades?.filter((cidade) => {
      return filterValue ? stringIncludesSubstring(cidade.nome, filterValue) : true;
    });
  }

  displayWithRamoNegocio(ramo: RamoNegocio): string {
    return ramo?.descricao;
  }

  displayWithEstado(estado: Estado): string {
    return estado?.nome;
  }

  displayWithCidade(cidade: Cidade): string {
    return cidade?.nome;
  }

  async getRamosNegocio(Termo: string = null) {
    try {
      this.searchingRamosNegocio = true;
      const res = await this.ramoNegocioService.getRamosNegocio({ Termo });
      if (res.data) this.ramosNegocio = res.data;
      if (this.form.controls.ramoNegocio.disabled) this.form.controls.ramoNegocio.enable();
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar ramos de negócio', color: "danger" });
    } finally {
      this.searchingRamosNegocio = false;
    }
  }

  async getEstados(Termo: string = null) {
    try {
      this.searchingEstados = true;
      if (!this.estados) {
        const res = await this.enderecoService.getEstadosBrasil({ Termo, PageSize: 27 });
        if (res.data) this.estados = res.data;
      }
      if (this.form.controls.estado.disabled) this.form.controls.estado.enable();
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar estados', color: "danger" });
    } finally {
      this.searchingEstados = false;
    }
  }

  async getCidades(Termo: string = null, SiglaEstado: SiglaEstado = null) {
    if (!SiglaEstado) return;
    try {
      this.searchingCidades = true;
      if (!this.cidades) {
        const res = await this.enderecoService.getCidadesEstado({ SiglaEstado, Termo, PageSize: 1000 });
        if (res.data) this.cidades = res.data;
      }
      if (this.form.controls.cidade.disabled) this.form.controls.cidade.enable();
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar cidades', color: "danger" });
    } finally {
      this.searchingCidades = false;
    }
  }

  focusOutInputEstado(inputRef: HTMLElement) {
    setTimeout(() => {
      const inputHasFocus = document.activeElement === inputRef;
      if (!inputHasFocus && typeof this.form.controls.estado.value === 'string') {
        this.form.controls.estado.setValue(null);
      }
    }, this.debounce);
  }

  focusOutInputCidade(inputRef: HTMLElement) {
    setTimeout(() => {
      const inputHasFocus = document.activeElement === inputRef;
      if (!inputHasFocus && typeof this.form.controls.cidade.value === 'string') {
        this.form.controls.cidade.setValue(null);
      }
    }, this.debounce);
  }

  focusOutInputDate(el: HTMLInputElement) {
    if (el.value?.length === 10 && dateDDMMYYYYIsInvalid(el.value)) {
      el.value = null;
      this.form.controls.dataCriacao.setValue(null);
      this.toastService.show({ body: 'Data inválida', color: "danger" });
    }
  }

}
