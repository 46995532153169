<div id="container">
  @if (!produtosVenda) {
    <operation-in-progress />
  } @else {
    <div id="content" class="d-flex flex-column gap-3">
      <div id="header" class="d-flex align-items-center justify-content-between px-4 py-3">
        <h4 class="fw-bold m-0" color="dark">Editar venda</h4>

        <button class="btn" (click)="dismiss()">
          <ng-mat-icon name="close" color="dark" />
        </button>
      </div>

      <div class="px-4">
        <produtos-venda [venda]="data?.venda" [produtosSelecionados]="produtosVenda" [date]="dataVenda" (cancel)="dismiss()" (vendaAtualizada)="vendaAtualizada(venda)" />
      </div>
    </div>
  }
</div>
