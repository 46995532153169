import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.dev';
import { Usuario } from '../model/usuario.model';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ApiResponseGetUnique, ApiResponsePost, ApiResponsePut, ApiResponseToken } from '../model/api.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private endpoints = environment.api.endpoints;
  private url = environment.api.url;

  constructor(private http: HttpClient) {}

  login(email: string, senha: string) {
    return lastValueFrom(this.http.post<ApiResponseToken>(this.url + this.endpoints.tokens, { email, senha }));
  }

  solicitaCodigo(email: string) {
    return lastValueFrom(this.http.post<ApiResponsePost<null>>(`${this.url}${this.endpoints.usuarios}/codigorecuperacaosenha`, { email }));
  }

  redefinirSenha(email: string, codigoRecuperacao: string, novaSenha: string) {
    return lastValueFrom(this.http.put<ApiResponsePut<null>>(`${this.url}${this.endpoints.usuarios}/redefinirsenha`, { email, codigoRecuperacao, novaSenha }));
  }

  getUser(id: string) {
    return lastValueFrom(this.http.get<ApiResponseGetUnique<Usuario>>(this.url + this.endpoints.usuarios + '/' + id));
  }

}
