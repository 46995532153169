import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { ModalService } from '../../../services/modal.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { UnidadeMedida } from '../../../model/unidade-medida.model';
import { NovaUnidadeMedidaComponent } from '../../../home/unidades-medida/nova-unidade-medida/nova-unidade-medida.component';
import { defaultClassModal } from '../../../globals/utils';
import { ModalClose } from '../../../model/custom-types';
import { NgMatIconComponent } from '../../ng-mat-icon/ng-mat-icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../../directives/color-tipo-produto.pipe';
import { TipoProdutoEnum } from '../../../model/produto.model';
import { MatOptionModule, MatOptionSelectionChange } from '@angular/material/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'nova-unidade-option',
  standalone: true,
  imports: [
    NgMatIconComponent,
    ColorDirective,
    ColorTipoProdutoPipe,
    MatOptionModule,
  ],
  templateUrl: './nova-unidade-option.component.html',
  styleUrl: './nova-unidade-option.component.scss'
})
export class NovaUnidadeOptionComponent extends BaseClass() {

  @Input({ required: true }) tipoProduto: TipoProdutoEnum;
  @Input({ required: true }) control: FormControl<UnidadeMedida> | AbstractControl<UnidadeMedida>;
  @Input({ required: true }) autocompleteTrigger: MatAutocompleteTrigger;

  @Output() unidadeAdicionada: EventEmitter<UnidadeMedida> = new EventEmitter();

  constructor(
    private modalService: ModalService,
  ) {
    super();
  }

  novaUnidadeMedida(ev: MatOptionSelectionChange) {
    if (ev.source.selected) return ev.source.deselect();

    this.autocompleteTrigger?.closePanel();

    const modal = this.modalService.presentModal(NovaUnidadeMedidaComponent, defaultClassModal(this), null, { windowClass: 'aria-modal' });

    const sub = modal.closed.subscribe({
      next: (res: ModalClose<UnidadeMedida, 'unidadeMedida'>) => {
        if (res.unidadeMedida) {
          this.control.setValue(res.unidadeMedida);
          this.control.disable();
          this.unidadeAdicionada.emit(res.unidadeMedida);
        }
      }
    });

    this.appendSubscription(sub);
  }
}
