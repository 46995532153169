import { MaterialIcon } from "material-icons";
import { HexColor } from "./custom-types";

export type Categoria = {
  id: string;
  nome: string;
  icone: MaterialIcon;
  status: StatusCategoria;
  cor: HexColor;
  quantidadeProdutosRelacionados?: number;
}
export type Categorias = Array<Categoria>;

export enum StatusCategoria {
  ATIVO = 1,
  INATIVO = 2,
}
