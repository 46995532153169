import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { ModalService } from '../../../services/modal.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { defaultClassModal } from '../../../globals/utils';
import { ModalClose } from '../../../model/custom-types';
import { NgMatIconComponent } from '../../ng-mat-icon/ng-mat-icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../../directives/color-tipo-produto.pipe';
import { TipoProdutoEnum } from '../../../model/produto.model';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Categoria } from '../../../model/categoria.model';
import { NovaCategoriaComponent } from '../../../home/categorias/nova-categoria/nova-categoria.component';

@Component({
  selector: 'nova-categoria-option',
  standalone: true,
  imports: [
    NgMatIconComponent,
    ColorDirective,
    ColorTipoProdutoPipe,
    MatOptionModule,
  ],
  templateUrl: './nova-categoria-option.component.html',
  styleUrl: './nova-categoria-option.component.scss'
})
export class NovaCategoriaOptionComponent extends BaseClass() {

  @Input({ required: true }) tipoProduto: TipoProdutoEnum;
  @Input({ required: true }) control: FormControl<Categoria> | AbstractControl<Categoria>;
  @Input({ required: true }) autocompleteTrigger: MatAutocompleteTrigger;

  @Output() categoriaAdicionada: EventEmitter<Categoria> = new EventEmitter();

  constructor(
    private modalService: ModalService,
  ) {
    super();
  }

  novaCategoria() {
    this.autocompleteTrigger?.closePanel();

    const modal = this.modalService.presentModal(NovaCategoriaComponent, defaultClassModal(this), null, { windowClass: 'aria-modal' });

    const sub = modal.closed.subscribe({
      next: (res: ModalClose<Categoria, 'categoria'>) => {
        if (res.categoria) {
          this.control.setValue(res.categoria);
          this.control.disable();
          this.categoriaAdicionada.emit(res.categoria);
        }
      }
    });

    this.appendSubscription(sub);
  }

}
