import { Component } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialIcon } from 'material-icons';
import { TOpcaoProdutos } from '../../../model/custom-types';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../directives/color.directive';
import { closeModal } from '../../../globals/utils';

@Component({
  selector: 'app-options-produtos',
  standalone: true,
  imports: [
    NgMatIconComponent,
    IconComponent,
    SmallLabelComponent,
    ColorDirective,
  ],
  templateUrl: './options.component.html',
  styleUrl: '../../../../custom-styles/opcoes-exportacao-modal.scss',
})
export class OptionsProdutosComponent extends BaseClass() {

  optionsImportacao: Array<Option> = [
    { label: 'Importar NF-e', customIcon: 'nfe_laranja', value: 'importar-nfe' },
    { label: 'Importar Excel', matIcon: 'file_upload', value: 'importar-excel' },
  ];

  optionsExportacao: Array<Option> = [
    { label: 'Exportar Excel', matIcon: 'file_download', value: 'exportar-excel' },
    { label: 'Exportar PDF', matIcon: 'file_download', value: 'exportar-pdf' },
    { label: 'Imprimir', matIcon: 'print', value: 'imprimir' },
  ];

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  optionSelected(option: TOpcaoProdutos) {
    closeModal<TOpcaoProdutos>(this.activeModal, "saved", option);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}

type Option = {
  label: string;
  matIcon?: MaterialIcon;
  customIcon?: string;
  value: TOpcaoProdutos;
}
