import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ColorDirective } from '../../../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { TFormProduto } from '../novo-produto.component';
import { AsyncPipe, CurrencyPipe, NgClass } from '@angular/common';
import { BaseClass } from '../../../../globals/base-class';
import { ProdutoComposicaoForm, ProdutosComposicaoForm, TipoProdutoEnum } from '../../../../model/produto.model';
import { NgMatIconComponent } from "../../../../custom-components/ng-mat-icon/ng-mat-icon.component";
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../../../custom-components/no-results/no-results.component';
import { ModalService } from '../../../../services/modal.service';
import { ComposicaoModalAddProdutoComposicao, SelecionarProdutoComposicaoComponent } from '../../../../custom-components/selecionar-produto-composicao/selecionar-produto-composicao.component';
import { defaultClassModal, showApiErrorMessages } from '../../../../globals/utils';
import { ModalClose } from '../../../../model/custom-types';
import { ConfirmarRemocaoProdutoComposicaoComponent } from '../../../../custom-components/confirmar-remocao-produto-composicao/confirmar-remocao-produto-composicao.component';
import { ProdutoService } from '../../../../services/produto.service';
import { ApiResponseError } from '../../../../model/api.model';
import { ToastService } from '../../../../services/toast.service';
import { PaginatorComponent, PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { BehaviorSubject } from 'rxjs';
import { UnidadesMedidaInsert } from '../../../../model/unidade-medida.model';

@Component({
  selector: 'section-produtos-composicao',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ColorDirective,
    ColorTipoProdutoPipe,
    SmallLabelComponent,
    NgClass,
    NgMatIconComponent,
    CurrencyPipe,
    OperationInProgressComponent,
    NoResultsComponent,
    PaginatorComponent,
    AsyncPipe,
  ],
  templateUrl: './section-produtos-composicao.component.html',
  styleUrl: './section-produtos-composicao.component.scss'
})
export class SectionProdutosComposicaoComponent extends BaseClass() {

  @Input({ required: true }) produtoID: string;
  @Input({ required: true }) produtosComposicao: ProdutosComposicaoForm;
  @Input({ required: true }) paginatorOpts: PaginatorOpts;
  @Input({ required: true }) operationInProgress$: BehaviorSubject<boolean>;
  @Input() produtoForm: FormGroup<Pick<TFormProduto, 'nome' | 'quantidadeUnidadeMedidaVenda' | 'tipo'>>;
  @Input() novoProduto: boolean = true;
  @Input() unidadesMedidaRendimento: UnidadesMedidaInsert<TipoProdutoEnum.composicao> = null;
  @Input() unidadesMedidaEquivalente: UnidadesMedidaInsert<TipoProdutoEnum.insumo> = null;

  @Output() produtoComposicaoAdicionado: EventEmitter<ProdutoComposicaoForm> = new EventEmitter<ProdutoComposicaoForm>();
  @Output() updatePageSize: EventEmitter<number> = new EventEmitter<number>();
  @Output() navigatePage: EventEmitter<number> = new EventEmitter<number>();

  TipoProdutoEnum = TipoProdutoEnum;


  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private produtoService: ProdutoService,
  ) {
    super();
  }

  async adicionarEditarProduto(tipo: TipoProdutoEnum, produtoComposicao: ProdutoComposicaoForm = null) {
    const windowClass = this.isDesktop ? '' : 'h-70 rounded-top-4';
    const composicao: ComposicaoModalAddProdutoComposicao = {
      id: this.produtoID,
      nome: this.produtoForm.value.nome,
      quantidade: this.produtoForm.value.quantidadeUnidadeMedidaVenda,
      tipoProduto: this.produtoForm.value.tipo,
    }
    const dataModal = {
      tipo,
      composicao,
      idProduto: this.produtoID,
      produtosJaAdicionados: this.produtosComposicao,
      produtoComposicao,
      unidadesMedidaRendimento: this.unidadesMedidaRendimento,
      unidadesMedidaEquivalente: this.unidadesMedidaEquivalente,
    }
    const modal = this.modalService.presentModal(SelecionarProdutoComposicaoComponent, defaultClassModal(this), dataModal, { windowClass });
    const res: ModalClose<ProdutoComposicaoForm, 'produto'> = await modal.result;
    if (res.reason !== 'saved') return;
    this.produtoComposicaoAdicionado.emit(res.produto);
  }

  async removeProdutoComposicao(produto: ProdutoComposicaoForm) {
    const modalData = {
      composicaoNome: this.produtoForm.value.nome,
      produtoComposicaoNome: produto.nome,
    }
    const confirm = this.modalService.presentModal(ConfirmarRemocaoProdutoComposicaoComponent, defaultClassModal(this), modalData, { windowClass: 'w-400px' });
    const result: ModalClose<boolean, 'data'> = await confirm.result;

    if (result.data !== true) return;

    const loading = this.modalService.presentLoading('Excluindo produto da composição', true);

    this.produtoService.deleteProdutoComposicao(this.produtoID, produto.id).then((res) => {
      if (!res.success) {
        const userNotified = !showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>);
        if (!userNotified) this.toastService.show({ body: 'Erro ao excluir produto', color: 'danger' });
        return;
      }

      this.toastService.show({ body: `Produto excluído com sucesso`, color: 'success' });
      this.produtosComposicao = this.produtosComposicao.filter(p => p.idProduto !== produto.idProduto);
    }).catch((err) => {
      console.log(err);
      const userNotified = !showApiErrorMessages(this.modalService, err as ApiResponseError<'validation'>);
      if (!userNotified) this.toastService.show({ body: 'Erro ao excluir produto', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    });
  }

}
