import { Component, Input } from '@angular/core';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { FormGroup } from '@angular/forms';
import { TFormProduto } from '../novo-produto.component';
import { TipoProdutoEnum } from '../../../../model/produto.model';
import { ColorDirective } from '../../../../directives/color.directive';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../../../globals/base-class';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';

@Component({
  selector: 'section-info-tipo-produto',
  standalone: true,
  imports: [
    SmallLabelComponent,
    ColorDirective,
    NgClass,
    ColorTipoProdutoPipe,
  ],
  templateUrl: './section-info-tipo-produto.component.html',
  styleUrl: './section-info-tipo-produto.component.scss'
})
export class SectionInfoTipoProdutoComponent extends BaseClass() {
  @Input() produtoForm: FormGroup<TFormProduto>;

  TipoProdutoEnum = TipoProdutoEnum;
}
