<div id="container" class="position-relative rounded-3">
  <div id="chart">
    @if (operationInProgress$ | async) {
      <div id="loading" class="position-absolute top-0 right-0 w-100 h-100 d-flex align-items-center justify-content-center">
        <operation-in-progress />
      </div>
    }
    <apx-chart
      #chartRef
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [stroke]="chartOptions.stroke"
      [title]="chartOptions.title"
      [fill]="chartOptions.fill"
      [dataLabels]="chartOptions.dataLabels"
      [colors]="chartOptions.colors"
      [tooltip]="chartOptions.tooltip"
      [legend]="chartOptions.legend"
      [plotOptions]="chartOptions.plotOptions"
    />
  </div>
</div>
