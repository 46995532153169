<div id="container" class="p-4">
  <div id="content" class="rounded-4 p-4">
    <router-outlet #routerDetalhesProduto="outlet" />

    <div id="produtos">
      <div [ngClass]="{ 'd-flex gap-4 align-items-end': isDesktop }" class="mb-5" id="header">
        <button [disabled]="operationInProgress$ | async" id="btn-novo" type="button" [color]="activeSegment.tipoProduto | colorTipoProduto" class="btn gap-3" [ngClass]="{ 'grid-cell-full': !isDesktop }" (click)="novoProduto()">
          <span> Novo produto </span>
        </button>

        <div class="form-group d-flex flex-column gap-2 flex-grow-1 mt-4" id="searchbox">
          <small-label class="fw-bold"> Busque pelos produtos </small-label>

          <div id="searchbox" class="input-group input-icon-end position-relative input-icon-end">
            <input
              type="text"
              class="form-control lh-2 pe-5"
              id="search-input"
              placeholder="Busque pelo produto"
              [debounce]="500"
              [operationObs$]="operationInProgress$"
              [enterOnly]="false"
              (onChange)="inputOnSearch()"
              [disabled]="operationInProgress$ | async"
              [(ngModel)]="searchTerm"
            />
            <ng-mat-icon class="cursor-pointer" slot="end" name="search" [color]="activeSegment.tipoProduto | colorTipoProduto" />
          </div>
        </div>

        <!-- <div class="form-group d-flex flex-column gap-2" id="options">
          <small-label> </small-label>

          <div class="d-flex gap-2">
            <button
              [disabled]="operationInProgress$ | async"
              id="btn-filtros"
              type="button"
              color="apoio"
              class="btn has-custom-color"
              (click)="selecionarFiltros()"
              ngbTooltip="Filtros"
              placement="top"
              [openDelay]="DelayShowTooltip"
            >
              <ng-mat-icon name="filter_alt" color="titulo" />
            </button>

            <button [disabled]="operationInProgress$ | async" id="btn-options" type="button" color="apoio" class="btn has-custom-color" (click)="options()">
              <ng-mat-icon name="more_vert" color="titulo" />
            </button>
          </div>
        </div> -->
      </div>

      <segments
        [isNavigation]="false"
        [activeSegment]="activeSegment"
        [segments]="segments"
        [color]="activeSegment.tipoProduto | colorTipoProduto"
        [disabled]="operationInProgress$ | async"
        (onSegmentChange)="activeSegmentChanged($event)"
        [queryParams]="queryParams"
      />

      <div class="mt-3">
        <button-group [buttons]="eVendido" [color]="activeSegment.tipoProduto | colorTipoProduto" [value]="queryParams.evendido" [disabled]="operationInProgress$ | async" (activeButtonChange)="eVendidoChanged($event)" />
      </div>

      <div id="body" class="mt-3">
        @if (operationInProgress$ | async) {
          <operation-in-progress />
        } @else {
          @if (produtos?.length > 0) {
            <div class="d-flex flex-column">
              <produto-list
                [produtos]="produtos"
                [cardBorderColor]="activeSegment.tipoProduto | colorTipoProduto"
                [tipo]="activeSegment.tipoProduto"
                [classificacao]="queryParams.evendido ? ClassificacaoProduto.vendido : ClassificacaoProduto.naoVendido"
                (clickedOnCard)="detalhesProduto($event.produto?.tagCadastroCompleto ? $event.produto.id : $event.produto, $event.area)"
              />

              @if (paginatorOpts) {
                <paginator
                  [isDisabled]="operationInProgress$.value"
                  [color]="activeSegment.tipoProduto | colorTipoProduto"
                  [showLabels]="false"
                  [page]="paginatorOpts?.page"
                  [pages]="paginatorOpts.pages"
                  [pageSize]="paginatorOpts.pageSize"
                  [rowsCount]="paginatorOpts.rowsCount"
                  [isDisabled]="operationInProgress$.value || !produtos?.length"
                  (updatePageSize)="updatePageSize($event)"
                  (navigatePage)="navigatePage($event)"
                />
              }
            </div>
          } @else {
            <no-results [classes]="['text-center']">
              <h2 color="black" class="fw-bold">Nenhum produto encontrado para os filtros selecionados</h2>
            </no-results>
          }
        }
      </div>
    </div>
  </div>
</div>
