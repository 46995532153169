import { AfterContentChecked, Component, HostListener } from '@angular/core';
import { mobileBreakpoint, tabletBreakpoint } from "../globals/globals";
import { Subscription } from 'rxjs';

export interface IBaseClass {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  windowResized: (rect: DOMRect) => void;

  /**
   * ## Array de subscriptions
   */
  subs: Array<Subscription>;
  /**
   * ## Adiciona uma Subscription ao array de subscriptions
   */
  appendSubscription: (sub: Subscription) => void;
}

interface ClassType<T = {}> {
  new(...args: any[]): T;
}

/**
 * ## Retorna uma classe que opcionalmente herda de uma classe passada por parâmetro, portanto se for preciso extender outra classe, basta passá-la como parâmetro
 * */
export const BaseClass = <T>(t: ClassType<T> = class {} as any) => {
  /**
   * Embora a utilização de randomizers para componentes não seja recomendada na maioria dos casos,
   * aqui ela é utilizada para garantir que cada componente que herda de BaseClass tenha um id único
   * evitando 'Component ID generation collision'(https://angular.io/errors/NG0912), que tecnicamente
   * não é um erro mas pode gerar conflitos em produção e testes.
   * */

  const componentId = 'c-' + Math.random().toString(36).substring(2);

  @Component({
    selector: 'app-window-resize',
    template: '',
    host: { 'id': componentId },
  })
  class _BaseClass extends (t as any) implements IBaseClass, AfterContentChecked {

    subs: Array<Subscription> = [];

    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;

    windowResized = (rect: DOMRect) => {};

    @HostListener('window:resize')
    private onResize = () => {
      this.setResponsiveClasses();
      this.windowResized(document.body.getBoundingClientRect());
    }

    constructor() {
      super();
      this.isMobile = this._isMobile();
      this.isTablet = this._isTablet();
      this.isDesktop = this._isDesktop();
    }

    private _windowSize = (): number => document.body.clientWidth;
    private _isMobile = (): boolean => this._windowSize() <= mobileBreakpoint;
    private _isTablet = (): boolean => this._windowSize() <= tabletBreakpoint && this._windowSize() > mobileBreakpoint;
    private _isDesktop = (): boolean => this._windowSize() > tabletBreakpoint;

    private setResponsiveClasses() {
      this.isMobile = this._isMobile();
      this.isTablet = this._isTablet();
      this.isDesktop = this._isDesktop();
      document.body.classList.toggle('_ismobile', this.isMobile);
      document.body.classList.toggle('_istablet', this.isTablet);
      document.body.classList.toggle('_isdesktop', this.isDesktop);
    }

    ngAfterContentChecked() {
      this.setResponsiveClasses();
      const component = document.querySelector(`#${componentId}`);
      if (!component) return;
      const componentFirstHTMLChild = component?.firstChild as HTMLElement;
      componentFirstHTMLChild?.classList?.toggle('_ismobile', this.isMobile);
      componentFirstHTMLChild?.classList?.toggle('_istablet', this.isTablet);
      componentFirstHTMLChild?.classList?.toggle('_isdesktop', this.isDesktop);
    }

    appendSubscription(sub: Subscription) {
      this.subs.push(sub);
    }

    ngOnDestroy() {
      this.subs?.forEach(sub => sub?.unsubscribe());
    }
  }

  return _BaseClass as unknown as ClassType<T & IBaseClass & AfterContentChecked>;
}
