import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { Compra, CompraHistoricoProduto, CompraInsert } from '../model/compra.model';
import { ApiConstraintGetCompras, ApiResponseGetHistoricoCompraProduto } from '../model/api.model';
import { makeParamsGet } from '../globals/utils';
import { environment } from '../../environments/environment.dev';
import { updateSession } from '../home/home.component';

@Injectable({
  providedIn: 'root'
})
export class CompraService extends BaseHttpService {

  getCompras(constraints: ApiConstraintGetCompras = null) {
    const params = makeParamsGet(constraints, ['DataFim', 'DataInicio', 'IdProduto', 'TipoVisualizacao']);
    return this.get<Compra>(environment.api.endpoints.compraprodutos, params);
  }

  getHistoricoCompras(constraints: ApiConstraintGetCompras = null) {
    const params = makeParamsGet(constraints, ['DataFim', 'DataInicio', 'IdProduto', 'TipoVisualizacao']);
    return this.get<CompraHistoricoProduto>(environment.api.endpoints.comprashistorico, params) as Promise<ApiResponseGetHistoricoCompraProduto>;;
  }

  getDashboardCompras(constraints: ApiConstraintGetCompras = null) {
    const params = makeParamsGet(constraints, ['DataFim', 'DataInicio', 'IdProduto', 'TipoVisualizacao']);
    return this.get<{ eixoX: string, eixoY: number }>(environment.api.endpoints.comprasdashboard, params) as Promise<Array<{ eixoX: string, eixoY: number }>>;
  }

  async insertCompra(compra: Partial<CompraInsert>) {
    const res = await this.post<Compra>(environment.api.endpoints.compraprodutos, compra);
    if (res.success) updateSession.emit();
    return res;
  }

  updateCompra(compra: Partial<CompraInsert>) {
    return this.put<Compra>(`${environment.api.endpoints.compraprodutos}/${compra.id}`, compra);
  }

  deleteCompra(id: string) {
    return this.delete<Compra>(environment.api.endpoints.compraprodutos, id);
  }

}
