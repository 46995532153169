import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDiff',
  standalone: true
})
export class TimeDiffPipe implements PipeTransform {

  private readonly TIME_UNITS = {
    "year": { 'singular': 'ano', 'plural': 'anos' },
    "month": { 'singular': 'mês', 'plural': 'meses' },
    "day": { 'singular': 'dia', 'plural': 'dias' },
  };

  transform(value: string = null): string {
    if (!value) return null;
    const date = new Date(value);
    const now = new Date();

    const diff = now.getTime() - date.getTime();
    const diffDate = new Date(diff);
    const years = diffDate.getFullYear() - 1970;
    const months = diffDate.getMonth();
    const days = diffDate.getDate() - 1;

    let result = '';
    if (years) {
      result += `${years} ${years === 1 ? this.TIME_UNITS.year.singular : this.TIME_UNITS.year.plural}`;
    }
    if (months) {
      if (result) result += ' e ';
      result += `${months} ${months === 1 ? this.TIME_UNITS.month.singular : this.TIME_UNITS.month.plural}`;
    }
    if (days) {
      if (result) result += ' e ';
      result += `${days} ${days === 1 ? this.TIME_UNITS.day.singular : this.TIME_UNITS.day.plural}`;
    }
    return result;
  }

}
