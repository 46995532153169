<div id="container">
  <div id="content" class="m-4">
    <div id="header">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">Confirme a remoção</h3>
        <button class="btn" (click)="dismiss()">
          <ng-mat-icon name="close" color="dark" />
        </button>
      </div>
    </div>

    <div class="mt-4 d-flex flex-column gap-5">
      <small-label color="cinza">
        <span>
          Você deseja realmente remover o <span class="fw-bold">{{ data.produtoComposicaoNome }}</span> selecionados do preparo de <span class="fw-bold">{{ data.composicaoNome }}</span
          >?
        </span>
      </small-label>

      <small-label color="titulo" class="fw-bold"> Confirme abaixo </small-label>
    </div>

    <div id="buttons" class="mt-4 d-flex flex-column gap-4 w-100">
      <button type="button" class="px-4 btn flex-grow-1" color="laranja" (click)="confirmar()">Remover</button>

      <button type="button" class="px-4 btn btn-outline flex-grow-1" color="laranja" (click)="dismiss()">Cancelar</button>
    </div>
  </div>
</div>
