import { Component, Input } from '@angular/core';
import { session$ } from '../../../../globals/globals';
import { ProdutoService } from '../../../../services/produto.service';
import { ModalService } from '../../../../services/modal.service';
import { ToastService } from '../../../../services/toast.service';
import { EmpresaService } from '../../../../services/empresa.service';
import { TFormProduto } from '../novo-produto.component';
import { FormGroup } from '@angular/forms';
import { DataModalEditarMeuNegocio, EditarMeuNegocioComponent } from '../../../meu-negocio/editar-meu-negocio/editar-meu-negocio.component';
import { Empresa, NegocioEmpresa } from '../../../../model/empresa.model';
import { ModalClose } from '../../../../model/custom-types';
import { showApiErrorMessages } from '../../../../globals/utils';
import { ApiResponseError } from '../../../../model/api.model';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { AsyncPipe, CurrencyPipe, NgClass } from '@angular/common';
import { ColorDirective } from '../../../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { CreditosButtonComponent } from '../../../../custom-components/creditos-button/creditos-button.component';
import { SugestaoPrecoComponent } from '../../../../custom-components/sugestao-preco/sugestao-preco.component';
import { BaseClass } from '../../../../globals/base-class';

@Component({
  selector: 'calculo-preco',
  standalone: true,
  imports: [
    SmallLabelComponent,
    NgClass,
    ColorDirective,
    ColorTipoProdutoPipe,
    CurrencyPipe,
    AsyncPipe,
    CreditosButtonComponent,
    SugestaoPrecoComponent,
  ],
  templateUrl: './calculo-preco.component.html',
  styleUrl: './calculo-preco.component.scss'
})
export class CalculoPrecoComponent extends BaseClass() {

  @Input({ required: true }) idProduto: string;
  @Input({ required: true }) produtoForm: FormGroup<TFormProduto>;

  precoSugerido: number = 0;

  session = session$;

  constructor(
    private produtoService: ProdutoService,
    protected modalService: ModalService,
    private toastService: ToastService,
    private empresaService: EmpresaService,
  ) {
    super();
  }

  async confirmarDadosNegocio() {
    if (!this.idProduto || !(session$.value?.usuario?.quantidadeCreditos > 0)) return;

    const loading = this.modalService.presentLoading('Carregando...');

    try {
      const empresa = await this.empresaService.getEmpresaById(session$.value.empresa);
      const data: DataModalEditarMeuNegocio = { empresa, apenasDadosFinanceiros: true }
      loading.dismiss();
      const modalDadosNegocio = this.modalService.presentModal(EditarMeuNegocioComponent, "slide-right", data, { windowClass: 'fullscreen-modal', backdrop: 'static', keyboard: false });
      const result: ModalClose<{ empresa: Empresa, negocio: NegocioEmpresa }> = await modalDadosNegocio?.result;

      if (result?.reason !== 'saved') return;

      this.calcularPrecoIdeal();
    } catch (error) {
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao confirmar dados do negócio', color: 'danger' });
    }
  }

  public async calcularPrecoIdeal() {
    const loading = this.modalService.presentLoading('Calculando preço ideal...');

    try {
      const res = await this.produtoService.calcularPrecoIdeal(this.idProduto);
      if (!res?.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Erro ao calcular preço ideal', color: 'danger' });
        return;
      }
      this.precoSugerido = Math.round(res.data * 100) / 100;
      if (!this.produtoForm.controls.valorVenda.value) this.produtoForm.controls.valorVenda.setValue(this.precoSugerido);
    } catch (error) {
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao calcular preço ideal', color: 'danger' });
    } finally {
      loading.dismiss();
    }
  }
}
