import { Pipe, PipeTransform } from '@angular/core';
import { TipoProdutoEnum } from '../model/produto.model';
import { AppColor } from '../model/custom-types';
import { DefaultColorTipoProduto } from '../globals/globals';

@Pipe({
  name: 'colorTipoProduto',
  standalone: true
})
export class ColorTipoProdutoPipe implements PipeTransform {

  transform(value: TipoProdutoEnum): AppColor {
    if (!value) return 'titulo';
    return DefaultColorTipoProduto[value];
  }

}
