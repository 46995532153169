<div id="container" class="rounded-3 p-3">
  <div id="content" class="h-100 rounded-4 p-4">
    <div [ngClass]="{ 'd-flex gap-5 align-items-end': isDesktop }" class="mb-5" id="header">
      <button [disabled]="operationInProgress$ | async" id="btn-novo" type="button" color="vermelho" class="btn gap-3" [ngClass]="{ 'w-100': !isDesktop }" (click)="addEdit()">
        <ng-mat-icon name="local_offer" color="white" />
        <span> Nova unidade </span>
      </button>

      <div class="form-group d-flex flex-column gap-2 flex-grow-1 mt-4" id="searchbox">
        <div id="searchbox" class="input-group input-icon-end position-relative input-icon-end">
          <input
            type="text"
            class="form-control lh-2 pe-5"
            id="search-input"
            placeholder="Busque pelo nome da unidade"
            [debounce]="defaultDebounceInput"
            [operationObs$]="operationInProgress$"
            [enterOnly]="false"
            (onChange)="inputOnSearch()"
            [disabled]="!unidadesMedida || (operationInProgress$ | async)"
            [(ngModel)]="searchTerm"
          />
          <ng-mat-icon class="cursor-pointer" slot="end" name="search" color="vermelho" />
        </div>
      </div>
    </div>

    <div id="body" class="mt-3 mb-5">
      @if (operationInProgress$ | async) {
        <operation-in-progress />
      } @else {
        @if (unidadesMedida?.length > 0) {
          <div id="list-unidades">
            @for (unidade of unidadesMedida; track $index) {
              <div class="card-unidade py-3 px-4 rounded-4">
                <div class="d-flex gap-2 justify-content-between">
                  <h6 class="m-0 fw-bold">{{ unidade.nome }} ({{ unidade.sigla }})</h6>
                  <ng-mat-icon classes="cursor-pointer" name="edit" color="vermelho" (click)="addEdit(unidade)" />
                </div>
              </div>
            }
          </div>
        } @else {
          <no-results [classes]="['text-center']">
            <h2 color="black" class="fw-bold">Nenhuma unidade de medida encontrada</h2>
          </no-results>
        }
      }
    </div>

    @if (paginatorOpts) {
      <paginator
        [isDisabled]="operationInProgress$.value"
        color="vermelho"
        [showLabels]="false"
        [page]="paginatorOpts?.page"
        [pages]="paginatorOpts.pages"
        [pageSize]="paginatorOpts.pageSize"
        [rowsCount]="paginatorOpts.rowsCount"
        [isDisabled]="operationInProgress$.value || !unidadesMedida?.length"
        (updatePageSize)="updatePageSize($event)"
        (navigatePage)="navigatePage($event)"
      />
    }
  </div>
</div>
