import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TFormProduto } from '../novo-produto.component';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { ColorDirective } from '../../../../directives/color.directive';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { BaseClass } from '../../../../globals/base-class';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { ClassificacaoProdutoEnum, TipoProdutoEnum } from '../../../../model/produto.model';
import { UnidadeMedidaAutocompleteComponent } from '../../../../custom-components/autocomplete/unidade-medida-autocomplete/unidade-medida-autocomplete.component';
import { ListMedidasCheckboxesComponent } from '../../../../custom-components/list-medidas-checkboxes/list-medidas-checkboxes.component';
import { UnidadeMedida, UnidadeMedidaInsert, UnidadesMedidaInsert } from '../../../../model/unidade-medida.model';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';

@Component({
  selector: 'section-medida-produto',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    ColorDirective,
    SmallLabelComponent,
    ColorTipoProdutoPipe,
    UnidadeMedidaAutocompleteComponent,
    ListMedidasCheckboxesComponent,
    NgMatIconComponent,
  ],
  templateUrl: './section-medida-produto.component.html',
  styleUrl: './section-medida-produto.component.scss'
})
export class SectionMedidaProdutoComponent extends BaseClass() {

  @Input({ required: true }) produtoForm: FormGroup<TFormProduto>;
  @Input({ required: true }) idCompraProduto: string;
  @Input() unidadesMedidaRendimento: UnidadesMedidaInsert<TipoProdutoEnum.composicao>;
  @Input() unidadesMedidaEquivalente: UnidadesMedidaInsert<TipoProdutoEnum.insumo>;

  @Output() removeUnidadeMedida: EventEmitter<UnidadeMedidaInsert<TipoProdutoEnum>> = new EventEmitter();
  @Output() updateUnidadeRendimentoEquivalente: EventEmitter<Pick<UnidadeMedidaInsert<TipoProdutoEnum>, 'idCompraProduto' | 'idUnidadeMedida' | 'nome'>> = new EventEmitter();

  ClassificacaoProduto = ClassificacaoProdutoEnum;
  TipoProdutoEnum = TipoProdutoEnum;

  control: FormControl<UnidadeMedida> = new FormControl(null);

  ngOnInit() {
    const unidadeMedidaInsert = (this.unidadesMedidaEquivalente || this.unidadesMedidaRendimento)?.[0];

    this.control.setValue({
      id: unidadeMedidaInsert?.idUnidadeMedida,
      nome: unidadeMedidaInsert?.nome,
      sigla: null,
    });

    const sub = this.control.valueChanges.subscribe({
      next: (unidade) => {
        if (!unidade) return;
        this.updateUnidadeRendimentoEquivalente.emit({
          idCompraProduto: this.idCompraProduto,
          idUnidadeMedida: unidade.id,
          nome: unidade.nome,
        });
      }
    });
    this.appendSubscription(sub);

    // this.control.valueChanges.subscribe({
    //   next: (v) => {
    //     const unidade: Pick<UnidadeMedidaInsert, 'idCompraProduto' | 'idUnidadeMedida' | 'nome'> = {
    //       idCompraProduto: this.idCompraProduto,
    //       idUnidadeMedida: v.id,
    //       nome: v.nome,
    //     }
    //     if (this.produtoForm.controls.tipo.value === TipoProdutoEnum.insumo) {
    //       if (!this.unidadesMedidaEquivalente.find(x => x.idUnidadeMedida === v.id)) {
    //         this.unidadesMedidaEquivalente.push({
    //           ...unidade,
    //           quantidadeEquivalente: null,
    //         });
    //       }
    //       return;
    //     }

    //     console.log(this.unidadesMedidaRendimento, this.unidadesMedidaEquivalente);
    //     if (!this.unidadesMedidaRendimento.find(x => x.idUnidadeMedida === v.id)) {
    //       this.unidadesMedidaRendimento.push({
    //         ...unidade,
    //         quantidadeRendimento: null,
    //       });
    //     }
    //   }
    // })
  }

}
