import { Component, Input } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DebounceDirective } from '../../../directives/debounce.directive';
import { NgMatIconComponent } from '../../ng-mat-icon/ng-mat-icon.component';
import { ColorTipoProdutoPipe } from '../../../directives/color-tipo-produto.pipe';
import { ColorDirective } from '../../../directives/color.directive';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NovaCategoriaOptionComponent } from '../../options-autocomplete/nova-categoria-option/nova-categoria-option.component';
import { BaseClass } from '../../../globals/base-class';
import { Categoria, Categorias } from '../../../model/categoria.model';
import { TipoProdutoEnum } from '../../../model/produto.model';
import { defaultDebounceInput } from '../../../globals/globals';
import { displayWith, showApiErrorMessages } from '../../../globals/utils';
import { CategoriaService } from '../../../services/categoria.service';
import { IconInEllipseComponent } from '../../icon-in-ellipse/icon-in-ellipse.component';
import { ModalService } from '../../../services/modal.service';
import { ApiResponseError } from '../../../model/api.model';

@Component({
  selector: 'categoria-autocomplete',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    DebounceDirective,
    NgMatIconComponent,
    ColorTipoProdutoPipe,
    ColorDirective,
    ReactiveFormsModule,
    IconInEllipseComponent,

    NovaCategoriaOptionComponent,
  ],
  templateUrl: './categoria-autocomplete.component.html',
  styleUrl: './categoria-autocomplete.component.scss'
})
export class CategoriaAutocompleteComponent extends BaseClass() {

  @Input({ required: true }) control: FormControl<Categoria>;
  @Input({ required: true }) tipoProduto: TipoProdutoEnum;

  searching: boolean = false;

  categorias: Categorias;

  debounce: number = defaultDebounceInput;

  displayWith = (value: Categoria) => displayWith(value, 'nome');

  constructor(
    private modalService: ModalService,
    private categoriaService: CategoriaService,
  ) {
    super();
  }

  ngOnInit() {
    this.getCategorias();
  }

  filter(el: HTMLInputElement) {
    el.disabled = false;
    el.focus();
    this.getCategorias(el.value);
  }

  async getCategorias(Termo: string = null) {
    try {
      this.searching = true;
      const res = await this.categoriaService.getCategorias({ Termo });
      if (!res.data) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.modalService.presentAlert("Erro", "Erro ao buscar categorias");
        return;
      }

      this.categorias = res.data;
      if (this.control.disabled) this.control.enable();
    } catch (error) {
      console.log(error);
      if (!showApiErrorMessages(this.modalService, error as ApiResponseError<'validation'>)) this.modalService.presentAlert("Erro", "Erro ao buscar categorias");
    } finally {
      this.searching = false;
    }
  }

}
