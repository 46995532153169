import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';
import { NgClass } from '@angular/common';

@Component({
  selector: 'checkbox',
  standalone: true,
  imports: [
    FormsModule,
    ColorDirective,
    NgClass,
  ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {

  @Input({ required: true }) label: string;
  @Input() color: AppColor = 'primary';
  @Input() labelPlacement: 'before' | 'after' = 'before';
  @Input() value: boolean = false;
  @Input() disabled: boolean = false;

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected control: FormControl<boolean> = new FormControl<boolean>(this.value);

  checkedChanged(event: Event) {
    this.control.setValue((event.target as HTMLInputElement).checked);
    this.value = this.control.value;
    this.valueChange.emit(this.value);
  }

}
