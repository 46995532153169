import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { BehaviorSubject } from 'rxjs';
import { defaultClassModal, showApiErrorMessages } from '../../globals/utils';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { SmallLabelComponent } from '../../custom-components/small-label/small-label.component';
import { DebounceDirective } from '../../directives/debounce.directive';
import { FormsModule } from '@angular/forms';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { NoResultsComponent } from '../../custom-components/no-results/no-results.component';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { ColorDirective } from '../../directives/color.directive';
import { IconInEllipseComponent } from '../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { UnidadeMedida, UnidadesMedida } from '../../model/unidade-medida.model';
import { UnidadeMedidaService } from '../../services/unidade-medida.service';
import { NovaUnidadeMedidaComponent } from './nova-unidade-medida/nova-unidade-medida.component';
import { ApiConstraintGetUnidadesMedida } from '../../model/api.model';
import { defaultDebounceInput, DefaultPageSize } from '../../globals/globals';
import { ModalClose, ModalCloseReason } from '../../model/custom-types';
import { PaginatorComponent, PaginatorOpts } from '../../custom-components/paginator/paginator.component';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-unidades-medida',
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    SmallLabelComponent,
    DebounceDirective,
    FormsModule,
    NgMatIconComponent,
    NoResultsComponent,
    OperationInProgressComponent,
    ColorDirective,
    IconInEllipseComponent,
    PaginatorComponent,
  ],
  templateUrl: './unidades-medida.component.html',
  styleUrl: './unidades-medida.component.scss'
})
export class UnidadesMedidaComponent extends BaseClass() {

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  unidadesMedida: UnidadesMedida;
  searchTerm: string;
  modalType = defaultClassModal(this);

  defaultDebounceInput = defaultDebounceInput;

  paginatorOpts: PaginatorOpts;

  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private unidadeMedidaService: UnidadeMedidaService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.getUnidades();
  }

  async getUnidades() {
    try {
      this.operationInProgress$.next(true);

      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_UNIDADES_MEDIDA') || DefaultPageSize.unidadesMedida;
      const constraints: ApiConstraintGetUnidadesMedida = {
        Page: this.paginatorOpts?.page,
        Termo: this.searchTerm,
        PageSize,
      };

      const res = await this.unidadeMedidaService.getUnidadesMedida(constraints);
      if (res.errors) return this.toastService.show({ body: 'Erro ao buscar unidades de medida', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data: unidades } = res;
      this.unidadesMedida = unidades;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar unidades de medida', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  addEdit(unidadeMedida: UnidadeMedida = null) {
    const modal = this.modalService.presentModal(NovaUnidadeMedidaComponent, this.modalType, { unidadeMedida });
    modal.closed.subscribe({
      next: (res: ModalClose<UnidadeMedida, 'unidadeMedida'>) => {
        if ((['saved', 'deleted'] as Array<ModalCloseReason>).includes(res?.reason)) this.getUnidades();
      }
    });
  }

  inputOnSearch() {
    this.getUnidades();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_UNIDADES_MEDIDA', size);
    this.getUnidades();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getUnidades();
  }
}
