import { Component, Inject } from '@angular/core';
import { ProdutosVendaComponent } from '../produtos-venda/produtos-venda.component';
import { BaseClass } from '../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Venda } from '../../../model/venda.model';
import { VendaService } from '../../../services/venda.service';
import { OperationInProgressComponent } from '../../../custom-components/operation-in-progress/operation-in-progress.component';
import { ProdutoNovaVenda } from '../../../model/produto.model';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { closeModal, showApiErrorMessages } from '../../../globals/utils';
import { ColorDirective } from '../../../directives/color.directive';
import { ModalService } from '../../../services/modal.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-editar-venda',
  standalone: true,
  imports: [
    ProdutosVendaComponent,
    OperationInProgressComponent,
    NgMatIconComponent,
    ColorDirective,
  ],
  templateUrl: './editar-venda.component.html',
  styleUrl: './editar-venda.component.scss'
})
export class EditarVendaComponent extends BaseClass() {

  produtosVenda: Array<ProdutoNovaVenda>;
  venda: Venda;
  dataVenda: Date;

  constructor(
    @Inject("data") protected data: { venda: Venda },
    private ngbActiveModal: NgbActiveModal,
    private modalService: ModalService,
    private toastService: ToastService,
    private vendaService: VendaService,
  ) {
    super();
  }

  ngOnInit() {
    this.getData();
  }

  async getData() {
    try {
      const [produtoRes, vendaRes] = await Promise.all([
        this.vendaService.getProdutosVenda(this.data.venda.id),
        this.vendaService.getVendaById(this.data.venda.id),
      ]);

      this.produtosVenda = produtoRes?.data?.map((x) => {
        return {
          id: x.idProduto,
          nome: x.nomeProduto,
          imagem: x.imagem,
          quantidade: x.quantidade,
          valorVenda: x.valorVenda,
        }
      });

      this.venda = vendaRes;
      this.dataVenda = new Date(this.venda.dataVenda);
    } catch (error) {
      if (showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: "Erro ao buscar dados da venda", color: "danger" });
      closeModal(this.ngbActiveModal, "error");
    }
  }

  vendaAtualizada(venda: Venda) {
    this.venda = venda;
    closeModal(this.ngbActiveModal, "saved", venda, "venda");
  }

  dismiss() {
    closeModal(this.ngbActiveModal, "cancel");
  }

}
