import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseClass } from '../../globals/base-class';

@Component({
  selector: 'modal-alert',
  standalone: true,
  imports: [UpperCasePipe, AsyncPipe],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent extends BaseClass() {

  header: string;
  message: string;

  constructor(
    @Inject('data') private data: Record<string, any>,
    private activeModal: NgbActiveModal,
  ) {
    super();

    this.header = this.data?.['header'] || 'Aviso';
    this.message = this.data?.['message'];
  }

  close() {
    this.activeModal.dismiss();
  }

}
