import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Compra, Compras } from '../../../../model/compra.model';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { AsyncPipe, CurrencyPipe, DatePipe, NgClass } from '@angular/common';
import { ColorDirective } from '../../../../directives/color.directive';
import { BehaviorSubject } from 'rxjs';
import { BaseClass } from '../../../../globals/base-class';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'compras-list',
  standalone: true,
  imports: [
    SmallLabelComponent,
    CurrencyPipe,
    DatePipe,
    ColorDirective,
    AsyncPipe,
    NgMatIconComponent,
    NgbAccordionModule,
    NgClass,
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class CompraListComponent extends BaseClass() {

  @Input() compras: Compras;
  @Input() operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Output() editarCompra: EventEmitter<Compra> = new EventEmitter();

}
