import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { OperationInProgressComponent } from '../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../../custom-components/no-results/no-results.component';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { PaginatorComponent, PaginatorOpts } from '../../../custom-components/paginator/paginator.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { Produto, Produtos, TipoProdutoEnum } from '../../../model/produto.model';
import { StorageService } from '../../../services/storage.service';
import { ToastService } from '../../../services/toast.service';
import { ProdutoService } from '../../../services/produto.service';
import { BaseClass } from '../../../globals/base-class';
import { DefaultPageSize } from '../../../globals/globals';
import { ApiConstraintGetProdutos, ApiResponseGetProdutos } from '../../../model/api.model';
import { AsyncPipe, NgClass } from '@angular/common';
import { ColorDirective } from '../../../directives/color.directive';
import { showApiErrorMessages } from '../../../globals/utils';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'produtos-pendentes',
  standalone: true,
  imports: [
    SmallLabelComponent,
    OperationInProgressComponent,
    NoResultsComponent,
    NgMatIconComponent,
    PaginatorComponent,
    AsyncPipe,
    ColorDirective,
    NgClass,
  ],
  templateUrl: './produtos-pendentes.component.html',
  styleUrls: [
    '../list-produtos-common.scss',
    './produtos-pendentes.component.scss',
  ]
})
export class ProdutosPendentesComponent extends BaseClass() {

  @Input() refreshList: Observable<void>;
  @Output() editarProduto: EventEmitter<Produto | ApiResponseGetProdutos> = new EventEmitter<Produto | ApiResponseGetProdutos>();

  paginatorOpts: PaginatorOpts;
  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  produtos: Array<ApiResponseGetProdutos | Produto>;

  constructor(
    private storageService: StorageService,
    private modalService: ModalService,
    private toastService: ToastService,
    private produtoService: ProdutoService,
  ) {
    super();
  }

  ngOnInit() {
    this.paginatorOpts = {
      pageSize: this.storageService.get('PAGE_SIZE_LIST_PENDENCIAS') || DefaultPageSize.produtos,
      page: 1,
    };

    this.getPendencias();

    const sub = this.refreshList.subscribe({
      next: () => {
        this.getPendencias();
      }
    });
    this.appendSubscription(sub);
  }

  async getPendencias() {
    try {
      this.operationInProgress$.next(true);

      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_CATEGORIAS')
      const constraints: ApiConstraintGetProdutos = {
        Page: this.paginatorOpts?.page,
        PageSize,
        TagCadastroCompleto: false,
      };
      const res = await this.produtoService.getProdutos(constraints);
      if (res.errors) return this.toastService.show({ body: 'Erro ao buscar categorias', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data } = res;
      this.produtos = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar categorias', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PENDENCIAS', size);
    this.getPendencias();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getPendencias();
  }
}
