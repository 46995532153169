<div id="container" class="d-flex flex-column h-100 align-items-between">
  <div class="d-grid align-items-center">
    <toolbar [currentPage]="{ title: 'Cadastre-se' }" [usuario]="null" [menu]="null" />
  </div>

  <div id="content" class="d-flex flex-column align-items-center" [ngClass]="{ 'justify-content-center h-100 pt-4': isDesktop }">
    <div id="body" class="gap-5 p-3 rounded-4 justify-content-center form" [ngClass]="{ 'd-flex flex-column h-100': isDesktop }">
      <div id="header" class="d-flex flex-column align-items-center gap-3 my-4">
        @switch (activeSection) {
          @case ("info-pessoal") {
            <ng-container *ngTemplateOutlet="headerContent1" />
          }
          @case ("info-negocio") {
            <ng-container *ngTemplateOutlet="headerContent2" />
          }
          @case ("financas-negocio") {
            <ng-container *ngTemplateOutlet="headerContent2" />
          }
          @case ("dados-login") {
            <ng-container *ngTemplateOutlet="headerContent3" />
          }
        }
      </div>

      @switch (activeSection) {
        @case ("info-pessoal") {
          <section-dados-pessoais [form]="form" />
        }
        @case ("info-negocio") {
          <section-dados-negocio [form]="form" />
        }
        @case ("financas-negocio") {
          <section-financas-negocio [form]="form" />
        }
        @case ("dados-login") {
          <section-dados-login [form]="form" />
        }
      }
    </div>

    <div id="actions" class="d-flex gap-3 align-items-center justify-content-center rounded-top-4">
      <button type="button" id="submit" class="flex-grow-1 btn" color="apoio" (click)="previous()">
        <span> {{ activeSection === sections[0] ? "Cancelar" : "Voltar" }} </span>
      </button>

      <button type="button" id="submit" class="flex-grow-1 btn btn-primary" (click)="next()">
        <span> {{ activeSection === sections[sections.length - 1] ? "Finalizar o cadastro" : "Continuar" }} </span>
      </button>
    </div>
  </div>
</div>

<ng-template #headerContent1>
  <h2 color="dark" class="fw-bold">Suas Informações</h2>
  <small-label class="fw-bold" color="roxo"> Preencha suas informações para o cadastro </small-label>
</ng-template>

<ng-template #headerContent2>
  <h2 color="dark" class="fw-bold">Algumas informações sobre o seu negócio</h2>
  <small-label class="fw-bold" color="roxo"> Através desses dados conseguimos orientar melhor sua experiência dentro da nossa solução </small-label>
</ng-template>

<ng-template #headerContent3>
  <h2 color="dark" class="fw-bold">Dados de login</h2>
</ng-template>
