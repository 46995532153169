import { Component, Inject } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { ColorDirective } from '../../directives/color.directive';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Produto } from '../../model/produto.model';
import { closeModal } from '../../globals/utils';

@Component({
  selector: 'confirmar-remocao-produto-composicao',
  standalone: true,
  imports: [
    ColorDirective,
    SmallLabelComponent,
    NgMatIconComponent,
  ],
  templateUrl: './confirmar-remocao-produto-composicao.component.html',
  styleUrl: './confirmar-remocao-produto-composicao.component.scss'
})
export class ConfirmarRemocaoProdutoComposicaoComponent extends BaseClass() {

  constructor(
    @Inject("data") protected data: { composicaoNome: string, produtoComposicaoNome: string },
    private ngbActiveModal: NgbActiveModal,
  ) {
    super();
  }

  confirmar() {
    closeModal<boolean>(this.ngbActiveModal, "saved", true);
  }

  dismiss() {
    closeModal<boolean>(this.ngbActiveModal, "cancel", false);
  }

}
