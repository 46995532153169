<section-produtos-composicao
  [produtoForm]="form"
  [produtoID]="composicao.id"
  [produtosComposicao]="produtosComposicao"
  [novoProduto]="false"
  [paginatorOpts]="paginatorOpts"
  [operationInProgress$]="operationInProgress$"
  [unidadesMedidaEquivalente]="form.controls.tipo.value === TipoProdutoEnum.insumo ? unidadesMedidaEquivalente : null"
  [unidadesMedidaRendimento]="form.controls.tipo.value === TipoProdutoEnum.composicao ? unidadesMedidaRendimento : null"
  (produtoComposicaoAdicionado)="getProdutosComposicao()"
  (navigatePage)="navigatePage($event)"
  (updatePageSize)="updatePageSize($event)"
/>
