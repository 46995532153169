import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TFormEditProduto, UnidadeMedidaFormEditar } from '../detalhes-produto.component';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { BaseClass } from '../../../../globals/base-class';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { UnidadeMedidaAutocompleteComponent } from '../../../../custom-components/autocomplete/unidade-medida-autocomplete/unidade-medida-autocomplete.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { UnidadeMedida } from '../../../../model/unidade-medida.model';
import { CheckboxComponent } from '../../../../custom-components/checkbox/checkbox.component';
import { ToastService } from '../../../../services/toast.service';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ClassificacaoProdutoEnum, TipoProdutoEnum } from '../../../../model/produto.model';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ListMedidasCheckboxesComponent } from '../../../../custom-components/list-medidas-checkboxes/list-medidas-checkboxes.component';
import { NgxMaskDirective } from 'ngx-mask';
import { CountItemsWithFieldPipe } from '../../../../pipes/count-items-with-field.pipe';

@Component({
  selector: 'section-medidas-produto-editar',
  standalone: true,
  imports: [
    FormsModule,
    SmallLabelComponent,
    UnidadeMedidaAutocompleteComponent,
    ColorDirective,
    ColorTipoProdutoPipe,
    CheckboxComponent,
    NgMatIconComponent,
    NgTemplateOutlet,
    NgClass,
    ListMedidasCheckboxesComponent,
    NgxMaskDirective,
    CountItemsWithFieldPipe,
  ],
  templateUrl: './section-medidas-produto-editar.component.html',
  styleUrl: './section-medidas-produto-editar.component.scss'
})
export class SectionMedidasProdutoEditarComponent extends BaseClass() {

  @Input() formEdit: FormGroup<TFormEditProduto>;
  @Input({ required: true }) unidadesMedida: Array<UnidadeMedidaFormEditar>;

  @Output() unidadeMedidaChange: EventEmitter<UnidadeMedidaFormEditar> = new EventEmitter<UnidadeMedidaFormEditar>();

  unidadeSelect: FormControl<UnidadeMedida> = new FormControl<UnidadeMedida>(null);

  TipoProduto = TipoProdutoEnum;
  ClassificacaoProduto = ClassificacaoProdutoEnum;

  constructor(private toastService: ToastService) {
    super();
  }

  ngOnInit() {
    const sub = this.unidadeSelect.valueChanges.subscribe({
      next: (unidade: UnidadeMedida) => {
        if (!unidade) return;

        if (this.unidadesMedida?.find(u => u.id === unidade.id)) return this.toastService.show({ body: 'Unidade de medida já adicionada', color: 'danger' });

        this.unidadeMedidaChange.emit({
          ...unidade,
          checked: true,
          quantidade: null,
        })
        // this.unidadesMedida.push({
        //   ...unidade,
        //   checked: true,
        //   quantidade: null,
        // });
      }
    });
    this.appendSubscription(sub);
  }
}
