import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClassificacaoProdutoEnum, Produto, TipoProdutoEnum } from '../../model/produto.model';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { ColorDirective } from '../../directives/color.directive';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../globals/base-class';
import { DebounceDirective } from '../../directives/debounce.directive';
import { defaultDebounceInput } from '../../globals/globals';
import { ProdutoService } from '../../services/produto.service';
import { displayWith, showApiErrorMessages } from '../../globals/utils';
import { ColorTipoProdutoPipe } from '../../directives/color-tipo-produto.pipe';
import { ApiResponseGetProdutos } from '../../model/api.model';
import { AppColor } from '../../model/custom-types';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'select-produto',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    NgMatIconComponent,
    SmallLabelComponent,
    ColorDirective,
    NgClass,
    DebounceDirective,
    ColorTipoProdutoPipe,
  ],
  templateUrl: './select-produto.component.html',
  styleUrl: './select-produto.component.scss'
})
export class SelectProdutoComponent extends BaseClass() {

  @Input({ required: true }) produtoControl: FormControl<Partial<Produto>>;

  @Input() label: string = 'Selecione o produto';
  @Input() tipo: TipoProdutoEnum;
  @Input() classificacao: ClassificacaoProdutoEnum;
  @Input() color: AppColor = 'primary';

  @Input() disabled: boolean = false;

  @Output() produtoSelecionado: EventEmitter<Produto> = new EventEmitter();

  debounce = defaultDebounceInput;

  searchingProdutos: boolean = false;
  produtos: Array<ApiResponseGetProdutos | Produto>;

  TipoProdutoEnum = TipoProdutoEnum;

  displayWith = (value: Produto) => displayWith(value, 'nome');

  constructor(
    private produtoService: ProdutoService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    this.getProdutos();
  }

  ngOnChanges() {
    if (this.produtoControl) this.disabled ? this.produtoControl.disable({ emitEvent: false }) : this.produtoControl.enable({ emitEvent: false });
  }

  filterProdutos(el: HTMLInputElement) {
    el.disabled = false;
    el.focus();
    this.getProdutos(el.value);
  }

  async getProdutos(Termo: string = null) {
    try {
      this.searchingProdutos = true;
      const res = await this.produtoService.getProdutos({
        Termo,
        TipoProduto: this.tipo,
        Classificacao: this.classificacao,
      });
      if (res.data) this.produtos = res.data;
      if (this.produtoControl.disabled && !this.disabled) this.produtoControl.enable();
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar produtos', color: 'danger' });
    } finally {
      this.searchingProdutos = false;
    }
  }

}
