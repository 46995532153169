<div id="container" class="rounded-3 p-3">
  <div id="content" class="h-100 rounded-4 p-4 d-flex flex-column gap-4">
    <h2 color="titulo" class="fw-bold">Informações do negócio</h2>

    <div id="body" class="d-grid" [ngClass]="{ 'gap-5': isDesktop, 'gap-4': !isDesktop }">
      <div class="dado-empresa form-group d-flex flex-column gap-2">
        <small-label color="cinza"> Nome da empresa </small-label>
        @if (!empresa) {
          <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
        } @else {
          @if (empresa?.nome) {
            <small-label color="titulo" class="fw-bold"> {{ empresa?.nome }} </small-label>
          } @else {
            <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
          }
        }
      </div>

      <div class="dado-empresa form-group d-flex flex-column gap-2">
        <small-label color="cinza"> Ramo do negócio </small-label>
        @if (!empresa) {
          <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
        } @else {
          @if (empresa?.ramoNegocio) {
            <small-label color="titulo" class="fw-bold"> {{ empresa?.ramoNegocio }} </small-label>
          } @else {
            <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
          }
        }
      </div>

      <div class="dado-empresa form-group d-flex flex-column gap-2">
        <small-label color="cinza"> Idade do negócio </small-label>
        @if (!empresa) {
          <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
        } @else {
          @if (empresa?.dataCriacao) {
            <small-label color="titulo" class="fw-bold"> {{ empresa?.dataCriacao | timeDiff }} </small-label>
          } @else {
            <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
          }
        }
      </div>

      <div class="dado-negocio form-group d-flex flex-column gap-2">
        <small-label color="cinza"> Custo fixo </small-label>
        @if (!empresa) {
          <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
        } @else {
          @if (empresa?.custoFixo) {
            <h5 color="titulo" class="fw-bold">{{ empresa?.custoFixo | currency }}</h5>
          } @else {
            <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
          }
        }
      </div>

      <div class="dado-negocio form-group d-flex flex-column gap-2">
        <small-label color="cinza"> Despesas de venda </small-label>
        @if (!empresa) {
          <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
        } @else {
          @if (empresa?.despesasVenda) {
            <h5 color="titulo" class="fw-bold">{{ empresa?.despesasVenda | currency }}</h5>
          } @else {
            <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
          }
        }
      </div>

      <div class="dado-negocio form-group d-flex flex-column gap-2">
        <small-label color="cinza"> Faturamento médio </small-label>
        @if (!empresa) {
          <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
        } @else {
          @if (empresa?.faturamentoMedio) {
            <h5 color="titulo" class="fw-bold">{{ empresa?.faturamentoMedio | currency }}</h5>
          } @else {
            <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
          }
        }
      </div>

      <div class="dado-negocio form-group d-flex flex-column gap-2">
        <small-label color="cinza"> Margem de lucro </small-label>
        @if (!empresa) {
          <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
        } @else {
          @if (empresa?.percentualMargemLucro) {
            <h5 color="titulo" class="fw-bold">{{ empresa?.percentualMargemLucro / 100 | percent: "1.0-2" }}</h5>
          } @else {
            <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
          }
        }
      </div>

      <div class="dado-negocio form-group d-flex flex-column gap-2">
        <small-label color="cinza"> Multiplicador </small-label>
        @if (!empresa) {
          <small-label color="cinza" class="fst-italic"> Carregando... </small-label>
        } @else {
          @if (empresa?.multiplicadorPreco) {
            <h5 color="titulo" class="fw-bold">{{ empresa?.multiplicadorPreco }}</h5>
          } @else {
            <small-label color="titulo" class="fw-bold fst-italic"> - </small-label>
          }
        }
      </div>
    </div>

    @if (empresa) {
      <div id="actions" class="d-flex gap-5 mt-5">
        <button class="btn px-5" color="titulo" [ngClass]="{ 'w-100': !isDesktop }" (click)="edit()">
          <span class="mx-5">Editar</span>
        </button>
      </div>
    }
  </div>
</div>
