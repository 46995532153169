<div id="container" class="h-100 overflow-y-hidden p-4">
  <div id="content" class="d-flex flex-column">
    <div id="header">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-bold">
          Alterar a categoria do <span color="roxo"> {{ produto.nome }} </span>
        </h3>
      </div>
    </div>

    <div id="body" class="overflow-y-auto mt-4">
      <!-- <small-label color="roxo" class="fw-bold"> Preciso de ajuda! </small-label> -->

      <div class="form-group col px-0 d-flex flex-column gap-2 mt-5">
        <small-label> Escolha a categoria </small-label>

        <categoria-autocomplete [control]="control" [tipoProduto]="produto?.tipoProduto" />
      </div>
    </div>

    <div id="buttons" class="mt-5 d-flex gap-2 mt-4">
      <button type="button" class="px-4 btn flex-grow-1" color="apoio" (click)="dismiss()">Cancelar</button>

      <button type="button" class="px-4 btn flex-grow-1" color="roxo" (click)="salvar()">Alterar</button>
    </div>
  </div>
</div>
