<div id="container" class="h-100">
  <div id="content" class="h-100 rounded-4 py-4">
    <div [ngClass]="{ 'd-flex gap-4 align-items-end': isDesktop }" class="mb-5" id="header">
      <button [disabled]="operationInProgress$ | async" id="btn-novo" type="button" color="laranja-forte" class="btn gap-3" [ngClass]="{ 'grid-cell-full': !isDesktop }" (click)="registrarCompra()">
        <span> Registrar compra </span>
      </button>

      <div class="form-group d-flex flex-column gap-2 flex-grow-1 mt-4" id="searchbox">
        <small-label class="fw-bold"> Busque pelo insumo cadastrado </small-label>

        <div id="searchbox" class="input-group input-icon-end position-relative input-icon-end">
          <input
            type="text"
            class="form-control lh-2 pe-5"
            id="search-input"
            placeholder="Busque pelo insumo"
            [debounce]="debounce"
            [operationObs$]="operationInProgress$"
            [enterOnly]="false"
            (onChange)="inputOnSearch()"
            [disabled]="operationInProgress$ | async"
            [(ngModel)]="searchTerm"
          />
          <ng-mat-icon class="cursor-pointer" slot="end" name="search" color="laranja-forte" />
        </div>
      </div>

      <!-- <div class="form-group d-flex flex-column gap-2" id="options">
        <small-label> </small-label>

        <div class="d-flex gap-2">
          <button
            [disabled]="operationInProgress$ | async"
            id="btn-filtros"
            type="button"
            color="apoio"
            class="btn has-custom-color"
            (click)="selecionarFiltros()"
            ngbTooltip="Filtros"
            placement="top"
            [openDelay]="DelayShowTooltip"
          >
            <ng-mat-icon name="filter_alt" color="laranja-forte" />
          </button>

          <button [disabled]="operationInProgress$ | async" id="btn-options" type="button" color="apoio" class="btn has-custom-color" (click)="options()">
            <ng-mat-icon name="more_vert" color="laranja-forte" />
          </button>
        </div>
      </div> -->
    </div>

    <div id="body" class="my-3">
      <div id="list" class="position-relative">
        @if (compras?.length > 0) {
          <compras-list [compras]="compras" [operationInProgress$]="operationInProgress$" (editarCompra)="registrarCompra($event)" />
        } @else if (compras?.length === 0) {
          <no-results [classes]="['text-center']">
            @if (searchTerm) {
              <h1 color="black" class="fw-bold">Nenhum resultado encontrado</h1>
              <p>Verifique se o insumo foi cadastrado</p>
            } @else {
              <h1 color="black" class="fw-bold">Você ainda não tem compras cadastradas</h1>
              <p>Comece registrando suas compras</p>
            }
          </no-results>
        } @else {
          <operation-in-progress />
        }
      </div>
    </div>

    @if (paginatorOpts) {
      <paginator
        [isDisabled]="operationInProgress$.value"
        color="laranja-forte"
        [showLabels]="false"
        [page]="paginatorOpts?.page"
        [pages]="paginatorOpts.pages"
        [pageSize]="paginatorOpts.pageSize"
        [rowsCount]="paginatorOpts.rowsCount"
        [isDisabled]="operationInProgress$.value || !compras?.length"
        (updatePageSize)="updatePageSize($event)"
        (navigatePage)="navigatePage($event)"
      />
    }
  </div>
</div>
