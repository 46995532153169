import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseClass } from '../../../../globals/base-class';
import { Venda, VendaHistoricoProduto, Vendas } from '../../../../model/venda.model';
import { ProdutoService } from '../../../../services/produto.service';
import { VendaService } from '../../../../services/venda.service';
import { ModalService } from '../../../../services/modal.service';
import { ToastService } from '../../../../services/toast.service';
import { StorageService } from '../../../../services/storage.service';
import { Produto } from '../../../../model/produto.model';
import { BehaviorSubject } from 'rxjs';
import { PaginatorComponent, PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { ApiConstraintGetVendas, ApiResponseError } from '../../../../model/api.model';
import { EditarVendaComponent } from '../../editar-venda/editar-venda.component';
import { closeModal, defaultClassModal, showApiErrorMessages } from '../../../../globals/utils';
import { ModalClose } from '../../../../model/custom-types';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';

@Component({
  selector: 'app-historico-vendas-produto',
  standalone: true,
  imports: [
    NgMatIconComponent,
    SmallLabelComponent,
    ColorDirective,
    DatePipe,
    CurrencyPipe,
    OperationInProgressComponent,
    AsyncPipe,
    PaginatorComponent,
  ],
  templateUrl: './historico-vendas-produto.component.html',
  styleUrl: './historico-vendas-produto.component.scss'
})
export class HistoricoVendasProdutoComponent extends BaseClass() {

  vendaHistorico: VendaHistoricoProduto;
  produto: Produto;
  vendas: Vendas;

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  paginatorOpts: PaginatorOpts;

  dataChanged: boolean = false;

  constructor(
    @Inject("data") private data: { vendaHistorico: VendaHistoricoProduto },
    private produtoService: ProdutoService,
    private ngbActiveModal: NgbActiveModal,
    private vendaService: VendaService,
    private modalService: ModalService,
    private toastService: ToastService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.vendaHistorico = this.data?.vendaHistorico;
    if (!this.vendaHistorico) return this.dismiss();
    this.getData();
  }

  async getData(apenasVendas: boolean = false) {
    try {
      if (!apenasVendas) await this.getProduto();
      await this.getVendasProduto();
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao carregar dados', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  private async getProduto() {
    const produto = await this.produtoService.getProdutoById(this.vendaHistorico.idProduto);
    if (!produto) return this.dismiss();
    this.produto = produto;
  }

  private async getVendasProduto() {
    const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_VENDAS')
    const constraints: ApiConstraintGetVendas = {
      Page: this.paginatorOpts?.page || 1,
      PageSize,
      IdProduto: this.vendaHistorico.idProduto,
    };
    const res = await this.vendaService.getVendas(constraints);
    const { page, pages, pageSize, rowsCount, data } = res;
    if (!data) return this.dismiss();
    this.vendas = data;
    this.paginatorOpts = { page, pages, pageSize, rowsCount };
  }

  async editarVenda(venda: Venda) {
    const modal = this.modalService.presentModal(EditarVendaComponent, 'slide-right', { venda }, { windowClass: this.isDesktop ? 'modal-80vw' : 'modal-fullscreen' });
    const result: ModalClose<Venda, 'venda'> = await modal.result;
    if (result?.reason !== 'saved' || !result?.venda) return;
    this.dataChanged = true;
    this.operationInProgress$.next(true);
    this.getData(true);
  }

  async excluirVenda(venda: Venda) {
    const confirm = this.modalService.presentConfirm("Excluir venda", "Deseja realmente excluir esta venda?", null, { yesButtonColor: 'danger' });
    const result: ModalClose<boolean> = await confirm.result;
    if (!result?.data) return;

    const loading = this.modalService.presentLoading('Excluindo...');
    try {
      const res = await this.vendaService.deleteVenda(venda.id);
      if (!res.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Erro ao excluir venda', color: 'danger' });
        return
      }
      this.dataChanged = true;
      this.operationInProgress$.next(true);
      this.getData(true);
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao excluir venda', color: 'danger' });
    } finally {
      loading.dismiss();
    }
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_VENDAS', size);
    this.getData(true);
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getData(true);
  }

  dismiss() {
    closeModal(this.ngbActiveModal, this.dataChanged ? "saved" : "cancel", this.dataChanged);
  }
}
