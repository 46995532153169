<div id="container" class="h-100 p-3">
  <div id="content" class="h-100 position-relative rounded-3 p-4">
    <div class="h-100 d-flex gap-3 d-flex text-center flex-column align-items-center justify-content-center">
      <h1 class="fw-600" color="verde">Tem alguma dúvida ou problema?</h1>
      <h3>Entre em contato conosco!</h3>

      <div class="d-grid gap-3">
        <div class="card rounded-3 px-4 py-3 cursor-pointer" (click)="sendMail()">
          <div class="body d-flex flex-column gap-3 align-items-center px-4 py-2">
            <ng-mat-icon name="email" color="dark" />
            <span class="fw-bold"> Email </span>
          </div>
        </div>

        <div class="card rounded-3 px-4 py-3 cursor-pointer" (click)="sendWhatsapp()">
          <div class="body d-flex flex-column gap-3 align-items-center px-4 py-2">
            <icon name="logo-whatsapp" />
            <span class="fw-bold"> Whatsapp </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
