import { Component, Inject } from '@angular/core';
import { Empresa, EmpresaUpdate, NegocioEmpresa } from '../../../model/empresa.model';
import { RamoNegocio, RamosNegocio } from '../../../model/ramo-negocio.model';
import { BaseClass } from '../../../globals/base-class';
import { EmpresaService } from '../../../services/empresa.service';
import { NgbActiveModal, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { closeModal, dateDDMMYYYYIsInvalid, showApiErrorMessages } from '../../../globals/utils';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../directives/color.directive';
import { NgClass } from '@angular/common';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { DropdownComponent } from '../../../custom-components/dropdown/dropdown.component';
import { RamoNegocioService } from '../../../services/ramo-negocio.service';
import { NgxMaskDirective } from 'ngx-mask';
import { ModalService } from '../../../services/modal.service';
import { ToastService } from '../../../services/toast.service';
import { ApiResponseError } from '../../../model/api.model';
import { parse } from 'date-fns';

export type DataModalEditarMeuNegocio = {
  empresa: Empresa;
  apenasDadosFinanceiros?: boolean;
};

type TFormEditarMeuNegocio = {
  nomeEmpresa: FormControl<string>;
  ramoNegocio: FormControl<RamoNegocio>;
  dataCriacao: FormControl<string>;

  custoFixo: FormControl<number>;
  despesasVenda: FormControl<number>;
  percentualMargemLucro: FormControl<number>;
  faturamentoMedio: FormControl<number>;
  multiplicadorPreco: FormControl<number>;
}

@Component({
  selector: 'editar-meu-negocio',
  standalone: true,
  imports: [
    SmallLabelComponent,
    ColorDirective,
    NgClass,
    ReactiveFormsModule,
    NgMatIconComponent,
    DropdownComponent,
    NgxMaskDirective,
    NgbDatepickerModule,
  ],
  templateUrl: './editar-meu-negocio.component.html',
  styleUrl: './editar-meu-negocio.component.scss'
})
export class EditarMeuNegocioComponent extends BaseClass() {

  empresa: Empresa;

  form: FormGroup<TFormEditarMeuNegocio> = new FormGroup({
    nomeEmpresa: new FormControl(null),
    ramoNegocio: new FormControl(null),
    dataCriacao: new FormControl(null),

    custoFixo: new FormControl(null),
    despesasVenda: new FormControl(null),
    percentualMargemLucro: new FormControl(null),
    faturamentoMedio: new FormControl(null),
    multiplicadorPreco: new FormControl(null),
  });

  ramosNegocio: RamosNegocio;

  now: NgbDateStruct = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  constructor(
    @Inject("data") protected data: DataModalEditarMeuNegocio,
    private ngbActiveModal: NgbActiveModal,
    private empresaService: EmpresaService,
    private ramoNegocioService: RamoNegocioService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.data) {
      this.empresa = this.data.empresa;

      this.form.patchValue({
        nomeEmpresa: this.empresa.nome || null,
        ramoNegocio: {
          descricao: this.empresa.ramoNegocio,
          id: this.empresa.idRamoNegocio,
        },
        dataCriacao: this.empresa.dataCriacao ? new Date(this.empresa.dataCriacao).toLocaleDateString('pt-BR') : null,

        custoFixo: this.empresa.custoFixo || null,
        despesasVenda: this.empresa.despesasVenda || null,
        percentualMargemLucro: this.empresa.percentualMargemLucro || null,
        faturamentoMedio: this.empresa.faturamentoMedio || null,
        multiplicadorPreco: this.empresa.multiplicadorPreco || null,
      });
      this.form.markAsPristine();

      this.getRamosNegocio();
    }
  }

  async getRamosNegocio() {
    this.ramoNegocioService.getRamosNegocio().then((res) => {
      this.ramosNegocio = res.data;
    }).catch((err) => {
      if (!showApiErrorMessages(this.modalService, err)) this.toastService.show({ body: "Erro ao buscar ramos de negócio", color: "danger" });
    })
  }

  async salvar() {
    const { nomeEmpresa, ramoNegocio, dataCriacao: dataCriacaoForm, custoFixo, despesasVenda, percentualMargemLucro, faturamentoMedio, multiplicadorPreco } = this.form.value;

    let dataCriacao: string = null;
    if (dataCriacaoForm) dataCriacao = parse(dataCriacaoForm, 'dd/MM/yyyy', new Date())?.toISOString();

    const empresa: EmpresaUpdate = {
      id: this.empresa.id,
      nome: nomeEmpresa,
      cpfCnpj: this.empresa.cpfCnpj,
      dataCriacao: dataCriacao || null,
      tipo: this.empresa.tipo,
      status: this.empresa.status,
      idRamoNegocio: ramoNegocio.id,
      idEstado: this.empresa.idEstado,
      idCidade: this.empresa.idCidade,
    }

    const dadosNegocio: NegocioEmpresa = {
      custoFixo,
      despesasVenda,
      percentualMargemLucro,
      faturamentoMedio,
      multiplicadorPreco,
    }

    if (this.form.pristine) return closeModal(this.ngbActiveModal, "saved", { empresa, negocio: dadosNegocio });

    const loading = this.modalService.presentLoading("Salvando dados do negócio...");

    try {
      const [empresaAtualizada, negocioAtualizado] = await Promise.all([
        this.empresaService.updateEmpresa(empresa),
        this.empresaService.updateNegocioEmpresa(dadosNegocio),
      ]);

      if (!empresaAtualizada?.success) return showApiErrorMessages(this.modalService, empresaAtualizada as ApiResponseError<'validation'>);
      if (!negocioAtualizado?.success) return showApiErrorMessages(this.modalService, negocioAtualizado as ApiResponseError<'validation'>);

      this.toastService.show({ body: "Dados do negócio atualizados com sucesso", color: "success" });
      closeModal(this.ngbActiveModal, "saved", { empresa: empresaAtualizada.data, negocio: negocioAtualizado.data });
    } catch (error) {
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: "Erro ao atualizar dados do negócio", color: "danger" });
    } finally {
      loading.dismiss();
    }
  }

  focusOutInputDate(el: HTMLInputElement) {
    if (el.value?.length === 10 && dateDDMMYYYYIsInvalid(el.value)) {
      el.value = null;
      this.form.controls.dataCriacao.setValue(null);
      this.toastService.show({ body: 'Data inválida', color: "danger" });
    }
  }

  dismiss() {
    closeModal(this.ngbActiveModal, "cancel", false);
  }

}
