<nav class="navbar bg-body-tertiary h-100 p-0">
  <div class="container p-0" [ngClass]="{ 'd-flex flex-column align-items-start': !isDesktop }">
    <div class="navbar-brand d-flex align-items-center gap-4 w-100" [class.px-4]="!isDesktop" [ngClass]="{ 'justify-content-between': !isDesktop }">
      <a href="/">
        <img class="logo" [src]="isDesktop ? 'assets/logo-sm.png' : 'assets/logo-pequena.png'" alt="Smartis Logo" height="24" />
      </a>

      <div class="d-flex align-items-center" [ngClass]="{ 'justify-content-between w-fill-available': isDesktop, 'flex-row-reverse gap-2': !isDesktop }">
        @if (!isDesktop) {
          @if (usuario) {
            <a (click)="menu?.toggle()">
              <img [src]="usuario?.imagem || 'https://ui-avatars.com/api/?name=' + usuario?.nome" class="avatar" />
            </a>
          }
        } @else {
          <span id="title" class="fw-600"> {{ currentPage?.title }} </span>
        }

        @if (usuario?.quantidadeCreditos != undefined) {
          <creditos-button [quantidadeCreditos]="usuario.quantidadeCreditos" (click)="showModalSugestaoPreco()" />
        }
      </div>
    </div>
  </div>

  @if (!isDesktop) {
    <div class="container p-0" [ngClass]="{ 'd-flex flex-column align-items-start': !isDesktop }">
      <div class="navbar-brand d-flex align-items-center gap-4 py-2" [class.px-4]="!isDesktop">
        <span id="title" class="fw-600"> {{ currentPage?.title }} </span>
      </div>
    </div>
  }
</nav>

<ng-template #SugestaoPrecoComponentRef>
  <sugestao-preco />
</ng-template>
