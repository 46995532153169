import { Component, Input } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { AppPages, AppPath, PagesApp, routerLinkActiveOptions } from '../app-pages';
import { StorageService } from '../../../services/storage.service';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { Menu } from '../../../model/menu.model';
import { AsyncPipe, NgClass } from '@angular/common';
import { BaseClass } from '../../../globals/base-class';
import { ColorDirective } from '../../../directives/color.directive';
import { Usuario } from '../../../model/usuario.model';
import { NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../services/modal.service';
import { defaultClassModal } from '../../../globals/utils';
import { ChangePasswordComponent } from '../../../login/change-password/change-password.component';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { dynamicAmountItemsMenu, toolbarHeightDesktop } from '../../../globals/globals';
import { ExtraOptionsComponent } from './extra-options/extra-options.component';
import { transitionDuration } from '../../../globals/globals';
import { BehaviorSubject } from 'rxjs';

export let PAGES_SIDENAV: BehaviorSubject<PagesApp> = new BehaviorSubject<PagesApp>([]);

@Component({
  selector: 'sidenav',
  standalone: true,
  imports: [RouterModule, NgClass, AsyncPipe, IconComponent, ColorDirective, NgbPopover, RouterLink, RouterLinkActive, NgMatIconComponent],
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss', './menu-behavior.scss'],
})
export class SidenavComponent extends BaseClass() {

  @Input() menu: Menu;
  @Input() usuario: Partial<Usuario>;

  modalExtraOptions: NgbModalRef;

  routerLinkActiveOptions = routerLinkActiveOptions;

  defaultAmountItemsMenu: number = 6;
  allOptions: Array<AppPath> = ['produtos', 'compras', 'vendas', 'categorias', 'unidades-medida', 'pendencias', 'minha-conta', 'meu-negocio', 'ajuda'];
  optionsToShowMobile: Array<AppPath> = ['minha-conta', 'meu-negocio', 'pendencias', 'unidades-medida', 'ajuda'];

  disabledExtrasOptionsButton: boolean = false;

  PAGES_SIDENAV = PAGES_SIDENAV;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.setPages();
  }

  setPages() {
    let maxOptionsDesktop = this.defaultAmountItemsMenu;

    if (dynamicAmountItemsMenu) {
      const profileOptionHeight = 101;
      const documentHeight = document.body.clientHeight;
      const menuOptionHeight = 85;

      const sidenavHeight = documentHeight - profileOptionHeight - toolbarHeightDesktop;
      maxOptionsDesktop = Math.floor(sidenavHeight / menuOptionHeight);
      maxOptionsDesktop = maxOptionsDesktop - (maxOptionsDesktop < this.allOptions.length ? 1 : 0);
    }

    this.PAGES_SIDENAV.next((this.isDesktop ? (this.allOptions.slice(0, maxOptionsDesktop)) : this.optionsToShowMobile).map(x => AppPages.find(p => p.path === x)));
  }

  override windowResized = () => {
    this.setPages();
  };

  showMoreOptions() {
    if (this.modalExtraOptions) return this.dismissModalExtrasOptions();

    const options = this.allOptions.filter(x => !this.PAGES_SIDENAV.value.map(p => p.path).includes(x));

    this.disabledExtrasOptionsButton = true;

    setTimeout(() => this.disabledExtrasOptionsButton = false, 2 * transitionDuration);

    this.modalExtraOptions = this.modalService.presentModal(ExtraOptionsComponent, 'slide-left', { options }, { backdrop: true, backdropClass: 'opacity-0', windowClass: 'modal-extra-options' });
    const subD = this.modalExtraOptions.closed.subscribe({
      next: () => {
        this.modalExtraOptions = null
      }
    });
    this.appendSubscription(subD);
  }

  private dismissModalExtrasOptions() {
    this.modalExtraOptions?.dismiss();
    this.modalExtraOptions = null;
  }

  isActiveChange(isActive: boolean = true) {
    if (!isActive) {
      this.closeMenu();
      this.dismissModalExtrasOptions();
    }
  }

  closeMenu() {
    if (this.menu?.opened) this.menu?.close();
  }

  changePassword() {
    this.modalService.presentModal(ChangePasswordComponent, defaultClassModal(this), null, { windowClass: 'blur-backdrop', });
  }

  logout() {
    this.router.navigate(['/login'], { replaceUrl: true }).then(() => this.storageService.removeSession());
  }
}
