import { Component, Inject } from '@angular/core';
import { Session, Usuario } from '../../../model/usuario.model';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass } from '@angular/common';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../directives/color.directive';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { NgxMaskDirective } from 'ngx-mask';
import { BaseClass } from '../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from '../../../services/usuario.service';
import { ModalService } from '../../../services/modal.service';
import { ToastService } from '../../../services/toast.service';
import { closeModal, showApiErrorMessages } from '../../../globals/utils';
import { ApiResponseError } from '../../../model/api.model';
import { minLengthPassword } from '../../../globals/globals';

export type DataModalEditarDadosLogin = {
  usuario: Usuario;
};

type TFormEditarEditarDadosLogin = {
  email: FormControl<string>;
  senhaAtual: FormControl<string>;
  novaSenha: FormControl<string>;
  confirmarNovaSenha: FormControl<string>;
}

@Component({
  selector: 'app-editar-dados-login',
  standalone: true,
  imports: [
    NgClass,
    SmallLabelComponent,
    ColorDirective,
    ReactiveFormsModule,
    NgMatIconComponent,
    NgxMaskDirective,
  ],
  templateUrl: './editar-dados-login.component.html',
  styleUrl: './editar-dados-login.component.scss'
})
export class EditarDadosLoginComponent extends BaseClass() {

  usuario: Usuario;

  form: FormGroup<TFormEditarEditarDadosLogin> = new FormGroup({
    email: new FormControl({ value: null, disabled: true }),
    senhaAtual: new FormControl(null, [Validators.required]),
    novaSenha: new FormControl(null, [Validators.required, Validators.minLength(minLengthPassword)]),
    confirmarNovaSenha: new FormControl(null, [Validators.required, Validators.minLength(minLengthPassword)]),
  });

  constructor(
    @Inject("data") private data: DataModalEditarDadosLogin,
    private ngbActiveModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    this.usuario = this.data.usuario;
    this.form.patchValue({
      email: this.usuario.email,
    });
  }

  async salvar() {
    const { email, senhaAtual, novaSenha, confirmarNovaSenha } = this.form.value;

    let messages: Array<string> = [];
    if (!novaSenha) messages.push(`Campo nova senha é obrigatório e deve ter no mínimo ${minLengthPassword} caracteres`);
    else if (novaSenha?.length < minLengthPassword) messages.push(`A senha deve ter no mínimo ${minLengthPassword} caracteres`);

    if (!confirmarNovaSenha) messages.push(`Campo confirmar nova senha é obrigatório e deve ter no mínimo ${minLengthPassword} caracteres`);

    if (novaSenha !== confirmarNovaSenha) messages.push('As senhas não conferem');

    if (messages.length) return this.modalService.presentAlert("Atenção", messages.join('\n'));

    const loading = this.modalService.presentLoading('Salvando informações...');

    try {
      const res = await this.usuarioService.changePassword(senhaAtual, novaSenha);
      if (!res && !showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>))
        this.toastService.show({ body: 'Erro ao alterar senha', color: 'danger' });

      this.toastService.show({ body: 'Senha alterada com sucesso', color: 'success' });
      closeModal(this.ngbActiveModal, 'saved', true);
    } catch (error) {
      if (!showApiErrorMessages(this.modalService, error))
        this.toastService.show({ body: 'Erro ao alterar senha', color: 'danger' });
    } finally {
      loading.dismiss();
    }
  }

  dismiss() {
    closeModal(this.ngbActiveModal, "cancel", false);
  }

}
