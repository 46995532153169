<div id="container">
  <input
    #inputCategoriaRef
    #autocompleteTrigger="matAutocompleteTrigger"
    type="text"
    class="categoria form-control lh-2"
    id="formControlName_categoria"
    placeholder="Ex: Alimentos, Bolos, Bebidas"
    [formControl]="control"
    [matAutocomplete]="autocompleteCategoria"
    [debounce]="debounce"
    (onChange)="filter($event)"
  />
  <ng-mat-icon class="cursor-pointer" slot="end" name="expand_more" [color]="tipoProduto | colorTipoProduto" (click)="inputCategoriaRef.focus()" />
  <mat-autocomplete #autocompleteCategoria="matAutocomplete" [displayWith]="displayWith" requireSelection>
    <nova-categoria-option [control]="control" [tipoProduto]="tipoProduto" [autocompleteTrigger]="autocompleteTrigger" (categoriaAdicionada)="getCategorias()" />
    @if (searching) {
      <mat-option disabled>Carregando categorias...</mat-option>
    } @else {
      @if (categorias.length === 0) {
        <mat-option disabled>Nenhuma categoria encontrada</mat-option>
      }
      @for (categoria of categorias; track categoria) {
        <mat-option [value]="categoria">
          <div class="d-flex gap-3 align-items-center">
            <icon-in-ellipse [matIcon]="categoria.icone" color="white" [bgColor]="categoria.cor || null" />
            <span>{{ categoria.nome }}</span>
          </div>
        </mat-option>
      }
    }
  </mat-autocomplete>
</div>
