import { Component, Inject } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { UnidadeMedida } from '../../../model/unidade-medida.model';
import { UnidadeMedidaService } from '../../../services/unidade-medida.service';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { ToastService } from '../../../services/toast.service';
import { ModalService } from '../../../services/modal.service';
import { closeModal, showApiErrorMessages } from '../../../globals/utils';
import { ModalClose } from '../../../model/custom-types';
import { ColorDirective } from '../../../directives/color.directive';
import { ApiResponseError } from '../../../model/api.model';

type TForm = { [key in keyof Pick<UnidadeMedida, 'nome' | 'sigla'>]: FormControl<string> };

@Component({
  selector: 'app-nova-unidade-medida',
  standalone: true,
  imports: [
    NgMatIconComponent,
    ReactiveFormsModule,
    SmallLabelComponent,
    ColorDirective,
  ],
  templateUrl: './nova-unidade-medida.component.html',
  styleUrl: './nova-unidade-medida.component.scss'
})
export class NovaUnidadeMedidaComponent extends BaseClass() {

  unidadeMedida: UnidadeMedida;
  form: FormGroup<TForm> = new FormGroup({
    nome: new FormControl(null),
    sigla: new FormControl(null),
  });

  constructor(
    @Inject('data') private data: { unidadeMedida: UnidadeMedida },
    private unidadeMedidaService: UnidadeMedidaService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.unidadeMedida = this.data?.unidadeMedida || null;

    this.setForm();
  }

  private setForm() {
    this.form.setValue({
      nome: this.unidadeMedida?.nome || null,
      sigla: this.unidadeMedida?.sigla || null,
    });
  }

  salvar() {
    const unidadeMedida: Partial<UnidadeMedida> = this.form.value;

    const loading = this.modalService.presentLoading("Salvando unidade de medida", true);

    (this.data?.unidadeMedida ? this.unidadeMedidaService.update({ id: this.data.unidadeMedida.id, ...unidadeMedida }) : this.unidadeMedidaService.insert(unidadeMedida)).then((res) => {
      if (!res.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastErrorSave();
        return;
      }

      this.toastService.show({ body: 'Unidade de medida salva com sucesso', color: 'success' });
      closeModal<UnidadeMedida, 'unidadeMedida'>(this.activeModal, 'saved', res.data, 'unidadeMedida');
    }).catch((err) => {
      if (showApiErrorMessages(this.modalService, err)) return;
      this.toastErrorSave()
    }).finally(() => {
      loading.dismiss();
    });
  }

  private toastErrorSave() {
    this.toastService.show({ body: 'Erro ao salvar unidade de medida', color: 'danger' });
  }

  async delete() {
    const confirm = this.modalService.presentConfirm('Tem certeza?', 'Deseja realmente excluir esta unidade de medida?', true, { yesButtonColor: 'danger' });

    const res: ModalClose<boolean> = await confirm.result;

    if (!res.data) return;

    const loading = this.modalService.presentLoading('Excluindo unidade de medida...', true);

    this.unidadeMedidaService.deleteUnidadeMedida(this.unidadeMedida.id).then((res) => {
      if (!res.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Erro ao excluir unidade de medida', color: 'danger' });
        return;
      }

      this.toastService.show({ body: `Unidade de medida excluída com sucesso`, color: 'success' });
      closeModal<UnidadeMedida, 'unidadeMedida'>(this.activeModal, 'deleted', this.unidadeMedida, 'unidadeMedida');
    }).catch((err) => {
      console.log(err);
      if (!showApiErrorMessages(this.modalService, err as ApiResponseError<'validation'>)) this.toastErrorSave();
    }).finally(() => {
      loading.dismiss();
    });
  }

  dismiss() {
    closeModal(this.activeModal, 'cancel');
  }

}
