import { Injectable, Injector } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../custom-components/alert/alert.component';
import { LoadingComponent } from '../custom-components/loading/loading.component';
import { ConfirmComponent } from '../custom-components/confirm/confirm.component';
import { AppColor } from '../model/custom-types';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private ngbModal: NgbModal) {}

  presentModal<T>(content: T, modalType: ModalType = "default", data: Record<string, any> = null, _options?: NgbModalOptions) {
    return this.present(content, modalType, data, _options);
  }

  presentAlert(header: string, message: string, blurBackdrop = false) {
    return this.present(AlertComponent, 'alert', { header, message }, { windowClass: 'alert'.concat(blurBackdrop ? ' blur-backdrop' : '') });
  }

  presentLoading(message: string = 'Carregando...', blurBackdrop = false) {
    return this.present(LoadingComponent, 'loading', { message }, { keyboard: false, backdrop: 'static', windowClass: 'alert'.concat(blurBackdrop ? ' blur-backdrop' : '') });
  }

  presentConfirm(header: string, message: string, blurBackdrop = false, config: { yesButtonColor?: AppColor, noButtonColor?: AppColor, yesText?: string, noText?: string } = null) {
    return this.present(ConfirmComponent, 'alert', { header, message, confirm: true, config }, { windowClass: 'alert'.concat(blurBackdrop ? ' blur-backdrop' : '') });
  }

  private present<T>(content: T, modalType: ModalType | 'alert' | 'loading', data: Record<string, any>, _options?: NgbModalOptions) {
    const injector = Injector.create({
      providers: [{ provide: 'data', useValue: data }]
    });

    const classByType = (['slide-bottom', 'slide-left', 'slide-right'] as Array<ModalType>).includes(modalType as ModalType) ? modalType : '';

    const options: NgbModalOptions = {
      centered: true,
      ..._options,
      modalDialogClass: [_options?.modalDialogClass, classByType].join(' '),
      injector,
    };
    return this.ngbModal.open(content, options);
  }
}

export type ModalType = "default" | "slide-left" | "slide-right" | "slide-bottom";
