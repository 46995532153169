<div id="container" class="h-100 p-4">
  <div id="content" class="position-relative h-100" [formGroup]="form">
    <h1 class="fw-bold mt-3" color="dark">
      Para produzir
      <span [color]="composicao?.tipoProduto | colorTipoProduto">
        <span> {{ unidadesMedidaRendimento?.at(0)?.quantidadeRendimento || unidadesMedidaEquivalente?.at(0)?.quantidadeEquivalente }} </span>
        <span> {{ (unidadesMedidaRendimento || unidadesMedidaEquivalente)?.at(0)?.nome }}(s) </span>
      </span>
      de
      <span [color]="composicao?.tipoProduto | colorTipoProduto"> {{ composicao?.nome }} </span>
      preciso de:
    </h1>

    <!-- <div class="d-flex mt-3" [ngClass]="{ 'justify-content-center': isDesktop }">
      <small-label color="roxo"> Preciso de ajuda! </small-label>
    </div> -->

    <select-produto [tipo]="tipo" [produtoControl]="form.controls.produto" [disabled]="!tipo" [color]="composicao?.tipoProduto | colorTipoProduto" />

    <div class="quantidade-unidade form-group w-100 d-flex flex-column gap-2 input-with-tag mb-3 mt-5">
      <small-label> Em que quantidade? </small-label>

      <div class="input-group">
        <div class="input-group-text">
          <input type="number" class="form-control lh-2" placeholder="10" formControlName="quantidadeUnidadeMedida" />
        </div>
        <unidade-medida-autocomplete class="flex-grow-1" [control]="form.controls.unidadeMedida" [tipoProduto]="composicao?.tipoProduto" [idProduto]="form.controls.produto?.value?.id" defaultSelect="unique" />
      </div>
    </div>

    <div class="d-flex w-100 position-absolute" id="actions">
      <button type="button" class="btn" color="apoio" (click)="voltar()">Voltar</button>

      <button type="button" class="btn" [color]="composicao?.tipoProduto | colorTipoProduto" (click)="confirm()">{{ data?.produtoComposicao?.id ? "Atualizar" : "Adicionar" }}</button>
    </div>
  </div>
</div>
