import { IsActiveMatchOptions, Params } from "@angular/router";
import { AppColor } from "../../model/custom-types";

export const AppPages: PagesApp = [
  { title: 'Produtos', path: 'produtos', icon: 'produtos', color: 'titulo' },
  { title: 'Compras', path: 'compras', icon: 'compras', color: 'laranja-forte' },
  { title: 'Vendas', path: 'vendas', icon: 'vendas', color: 'secondary' },
  { title: 'Categorias', path: 'categorias', icon: 'categorias', color: 'roxo' },
  { title: 'Unidades de medida', path: 'unidades-medida', icon: 'unidades_medida', color: 'vermelho' },
  { title: 'Pendências', path: 'pendencias', icon: 'pendencias', color: 'warning' },

  { title: 'Minha Conta', path: 'minha-conta', icon: 'minha_conta' },
  { title: 'Meu Negócio', path: 'meu-negocio', icon: 'meu_negocio' },
  { title: 'Ajuda', path: 'ajuda', icon: 'ajuda' },
];

export type PagesApp = Array<PageApp>

export type PageApp = {
  title: string;
  path: AppPath;
  icon: string;
  queryParams?: Params;
  color?: AppColor;
};

export type AppPath = 'produtos' | 'compras' | 'vendas' | 'categorias' | 'unidades-medida' | 'pendencias' | 'minha-conta' | 'meu-negocio' | 'ajuda';

/**
 * ## por queryParams ser dinâmico é necessário fazer match exact apenas com o fragment
 * */
export const routerLinkActiveOptions: IsActiveMatchOptions = {
  fragment: 'exact',
  matrixParams: 'ignored',
  paths: 'subset',
  queryParams: 'ignored',
};
