import { Component, Input } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import { Produto } from '../../../../model/produto.model';
import { VendaService } from '../../../../services/venda.service';
import { VendaHistoricoProduto } from '../../../../model/venda.model';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../../../custom-components/no-results/no-results.component';
import { CurrencyPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { ModalService } from '../../../../services/modal.service';
import { ToastService } from '../../../../services/toast.service';
import { showApiErrorMessages } from '../../../../globals/utils';

@Component({
  selector: 'section-vendas-produto',
  standalone: true,
  imports: [
    SmallLabelComponent,
    OperationInProgressComponent,
    NoResultsComponent,
    NgClass,
    NgTemplateOutlet,
    NgMatIconComponent,
    CurrencyPipe,
    ColorDirective,
    ColorTipoProdutoPipe,
  ],
  templateUrl: './section-vendas-produto.component.html',
  styleUrl: './section-vendas-produto.component.scss'
})
export class SectionVendasProdutoComponent extends BaseClass() {

  @Input() produto: Produto;

  vendasHistorico: Array<VendaHistoricoProduto>;

  constructor(
    private vendaService: VendaService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getVendas();
  }

  getVendas() {
    this.vendaService.getHistoricoVendas({ IdProduto: this.produto.id }).then((res) => {
      this.vendasHistorico = res.vendaProdutoHistorico?.data;
    }).catch((err) => {
      if (!showApiErrorMessages(this.modalService, err)) this.toastService.show({ body: 'Erro ao buscar vendas', color: 'danger' });
    });
  }

}
