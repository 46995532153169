import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../directives/color.directive';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BaseClass } from '../../../globals/base-class';
import { UsuarioService } from '../../../services/usuario.service';
import { Usuario } from '../../../model/usuario.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { NgxMaskDirective } from 'ngx-mask';
import { ToastService } from '../../../services/toast.service';
import { ModalService } from '../../../services/modal.service';
import { closeModal, showApiErrorMessages } from '../../../globals/utils';
import { ApiResponseError } from '../../../model/api.model';

export type DataModalEditarMinhasInfo = {
  usuario: Usuario;
};

type TFormEditarMinhasInfo = {
  nome: FormControl<string>;
  telefone: FormControl<string>;
  redeSocial: FormControl<string>;
}

@Component({
  selector: 'app-editar-informacoes-conta',
  standalone: true,
  imports: [
    NgClass,
    SmallLabelComponent,
    ColorDirective,
    ReactiveFormsModule,
    NgMatIconComponent,
    NgxMaskDirective,
  ],
  templateUrl: './editar-informacoes-conta.component.html',
  styleUrl: './editar-informacoes-conta.component.scss'
})
export class EditarInformacoesContaComponent extends BaseClass() {

  usuario: Usuario;

  form: FormGroup<TFormEditarMinhasInfo> = new FormGroup({
    nome: new FormControl(null),
    telefone: new FormControl(null),
    redeSocial: new FormControl(null),
  });

  constructor(
    @Inject("data") private data: DataModalEditarMinhasInfo,
    private ngbActiveModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    this.usuario = this.data.usuario;

    this.form.patchValue({
      nome: this.usuario.nome,
      telefone: this.usuario.telefone,
      redeSocial: this.usuario.redeSocial,
    });
  }

  async salvar() {
    if (this.form.invalid) return;

    const loading = this.modalService.presentLoading('Salvando informações...');

    const { nome, telefone, redeSocial } = this.form.value;
    try {
      const res = await this.usuarioService.update({
        ...this.usuario,
        nome,
        telefone,
        redeSocial,
      });
      if (!res && !showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) {
        this.toastService.show({ body: 'Erro ao atualizar informações', color: 'danger' });
        return;
      }

      this.toastService.show({ body: 'Informações atualizadas com sucesso', color: 'success' });
      closeModal(this.ngbActiveModal, 'saved', true);
    } catch (error) {
      if (!showApiErrorMessages(this.modalService, error))
        this.toastService.show({ body: 'Erro ao atualizar informações', color: 'danger' });

      closeModal(this.ngbActiveModal);
    } finally {
      loading.dismiss();
    }
  }

  dismiss() {
    closeModal(this.ngbActiveModal, "cancel", false);
  }

}
