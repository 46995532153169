import { Component, Inject } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { closeModal } from '../../globals/utils';
import { ColorDirective } from '../../directives/color.directive';
import { TipoProdutoEnum } from '../../model/produto.model';
import { ColorTipoProdutoPipe } from '../../directives/color-tipo-produto.pipe';
import { NgClass } from '@angular/common';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

export type ModalCloseConfirmCompletarCadastroProduto = "agora" | "depois";

@Component({
  selector: 'confirm-completar-cadastro-produto',
  standalone: true,
  imports: [
    ColorDirective,
    ColorTipoProdutoPipe,
    NgClass,
    SmallLabelComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './confirm-completar-cadastro-produto.component.html',
  styleUrl: './confirm-completar-cadastro-produto.component.scss'
})
export class ConfirmCompletarCadastroProdutoComponent extends BaseClass() {

  tipoProduto: TipoProdutoEnum;
  control: FormControl<ModalCloseConfirmCompletarCadastroProduto> = new FormControl<ModalCloseConfirmCompletarCadastroProduto>("agora");

  constructor(
    @Inject("data") private data: { tipo: TipoProdutoEnum },
    private ngbActiveModal: NgbActiveModal,
  ) {
    super();
    this.tipoProduto = data.tipo;
  }

  confirm() {
    closeModal<ModalCloseConfirmCompletarCadastroProduto>(this.ngbActiveModal, "cancel", this.control.value);
  }

  cancel() {
    closeModal<null>(this.ngbActiveModal, "saved", null);
  }

}
