import { SiglaEstado } from "../services/ibge.service";
import { Usuario } from "./usuario.model";

export type Empresa = {
  id: string;
  logradouro: string;
  bairro: string;
  cidade: string;
  estado: SiglaEstado;
  status: StatusEmpresaEnum;
  nomeResponsavel: string;

  nomeUsuario: string;
  telefone: string;
  redeSocial: string;
  email: string;
  senha: string;
  nome: string;
  cpfCnpj: string;
  tipo: TipoEmpresaEnum;
  // idadeNegocio: string;
  idRamoNegocio: string;
  ramoNegocio: string;
  idNegocioEmpresa: string;
  idEstado: string;
  idCidade: string;
  dataCriacao: string;
} & NegocioEmpresa;
export type Empresas = Array<Empresa>;

export enum TipoEmpresaEnum {
  _1 = 1,
  _2 = 2,
}

export enum StatusEmpresaEnum {
  ATIVO = 1,
  INATIVO = 2,
}

export type NegocioEmpresa = {
  custoFixo: number;
  despesasVenda: number;
  percentualMargemLucro: number;
  faturamentoMedio: number;
  multiplicadorPreco?: number;
}

export type EmpresaInsert = Pick<Empresa, 'nome' | 'cpfCnpj' | 'tipo' | 'idRamoNegocio' | 'idEstado' | 'idCidade' | 'dataCriacao'> & {
  usuario: Pick<Usuario, 'nome' | 'email' | 'telefone' | 'senha' | 'redeSocial'>,
  negocioEmpresa: NegocioEmpresa;
}

export type EmpresaUpdate = Pick<Empresa, 'id' | 'nome' | 'cpfCnpj' | 'dataCriacao' | 'tipo' | 'status' | 'idRamoNegocio' | 'idEstado' | 'idCidade'>;
