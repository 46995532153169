import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { TFormProduto } from '../novo-produto.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ColorDirective } from '../../../../directives/color.directive';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { BaseClass } from '../../../../globals/base-class';
import { TipoProdutoEnum } from '../../../../model/produto.model';
import { ListMedidasCheckboxesComponent } from '../../../../custom-components/list-medidas-checkboxes/list-medidas-checkboxes.component';
import { UnidadesMedidaInsert } from '../../../../model/unidade-medida.model';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'section-quantidade-medida-produto',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    ColorDirective,
    SmallLabelComponent,
    ColorTipoProdutoPipe,
    NgMatIconComponent,
    ListMedidasCheckboxesComponent,
    NgxMaskDirective,
    NgTemplateOutlet,
  ],
  templateUrl: './section-quantidade-medida-produto.component.html',
  styleUrl: './section-quantidade-medida-produto.component.scss'
})
export class SectionQuantidadeMedidaProdutoComponent extends BaseClass() {

  @Input({ required: true }) produtoForm: FormGroup<TFormProduto>;

  @Input() unidadesMedidaRendimento: UnidadesMedidaInsert<TipoProdutoEnum.composicao>;
  @Input() unidadesMedidaEquivalente: UnidadesMedidaInsert<TipoProdutoEnum.insumo>;

  TipoProdutoEnum = TipoProdutoEnum;
}
