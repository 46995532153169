import { Component } from '@angular/core';
import { AsyncPipe, CurrencyPipe, Location, NgClass, TitleCasePipe } from '@angular/common';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { BaseClass } from '../../../globals/base-class';
import { BehaviorSubject } from 'rxjs';
import { ApiConstraintGetHistoricoVendas, ApiConstraintGetVendas } from '../../../model/api.model';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { ToastService } from '../../../services/toast.service';
import { PaginatorComponent, PaginatorOpts } from '../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../services/storage.service';
import { defaultDebounceInput, DefaultPageSize, defaultViewChartHistoricoVendasCompras, periodosChartHistoricoVendaCompra } from '../../../globals/globals';
import { ActivatedRoute } from '@angular/router';
import { defaultClassModal, getPeriodosChartHistoricoVendasCompras, showApiErrorMessages, updateUrl } from '../../../globals/utils';
import { ProdutoAutocompleteComponent } from '../../../custom-components/autocomplete/produto-autocomplete/produto-autocomplete.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Produto } from '../../../model/produto.model';
import { DropdownComponent } from '../../../custom-components/dropdown/dropdown.component';
import { OperationInProgressComponent } from '../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../../custom-components/no-results/no-results.component';
import { VendaHistoricoProduto } from '../../../model/venda.model';
import { ChartHistorioVendasComprasComponent, PeriodoChartHistoricoVendaCompra, PeriodoHistoricoVendaCompra, TipoVisualizacaoChartHistoricoVendasComprasEnum } from '../../../custom-components/chart-historio-vendas-compras/chart-historio-vendas-compras.component';
import { ChartSeriesData, ModalClose } from '../../../model/custom-types';
import { VendaService } from '../../../services/venda.service';
import { ModalService } from '../../../services/modal.service';
import { HistoricoVendasProdutoComponent } from './historico-vendas-produto/historico-vendas-produto.component';

@Component({
  selector: 'app-historico-vendas',
  standalone: true,
  imports: [
    ChartHistorioVendasComprasComponent,
    NgClass,
    SmallLabelComponent,
    CurrencyPipe,
    NgMatIconComponent,
    ColorDirective,
    AsyncPipe,
    PaginatorComponent,
    ProdutoAutocompleteComponent,
    FormsModule,
    ReactiveFormsModule,
    DropdownComponent,
    OperationInProgressComponent,
    NoResultsComponent,
  ],
  providers: [TitleCasePipe],
  templateUrl: './historico-vendas.component.html',
  styleUrl: './historico-vendas.component.scss'
})
export class HistoricoVendasComponent extends BaseClass() {

  operationChartInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  operationHistoricoInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  vendas: Array<VendaHistoricoProduto>;

  dadosGrafico: Array<ChartSeriesData>;

  tipoVisualizacao: TipoVisualizacaoChartHistoricoVendasComprasEnum = defaultViewChartHistoricoVendasCompras;
  TipoVisualizacaoEnum = TipoVisualizacaoChartHistoricoVendasComprasEnum;

  periodosChart: Array<PeriodoChartHistoricoVendaCompra> = periodosChartHistoricoVendaCompra;
  periodoSelecionado: PeriodoChartHistoricoVendaCompra;
  dataInicio: Date;
  dataFim: Date;

  periodosHistorico: Array<PeriodoHistoricoVendaCompra> = getPeriodosChartHistoricoVendasCompras(this.titleCasePipe);

  periodoHistoricoControl: FormControl<PeriodoHistoricoVendaCompra> = new FormControl<PeriodoHistoricoVendaCompra>(null);

  paginatorOpts: PaginatorOpts;

  produtoControl: FormControl<Produto> = new FormControl<Produto>(null);

  debounce = defaultDebounceInput;

  constructor(
    private storageService: StorageService,
    private toastService: ToastService,
    private vendaService: VendaService,
    private route: ActivatedRoute,
    private location: Location,
    private titleCasePipe: TitleCasePipe,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    const queryParams = this.route.snapshot?.queryParams;

    let periodo: PeriodoChartHistoricoVendaCompra;

    const mes = queryParams?.['mes'];
    if (mes) this.periodoHistoricoControl.setValue(this.periodosHistorico.find(p => p.mes === +mes));

    if (!mes || !this.periodoHistoricoControl.value)
      periodo = queryParams?.['diasAntes'] ?
        this.periodosChart.find(p => p.diasAntes === +queryParams?.['diasAntes']) :
        (queryParams?.['mesesAntes'] ? this.periodosChart.find(p => p.mesesAntes === +queryParams?.['mesesAntes']) : this.periodosChart[0]);

    this.tipoVisualizacao = queryParams?.['tipoVisualizacao'] ? +queryParams?.['tipoVisualizacao'] : defaultViewChartHistoricoVendasCompras;

    const page = queryParams?.['page'] ? +queryParams?.['page'] : 1;
    this.paginatorOpts = { ...this.paginatorOpts, page };

    this.periodoSelecionado = periodo;
    this.setDates();

    if (periodo) return this.selecionarPeriodo(periodo);
    this.getHistoricoVendas();
    this.getVendasGrafico();
  }

  selecionarPeriodo(periodo: PeriodoChartHistoricoVendaCompra) {
    this.operationChartInProgress$.next(true);
    this.periodoSelecionado = periodo;
    this.periodoHistoricoControl.setValue(null);
    this.setDates();
    this.getHistoricoVendas();
    this.getVendasGrafico();
  }

  selecionarTipoVisualizacao(tipoVisualizacao: TipoVisualizacaoChartHistoricoVendasComprasEnum) {
    this.operationChartInProgress$.next(true);
    this.tipoVisualizacao = tipoVisualizacao;
    this.getVendasGrafico();
  }

  produtoChanged() {
    this.operationHistoricoInProgress$.next(true);
    this.getHistoricoVendas();
    this.getVendasGrafico();
  }

  private setDates() {

    if (this.periodoHistoricoControl.value) {
      const { mes, ano } = this.periodoHistoricoControl.value;
      this.dataFim = new Date(ano, mes, 0);
      this.dataInicio = new Date(ano, mes - 1, 0);
      return;
    }

    const hoje = new Date();
    this.dataFim = new Date(hoje);
    this.dataInicio = new Date(hoje.getFullYear(), hoje.getMonth() - (this.periodoSelecionado.mesesAntes || 0), hoje.getDate() - (this.periodoSelecionado.diasAntes || 0));
  }

  private getConstrains(): ApiConstraintGetVendas {
    return {
      DataInicio: this.dataInicio.toISOString(),
      DataFim: this.dataFim.toISOString(),
      IdProduto: this.produtoControl.value?.id,
    }
  }

  async getHistoricoVendas() {
    try {
      this.operationHistoricoInProgress$.next(true);

      const constraints = this.getConstrains();

      const constraintsPagination: ApiConstraintGetHistoricoVendas = {
        Page: this.paginatorOpts?.page,
        PageSize: this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_HISTORICO_VENDAS') || DefaultPageSize.vendas,
      };

      const res = await this.vendaService.getHistoricoVendas({ ...constraints, ...constraintsPagination });
      const { page, pages, pageSize, rowsCount, data } = res.vendaProdutoHistorico;
      this.vendas = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
      this._updateUrl();
    } catch (error) {
      console.error(error);
      this.toastService.show({ body: 'Erro ao buscar categorias', color: 'danger' });
    } finally {
      this.operationHistoricoInProgress$.next(false);
    }
  }

  getVendasGrafico() {
    this.operationChartInProgress$.next(true);
    this._updateUrl();

    const constraints = this.getConstrains();
    this.vendaService.getDashboardVendas({ ...constraints, TipoVisualizacao: this.tipoVisualizacao }).then((dadosGrafico) => {
      this.dadosGrafico = dadosGrafico;
    }).catch((err) => {
      console.error(err);
      if (!showApiErrorMessages(this.modalService, err)) this.toastService.show({ body: 'Erro ao buscar dados para o gráfico', color: 'danger' });
    }).finally(() => {
      this.operationChartInProgress$.next(false);
    });
  }

  async editarVenda(vendaHistorico: VendaHistoricoProduto) {
    const modal = this.modalService.presentModal(HistoricoVendasProdutoComponent, defaultClassModal(this), { vendaHistorico });

    const result: ModalClose<boolean> = await modal.result;

    if (result.data) {
      this.operationHistoricoInProgress$.next(true);
      this.getHistoricoVendas();
      this.getVendasGrafico();
    }
  }

  private _updateUrl() {
    updateUrl(this.location, {
      tipoVisualizacao: this.tipoVisualizacao,
      diasAntes: this.periodoSelecionado?.diasAntes,
      mesesAntes: this.periodoSelecionado?.mesesAntes,
      mes: this.periodoHistoricoControl?.value?.mes,
      page: this.paginatorOpts.page,
    });
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_HISTORICO_VENDAS', size);
    this.getHistoricoVendas();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getHistoricoVendas();
  }

  periodoHistoricoVendasChanged(periodo: PeriodoHistoricoVendaCompra) {
    this.periodoSelecionado = null;
    this.setDates();
    this.getHistoricoVendas();
    this.getVendasGrafico();
  }
}

