import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countItemsWithField',
  pure: false,
  standalone: true
})
export class CountItemsWithFieldPipe implements PipeTransform {

  transform(value: Array<any>, ...fields: Array<typeof value[number]>): number {
    if (!value) return null;
    return value.filter(item => fields.some(field => item[field]))?.length || 0;
  }

}
