<div id="container" class="h-100 overflow-y-hidden">
  <div id="content" class="m-4">
    <div id="header">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">{{ categoria?.id ? "Editar" : "Nova" }} Categoria</h3>
        <button class="btn" (click)="dismiss()">
          <ng-mat-icon name="close" color="dark" />
        </button>
      </div>
    </div>

    <div id="body" class="mt-4">
      <div id="form" [ngClass]="{ 'gap-5': !isDesktop }">
        <div id="color-icon" class="d-flex flex-column gap-2 align-items-center cursor-pointer" (click)="form.disabled ? null : selectIconColor()">
          <small-label> Cor e ícone </small-label>
          <icon-in-ellipse [matIcon]="form.controls.icone.value ? form.controls.icone.value : iconPlaceholder" [matIconOutlined]="false" [bgColor]="form.controls.cor.value ? 'rgb(' + form.controls.cor.value + ')' : null" />
        </div>

        <div id="nome" class="form-group d-flex flex-column gap-2 flex-grow-1" [formGroup]="form">
          <small-label> Nome da categoria </small-label>
          <input type="text" class="form-control lh-2" id="nome" placeholder="Insira um nome para a categoria" formControlName="nome" />
        </div>

        <div id="status" class="form-group d-flex flex-column gap-2">
          <small-label> Status </small-label>

          <div class="btn-group" role="group">
            <button [disabled]="form.disabled" type="button" class="btn" [class.btn-outline]="form.controls.status?.value === CategoriaStatus.INATIVO" (click)="setStatus(CategoriaStatus.ATIVO)" color="roxo">Ativo</button>
            <button [disabled]="form.disabled" type="button" class="btn" [class.btn-outline]="form.controls.status?.value === CategoriaStatus.ATIVO" (click)="setStatus(CategoriaStatus.INATIVO)" color="roxo">Inativo</button>
          </div>
        </div>
      </div>

      @if (produtos?.length) {
        <div id="produtos" class="d-flex flex-column gap-2 mt-4">
          <div id="list-produtos-header" class="p-3 rounded-top-4">
            <h6 class="fw-bold text-center m-0">Produtos na categoria</h6>
          </div>
          <div class="pb-2 d-flex flex-column gap-2">
            @for (produto of produtos; track produto) {
              <div class="produto rounded-3 px-3 mx-1 d-flex justify-content-between align-items-center">
                <small-label class="fw-bold" color="dark"> {{ produto.nome }} </small-label>

                <div class="d-flex gap-2">
                  <button type="button" class="btn clear p-0" (click)="trocarCategoria(produto)">
                    <ng-mat-icon name="shuffle" color="roxo" />
                  </button>

                  <button type="button" class="btn clear p-0" (click)="removeProduto(produto)">
                    <ng-mat-icon name="delete" color="roxo" />
                  </button>
                </div>
              </div>
            }

            @if (paginatorOpts) {
              <paginator
                [isDisabled]="operationInProgress$ | async"
                color="roxo"
                [showLabels]="false"
                [page]="paginatorOpts?.page"
                [pages]="paginatorOpts.pages"
                [pageSize]="paginatorOpts.pageSize"
                [rowsCount]="paginatorOpts.rowsCount"
                [isDisabled]="operationInProgress$.value || !produtos?.length"
                (updatePageSize)="updatePageSize($event)"
                (navigatePage)="navigatePage($event)"
              />
            }
          </div>
        </div>
      }
    </div>

    <div id="buttons" class="mt-4 mx-2 position-absolute d-flex gap-2">
      @if (categoria?.id) {
        <button type="button" class="px-4 btn flex-grow-1 btn-outline" color="danger" (click)="delete()">Excluir</button>
      }

      <button type="button" class="px-4 btn flex-grow-1" color="apoio" (click)="dismiss()">Cancelar</button>

      <button type="button" class="px-4 btn flex-grow-1" color="roxo" (click)="salvar()">
        {{ categoria?.id ? "Salvar" : "Criar" }}
        @if (isDesktop) {
          categoria
        }
      </button>
    </div>
  </div>
</div>
