import { Component } from '@angular/core';
import { ColorDirective } from '../../directives/color.directive';
import { BaseClass } from '../../globals/base-class';
import { ModalService } from '../../services/modal.service';
import { DebounceDirective } from '../../directives/debounce.directive';
import { BehaviorSubject } from 'rxjs';
import { ClassificacaoProdutoEnum, Produto, Produtos, TipoProdutoEnum } from '../../model/produto.model';
import { AsyncPipe, CommonModule, Location as NgLocation, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SmallLabelComponent } from '../../custom-components/small-label/small-label.component';
import { DelayShowTooltip } from '../../globals/globals';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';
import { defaultClassModal, showApiErrorMessages, updateUrl } from '../../globals/utils';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { Segment as _Segment, SegmentsComponent } from '../../custom-components/segments/segments.component';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { ButtonGroup, ButtonGroupComponent, OptionButtonGroup } from '../../custom-components/button-group/button-group.component';
import { ModalClose, ModalCloseReason, TOpcaoProdutos } from '../../model/custom-types';
import { ProdutoClickArea, ProdutoListComponent } from './list/list.component';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../custom-components/no-results/no-results.component';
import { OptionsProdutosComponent } from './options/options.component';
import { DetalhesProdutoComponent } from './detalhes-produto/detalhes-produto.component';
import { DataModalNovoProduto, NovoProdutoComponent, SectionNovoProduto } from './novo-produto/novo-produto.component';
import { ProdutoService } from '../../services/produto.service';
import { ApiConstraintGetProdutos, ApiResponseGetProdutos } from '../../model/api.model';
import { PaginatorComponent, PaginatorOpts } from '../../custom-components/paginator/paginator.component';
import { StorageService } from '../../services/storage.service';
import { ColorTipoProdutoPipe } from '../../directives/color-tipo-produto.pipe';

type Segment = _Segment & { tipoProduto: TipoProdutoEnum };

export type TQueryParams = {
  id?: string;
  action?: string;
  section?: SectionNovoProduto;
  evendido?: boolean;
  tipo?: TipoProdutoEnum;
  produtoID?: string;
}

@Component({
  selector: 'app-produtos',
  standalone: true,
  imports: [
    ColorDirective,
    DebounceDirective,
    AsyncPipe,
    FormsModule,
    SmallLabelComponent,
    NgbTooltipModule,
    NgMatIconComponent,
    SegmentsComponent,
    RouterModule,
    ButtonGroupComponent,
    ProdutoListComponent,
    OperationInProgressComponent,
    NoResultsComponent,
    NgClass,
    RouterOutlet,
    CommonModule,
    PaginatorComponent,
    ColorTipoProdutoPipe,
  ],
  templateUrl: './produtos.component.html',
  styleUrl: './produtos.component.scss'
})
export class ProdutosComponent extends BaseClass() {

  segments: Array<Segment> = [
    { path: ['/home', 'produtos', TipoProdutoEnum[TipoProdutoEnum.composicao]], title: 'Composições', tipoProduto: TipoProdutoEnum.composicao },
    { path: ['/home', 'produtos', TipoProdutoEnum[TipoProdutoEnum.insumo]], title: 'Insumos', tipoProduto: TipoProdutoEnum.insumo },
  ];
  activeSegment: Segment;

  eVendido: ButtonGroup<boolean> = [
    { label: 'Vendidos', value: true },
    { label: 'Não vendidos', value: false },
  ];

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  produtos: Array<ApiResponseGetProdutos>;
  paginatorOpts: PaginatorOpts;
  searchTerm: string;
  DelayShowTooltip = DelayShowTooltip;

  protected queryParams: TQueryParams = {};

  ClassificacaoProduto = ClassificacaoProdutoEnum;

  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private _location: NgLocation,
    private produtoService: ProdutoService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    const segment = this.router.url.split('/')?.[3]?.split('?')?.[0];
    this.activeSegment = this.segments.find(x => x.path[2] === segment) || this.segments[0];

    const queryParams = this.route.snapshot.queryParams;
    // const { action, id } = queryParams;
    // this.queryParams.evendido = evendido === 'false' ? false : true;
    this.queryParams = Object.fromEntries(Object.entries({
      action: queryParams['action'],
      evendido: queryParams['evendido'] === 'false' ? false : true,
      id: queryParams['id'],
      section: queryParams['section'],
      tipo: queryParams['tipo'],
    }).filter(([_, v]) => !!v || v === false));

    this.updateURLProduto();

    if (this.queryParams.id) this.detalhesProduto(this.queryParams.id, 'card');
    if (this.queryParams.action && this.queryParams.action === 'novo') this.novoProduto(queryParams?.['produtoID']);

    this.getProdutos();
  }

  private getContrainsts(): ApiConstraintGetProdutos {
    const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_PRODUTOS')
    const constraints: ApiConstraintGetProdutos = {
      Termo: this.searchTerm,
      TipoProduto: TipoProdutoEnum[this.activeSegment?.path?.[2] as unknown as TipoProdutoEnum] as unknown as TipoProdutoEnum,
      Classificacao: this.queryParams.evendido ? ClassificacaoProdutoEnum.vendido : ClassificacaoProdutoEnum.naoVendido,
      PageSize,
      Page: this.paginatorOpts?.page || 1
    };

    return constraints;
  }

  private getProdutos() {
    this.operationInProgress$.next(true);

    const constraints = this.getContrainsts();

    this.produtoService.getProdutos(constraints).then((res) => {
      const { page, pages, pageSize, rowsCount, data: produtos } = res;
      this.produtos = produtos;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    }).catch((err) => {
      console.error(err);
      if (!showApiErrorMessages(this.modalService, err)) this.toastService.show({ body: 'Erro ao buscar produtos', color: 'danger' });
    }).finally(() => {
      this.operationInProgress$.next(false);
    });
  }

  private updateURLProduto(params: TQueryParams = {}, useRouter: boolean = false) {
    if (useRouter) return this.router.navigate(this.activeSegment.path, { queryParams: { ...this.queryParams, ...params } });
    updateUrl(this._location, { ...params, evendido: this.queryParams.evendido });
  }

  inputOnSearch() {
    this.getProdutos();
  }

  selecionarFiltros() {
    this.notImplemented();
  }

  activeSegmentChanged(segment: _Segment) {
    this.activeSegment = this.segments.find(s => s.title === segment.title);
    this.router.navigate(segment.path, { queryParams: this.queryParams });
  }

  eVendidoChanged(evendidoChecked: OptionButtonGroup<boolean>) {
    this.queryParams.evendido = evendidoChecked.value;
    this.updateURLProduto();
    this.getProdutos();
  }

  novoProduto(produto?: string | Produto) {
    const section: SectionNovoProduto = this.queryParams.section || 'detalhes';
    const evendido: boolean = this.queryParams.evendido;

    const queryParams: TQueryParams = {
      action: 'novo',
      section,
    }
    const data: DataModalNovoProduto = {
      ...this.getContrainsts(),
      section,
      evendido,
    };

    if (produto) {
      if (typeof produto === 'string') queryParams.produtoID = produto;
      else data.produto = produto;
    }

    this.updateURLProduto(queryParams, typeof produto !== 'string');
    const modal = this.modalService.presentModal(NovoProdutoComponent, "slide-bottom", data, { keyboard: false, windowClass: 'modal-full-content', backdrop: false })
    modal.closed.subscribe({
      next: (res: ModalClose<Produto, 'produto'>) => {
        if ((['saved'] as Array<ModalCloseReason>).includes(res.reason)) {
          if (res.produto?.tipoProduto !== this.activeSegment.path[2] as unknown as TipoProdutoEnum)
            this.activeSegment = this.segments.find(s => (s.path[2] as unknown as TipoProdutoEnum) === TipoProdutoEnum[res.produto.tipoProduto as unknown as TipoProdutoEnum] as unknown as TipoProdutoEnum);

          if ((res.produto?.classificacao === ClassificacaoProdutoEnum.vendido) !== this.queryParams.evendido)
            this.queryParams.evendido = res.produto.classificacao === ClassificacaoProdutoEnum.vendido;
          this.getProdutos();
        }
        this.queryParams.action = undefined;
        this.queryParams.section = undefined;
        this.updateURLProduto()
      }
    });
  }

  detalhesProduto(produto: Produto | ApiResponseGetProdutos | string, area: ProdutoClickArea) {
    if (typeof produto !== 'string') {
      if (!produto.tagCadastroCompleto) this.novoProduto(produto as Produto);
      return
    }

    this.updateURLProduto({ id: produto })
    const params = { id: produto };
    const modal = this.modalService.presentModal(DetalhesProdutoComponent, "slide-bottom", params, { windowClass: 'modal-full-content', backdrop: false })
    modal.closed.subscribe({
      next: (res: ModalClose<Produto, 'produto'>) => {
        if ((['deleted', 'saved'] as Array<ModalCloseReason>).includes(res.reason)) this.getProdutos();
        delete this.queryParams.id;
        delete this.queryParams.action;
        delete this.queryParams.section;
        this.updateURLProduto({ evendido: res.produto?.classificacao === ClassificacaoProdutoEnum.vendido });
      }
    });
  }

  options() {
    const windowClass = [
      'w-400px',
      !this.isDesktop ? 'h-90 rounded-top-4' : null
    ].filter(x => x).join(' ');
    const modal = this.modalService.presentModal(OptionsProdutosComponent, defaultClassModal(this), {}, { windowClass });
    modal.closed.subscribe({
      next: (res: ModalClose<TOpcaoProdutos>) => {
        if (res.reason !== 'saved' || !res.data) return;
        switch (res.data) {
          case 'importar-nfe': this.importarNFE(); break;
          case 'importar-excel': this.importarExcel(); break;
          case 'exportar-excel': this.exportarExcel(); break;
          case 'exportar-pdf': this.exportarPDF(); break;
          case 'imprimir': this.imprimir(); break;
          default: break;
        }
      }
    });
  }

  private importarNFE() {
    this.notImplemented();
  }

  private importarExcel() {
    this.notImplemented();
  }

  private exportarExcel() {
    this.notImplemented();
  }

  private exportarPDF() {
    this.notImplemented();
  }

  private imprimir() {
    this.notImplemented();
  }

  private notImplemented() {
    this.toastService.show({ body: 'Em breve!', color: 'secondary' });
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PRODUTOS', size);
    this.getProdutos();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getProdutos();
  }

}
