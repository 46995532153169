<div id="container">
  <div class="d-flex flex-column mb-5" id="header">
    <h3 class="fw-bold" color="dark">NF-e salva para lançamento</h3>

    <small-label> Você tem algumas NF-e salvas para lançar depois, continue o lançamento delas. </small-label>
  </div>

  <div id="body" class="mt-3 mb-5">
    @if (operationInProgress$ | async) {
      <operation-in-progress />
    } @else {
      @if (produtos?.length > 0) {
        <div id="list-produtos">
          <div id="header-list-produto" class="rounded-top-4 d-flex gap-2 justify-content-between p-3">
            <small-label class="fw-bold">Produto</small-label>
            <small-label class="fw-bold">Ações</small-label>
          </div>

          @for (produto of produtos; track $index) {
            <div class="card-produto px-4 py-3 rounded-4 my-2">
              <div class="card-content d-flex flex-column gap-2">
                <div class="d-flex gap-2 justify-content-between">
                  <div class="d-flex gap-2 align-items-center">
                    <h6 class="m-0 fw-bold">{{ produto.nome }}</h6>
                  </div>

                  <ng-mat-icon name="edit" color="amarelo" (click)="editarProduto.emit(produto)" />
                </div>
              </div>
            </div>
          }
        </div>
      } @else {
        <no-results [classes]="['text-center']">
          <h2 color="black" class="fw-bold">Nenhuma pendência encontrada</h2>
        </no-results>
      }
    }
  </div>

  @if (paginatorOpts) {
    <paginator
      [isDisabled]="operationInProgress$.value"
      color="amarelo"
      [showLabels]="false"
      [page]="paginatorOpts?.page"
      [pages]="paginatorOpts.pages"
      [pageSize]="paginatorOpts.pageSize"
      [rowsCount]="paginatorOpts.rowsCount"
      [isDisabled]="operationInProgress$.value || !produtos?.length"
      (updatePageSize)="updatePageSize($event)"
      (navigatePage)="navigatePage($event)"
    />
  }
</div>
