import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { inject } from '@angular/core';
import { Session, Usuario } from '../model/usuario.model';
import { session$, sessionTimeout } from '../globals/globals';
import { UsuarioService } from '../services/usuario.service';
import { ToastService } from '../services/toast.service';
import { ModalService } from '../services/modal.service';
import { showApiErrorMessages } from '../globals/utils';

export const authGuard: CanActivateFn = async (route, state) => {

  try {
    const storageService = inject(StorageService);
    const session: Session = storageService.getSession();
    const usuarioService = inject(UsuarioService);
    const router = inject(Router);
    const toastService = inject(ToastService);
    const modalService = inject(ModalService);

    const sessionExpired: boolean = !session?.keepConnected && ((session?.timestamp + sessionTimeout) < Date.now());

    if (!session || sessionExpired) {
      redirectLogin(router, storageService, state.url);
      return false;
    }

    const user: Usuario = await checkSessionAPI(router, usuarioService, session, storageService, toastService, modalService, state);
    session$.next({ ...session, usuario: { ...session.usuario, quantidadeCreditos: user?.quantidadeCreditos } });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const checkSessionAPI = async (
  router: Router,
  usuarioService: UsuarioService,
  session: Session,
  storageService: StorageService,
  toastService: ToastService,
  modalService: ModalService,
  state: RouterStateSnapshot,
) => {
  try {
    const user = await usuarioService.getUserById(session.usuario?.id);

    if (!user || user.error || user.errors) {
      redirectLogin(router, storageService, state.url);
      toastService.show({ body: 'Sessão expirada', color: 'danger' });
    }

    return user;
  } catch (error) {
    // if (!showApiErrorMessages(modalService, error)) toastService.show({ body: 'Erro ao verificar sessão', color: 'danger' });
    return null;
  }
}

const redirectLogin = (router: Router, storageService: StorageService, redirectTo: string = null) => {
  storageService.removeSession();
  router.navigate(['/login'], { replaceUrl: true, queryParams: { redirectTo } });
}
