<div id="container" class="h-100" [formGroup]="produtoForm">
  <div id="content" class="mx-auto d-flex flex-column align-items-center justify-content-center h-100" [ngClass]="{ 'px-5': isDesktop }">
    <h1 class="fw-bold" color="dark">
      Por quanto você vende hoje 1
      <span [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ (unidadesMedidaEquivalente || unidadesMedidaRendimento)?.[0]?.nome }} </span>
      de
      <span [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ produtoForm.controls.nome.value || "" }}</span
      >?
      <!-- <div class="d-flex mt-3" [ngClass]="{ 'justify-content-center': isDesktop }">
        <small-label color="roxo"> Não sei o que responder, preciso de ajuda! </small-label>
      </div> -->
    </h1>

    <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag mt-5">
      <small-label> Qual o valor de venda? </small-label>

      <div class="input-group">
        <div class="input-group-text" [color]="produtoForm.controls.tipo.value | colorTipoProduto">
          <span class="fw-bold"> R$ </span>
        </div>
        <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="valorVenda" />
      </div>
    </div>

    <calculo-preco [produtoForm]="produtoForm" [idProduto]="produtoID" />
  </div>
</div>
