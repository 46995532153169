<div id="container" class="h-100" [formGroup]="produtoForm">
  <div id="content" class="mx-auto d-flex flex-column align-items-start justify-content-center h-100" [ngClass]="{ 'px-5': isDesktop }">
    <small-label [color]="produtoForm.controls.tipo.value | colorTipoProduto"> {{ produtoForm.controls.nome.value }} </small-label>

    <h1 class="fw-bold mt-5" color="dark" [ngClass]="{ 'text-center': isDesktop }">
      <span> Qual o valor de venda? </span>
      <!-- <div class="d-flex mt-3" [ngClass]="{ 'justify-content-center': isDesktop }">
        <small-label color="roxo"> Não sei o que responder, preciso de ajuda! </small-label>
      </div> -->
    </h1>

    <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag mt-5">
      <small-label> Qual o valor de venda? </small-label>

      <div class="input-group">
        <div class="input-group-text" [color]="produtoForm.controls.tipo.value | colorTipoProduto">
          <span class="fw-bold"> R$ </span>
        </div>
        <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="valorVenda" />
      </div>
    </div>

    <!-- <div class="w-100 badge mt-5 d-flex gap-2 align-items-center text-wrap py-2" color="azul">
      <ng-mat-icon name="info" color="white" />

      <span class="text-start"> Para saber o preço ideal de venda, realize o cadastro completo </span>
    </div> -->
    <calculo-preco [produtoForm]="produtoForm" [idProduto]="produtoID" />
  </div>
</div>
