<div id="container" class="py-1">
  <div id="content">
    <div id="list" ngbAccordion [closeOthers]="true">
      <div id="header" class="d-flex list-row align-items-center rounded-top-4 px-3">
        <div class="cell text-center fw-bold py-2 flex-grow-1 detalhes">Detalhes da compra</div>
        <div class="cell text-center fw-bold py-2 acoes">Ações</div>
      </div>

      <div id="list-content" class="py-2 d-flex flex-column gap-2">
        @for (compra of compras; track $index) {
          <div #accordionRef="ngbAccordionItem" ngbAccordionItem class="list-row compra d-flex gap-2 rounded-3 ps-3 flex-column position-relative" [class.collapsed]="accordionRef.collapsed">
            <div class="detalhes flex-grow-1 align-items-center" (click)="accordionRef.toggle()" [ngClass]="{ 'd-grid grid-auto-columns': isDesktop, 'd-flex justify-content-between': !isDesktop }">
              <span class="cell fw-bold py-2 produto"> {{ compra.nomeProduto }} </span>
              @if (accordionRef.collapsed) {
                <span class="cell fw-bold py-2 data-ultima-compra"> {{ (compra.dataUltimaCompra | date: "dd/MM/yyyy") || "-" }} </span>
                <span class="cell fw-bold py-2 valor-compra"> {{ (compra.valorComprado | currency) || "-" }} </span>
              }
            </div>

            <div class="acoes d-flex justify-content-center position-absolute right-0 top-0">
              <button class="h-100 btn px-1" (click)="editarCompra.emit(compra)" [disabled]="operationInProgress$ | async">
                <ng-mat-icon name="edit" color="laranja-forte" />
              </button>

              <button class="h-100 btn px-1" (click)="accordionRef.toggle()">
                <ng-mat-icon name="expand_more" color="laranja-forte" />
              </button>
            </div>

            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="p-1">
                <ng-template>
                  <div class="d-flex flex-column gap-3 w-100">
                    <div class="d-flex flex-column gap-2">
                      <small-label class="fw-200" color="cinza"> Marca </small-label>
                      <span class="fw-bolder"> {{ compra.marca || "-" }} </span>
                    </div>

                    <div class="d-flex justify-content-start">
                      <div class="d-flex flex-column gap-2 flex-grow-1">
                        <small-label class="fw-200" color="cinza"> Data </small-label>
                        <span class="fw-bolder"> {{ (compra.dataUltimaCompra | date: "dd/MM/yyyy") || "-" }} </span>
                      </div>

                      <div class="d-flex flex-column gap-2 flex-grow-1">
                        <small-label class="fw-200" color="cinza"> R$ por unidade </small-label>
                        <span class="fw-bolder"> {{ (compra.valorComprado | currency) || "-" }} </span>
                      </div>
                    </div>

                    <div class="d-flex flex-column gap-2">
                      <small-label class="fw-200" color="cinza"> UN compra </small-label>
                      <span class="fw-bolder"> {{ compra.unidadeCompra || "-" }} </span>
                    </div>

                    <div class="d-flex flex-column gap-2">
                      <small-label class="fw-200" color="cinza"> Onde comprei </small-label>
                      <span class="fw-bolder"> {{ compra.localCompra || "-" }} </span>
                    </div>

                    <div class="d-flex flex-column gap-2">
                      <small-label class="fw-200" color="cinza"> Usado para cálculos </small-label>
                      <span class="fw-bolder"> {{ compra.tagCalculoCusto ? "Sim" : "Não" }} </span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
