import { Component, Inject } from '@angular/core';
import { Categoria, StatusCategoria } from '../../../model/categoria.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { BaseClass } from '../../../globals/base-class';
import { CategoriaService } from '../../../services/categoria.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
import { ModalService } from '../../../services/modal.service';
import { IconInEllipseComponent } from '../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { closeModal, colorHexToRGB, colorRGBToHex, defaultClassModal, showApiErrorMessages } from '../../../globals/utils';
import { ColorDirective } from '../../../directives/color.directive';
import { ModalClose, RGBColor } from '../../../model/custom-types';
import { RGBColors } from '../../../globals/globals';
import { MaterialIcon } from 'material-icons';
import { ModalClosedDataSelectIconColorCategoria, SelectIconColorCategoriaComponent } from '../../../custom-components/select-icon-color-categoria/select-icon-color-categoria.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { ProdutoService } from '../../../services/produto.service';
import { Produto, Produtos } from '../../../model/produto.model';
import { AlterarCategoriaProdutoComponent } from '../../../custom-components/alterar-categoria-produto/alterar-categoria-produto.component';
import { PaginatorComponent, PaginatorOpts } from '../../../custom-components/paginator/paginator.component';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../../../services/storage.service';
import { ApiConstraintGetProdutos, ApiResponseError, ApiResponseGetProdutos } from '../../../model/api.model';

type TForm = { [key in keyof Pick<Categoria, 'nome' | 'icone' | 'cor' | 'status'>]: FormControl };

@Component({
  selector: 'app-nova-categoria',
  standalone: true,
  imports: [
    NgMatIconComponent,
    ReactiveFormsModule,
    SmallLabelComponent,
    IconInEllipseComponent,
    ColorDirective,
    NgClass,
    PaginatorComponent,
    AsyncPipe,
  ],
  templateUrl: './nova-categoria.component.html',
  styleUrl: './nova-categoria.component.scss'
})
export class NovaCategoriaComponent extends BaseClass() {

  categoria: Categoria;

  defaultColor: RGBColor = RGBColors[16];
  defaultIcon: MaterialIcon = 'category';

  form: FormGroup<TForm> = new FormGroup({
    nome: new FormControl(null),
    status: new FormControl(StatusCategoria.ATIVO),
    icone: new FormControl(this.defaultIcon),
    cor: new FormControl(this.defaultColor),
  });

  iconPlaceholder: string = 'receipt';

  CategoriaStatus = StatusCategoria;

  produtos: Array<ApiResponseGetProdutos | Produto>;
  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  paginatorOpts: PaginatorOpts;

  constructor(
    @Inject('data') private data: { categoria: Categoria },
    private categoriaService: CategoriaService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private modalService: ModalService,
    private produtoService: ProdutoService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.data?.categoria) this.categoria = this.data.categoria;
    if (this.categoria?.id) this.getProdutos();

    this.setForm();
  }

  async getProdutos() {
    try {
      this.operationInProgress$.next(true);

      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_PRODUTOS')
      const constraints: ApiConstraintGetProdutos = {
        Page: this.paginatorOpts?.page,
        PageSize,
        IdCategoria: this.categoria.id,
      };
      const res = await this.produtoService.getProdutos(constraints);
      if (res.errors) return this.toastService.show({ body: 'Erro ao buscar produtos', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data } = res;
      this.produtos = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar produtos', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  private setForm() {
    if (this.categoria) {
      const { cor, ...rest } = this.categoria;
      this.form.patchValue({ ...rest, cor: cor ? colorHexToRGB(cor) : this.defaultColor });
      this.setStatus(this.categoria.status);
    } else this.form.reset({
      icone: this.defaultIcon,
      cor: this.defaultColor,
      status: StatusCategoria.ATIVO,
    });
  }

  salvar() {
    const { cor, ...formValue } = this.form.value;

    const toSave: Partial<Categoria> = {
      ...this.categoria,
      ...formValue,
      cor: cor ? colorRGBToHex(cor) : null,
    };
    delete toSave.quantidadeProdutosRelacionados;

    if (!toSave.nome) return this.presentErrorToast('Você precisa informar o nome da categoria');

    this.form.disable();
    const loading = this.modalService.presentLoading('Salvando categoria...', true);

    (this.categoria?.id ? this.categoriaService.update({ ...toSave, id: this.categoria.id }) : this.categoriaService.insert(toSave)).then((res) => {
      if (!res.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.presentErrorToast('Erro ao salvar categoria');
        return this.form.enable();
      }

      this.toastService.show({ body: `Categoria salva com sucesso`, color: 'success' });
      this.form.reset({ ...res.data, cor: res.data.cor ? colorHexToRGB(res.data.cor) : this.defaultColor });
      this.categoria = { ...this.categoria, ...res.data };
      closeModal<Categoria, "categoria">(this.activeModal, 'saved', this.categoria, 'categoria');
    }).catch((err) => {
      console.log(err);
      this.form.enable();
      if (!showApiErrorMessages(this.modalService, err)) this.presentErrorToast('Erro ao salvar categoria');
    }).finally(() => {
      loading.dismiss();
    });
  }

  private presentErrorToast(body: string) {
    this.toastService.show({ body, color: 'danger' });
  }

  private toastErrorSave() {
    this.toastService.show({ body: 'Erro ao salvar categoria', color: 'danger' });
  }

  selectIconColor() {
    const { icone, cor } = this.form.value;
    const modal = this.modalService.presentModal(SelectIconColorCategoriaComponent, defaultClassModal(this), { icone, cor }, { windowClass: 'blur-backdrop' });

    modal.closed.subscribe({
      next: (res: ModalClose<ModalClosedDataSelectIconColorCategoria>) => {
        if (res?.data) {
          const { cor, icone } = res.data;
          this.form.controls.icone.setValue(icone);
          this.form.controls.cor.setValue(cor);
        }
      }
    })
  }

  setStatus(value: StatusCategoria) {
    this.form.controls.status.setValue(value);
  }

  delete() {
    const confirm = this.modalService.presentConfirm('Tem certeza?', 'Deseja realmente excluir esta categoria?', true, { yesButtonColor: 'danger' });

    confirm.closed.subscribe({
      next: (res: ModalClose<boolean>) => {
        if (res.data === true) {
          const loading = this.modalService.presentLoading('Excluindo categoria...', true);

          this.categoriaService.deleteCategoria(this.categoria.id).then((res) => {
            if (!res.success) {
              if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.presentErrorToast('Erro ao excluir categoria');
              return;
            }

            this.toastService.show({ body: `Categoria excluída com sucesso`, color: 'success' });
            closeModal<Categoria, "categoria">(this.activeModal, 'deleted', this.categoria);
          }).catch((err) => {
            console.log(err);
            if (showApiErrorMessages(this.modalService, err)) return;
            this.toastErrorSave();
          }).finally(() => {
            loading.dismiss();
          });
        }
      }
    });
  }

  async trocarCategoria(produto: Produto | ApiResponseGetProdutos) {
    const modal = this.modalService.presentModal(AlterarCategoriaProdutoComponent, defaultClassModal(this), { produto, categoriaAtual: this.categoria }, { windowClass: this.isDesktop ? null : 'h-400px blur-backdrop' });
    const result: ModalClose<boolean> = await modal.result;

    if (!result.data) return;

    this.getProdutos();
  }

  async removeProduto(_produto: Produto | ApiResponseGetProdutos) {
    const modal = this.modalService.presentConfirm("Tem certeza?", `Deseja realmente remover <span class="fw-bold">${_produto.nome}</span> da categoria <span class="fw-bold">${this.categoria?.nome}</span>?`, true);
    const result: ModalClose<boolean> = await modal?.result;

    if (!result.data) return;

    const loading = this.modalService.presentLoading('Removendo produto...', true);
    try {
      const produto = await this.produtoService.getProdutoById(_produto.id);
      const resUpdate = await this.produtoService.updateProduto({ ...produto, idCategoria: null });
      if (!resUpdate.success) throw new Error('Erro ao remover produto');

      this.toastService.show({ body: 'Produto removido com sucesso', color: 'success' });
      this.getProdutos();
    } catch (err) {
      console.error(err);
      if (!showApiErrorMessages(this.modalService, err))
        this.toastService.show({ body: 'Erro ao remover produto', color: 'danger' });
    } finally {
      loading.dismiss();
    }

  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PRODUTOS', size);
    this.getProdutos();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getProdutos();
  }


  dismiss() {
    this.activeModal.dismiss();
  }
}
