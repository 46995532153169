<div id="container" class="h-100 w-100" [class.close-menu]="menu.closing">
  <div id="backdrop" (click)="closeMenu()" [ngClass]="{ 'h-100 w-100': !isDesktop }"></div>

  <nav id="menu" class="nav flex-column h-100 overflow-y-auto flex-nowrap" [ngClass]="{ 'pt-5 ps-3 gap-3': !isDesktop }">
    @if (!isDesktop) {
      <div class="nav-link d-flex align-items-end">
        <img [src]="usuario?.imagem || 'https://ui-avatars.com/api/?name=' + usuario?.nome" class="avatar" />
        <p color="dark" class="mb-0 ms-3 d-flex flex-column" [ngClass]="{ 'mt-3': isDesktop }">
          <span class="fw-600"> {{ usuario?.nome }} </span>
          <span class="small"> {{ usuario?.email }} </span>
        </p>
      </div>
    }

    @for (page of PAGES_SIDENAV.value; track $index) {
      <a
        color="titulo"
        [ngClass]="{ 'flex-column text-center mx-auto py-2 my-1': isDesktop, 'gap-3 fw-600': !isDesktop }"
        class="nav-link d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="'/home/' + page.path"
        [queryParams]="page.queryParams || {}"
        [routerLinkActiveOptions]="routerLinkActiveOptions"
        #route="routerLinkActive"
        (isActiveChange)="isActiveChange($event)"
      >
        <icon [name]="route.isActive ? page.icon + (page.color ? '_highlight' : '') : page.icon" />
        <span [color]="route.isActive ? page.color : 'cinza'" class="mt-2" [ngClass]="{ 'ms-2 fw-bold': !isDesktop, 'fw-500': isDesktop }"> {{ page.title }} </span>
      </a>
    }

    @if (isDesktop && PAGES_SIDENAV.value?.length < allOptions.length) {
      <a
        color="titulo"
        [ngClass]="{ 'flex-column text-center mx-auto p-0 my-3': isDesktop, 'gap-3 fw-600': !isDesktop, 'pe-none': disabledExtrasOptionsButton }"
        class="nav-link d-flex align-items-center"
        (click)="showMoreOptions()"
      >
        <icon name="menu" />
        <span color="cinza" class="mt-2" [ngClass]="{ 'ms-2 fw-bold': !isDesktop, 'fw-500': isDesktop }"> Mais </span>
      </a>
    }

    <a class="mt-auto nav-link d-flex text-center" [ngClass]="{ 'flex-column justify-content-end mx-auto px-0': isDesktop, 'align-items-end mb-4 gap-3': !isDesktop }">
      @if (isDesktop) {
        <img [src]="usuario?.imagem || 'https://ui-avatars.com/api/?name=' + usuario?.nome" class="avatar" [ngbPopover]="popContent" placement="end" />

        <div class="d-flex flex-column gap-3">
          <ng-template #popContent>
            <div>
              <div class="d-flex align-items-center gap-3">
                <p color="dark" class="mb-0 ms-3 d-flex flex-column">
                  <span class="fw-600"> {{ usuario?.nome }} </span>
                  <span class="small"> {{ usuario?.email }} </span>
                </p>
              </div>

              <div class="d-flex gap-2 mt-3 align-items-center justify-content-between">
                <div class="d-flex gap-2 align-items-center cursor-pointer" (click)="changePassword()">
                  <ng-mat-icon name="lock_open" color="dark" />
                  <span [color]="!isMobile ? 'primary' : 'dark'" class="cursor-pointer fw-600"> Alterar Senha </span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      } @else {
        <ng-mat-icon name="logout" color="dark" />
      }
      <span (click)="logout()" [color]="!isMobile ? 'primary' : 'dark'" class="cursor-pointer fw-600" [ngClass]="{ 'mt-3': isDesktop }"> Sair </span>
    </a>
  </nav>
</div>
