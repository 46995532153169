import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[pasteImage]',
  standalone: true
})
export class PasteImageDirective {

  @Input({ required: true }) control: FormControl<File>;

  @HostListener('document:paste', ['$event', 'this.control'])
  pasteImage(event: ClipboardEvent, control: FormControl<File>) {
    const image = event.clipboardData.files[0];
    if (!image || !image?.type?.startsWith('image')) return;
    control.setValue(image);
  }

  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.addEventListener('click', () => this.selectImage());
  }

  selectImage() {
    const inputEl = document.createElement('input');
    inputEl.type = 'file';
    inputEl.accept = 'image/*';
    inputEl.click();

    inputEl.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files[0];
      this.control.setValue(file);
    }
  }

}
