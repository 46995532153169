import { Input, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileToImgSrc',
  standalone: true
})
export class FileToImgSrcPipe implements PipeTransform {

  @Input() value: File;

  transform(value: File): string {
    return value ? URL.createObjectURL(value) : '';
  }

}
