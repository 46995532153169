<div id="container" class="h-100">
  <div id="content" class="h-100 rounded-4 py-4 d-flex" [ngClass]="{ 'd-flex': isDesktop }">
    <div id="left" [ngClass]="{ 'd-flex flex-column gap-5': !produtosSelecionados?.length }">
      <div class="form-group d-flex flex-column gap-2 input-icon-end position-relative">
        <small-label> Produto Exibido </small-label>

        <produto-autocomplete [classificaProduto]="ClassificacaoProdutoEnum.vendido" [control]="form.controls.produto" highlightColor="secondary" (produtoSelecionado)="produtoSelecionado($event)" dropdownIcon="search" />
      </div>

      @if (!produtosSelecionados?.length) {
        <div class="d-flex flex-column gap-4 align-items-center justify-content-center">
          <h5 class="fw-bold">Você ainda não tem produtos na lista de venda</h5>
          <h6>Comece buscando os produtos que deseja registrar a venda</h6>
        </div>
      }

      @if (produtosSelecionados?.length > 0) {
        <div id="list-produtos" class="d-grid gap-3 py-3">
          @for (produtoSelecionado of produtosSelecionados; track produtoSelecionado) {
            <div class="produto-selecionado p-2 rounded-4 d-flex gap-1 overflow-hidden" [ngClass]="{ 'w-100': !isDesktop }" [id]="'p-' + produtoSelecionado.id">
              <div class="image d-flex align-items-end">
                @if (produtoSelecionado.imagem) {
                  <img class="img-thumbnail avatar" [src]="produtoSelecionado.imagem" [alt]="produtoSelecionado.nome" (error)="produtoSelecionado.imagem = null" />
                } @else {
                  <div class="image-placeholder d-flex">
                    <icon-in-ellipse [fitParent]="true" matIcon="image" color="fog" />
                  </div>
                }
              </div>

              <div class="body d-flex flex-column gap-3 flex-grow-1">
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="fw-bold mb-0" color="cinza">{{ produtoSelecionado?.nome }}</h6>

                  <ng-mat-icon class="cursor-pointer" name="delete" color="cinza" (click)="removerProduto($index)" />
                </div>

                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-column gap-2">
                    <small-label color="secondary"> Preço de venda </small-label>
                    <h5 color="secondary" class="fw-bold mb-0">{{ produtoSelecionado?.valorVenda | currency }}</h5>
                  </div>

                  <div class="actions d-flex align-items-center gap-2">
                    <button class="btn small rounded-circle" color="secondary" (click)="quantidadeProdutoSub($index)">
                      <ng-mat-icon name="remove" />
                    </button>
                    <input #inputRef type="number" class="form-control text-center px-0" [(ngModel)]="produtoSelecionado.quantidade" (blur)="focusOutInputQuantidadeProduto($index, inputRef)" />
                    <button class="btn small rounded-circle" color="secondary" (click)="quantidadeProdutoAdd($index)">
                      <ng-mat-icon name="add" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>

    @if (produtosSelecionados?.length >= 1) {
      <div id="right">
        <div class="d-flex flex-column gap-3" [formGroup]="form">
          @if (!editDate) {
            <div class="d-flex gap-2 align-items-center">
              <small-label color="secondary" class="fw-bold"> {{ datetime | date: "'Data:' dd/MM/yyyy - 'Hora:' HH:mm" }} </small-label>

              <ng-mat-icon class="cursor-pointer" name="edit" color="secondary" (click)="editDate = true" />
            </div>
          } @else {
            <div class="d-flex gap-2">
              <div class="w-fit-content input-group input-with-tag flex-grow-1">
                <div class="input-group-text" color="secondary">
                  <ng-mat-icon name="calendar_month" />
                </div>

                <input [readonly]="true" formControlName="data" [maxDate]="maxDate" ngbDatepicker #dataDP="ngbDatepicker" id="input-dp-data" class="position-absolute h-100" (dateSelect)="dateSelected(mainInputData)" />
                <input
                  #mainInputData
                  mask="00/00/0000"
                  [dropSpecialCharacters]="false"
                  [readonly]="true"
                  class="form-control date lh-2 text-center p-0"
                  placeholder="22/08/2024"
                  formControlName="data"
                  (click)="dataDP.toggle()"
                  (blur)="focusOutInputDate(mainInputData)"
                  (ngModelChange)="dataDP.writeValue(form.controls.data.value)"
                />
              </div>

              <div class="w-fit-content input-group input-with-tag">
                <div class="input-group-text" color="secondary">
                  <ng-mat-icon name="calendar_month" />
                </div>

                <input
                  #mainInputHora
                  mask="90:00"
                  [dropSpecialCharacters]="false"
                  class="form-control date lh-2 text-center"
                  placeholder="11:00"
                  formControlName="hora"
                  (blur)="focusOutInputHora(mainInputHora)"
                  (input)="inputOnData(mainInputHora)"
                />
              </div>
            </div>

            <button class="btn" color="apoio" (click)="atualizarDatetime()">Atualizar</button>
          }

          <small-label color="cinza" class="fw-bold"> Valor: </small-label>

          <h2 color="secondary" class="fw-bold">{{ total | currency }}</h2>

          <div id="actions" class="d-flex flex-column gap-3">
            <button class="btn w-100" color="secondary" (click)="finalizarVenda()">{{ venda?.id ? "Atualizar" : "Registrar" }} venda</button>

            @if (!venda?.id) {
              <button class="btn w-100" color="apoio" (click)="limparLista()">Limpar lista</button>
            } @else {
              <button class="btn w-100" color="apoio" (click)="cancelarEdicao()">Cancelar</button>
            }
          </div>
        </div>
      </div>
    }
  </div>
</div>
