import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DebounceDirective } from '../../../directives/debounce.directive';
import { NgMatIconComponent } from '../../ng-mat-icon/ng-mat-icon.component';
import { ColorTipoProdutoPipe } from '../../../directives/color-tipo-produto.pipe';
import { ColorDirective } from '../../../directives/color.directive';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BaseClass } from '../../../globals/base-class';
import { ClassificacaoProdutoEnum, Produto, TipoProdutoEnum } from '../../../model/produto.model';
import { defaultDebounceInput } from '../../../globals/globals';
import { displayWith, showApiErrorMessages } from '../../../globals/utils';
import { IconInEllipseComponent } from '../../icon-in-ellipse/icon-in-ellipse.component';
import { ProdutoService } from '../../../services/produto.service';
import { AppColor } from '../../../model/custom-types';
import { NgClass } from '@angular/common';
import { MaterialIcon } from 'material-icons';
import { ApiResponseGetProdutos } from '../../../model/api.model';
import { ModalService } from '../../../services/modal.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'produto-autocomplete',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    DebounceDirective,
    NgMatIconComponent,
    ColorTipoProdutoPipe,
    ColorDirective,
    ReactiveFormsModule,
    IconInEllipseComponent,
    NgClass,
  ],
  templateUrl: './produto-autocomplete.component.html',
  styleUrl: './produto-autocomplete.component.scss'
})
export class ProdutoAutocompleteComponent extends BaseClass() {

  @Input({ required: true }) control: FormControl<Produto>;
  @Input() showAllOption: boolean = false;
  @Input() tipoProduto: TipoProdutoEnum;
  @Input() classificaProduto: ClassificacaoProdutoEnum;
  @Input() highlightColor: AppColor = 'primary';
  @Input() inputClasses: Array<string> = [];
  @Input() dropdownIcon: MaterialIcon = 'expand_more';

  @Output() produtoSelecionado: EventEmitter<Produto> = new EventEmitter();

  searching: boolean = false;

  produtos: Array<ApiResponseGetProdutos | Produto>;

  debounce: number = defaultDebounceInput;

  displayWith = (value: Produto) => displayWith(value, 'nome');

  constructor(
    private produtoService: ProdutoService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    this.getProdutos();
  }

  filter(el: HTMLInputElement) {
    el.disabled = false;
    el.focus();
    this.getProdutos(el.value);
  }

  async getProdutos(Termo: string = null) {
    try {
      this.searching = true;
      const res = await this.produtoService.getProdutos({
        Termo,
        TipoProduto: this.tipoProduto,
        Classificacao: this.classificaProduto,
      });
      if (res.data) this.produtos = res.data;
      if (this.control.disabled) this.control.enable();
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar produtos', color: 'danger' });
    } finally {
      this.searching = false;
    }
  }

  selectProduto(event: MatAutocompleteSelectedEvent) {
    this.getProdutos();
    this.produtoSelecionado.emit(event.option.value);

  }
}
