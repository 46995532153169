import { Location, TitleCasePipe } from "@angular/common";
import { IBaseClass } from "./base-class";
import { HttpParams } from "@angular/common/http";
import { format, isValid, parse } from "date-fns";
import { AbstractControl, FormControl } from "@angular/forms";
import { HexColor, ModalClose, ModalCloseFieldData, ModalCloseReason, RGBColor } from "../model/custom-types";
import { ModalService, ModalType } from "../services/modal.service";
import { minLengthPassword, session$ } from "./globals";
import { PaginatorContraints, ValidationError } from "../model/api.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ptBR } from "date-fns/locale";
import { EmpresaService } from "../services/empresa.service";
import { DataModalEditarMeuNegocio, EditarMeuNegocioComponent } from "../home/meu-negocio/editar-meu-negocio/editar-meu-negocio.component";
import { Empresa, NegocioEmpresa } from "../model/empresa.model";
import { ToastService } from "../services/toast.service";

/**
 * Atualiza a url com os parâmetros fornecidos
 * @param _location deve ser importado de @angular/common
 * @param params parâmetros a serem adicionados na url
 */
export const updateUrl = (_location: Location, params: Record<string, string | boolean | number> | HttpParams = {}) => {
  let _params: HttpParams;
  _params = (!(params instanceof HttpParams)) ? new HttpParams().appendAll(Object.fromEntries(Object.entries(params)?.filter(x => !!x?.[1] || x?.[1] === false))) : params;
  _location.replaceState(location.pathname, _params.toString());
}

/**
 * Retorna a classe modal padrão de acordo com o tamanho da tela
 * @param responsive objeto que contém informações sobre o tamanho da tela
 * @returns classe modal padrão
 */
export const defaultClassModal = (responsive: IBaseClass): ModalType => {
  if (responsive.isDesktop) return "slide-right";
  if (!responsive.isDesktop) return "slide-bottom";
  return null;
}

/**
 * verifica se uma data no formato dd/MM/yyyy é válida
 * @param date data no formato dd/MM/yyyy ou um AbstractControl/FormControl(também formatado como dd/MM/yyyy)
 * @returns null se a data for válida, { 'dateInvalid': true } se a data for inválida
 */
export const dateDDMMYYYYIsInvalid = (date: string | AbstractControl | FormControl) => {
  const parsed = parse(typeof date === 'string' ? date : date.value, "dd/MM/yyyy", new Date());
  const valid = isValid(parsed)
  return valid ? null : { 'dateInvalid': true };
}

export const horaHHMMIsInvalid = (time: string | AbstractControl | FormControl) => {
  const parsed = parse(typeof time === 'string' ? time : time.value, "HH:mm", new Date());
  const valid = isValid(parsed)
  return valid ? null : { 'timeInvalid': true };
}

export const aGreaterThenB = <T extends number | string>(a: T, b: T): 1 | -1 | 0 => {
  if (typeof a === 'string' && typeof b === 'string') {
    if (a?.toUpperCase() === b?.toUpperCase()) return 0;
    if (!a && b) return -1;
    if (a && !b) return 1;
    return a?.toUpperCase() > b?.toUpperCase() ? 1 : -1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    if (a === b) return 0;
    return a > b ? 1 : -1;
  }

  return 0;
}

/**
 * substitui caracteres especiais por caracteres comuns
 */
export const replaceSpecialChars = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Verifica se uma string inclui um substring, ignorando acentos e case sensitive
 */
export const stringIncludesSubstring = (str: string, substr: string): boolean => {
  if (!str || !substr) return false;
  const haystack = replaceSpecialChars(str).toLowerCase();
  const needle = replaceSpecialChars(substr).toLowerCase();

  return haystack.includes(needle);
}

/**
 * Converte uma string no formato 'r,g,b' para sua representação em hexadecimal (#rrggbb)
 * @param color
 * @returns
 */
export const colorRGBToHex = (color: RGBColor): HexColor => {
  const [r, g, b] = color.split(',').map(Number);
  const rHex = Number(r).toString(16).padStart(2, '0');
  const gHex = Number(g).toString(16).padStart(2, '0');
  const bHex = Number(b).toString(16).padStart(2, '0');
  return `#${rHex}${gHex}${bHex}`;
}

/**
 * Converte uma string no formato '#rrggbb' para sua representação em 'r,g,b'
 */
export const colorHexToRGB = (color: HexColor): RGBColor => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r},${g},${b}`;
}

/**
 * Valida um email usando regex
 */
export const emailIsValid = (email: string): boolean => {
  const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  return regex.test(email);
}

/**
 * Verifica se uma string é uma cor no formato 'r,g,b'
 */
export const stringIsRGBColor = (color: string): boolean => {
  const regex = /^(\d{1,3},){2}\d{1,3}$/;
  return regex.test(color);
}

/**
 * Verifica se uma string é uma cor no formato '#rrggbb'
 */
export const stringIsHexColor = (color: string): boolean => {
  const regex = /^#[0-9A-Fa-f]{6}$/;
  return regex.test(color);
}

export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const passwordValidations: Array<{ label: string, validation: (value: string, value2?: string) => boolean }> = [
  { label: `Mínimo de ${minLengthPassword} caracteres`, validation: (value) => value?.length >= minLengthPassword },
  { label: 'Pelo menos uma letra maiúscula', validation: (value) => /[A-Z]/.test(value) },
  { label: 'Pelo menos uma letra minúscula', validation: (value) => /[a-z]/.test(value) },
  { label: 'Pelo menos um número', validation: (value) => /\d/.test(value) },
  { label: 'Pelo menos um caractere especial', validation: (value) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value) },
  { label: 'Senhas conferem', validation: (value, value2) => value === value2 },

];

export const makeParamsGet = <T, K extends Exclude<keyof T, keyof PaginatorContraints>>(constraints: T & PaginatorContraints, fieldsFilter: Array<K> = []) => {
  const fieldsPaginator: Array<keyof PaginatorContraints> = ['Termo', 'PageSize', 'Page', 'FirstRow'];
  const fields: Array<K | keyof PaginatorContraints> = [fieldsFilter, fieldsPaginator].flat()
  return Object.fromEntries([fields, fieldsPaginator].flat().map(field => [field, constraints?.[field]]).filter(x => x[1] != undefined))
}

export const closeModal = <T = void, K extends ModalCloseFieldData = "data">(activeModal: NgbActiveModal, reason: ModalCloseReason = null, data: T = null, dataFieldName: K = null) => {
  const closeData: ModalClose<T, K> = {
    reason,
    [dataFieldName || 'data']: data,
  } as ModalClose<T, K>;
  activeModal.close(closeData);
}

export const displayWith = <T extends { nome: string }, K extends (keyof T | 'nome') = 'nome'>(item: T, field: K): string => {
  return item?.[field]?.toString();
}

export const showApiErrorMessages = (
  modalService: ModalService,
  error: {
    validations?: Array<ValidationError>,
    error?: { validations?: Array<ValidationError> },
    errors?: { validations?: Array<ValidationError> }
  },
  title: string = "Ocorreu um erro") => {
  const messages = [error?.validations?.map(v => v.message), error?.error?.validations?.map(x => x.message), error?.errors?.validations?.map(x => x.message)]?.flat()?.filter(x => x);
  if (messages?.length > 0) {
    modalService.presentAlert(title, messages.join('\n'));
    return true;
  }
  return false;
}

export const getPeriodosChartHistoricoVendasCompras = (titleCasePipe: TitleCasePipe) => {
  return [...new Array(12).keys()].map(x => {
    const today = new Date();
    const date = new Date(today.getFullYear(), today.getMonth() - (12 - x - 1), 1);
    return {
      label: titleCasePipe.transform(`${format(date, 'MMMM', { locale: ptBR })} ${format(date, 'yyyy', { locale: ptBR })}`),
      mes: date.getMonth() + 1,
      ano: date.getFullYear(),
    };
  }).reverse();
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
