<div id="container" class="h-100" [class.big]="showFormSection" [class.position-relative]="isEdit">
  @if (isEdit && !controlProduto?.value) {
    <div class="loading position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
      <operation-in-progress messageColor="white" />
    </div>
  }

  <div id="header" class="d-flex align-items-center justify-content-between position-sticky top-0 left-0 w-100 p-4">
    <h4 class="fw-bold m-0" color="dark">
      @if (controlProduto?.value) {
        {{ isEdit ? "Editar" : "Nova" }} compra de <span [color]="controlProduto?.value?.tipoProduto | colorTipoProduto">{{ controlProduto.value?.nome }}</span>
      } @else {
        Deseja registrar a compra de qual insumo?
      }
    </h4>

    <button type="button" class="btn clear" (click)="dismiss()">
      <ng-mat-icon color="dark" name="close" />
    </button>
  </div>

  <div id="content" class="position-relative p-4 overflow-y-auto h-100" [formGroup]="form">
    <!-- <div class="d-flex mt-3">
      <small-label color="roxo"> Preciso de ajuda! </small-label>
    </div> -->

    @if (!showFormSection) {
      <select-produto [disabled]="isEdit" [tipo]="TipoProdutoEnum.insumo" [produtoControl]="controlProduto" (produtoSelecionado)="getProduto($event.id)" />
    } @else {
      <div id="form" class="d-flex flex-column gap-5">
        <form-compra-produto [form]="form" [showTagCalculoCusto]="true" />
      </div>
    }
  </div>

  <div class="d-flex w-100 position-absolute gap-2 bottom-0 left-0 p-4" id="actions">
    @if (compra?.id) {
      <button type="button" class="btn" color="danger" (click)="excluir()">Excluir</button>
    }

    <button type="button" class="btn" color="apoio" (click)="back()">{{ showFormSection ? "Voltar" : "Cancelar" }}</button>

    <button [disabled]="!showFormSection && !controlProduto?.value" type="button" class="btn" [color]="controlProduto?.value?.tipoProduto | colorTipoProduto" (click)="confirmSelecaoProduto()">
      {{ showFormSection ? (isEdit ? "Atualizar" : "Registrar") + (isDesktop ? " compra" : "") : "Continuar" }}
    </button>
  </div>
</div>
