import { Component, Input } from '@angular/core';
import { ColorDirective } from '../../../../directives/color.directive';
import { DefaultColorTipoProduto } from '../../../../globals/globals';
import { TipoProdutoEnum } from '../../../../model/produto.model';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TFormProduto } from '../novo-produto.component';
import { BaseClass } from '../../../../globals/base-class';

@Component({
  selector: 'section-aviso-tipo-produto',
  standalone: true,
  imports: [
    ColorDirective,
    ReactiveFormsModule,
  ],
  templateUrl: './section-aviso-tipo-produto.component.html',
  styleUrl: './section-aviso-tipo-produto.component.scss'
})
export class SectionAvisoTipoProdutoComponent extends BaseClass() {
  @Input() formProduto: FormGroup<TFormProduto>;

  DefaultColorTipoProduto = DefaultColorTipoProduto;
  TipoProdutoEnum = TipoProdutoEnum;

}
