import { Component } from '@angular/core';
import { AppPages, AppPath, PagesApp, routerLinkActiveOptions } from '../app-pages';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../../globals/base-class';
import { ColorDirective } from '../../../directives/color.directive';

@Component({
  selector: 'tabbar',
  standalone: true,
  imports: [IconComponent, RouterLink, RouterLinkActive, NgClass, ColorDirective],
  templateUrl: './tabbar.component.html',
  styleUrl: './tabbar.component.scss'
})
export class TabbarComponent extends BaseClass() {

  private pagesToShow: Array<AppPath> = ['produtos', 'compras', 'vendas', 'categorias'];
  pages: PagesApp = AppPages.filter(page => this.pagesToShow.includes(page.path));

  routerLinkActiveOptions = routerLinkActiveOptions;
}
