<div class="d-flex align-items-center justify-content-center">
  <button type="button" class="btn btn-sm btn-outline mt-2 align-self-center" [color]="tipoProduto | colorTipoProduto" pasteImage [control]="imagemFileControl">
    @if (!imagemControl.value && !imagemFileControl.value) {
      <div class="d-flex flex-column align-items-center">
        <ng-mat-icon name="image" [color]="tipoProduto | colorTipoProduto" />
        <span> Enviar foto </span>
      </div>
    } @else {
      @if (imagemFileControl.value) {
        <img [src]="imagemFileControl.value | fileToImgSrc" alt="Imagem do produto" class="w-100 h-100" />
      } @else {
        <img [src]="imagemControl.value" class="w-100 h-100" (error)="imagemControl.setValue(null)" />
      }
    }
  </button>
</div>
