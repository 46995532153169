<div id="container">
  <div id="list-produtos" class="my-1">
    @for (produto of produtos; track $index) {
      <div class="card-produto rounded-4 p-2 d-flex gap-3 cursor-pointer" [color]="cardBorderColor" (click)="clickProduto($event, produto)" area="card">
        <div class="img" area="image">
          <div>
            @if (produto?.imagem) {
              <img #imgRef class="img-thumbnail avatar" [src]="produto?.imagem" [alt]="produto.nome" (error)="produto.imagem = null" />
            } @else {
              <icon-in-ellipse matIcon="image" color="fog" />
            }
          </div>
        </div>

        <div class="body ms-5">
          <div class="d-flex flex-column gap-1">
            <h6 class="card-title fw-600 mb-2" area="nome">{{ produto.nome }}</h6>

            <div class="d-flex gap-4">
              @if (classificacao === ClassificacaoProduto.vendido) {
                <div class="preco-venda d-flex flex-column gap-1" color="secondary" area="pv">
                  <small-label class="fw-200"> Preço de venda </small-label>
                  <span class="fw-bold">
                    {{ (produto.valorVenda | currency) || "-" }}
                    @if (produto.unidadesMedidaEquivalentes?.at(0) || produto.unidadesMedidaRendimentos?.at(0)) {
                      <span class="small fw-200"> ({{ (produto.unidadesMedidaEquivalentes?.at(0) || produto.unidadesMedidaRendimentos?.at(0))?.siglaUnidadeMedida }}) </span>
                    }
                  </span>
                </div>
              }

              <div class="preco-custo d-flex flex-column gap-1" [color]="!produto.tagCadastroCompleto ? 'warning' : 'laranja-forte'" area="pc">
                <small-label class="fw-200"> Preço de custo </small-label>
                @if (!produto.tagCadastroCompleto) {
                  <span class="d-flex">
                    <ng-mat-icon name="warning" color="warning" />
                  </span>
                } @else {
                  <span class="fw-bold">
                    {{ (produto.valorCusto / (produto.unidadesMedidaRendimentos?.at(0)?.quantidadeRendimento || 1) | currency) || "-" }}
                    @if (produto.siglaUnidadeMedidaCompra) {
                      <span class="fw-200"> ({{ produto.siglaUnidadeMedidaCompra }}) </span>
                    } @else {
                      <span class="fw-200"> ({{ produto.unidadesMedidaRendimentos?.at(0)?.siglaUnidadeMedida }}) </span>
                    }
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
