<ul class="nav" [ngClass]="{ 'nowrap gap-4': nowrap }" [style.--base-color]="'var(--color-' + color + ', var(--bs-' + color + '))'">
  @for (segment of segments; track $index) {
    <li
      class="nav-item"
      [routerLink]="isNavigation ? segment.path : null"
      routerLinkActive="active"
      #route="routerLinkActive"
      [queryParams]="queryParams"
      (isActiveChange)="routeChanged(route, segment)"
      [class.active]="activeSegment?.title === segment.title"
      (click)="activeSegment?.title === segment.title ? null : isNavigation ? null : selectSegment(segment)"
      [class.pe-none]="disabled"
    >
      <a class="nav-link p-0">{{ segment.title }}</a>
    </li>
  }
</ul>
