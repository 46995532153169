import { Component, Inject } from '@angular/core';
import { Compra, CompraHistoricoProduto, Compras } from '../../../../model/compra.model';
import { BaseClass } from '../../../../globals/base-class';
import { ProdutoService } from '../../../../services/produto.service';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { Produto } from '../../../../model/produto.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { closeModal, defaultClassModal, showApiErrorMessages } from '../../../../globals/utils';
import { CompraService } from '../../../../services/compra.service';
import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { ModalService } from '../../../../services/modal.service';
import { BehaviorSubject } from 'rxjs';
import { ModalClose } from '../../../../model/custom-types';
import { NovaCompraComponent } from '../../compras/nova-compra/nova-compra.component';
import { ToastService } from '../../../../services/toast.service';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { PaginatorComponent, PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../../services/storage.service';
import { ApiConstraintGetCompras, ApiResponseError } from '../../../../model/api.model';

@Component({
  selector: 'app-historico-compras-produto',
  standalone: true,
  imports: [
    NgMatIconComponent,
    SmallLabelComponent,
    ColorDirective,
    DatePipe,
    CurrencyPipe,
    OperationInProgressComponent,
    AsyncPipe,
    PaginatorComponent,
  ],
  templateUrl: './historico-compras-produto.component.html',
  styleUrl: './historico-compras-produto.component.scss'
})
export class HistoricoComprasProdutoComponent extends BaseClass() {

  compraHistorico: CompraHistoricoProduto;
  produto: Produto;
  compras: Compras;

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  paginatorOpts: PaginatorOpts;

  constructor(
    @Inject("data") private data: { compraHistorico: CompraHistoricoProduto },
    private produtoService: ProdutoService,
    private ngbActiveModal: NgbActiveModal,
    private compraService: CompraService,
    private modalService: ModalService,
    private toastService: ToastService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.compraHistorico = this.data?.compraHistorico;
    if (!this.compraHistorico) return this.dismiss();
    this.getData();
  }

  async getData(apenasCompras: boolean = false) {
    try {
      if (!apenasCompras) await this.getProduto();

      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_COMPRAS')
      const constraints: ApiConstraintGetCompras = {
        Page: this.paginatorOpts?.page || 1,
        PageSize,
        IdProduto: this.compraHistorico.idProduto,
      };
      const res = await this.compraService.getCompras(constraints);
      const { page, pages, pageSize, rowsCount, data } = res;
      if (!data) return this.dismiss();

      this.compras = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar dados', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  private async getProduto() {
    const produto = await this.produtoService.getProdutoById(this.compraHistorico.idProduto);
    if (!produto) return this.dismiss();
    this.produto = produto;
  }

  async editarCompra(compra: Compra) {
    const modal = this.modalService.presentModal(NovaCompraComponent, defaultClassModal(this), { compra }, { windowClass: 'w-400px' });
    const result: ModalClose<Compra, 'compra'> = await modal.result;
    if (result?.reason !== 'saved' || !result?.compra) return;
    this.operationInProgress$.next(true);
    this.getData(true);
  }

  async excluirCompra(compra: Compra) {
    const confirm = this.modalService.presentConfirm("Excluir compra", "Deseja realmente excluir esta compra?", null, { yesButtonColor: 'danger' });
    const result: ModalClose<boolean> = await confirm.result;
    if (!result?.data) return;

    const loading = this.modalService.presentLoading('Excluindo...');
    try {
      const res = await this.compraService.deleteCompra(compra.id);
      if (!res.success && !showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) return this.toastService.show({ body: 'Erro ao excluir compra', color: 'danger' });
      this.toastService.show({ body: 'Compra excluída com sucesso', color: 'success', delay: 9999999999999 });
      this.operationInProgress$.next(true);
      this.getData(true);
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao excluir compra', color: 'danger' });
    } finally {
      loading.dismiss();
    }
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_COMPRAS', size);
    this.getData(true);
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getData(true);
  }

  dismiss() {
    closeModal(this.ngbActiveModal, "cancel", false);
  }
}
