import { Component, Input } from '@angular/core';
import { ColorDirective } from '../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../directives/color-tipo-produto.pipe';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { NgClass } from '@angular/common';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { NgxMaskDirective } from 'ngx-mask';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TFormProduto } from '../../home/produtos/novo-produto/novo-produto.component';
import { NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { dateDDMMYYYYIsInvalid } from '../../globals/utils';
import { ToastService } from '../../services/toast.service';
import { BaseClass } from '../../globals/base-class';
import { UnidadeMedidaAutocompleteComponent } from '../autocomplete/unidade-medida-autocomplete/unidade-medida-autocomplete.component';

@Component({
  selector: 'form-compra-produto',
  standalone: true,
  imports: [
    ColorDirective,
    ColorTipoProdutoPipe,
    SmallLabelComponent,
    NgClass,
    NgMatIconComponent,
    NgxMaskDirective,
    NgbDatepickerModule,
    ReactiveFormsModule,
    UnidadeMedidaAutocompleteComponent,
  ],
  templateUrl: './form-compra-produto.component.html',
  styleUrl: './form-compra-produto.component.scss'
})
export class FormCompraProdutoComponent extends BaseClass() {

  @Input() showTagCalculoCusto: boolean = false;
  @Input({ required: true }) form: FormGroup<Pick<TFormProduto, 'tipo' | 'valorUltimaCompra' | 'dataUltimaCompra' | 'quantidadeUnidadeMedidaUltimaCompra' | 'unidadeMedidaUltimaCompra' | 'marcaUltimaCompra' | 'localUltimaCompra' | 'tagCalculoCusto'>>;
  @Input() maxDate: NgbDateStruct = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  constructor(
    private toastService: ToastService,
  ) {
    super();
  }

  focusOutInputDate(el: HTMLInputElement) {
    if (el.value?.length === 10 && dateDDMMYYYYIsInvalid(el.value)) {
      el.value = null;
      this.form.controls.dataUltimaCompra.setValue(null);
      this.toastService.show({ body: 'Data inválida', color: "danger" });
    }
  }

}
