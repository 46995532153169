<div id="produto" class="form-group w-100 d-flex flex-column gap-2 mt-5">
  <small-label> {{ label }} </small-label>

  <input
    #inputProdutoRef
    type="text"
    class="form-control lh-2"
    id="produto-input"
    placeholder="Ex: Trigo, Açúcar, Óleo"
    [formControl]="produtoControl"
    [matAutocomplete]="autocompleteProduto"
    [debounce]="debounce"
    (onChange)="filterProdutos($event)"
  />
  <ng-mat-icon class="cursor-pointer" slot="end" name="expand_more" [color]="color || (produtoControl?.value?.tipoProduto | colorTipoProduto)" (click)="inputProdutoRef.focus()" />
  <mat-autocomplete #autocompleteProduto="matAutocomplete" [displayWith]="displayWith" requireSelection class="autocomplete-selecionar-produto" (optionSelected)="produtoSelecionado.emit($event.option.value)">
    @if (searchingProdutos) {
      <mat-option disabled>Carregando produtos...</mat-option>
    } @else {
      @if (produtos?.length === 0) {
        <mat-option disabled>Nenhum produto encontrado</mat-option>
      }
      @for (produto of produtos; track produto) {
        <mat-option [value]="produto">
          <div class="d-flex gap-3 align-items-center">
            <span>{{ produto.nome }}</span>
          </div>
        </mat-option>
      }
    }
  </mat-autocomplete>
</div>
