<div id="form-financas-negocio" class="mx-auto gap-3 align-items-center" [ngClass]="{ 'd-grid grid-two-columns': isDesktop, 'd-flex flex-column': !isDesktop }" [formGroup]="form">
  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <div class="d-flex align-items-center justify-content-between">
      <small-label>Qual seu custo fixo?</small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <span class="fw-bold"> R$ </span>
      </div>
      <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="custoFixo" />
    </div>
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <div class="d-flex align-items-center justify-content-between">
      <small-label>Qual o valor das suas despesas de venda?</small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <span class="fw-bold"> R$ </span>
      </div>
      <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="despesasVendas" />
    </div>
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <div class="d-flex align-items-center justify-content-between">
      <small-label>Qual a margem de lucro desejada?</small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <span> % </span>
      </div>
      <input mask="separator.2" thousandSeparator="." suffix="%" type="text" class="form-control lh-2" placeholder="Ex: 36%" formControlName="margemLucro" />
    </div>
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <div class="d-flex align-items-center justify-content-between">
      <small-label>Qual o seu faturamento médio?</small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <span class="fw-bold"> R$ </span>
      </div>
      <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 10.000,00" formControlName="faturamentoMedio" />
    </div>
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2 input-with-tag">
    <div class="d-flex align-items-center justify-content-between">
      <small-label>Qual o MULTIPLICADOR você usa para precificar seus produtos?</small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <div class="input-group">
      <div class="input-group-text" color="primary">
        <ng-mat-icon name="close" color="white" />
      </div>
      <input mask="separator.2" thousandSeparator="." type="text" class="form-control lh-2" placeholder="Ex: 2,2" formControlName="multiplicador" />
    </div>
  </div>
</div>
