<div id="container" class="h-100">
  <div id="content" class="d-flex flex-column justify-content-between">
    <div>
      <div id="header">
        <div id="title" class="d-flex justify-content-between align-items-center">
          <h3 color="dark" class="fw-600">Opções</h3>
          <button class="btn" (click)="dismiss()">
            <ng-mat-icon name="close" color="dark" />
          </button>
        </div>
      </div>

      <div id="body" class="py-3">
        <div class="d-flex flex-column gap-4">
          <div class="d-flex flex-column gap-3">
            <small-label class="bold"> Importar </small-label>

            <div class="list-options d-flex flex-column gap-3">
              @for (option of optionsImportacao; track $index) {
                <button color="neutro" class="btn btn-outline has-custom-color justify-content-start gap-3" (click)="optionSelected(option.value)">
                  @if (option.customIcon) {
                    <icon [name]="option.customIcon" />
                  } @else {
                    <ng-mat-icon [name]="option.matIcon" color="laranja-forte" />
                  }
                  <span color="dark"> {{ option.label }} </span>
                </button>
              }
            </div>
          </div>

          <div class="d-flex flex-column gap-3">
            <small-label class="bold"> Exportar </small-label>

            <div class="list-options d-flex flex-column gap-3">
              @for (option of optionsExportacao; track $index) {
                <button color="neutro" class="btn btn-outline has-custom-color justify-content-start gap-3" (click)="optionSelected(option.value)">
                  @if (option.customIcon) {
                    <icon [name]="option.customIcon" />
                  } @else {
                    <ng-mat-icon [name]="option.matIcon" color="laranja-forte" />
                  }
                  <span color="dark"> {{ option.label }} </span>
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    <button id="dismiss-button" color="apoio" class="btn has-custom-color w-100" (click)="dismiss()">Voltar</button>
  </div>
</div>
