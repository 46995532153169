<div id="form-dados-negocio" class="mx-auto gap-3 align-items-center" [ngClass]="{ 'd-grid grid-two-columns': isDesktop, 'd-flex flex-column': !isDesktop }" [formGroup]="form">
  <div class="form-group w-100 d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-between">
      <small-label> Nome da empresa <span class="ms-1"> * </span> </small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <input type="text" class="form-control lh-2" placeholder="Nome da empresa" formControlName="nomeEmpresa" />
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-between">
      <small-label> Ramo do negócio <span class="ms-1"> * </span> </small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <input
      #inputRamoNegocioRef
      type="text"
      class="form-control lh-2"
      placeholder="Ramo do negócio"
      formControlName="ramoNegocio"
      [matAutocomplete]="autocompleteRamoNegocio"
      [debounce]="debounce"
      (onChange)="_filter($event, this.getRamosNegocio)"
    />
    <input type="hidden" id="inputRamoNegocioHelper" />
    <ng-mat-icon slot="end" name="expand_more" color="primary" (click)="inputRamoNegocioRef.focus()" class="cursor-pointer" />

    <mat-autocomplete #autocompleteRamoNegocio="matAutocomplete" [displayWith]="displayWithRamoNegocio" requireSelection>
      @if (searchingRamosNegocio) {
        <mat-option disabled>Carregando...</mat-option>
      } @else {
        @for (ramo of filteredRamosNegocio | async; track ramo) {
          <mat-option [value]="ramo">
            <div class="d-flex gap-3 align-items-center">
              <span>{{ ramo.descricao }}</span>
            </div>
          </mat-option>
        }
      }
    </mat-autocomplete>
  </div>

  <!-- <div class="form-group w-100 d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-between">
      <small-label> Idade do negócio <span class="ms-1"> * </span> </small-label>
      <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" />
    </div>
    <input type="text" class="form-control lh-2" placeholder="Idade do negócio" formControlName="idadeNegocio" />
  </div> -->

  <div id="data-criacao" class="form-group w-100 d-flex flex-column gap-2">
    <small-label> Data fundação? </small-label>
    <div class="input-group">
      <input
        [readonly]="true"
        formControlName="dataCriacao"
        [maxDate]="now"
        ngbDatepicker
        #dataCriacaoDP="ngbDatepicker"
        id="input-dp-data-criacao"
        (dateSelect)="mainInput.value = form.controls.dataCriacao.value"
        [restoreFocus]="'false'"
      />
      <input
        #mainInput
        mask="00/00/0000"
        [dropSpecialCharacters]="false"
        class="form-control date lh-2 rounded-3"
        placeholder="22/08/2024"
        formControlName="dataCriacao"
        (click)="dataCriacaoDP.toggle()"
        (blur)="focusOutInputDate(mainInput)"
        (ngModelChange)="dataCriacaoDP.writeValue(form.controls.dataCriacao.value)"
      />
    </div>
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-between">
      <small-label> CNPJ </small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <input mask="00.000.000/0000-00" type="text" class="form-control lh-2" placeholder="CNPJ" formControlName="cnpj" />
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-between">
      <small-label> Estado </small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <input
      #inputEstadoRef
      type="text"
      class="form-control lh-2"
      placeholder="Estado"
      formControlName="estado"
      [matAutocomplete]="autocompleteEstado"
      [debounce]="debounce"
      (onChange)="_filter($event, this.getEstados)"
      (blur)="focusOutInputEstado(inputEstadoRef)"
    />
    <ng-mat-icon slot="end" name="expand_more" color="primary" (click)="inputEstadoRef.focus()" class="cursor-pointer" />

    <mat-autocomplete #autocompleteEstado="matAutocomplete" [displayWith]="displayWithEstado">
      @if (searchingEstados) {
        <mat-option disabled>Carregando...</mat-option>
      } @else {
        @for (estado of filteredEstados | async; track estado) {
          <mat-option [value]="estado">
            <div class="d-flex gap-3 align-items-center">
              <span>{{ estado.nome }}</span>
            </div>
          </mat-option>
        }
      }
    </mat-autocomplete>
  </div>

  <div class="form-group w-100 d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-between">
      <small-label> Cidade </small-label>
      <!-- <ng-mat-icon name="help_outline" [classes]="'small cursor-pointer'" color="roxo" /> -->
    </div>
    <input
      #inputCidadeRef
      type="text"
      class="form-control lh-2"
      placeholder="Cidade"
      formControlName="cidade"
      [matAutocomplete]="autocompleteCidade"
      [debounce]="debounce"
      (onChange)="_filter($event, this.getCidades)"
      (focusOutInputCidade)="focusOutInputCidade(inputCidadeRef)"
    />
    <ng-mat-icon slot="end" name="expand_more" color="primary" (click)="inputCidadeRef.focus()" class="cursor-pointer" />

    <mat-autocomplete #autocompleteCidade="matAutocomplete" [displayWith]="displayWithCidade">
      @if (searchingCidades) {
        <mat-option disabled>Carregando...</mat-option>
      } @else {
        @for (cidade of filteredCidades | async; track cidade) {
          <mat-option [value]="cidade">
            <div class="d-flex gap-3 align-items-center">
              <span>{{ cidade.nome }}</span>
            </div>
          </mat-option>
        }
      }
    </mat-autocomplete>
  </div>
</div>
