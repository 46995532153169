import { Injectable } from '@angular/core';
import { ApiConstraintGetCategorias } from '../model/api.model';
import { Categoria } from '../model/categoria.model';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../environments/environment.dev';
import { makeParamsGet } from '../globals/utils';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService extends BaseHttpService {

  getCategoriaById(id: string) {
    return this.getById<Categoria>(`${environment.api.endpoints.categorias}`, id);
  }

  getCategorias(constraints?: ApiConstraintGetCategorias) {
    const params = makeParamsGet(constraints);
    return this.get<Categoria>(`${environment.api.endpoints.categorias}`, params);
  }

  insert(categoria: Partial<Categoria>) {
    return this.post<Categoria>(`${environment.api.endpoints.categorias}`, categoria);
  }

  update(categoria: Partial<Categoria>) {
    return this.put<Categoria>(`${environment.api.endpoints.categorias}/${categoria.id}`, categoria);
  }

  deleteCategoria(id: string) {
    return this.delete<Categoria>(`${environment.api.endpoints.categorias}`, id);
  }
}
