import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.dev';
import { Usuario, UsuarioSession } from '../model/usuario.model';
import { ApiResponsePatch } from '../model/api.model';
import { session$ } from '../globals/globals';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends BaseHttpService {

  getUserById(id: string) {
    return this.getById<Usuario>(environment.api.endpoints.usuarios, id);
  }

  changePassword(senhaAtual: string, novaSenha: string) {
    const idUsuario = session$?.value?.usuario?.id;
    const body = {
      idUsuario,
      senhaAtual,
      novaSenha
    };
    return this.patch<typeof body, ApiResponsePatch<UsuarioSession & Pick<Usuario, 'telefone' | 'status'>>>(`${environment.api.endpoints.usuarios}/${idUsuario}/senha`, body);
  }

  insert(usuario: Partial<Usuario>) {
    return this.post<Usuario>(`${environment.api.endpoints.usuarios}`, usuario);
  }

  update(usuario: Partial<Usuario>) {
    return this.put<Usuario>(`${environment.api.endpoints.usuarios}/${usuario.id}`, usuario);
  }
}
