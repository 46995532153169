import { Component, Output } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { IconInEllipseComponent } from '../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { DebounceDirective } from '../../../directives/debounce.directive';
import { BehaviorSubject } from 'rxjs';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { defaultDebounceInput, DelayShowTooltip } from '../../../globals/globals';
import { ColorDirective } from '../../../directives/color.directive';
import { NoResultsComponent } from '../../../custom-components/no-results/no-results.component';
import { Compra, Compras } from '../../../model/compra.model';
import { ModalService } from '../../../services/modal.service';
import { OptionsComprasComponent } from './options/options.component';
import { defaultClassModal, showApiErrorMessages } from '../../../globals/utils';
import { ToastService } from '../../../services/toast.service';
import { ModalClose, ModalCloseReason, TOpcaoCompras } from '../../../model/custom-types';
import { CompraListComponent } from './list/list.component';
import { OperationInProgressComponent } from '../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NovaCompraComponent } from './nova-compra/nova-compra.component';
import { CompraService } from '../../../services/compra.service';
import { UnidadeMedidaService } from '../../../services/unidade-medida.service';
import { PaginatorComponent, PaginatorOpts } from '../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../services/storage.service';
import { ApiConstraintGetCompras, ApiResponseError } from '../../../model/api.model';

@Component({
  selector: 'app-compras',
  standalone: true,
  imports: [
    FormsModule,
    IconInEllipseComponent,
    SmallLabelComponent,
    DebounceDirective,
    NgMatIconComponent,
    AsyncPipe,
    NgbTooltipModule,
    ColorDirective,
    NoResultsComponent,
    OperationInProgressComponent,
    NgClass,
    CompraListComponent,
    PaginatorComponent,
  ],
  templateUrl: './compras.component.html',
  styleUrl: './compras.component.scss'
})
export class ComprasComponent extends BaseClass() {

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  compras: Compras;
  searchTerm: string;
  DelayShowTooltip = DelayShowTooltip;

  paginatorOpts: PaginatorOpts;

  debounce = defaultDebounceInput;

  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private compraService: CompraService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.getCompras();
  }

  async getCompras() {
    try {
      this.operationInProgress$.next(true);

      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_COMPRAS')
      const constraints: ApiConstraintGetCompras = {
        Page: this.paginatorOpts?.page,
        PageSize,
        Termo: this.searchTerm,
      };
      const res = await this.compraService.getCompras(constraints);
      if (!res.data) {
        if (showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Erro ao buscar compras', color: 'danger' });
        return;
      }

      const { page, pages, pageSize, rowsCount, data } = res;
      this.compras = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar compras', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  selecionarFiltros() {
    this.notImplemented();
  }

  private notImplemented() {
    this.toastService.show({ body: 'Em breve!', color: 'secondary' });
  }

  private importarNFE() {
    this.notImplemented();
  }

  private importarExcel() {
    this.notImplemented();
  }

  private exportarExcel() {
    this.notImplemented();
  }

  private exportarPDF() {
    this.notImplemented();
  }

  private imprimir() {
    this.notImplemented();
  }

  options() {
    const modal = this.modalService.presentModal(OptionsComprasComponent, defaultClassModal(this), {}, { windowClass: 'w-400px' });
    modal.closed.subscribe({
      next: (res: ModalClose<TOpcaoCompras>) => {
        if (res?.reason !== 'saved' || !res?.data) return;
        switch (res.data) {
          case 'importar-nfe': this.importarNFE(); break;
          case 'importar-excel': this.importarExcel(); break;
          case 'exportar-excel': this.exportarExcel(); break;
          case 'exportar-pdf': this.exportarPDF(); break;
          case 'imprimir': this.imprimir(); break;
          default: break;
        }
      }
    });
  }

  registrarCompra(compra: Compra = null) {
    const modal = this.modalService.presentModal(NovaCompraComponent, defaultClassModal(this), { compra }, { windowClass: 'w-400px' });
    modal.closed.subscribe({
      next: (res: ModalClose<Compra, 'compra'>) => {
        if ((['saved', 'deleted'] as Array<ModalCloseReason>)?.includes(res?.reason))
          this.getCompras();
      }
    });
  }

  inputOnSearch() {
    this.getCompras();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_COMPRAS', size);
    this.getCompras();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getCompras();
  }
}
