// Todas as variáveis globais do projeto são declaradas aqui

import { BehaviorSubject } from "rxjs";
import { Session } from "../model/usuario.model";
import { AppColor, RGBColor } from "../model/custom-types";
import { MaterialIcon } from "material-icons";
import { MatIcons } from "./mat-icons-names";
import { TipoProdutoEnum } from "../model/produto.model";
import { PeriodoChartHistoricoVendaCompra, TipoVisualizacaoChartHistoricoVendasComprasEnum } from "../custom-components/chart-historio-vendas-compras/chart-historio-vendas-compras.component";

export const session$: BehaviorSubject<Session> = new BehaviorSubject(null);
// export const tokenBearer$: BehaviorSubject<string> = new BehaviorSubject(null);

// [css] breakpoint para dispositivos móveis. Todos os estilos que dependem do tamanho da tela herdam esses valores. Utilizar a classe _ismobile, _istablet e _isdesktop ao invés de media queries
export const mobileBreakpoint: number = 768;
export const tabletBreakpoint: number = 1024;
export const maxScreenSize: number = 1600;

// [css] tempo de duração das animações
export const transitionDuration: number = 300;

// [css] tempo de exibição do modal feedback
export const modalFeedbackDuration: number = 3000;

// [ts] tempo de duração do toast
export const toastDelay: number = 5000;

// [ts] tempo de duração da sessão. Após esse tempo, será necessário fazer login novamente
export const sessionTimeout: number = 1000 * 60 * 30; // 30 minutos

// [ts] nome do query param que contém o código/token de recuperação de senha
export const resetPasswordKey: string = 'reset-password-code';

// [ts] tamanho mínimo da senha
export const minLengthPassword: number = 8;

// [ts] tamanhos de páginas disponíveis para paginação
export const PaginatorPageSize: Array<number> = [5, 10, 20, 50, 100];

// [ts] quantidade de períodos exibidos no dropdown da toolbar
export const defaultAmountPeriodosDropdownToolbar: number = 24;

// [ts] delay para emitir event onChange em input[debounce]
export const defaultDebounceInput: number = 500;

export const offsetShakeAnimation: number = 5;

export const defaultViewChartHistoricoVendasCompras: TipoVisualizacaoChartHistoricoVendasComprasEnum = TipoVisualizacaoChartHistoricoVendasComprasEnum.periodo;

export const DefaultPageSize = {
  produtos: 10,
  categorias: 10,
  unidadesMedida: 10,
  empresas: 10,
  compras: 5,
  vendas: 5,
};

export const DelayShowTooltip: number = 500;

// [css] altura da toolbar
export const toolbarHeightDesktop: number = 72;
export const toolbarHeightMobile: number = 56;

// [css] se true, o menu lateral exibirá a quantidade de itens dinamicamente(de acordo com o tamanho da tela)
export const dynamicAmountItemsMenu: boolean = false;

export const Contato = {
  emailSuporte: 'suporte@smartis.com.br',
  whatsappSuporte: '5531900000000',
}

// export const HexColors: Array<HexColor> = [
//   "#9b63f8", "#5cf9b9", "#039c55", "#2c81fb", "#33a8a1", "#438bf9", "#5ba6eb", "#702bfe", "#87dbdd", "#98dd62",
//   "#aff218", "#b0a6e5", "#e54887", "#e81a0c", "#f4afca", "#fe6900", "#fe7d87", "#feb912", "#feda49", "#ff4400",

//   "#134cec", "#dba831", "#ecfc17", "#0857de", "#91445a", "#d25fcb", "#fb6694", "#529368", "#2a7f98", "#5721c8",
//   "#99569b", "#83f5b4", "#10ad28", "#793e33", "#5c9800", "#049176", "#b62e07", "#5a0619", "#e1a983", "#c378a4",
//   "#89adc0", "#c3d296", "#652b13", "#a36787", "#16b567", "#c7a2c8", "#efc983", "#d44533", "#68ecbd", "#907286",
//   "#e9df83", "#25dccc", "#1dd021", "#f11bce", "#fd1c09", "#adb80c", "#7f9b73", "#374b2f", "#2b97c2", "#ea8003",
//   "#836eda", "#2f7a02", "#9e0f57", "#21f68c", "#9893ad", "#1c07df", "#d2ff1d", "#069263", "#609531", "#79b45b",
//   "#e00e07", "#8f7245", "#fe50d8", "#20ab2a", "#aa75a6", "#12b389", "#2fd456", "#962c46", "#0fdb4f", "#4054d9",
//   "#170136", "#a1f453", "#240c27", "#69e873", "#aff8a5", "#e82f7b", "#c9e598", "#8394c8", "#ada4e3", "#eca8a4",
//   "#cbc9c0", "#0e9c34", "#c5c24d", "#0fcc3f", "#3f6c09", "#a44adc", "#e50172", "#a1c46b", "#321c64", "#b320c1",
//   "#feb5a2", "#0c2220", "#e57bcf", "#3b967d", "#760fa2", "#d1179c", "#d6632e", "#5129e6", "#1f339d", "#18950a",
//   "#e348e6", "#585cc4", "#1c0e22", "#3b69eb", "#fdcf20", "#0150df", "#131a4b", "#bc93f4", "#a0b82d", "#2d91e0",
// ];

export const RGBColors: Array<RGBColor> = [
  '11,124,192', '67,172,76', '25,135,84', '34,187,238', '255,193,7', '220,53,69', '33,37,41',
  '44,62,80', '113,125,150', '203,210,224', '8,155,5', '179,179,179', '92,164,249', '255,184,184', '255,82,82', '254,157,53',

  '155,99,248', '131,245,180', '3,156,85', '44,129,251', '51,168,161', '67,139,249', '91,166,235', '112,43,254', '135,219,221', '152,221,98',
  '175,242,24', '176,166,229', '229,72,135', '232,26,12', '244,175,202', '254,105,0', '254,125,135', '254,185,18', '254,218,73', '255,68,0',

  '19,76,236', '219,168,49', '236,252,23', '8,87,222', '145,68,90', '210,95,203', '251,102,148', '82,147,104', '42,127,152', '87,33,200',
  '153,86,155', '92,249,185', '16,173,40', '121,62,51', '92,152,0', '4,145,118', '182,46,7', '90,6,25', '225,169,131', '195,120,164',
  '137,173,192', '195,210,150', '101,43,19', '163,103,135', '22,181,103', '199,162,200', '239,201,131', '212,69,51', '104,236,189', '144,114,134',
  '233,223,131', '37,220,204', '29,208,33', '241,27,206', '253,28,9', '173,184,12', '127,155,115', '55,75,47', '43,151,194', '234,128,3',
  '131,110,218', '47,122,2', '158,15,87', '33,246,140', '152,147,173', '28,7,223', '210,255,29', '6,146,99', '96,149,49', '121,180,91',
  '224,14,7', '143,114,69', '254,80,216', '32,171,42', '170,117,166', '18,179,137', '47,212,86', '150,44,70', '15,219,79', '64,84,217',
  '23,1,54', '161,244,83', '36,12,39', '105,232,115', '175,248,165', '232,47,123', '201,229,152', '131,148,200', '173,164,227', '236,168,164',
  '203,201,192', '14,156,52', '197,194,77', '15,204,63', '63,108,9', '164,74,220', '229,1,114', '161,196,107', '50,28,100', '179,32,193',
  '254,181,162', '12,34,32', '229,123,207', '59,150,125', '118,15,162', '209,23,156', '214,99,46', '81,41,230', '31,51,157', '24,149,10',
  '227,72,230', '88,92,196', '28,14,34', '59,105,235', '253,207,32', '1,80,223', '19,26,75', '188,147,244', '160,184,45', '45,145,224',
];

// [ts] extensões permitidas para importação de arquivos de produtos
export const AllowedExtensionsImportPeriodo = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/csv',
];

export const iconesCategoriaSlice = (): Array<MaterialIcon> => {
  const start = 1100;
  const end = 1180;
  const icons: Array<MaterialIcon> = MatIcons.slice(start, end); // setar o range de ícones a serem exibidos ou declarar um array com os ícones desejados
  return icons;
}

export const DefaultColorTipoProduto: {
  [key in TipoProdutoEnum]: AppColor;
} = {
  [TipoProdutoEnum.composicao]: 'info',
  [TipoProdutoEnum.insumo]: 'laranja-forte',
}

// [ts] aumenta o eixo y do gráfico em ((ChartYAxisRedudancyFactor - 1) * 10)%, assim a linha do gráfico nunca tocará o topo
export const ChartYAxisRedudancyFactor: number = 1.1;

export const periodosChartHistoricoVendaCompra: Array<PeriodoChartHistoricoVendaCompra> = [
  // { label: '3 dias', diasAntes: 3 },
  { label: '40 dias', diasAntes: 40 },
  { label: '3 meses', mesesAntes: 3 },
  { label: '6 meses', mesesAntes: 6 },
  { label: '1 ano', mesesAntes: 12 },
];

export const SwipeEventTreshold: number = 100;
