import { Component, Input } from '@angular/core';
import { SectionProdutosComposicaoComponent } from '../../novo-produto/section-produtos-composicao/section-produtos-composicao.component';
import { Composicao, ProdutosComposicaoForm, TipoProdutoEnum, UnidadeMedidaProdutoEquivalentRendimento } from '../../../../model/produto.model';
import { TFormProduto } from '../../novo-produto/novo-produto.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ColorDirective } from '../../../../directives/color.directive';
import { BaseClass } from '../../../../globals/base-class';
import { ModalService } from '../../../../services/modal.service';
import { ToastService } from '../../../../services/toast.service';
import { ProdutoService } from '../../../../services/produto.service';
import { showApiErrorMessages } from '../../../../globals/utils';
import { ApiConstraintGetProdutosComposicao, ApiResponseError } from '../../../../model/api.model';
import { PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../../../../services/storage.service';
import { UnidadeMedidaInsert, UnidadesMedidaInsert } from '../../../../model/unidade-medida.model';

@Component({
  selector: 'section-composicao-produto-editar',
  standalone: true,
  imports: [
    ColorDirective,
    SectionProdutosComposicaoComponent,
  ],
  templateUrl: './section-composicao-produto-editar.component.html',
  styleUrl: './section-composicao-produto-editar.component.scss'
})
export class SectionComposicaoProdutoEditarComponent extends BaseClass() {

  @Input({ required: true }) composicao: Composicao;
  produtosComposicao: ProdutosComposicaoForm;

  form: FormGroup<TFormProduto> = new FormGroup<TFormProduto>({
    nome: new FormControl(null),
    quantidadeUnidadeMedidaVenda: new FormControl(null),
    tipo: new FormControl(null),
  });

  paginatorOpts: PaginatorOpts;
  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  TipoProdutoEnum = TipoProdutoEnum;

  unidadesMedidaEquivalente: UnidadesMedidaInsert<TipoProdutoEnum.insumo>;
  unidadesMedidaRendimento: UnidadesMedidaInsert<TipoProdutoEnum.composicao>;

  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private produtoService: ProdutoService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    const mapper = (x: Partial<UnidadeMedidaProdutoEquivalentRendimento>): Pick<UnidadeMedidaInsert, 'id' | 'idCompraProduto' | 'idUnidadeMedida' | 'nome'> => {
      return {
        idCompraProduto: x.idCompraProduto,
        idUnidadeMedida: x.idUnidadeMedida,
        id: x.idUnidadeMedida,
        nome: x.nomeUnidadeMedida,
      };
    }

    this.unidadesMedidaEquivalente = this.composicao.unidadesMedidaEquivalentes.map((x) => {
      return {
        ...mapper(x),
        quantidadeEquivalente: x.quantidadeEquivalente,
        tipo: TipoProdutoEnum.insumo,
      } as UnidadeMedidaInsert<TipoProdutoEnum.insumo>;
    });

    this.unidadesMedidaRendimento = this.composicao.unidadesMedidaRendimentos.map((x) => {
      return {
        ...mapper(x),
        quantidadeRendimento: x.quantidadeRendimento,
        tipo: TipoProdutoEnum.composicao,
      } as UnidadeMedidaInsert<TipoProdutoEnum.composicao>;
    });

    this.form.patchValue({
      nome: this.composicao.nome,
      quantidadeUnidadeMedidaVenda: this.composicao.quantidadeMediaVendidaMensal,
      tipo: this.composicao.tipoProduto,
    });

    this.getProdutosComposicao(true);
  }

  async getProdutosComposicao(showLoading: boolean = false) {
    const loading = showLoading ? this.modalService.presentLoading("Carregando produtos da composição...") : null;

    try {
      this.operationInProgress$.next(true);

      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_PRODUTOS_COMPOSICAO')
      const constraints: ApiConstraintGetProdutosComposicao = {
        Page: this.paginatorOpts?.page,
        PageSize,
      };
      const res = await this.produtoService.getProdutosComposicao(this.composicao.id, constraints);
      if (!res?.data && !showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) {
        return this.toastService.show({ body: "Erro ao carregar produtos da composição", color: "danger" });
      }
      const { page, pages, pageSize, rowsCount, data } = res;
      this.produtosComposicao = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error as ApiResponseError<'validation'>)) {
        this.toastService.show({ body: "Erro ao carregar produtos da composição", color: "danger" });
      }
    } finally {
      this.operationInProgress$.next(false);
      loading?.dismiss();
    }
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PRODUTOS_COMPOSICAO', size);
    this.getProdutosComposicao();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getProdutosComposicao();
  }

}
