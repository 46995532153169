import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClassificacaoProdutoEnum, Produto, TipoProdutoEnum } from '../../../model/produto.model';
import { AppColor } from '../../../model/custom-types';
import { ColorDirective } from '../../../directives/color.directive';
import { BaseClass } from '../../../globals/base-class';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { CurrencyPipe } from '@angular/common';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { IconInEllipseComponent } from '../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { ApiResponseGetProdutos } from '../../../model/api.model';

@Component({
  selector: 'produto-list',
  standalone: true,
  imports: [
    ColorDirective,
    SmallLabelComponent,
    CurrencyPipe,
    NgMatIconComponent,
    IconInEllipseComponent,
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class ProdutoListComponent extends BaseClass() {
  @Input() produtos: Array<ApiResponseGetProdutos | Produto>;
  @Input() cardBorderColor: AppColor = 'dark';

  @Input({ required: true }) tipo: TipoProdutoEnum;
  @Input({ required: true }) classificacao: ClassificacaoProdutoEnum;

  @Output() clickedOnCard: EventEmitter<{ produto: Produto | ApiResponseGetProdutos, area: ProdutoClickArea }> = new EventEmitter();

  ClassificacaoProduto = ClassificacaoProdutoEnum;

  clickProduto(event: Event, produto: ApiResponseGetProdutos | Produto) {
    const getAreaClicked = (_el: HTMLElement): ProdutoClickArea => {
      const elements = [_el];
      let element = _el;
      while (element && !element.classList.contains('card-produto')) {
        element = element.parentElement;
        elements.push(element);
      }

      return elements.map(el => el.getAttribute('area') as ProdutoClickArea).find(area => !!area);
    };

    const area: ProdutoClickArea = getAreaClicked(event.target as HTMLElement);

    this.clickedOnCard.emit({ produto, area });
  }
}

export type ProdutoClickArea = 'image' | 'pv' | 'pc' | 'nome' | 'card';
