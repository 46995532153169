import { Component } from '@angular/core';
import { Segment, SegmentsComponent } from '../../custom-components/segments/segments.component';
import { BaseClass } from '../../globals/base-class';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-compras-wrapper',
  standalone: true,
  imports: [SegmentsComponent, RouterOutlet],
  templateUrl: './compras-wrapper.component.html',
  styleUrls: ['./compras-wrapper.component.scss'],
})
export class ComprasWrapperComponent extends BaseClass() {
  segments: Array<Segment> = [
    { path: ['/home', 'compras', 'list'], title: 'Compras' },
    { path: ['/home', 'compras', 'historico'], title: 'Histórico de compras' },
  ];

  activeSegment: Segment;
}
