import { Component } from '@angular/core';
import { Segment, SegmentsComponent } from '../../custom-components/segments/segments.component';
import { ColorDirective } from '../../directives/color.directive';
import { SmallLabelComponent } from '../../custom-components/small-label/small-label.component';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../globals/base-class';
import { Usuario } from '../../model/usuario.model';
import { UsuarioService } from '../../services/usuario.service';
import { StorageService } from '../../services/storage.service';
import { minLengthPassword } from '../../globals/globals';
import { ToastService } from '../../services/toast.service';
import { defaultClassModal, showApiErrorMessages } from '../../globals/utils';
import { ModalService } from '../../services/modal.service';
import { ApiResponseError } from '../../model/api.model';
import { EditarInformacoesContaComponent } from './editar-informacoes-conta/editar-informacoes-conta.component';
import { EditarDadosLoginComponent } from './editar-dados-login/editar-dados-login.component';

@Component({
  selector: 'app-minha-conta',
  standalone: true,
  imports: [
    SegmentsComponent,
    ColorDirective,
    SmallLabelComponent,
    NgClass,
  ],
  templateUrl: './minha-conta.component.html',
  styleUrl: './minha-conta.component.scss'
})
export class MinhaContaComponent extends BaseClass() {

  segments: Array<Segment> = [
    { path: ['suas-info'], title: 'Suas informações' },
    { path: ['dados-login'], title: 'Dados de login' },
  ]

  activeSegment: Segment = this.segments[0];

  usuario: Usuario;

  minLengthPassword = [...new Array(minLengthPassword).keys()];

  constructor(
    private storageService: StorageService,
    private usuarioService: UsuarioService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.getUsuario();
  }

  async getUsuario() {
    try {
      const session = this.storageService.getSession();
      this.usuario = await this.usuarioService.getUserById(session.usuario.id);
      if (!this.usuario && !showApiErrorMessages(this.modalService, this.usuario as ApiResponseError<'validation'>))
        this.toastService.show({ body: 'Erro ao buscar usuário', color: "danger" });
    } catch (error) {
      if (!showApiErrorMessages(this.modalService, error))
        this.toastService.show({ body: 'Erro ao buscar usuário', color: "danger" });
    }
  }

  async edit() {
    const component = this.activeSegment.path[0] === 'suas-info' ? EditarInformacoesContaComponent : EditarDadosLoginComponent;
    const dataModal = { usuario: this.usuario };
    const modal = this.modalService.presentModal(component, defaultClassModal(this), dataModal, { windowClass: 'modal-fullscreen' });
    const result = await modal.result;
    if (result?.reason === "saved") this.getUsuario();
  }

}
