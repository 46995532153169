import { I18nPluralPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ColorDirective } from '../../directives/color.directive';
import { SmallLabelComponent } from '../small-label/small-label.component';

@Component({
  selector: 'creditos-button',
  standalone: true,
  imports: [
    I18nPluralPipe,
    ColorDirective,
    SmallLabelComponent,
  ],
  templateUrl: './creditos-button.component.html',
  styleUrl: './creditos-button.component.scss'
})
export class CreditosButtonComponent {

  @Input({ required: true }) quantidadeCreditos: number;

  creditoMapping: { [k: string]: string } = {
    '=1': '1 crédito',
    'other': '# créditos',
  };

}
