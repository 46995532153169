<div id="content">
  <div class="modal-header">
    <h5 class="modal-title fw-bolder" color="dark" [innerHTML]="header"></h5>
    <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <ng-mat-icon name="close" color="danger" />
    </button>
  </div>
  <div class="modal-body text-pre-line">
    <p class="m-0" [innerHTML]="message"></p>
  </div>
  <div class="d-flex gap-1 modal-footer p-2">
    <button type="button" [color]="noButtonColor" class="px-4 btn cancel-button" (click)="close(false)">{{ noText }}</button>
    <button type="button" [color]="yesButtonColor" class="px-4 btn" (click)="close(true)">{{ yesText }}</button>
  </div>
</div>
