<div id="container" class="h-100 overflow-y-hidden">
  <div id="content" class="h-100 d-flex flex-column">
    <div id="header" class="p-4">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">
          Histórico de venda <span color="secondary">{{ vendaHistorico?.nomeProduto }}</span>
        </h3>
        <button class="btn" (click)="dismiss()">
          <ng-mat-icon name="close" color="dark" />
        </button>
      </div>
    </div>

    <div id="body" class="p-4 d-flex flex-column gap-2 overflow-y-auto">
      @if (operationInProgress$ | async) {
        <operation-in-progress />
      } @else {
        @for (venda of vendas; track $index) {
          <div class="venda card d-flex flex-column p-3 gap-1">
            <div class="d-flex gap-2 align-items-center justify-content-between">
              <small-label class="fw-bold"> {{ venda.dataVenda | date: "dd/MM/yyyy - HH:mm" }} </small-label>

              <div class="d-flex gap-2">
                <ng-mat-icon name="edit" color="secondary" classes="cursor-pointer" (click)="editarVenda(venda)" />
                <ng-mat-icon name="delete" color="secondary" classes="cursor-pointer" (click)="excluirVenda(venda)" />
              </div>
            </div>
          </div>
        }
      }
    </div>

    @if (paginatorOpts) {
      <div id="footer" class="p-4">
        <paginator
          color="secondary"
          [marginTop]="false"
          [showLabels]="false"
          [page]="paginatorOpts?.page"
          [pages]="paginatorOpts.pages"
          [pageSize]="paginatorOpts.pageSize"
          [rowsCount]="paginatorOpts.rowsCount"
          [isDisabled]="operationInProgress$.value || !vendas?.length"
          (updatePageSize)="updatePageSize($event)"
          (navigatePage)="navigatePage($event)"
        />
      </div>
    }
  </div>
</div>
