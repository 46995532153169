import { ApplicationConfig, DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { provideNgxMask } from 'ngx-mask';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateAdapter } from './ngb-datepicker/custom-ngb-date.adapter';
import { CustomNgbDateParserFormatter } from './ngb-datepicker/custom-ngb-date-parser.formatter';

registerLocaleData(localePt);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withJsonpSupport()),
    provideNgxMask(),
    provideAnimationsAsync(),
    { provide: LOCALE_ID, useValue: 'pt-BR' },

    // adicionar espaço após o R$
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'R$ ' },

    { provide: NgbDateAdapter, useClass: CustomNgbDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
  ]
};
