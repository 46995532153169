<div id="container" class="h-100">
  <div id="content" class="h-100 d-flex flex-column align-items-center justify-content-center gap-5" [ngClass]="{ 'mx-5 px-5': isDesktop }">
    <h1 class="fw-500" color="dark">
      O(a) <span class="fw-bolder" [color]="produtoForm.controls.tipo?.value | colorTipoProduto"> {{ produtoForm.controls.nome.value }} </span> é comprado ou é um produto que você produz?
      <!-- <small-label class="mt-3" color="roxo"> Preciso de ajuda </small-label> -->
    </h1>

    <div class="form-group d-flex flex-column gap-2" id="tipo" [class.w-100]="!isDesktop">
      <small-label> Esse produto é: </small-label>

      <div class="btn-group" role="group">
        <button
          type="button"
          class="btn"
          [ngClass]="{ 'px-5': isDesktop }"
          [color]="produtoForm.controls.tipo.value | colorTipoProduto"
          [class]="'btn-' + (produtoForm.controls.tipo?.value === TipoProdutoEnum.composicao ? '' : 'outline')"
          (click)="produtoForm.controls.tipo.setValue(TipoProdutoEnum.composicao)"
        >
          Produzido
        </button>
        <button
          type="button"
          class="btn"
          [ngClass]="{ 'px-5': isDesktop }"
          [color]="produtoForm.controls.tipo.value | colorTipoProduto"
          [class]="'btn-' + (produtoForm.controls.tipo?.value === TipoProdutoEnum.insumo ? '' : 'outline')"
          (click)="produtoForm.controls.tipo.setValue(TipoProdutoEnum.insumo)"
        >
          Comprado
        </button>
      </div>
    </div>
  </div>
</div>
