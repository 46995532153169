<div id="container" class="d-flex justify-content-center h-100" [class.background-el]="!isDesktop">
  @if (showLogin || isDesktop) {
    @if (isDesktop) {
      <section id="banner">
        <div class="h-100 background-el">
          <ng-container *ngTemplateOutlet="contentBannerRef" />
        </div>
      </section>
    }

    <section class="form-user gap-3 d-flex flex-column" [class.w-100]="!isDesktop">
      <ng-container *ngTemplateOutlet="logoHeaderRef" />

      <form class="d-flex gap-3 flex-column" [formGroup]="form" [ngClass]="{ 'mt-5 pt-3': !isDesktop }">
        <div>
          <div class="form-group mb-3">
            <label [color]="isDesktop ? 'dark' : 'white'" class="fw-600 mb-1" for="email"> Login </label>
            <input #inputEmail type="email" class="form-control" id="email" placeholder="seuemail@email.com" formControlName="email" />
          </div>

          <div class="form-group mb-1">
            <label [color]="isDesktop ? 'dark' : 'white'" class="fw-600 mb-1" for="senha"> Senha </label>
            <input #inputPassword [type]="hidePassword ? 'password' : 'text'" class="form-control" id="senha" placeholder="******" formControlName="senha" />

            <ng-mat-icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="hidePassword ? 'visibility' : 'visibility_off'" color="dark" />
          </div>

          <div class="form-check d-flex align-items-center gap-3 mt-3">
            <input type="checkbox" id="manter-conectado" class="form-check-input my-0" formControlName="manterConectado" />
            <label [color]="isDesktop ? 'dark' : 'white'" class="fw-400 form-check-label cursor-pointer no-selection" for="manter-conectado"> Mantenha me conectado </label>
          </div>
        </div>

        <div class="d-grid">
          <button [disabled]="form.disabled" type="button" id="submit" (click)="login()" class="btn btn-secondary">
            <span> {{ isDesktop ? "Login" : "Entrar" }} </span>
            @if (fazendoLogin) {
              <div class="spinner-border ms-3" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            }
          </button>
        </div>

        <button [disabled]="form.disabled" type="button" id="esqueci-senha" class="btn clear fw-600" (click)="forgotPassword()">
          <span [color]="isDesktop ? 'info' : 'white'" class="text-decoration-underline underline-offset-2"> Esqueci minha senha </span>
        </button>

        <ng-container *ngTemplateOutlet="createAccountButton" />
      </form>
    </section>
  } @else {
    <ng-container *ngTemplateOutlet="contentBannerRef" />
  }
</div>

<ng-template #contentBannerRef>
  <div id="content-banner" class="d-flex flex-column gap-5 align-items-center justify-content-center h-100" [ngClass]="{ 'p-5 gap-5': !isDesktop }">
    @if (isDesktop) {
      <img src="assets/images/login-fg.svg" alt="" />
    }
    <div class="mt-5 text text-center d-flex flex-column gap-5">
      <h1 color="white" class="fw-700" [class.text-uppercase]="!isDesktop">Inteligência de negócios e composição de custos</h1>
      <h5 color="white" class="fw-400">Saiba seus custos e direcione seu negócio</h5>
    </div>
    @if (!isDesktop && !showLogin) {
      <div class="d-flex flex-column gap-3 w-100 mt-5">
        <ng-container *ngTemplateOutlet="logoHeaderRef" />

        <button [disabled]="form.disabled" type="button" id="submit" (click)="showLogin = true" class="btn btn-secondary w-100">
          <span> Login </span>
        </button>

        <ng-container *ngTemplateOutlet="createAccountButton" />
      </div>
    }
  </div>
</ng-template>

<ng-template #createAccountButton>
  <button [disabled]="form.disabled" type="button" id="create-senha" (click)="createAccount()" class="btn" color="apoio" [class.w-100]="!isDesktop">
    <span> {{ showLogin ? "Voltar" : "Cadastre-se" }} </span>
  </button>
</ng-template>

<ng-template #logoHeaderRef>
  <logo-header [title]="isDesktop ? 'Faça seu Login' : null" [subtitle]="isDesktop ? 'Inteligência de negócios e composição de custos' : null" [hideLine]="!isDesktop" />
</ng-template>
