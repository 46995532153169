import { JsonPipe, NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Compra, CompraInsert } from '../../../../model/compra.model';
import { Produto, TipoProdutoEnum } from '../../../../model/produto.model';
import { SelectProdutoComponent } from '../../../../custom-components/select-produto/select-produto.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BaseClass } from '../../../../globals/base-class';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../../directives/color.directive';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { closeModal, dateDDMMYYYYIsInvalid, showApiErrorMessages } from '../../../../globals/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColorTipoProdutoPipe } from '../../../../directives/color-tipo-produto.pipe';
import { TFormProduto } from '../../../produtos/novo-produto/novo-produto.component';
import { FormCompraProdutoComponent } from '../../../../custom-components/form-compra-produto/form-compra-produto.component';
import { ModalService } from '../../../../services/modal.service';
import { CompraService } from '../../../../services/compra.service';
import { ToastService } from '../../../../services/toast.service';
import { format, parse } from 'date-fns';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { ProdutoService } from '../../../../services/produto.service';
import { UnidadeMedidaService } from '../../../../services/unidade-medida.service';
import { ModalClose } from '../../../../model/custom-types';
import { ApiResponseError } from '../../../../model/api.model';

type TForm = Pick<TFormProduto, 'tipo' | 'valorUltimaCompra' | 'dataUltimaCompra' | 'quantidadeUnidadeMedidaUltimaCompra' | 'unidadeMedidaUltimaCompra' | 'marcaUltimaCompra' | 'localUltimaCompra' | 'tagCalculoCusto'>;

@Component({
  selector: 'app-nova-compra',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    JsonPipe,
    SelectProdutoComponent,
    NgClass,
    SmallLabelComponent,
    ColorDirective,
    ColorTipoProdutoPipe,
    NgMatIconComponent,
    FormCompraProdutoComponent,
    OperationInProgressComponent,
  ],
  templateUrl: './nova-compra.component.html',
  styleUrl: './nova-compra.component.scss'
})
export class NovaCompraComponent extends BaseClass() {

  compra: Compra;

  controlProduto: FormControl<Partial<Produto>> = new FormControl({ value: null, disabled: true });

  form: FormGroup<TForm> = new FormGroup<TForm>({
    tipo: new FormControl(null),
    valorUltimaCompra: new FormControl(null),
    dataUltimaCompra: new FormControl(null),
    quantidadeUnidadeMedidaUltimaCompra: new FormControl(null),
    unidadeMedidaUltimaCompra: new FormControl({ value: null, disabled: true }),
    marcaUltimaCompra: new FormControl(null),
    localUltimaCompra: new FormControl(null),
    tagCalculoCusto: new FormControl(false),
  });

  showFormSection: boolean = false;

  isEdit: boolean = false;

  TipoProdutoEnum = TipoProdutoEnum;

  constructor(
    @Inject('data') protected data: { compra?: Compra },
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private compraService: CompraService,
    private toastService: ToastService,
    private produtoService: ProdutoService,
    private unidadeMedidaService: UnidadeMedidaService,
  ) {
    super();
    if (this.data.compra) {
      this.isEdit = true;
      this.compra = this.data.compra;
      this.form.patchValue({
        valorUltimaCompra: this.compra.valorComprado,
        dataUltimaCompra: this.compra.dataUltimaCompra ? format(new Date(this.compra.dataUltimaCompra), 'dd/MM/yyyy') : null,
        quantidadeUnidadeMedidaUltimaCompra: this.compra.quantidadeComprada,
        marcaUltimaCompra: this.compra.marca,
        localUltimaCompra: this.compra.localCompra,
        tagCalculoCusto: this.compra.tagCalculoCusto
      });
      this.getUnidadeMedida(this.compra.idUnidadeMedida);
      this.getProduto(this.compra.idProduto);
    }
  }

  getUnidadeMedida(id: string) {
    this.unidadeMedidaService.getUnidadeMedidaById(id).then(res => {
      if (!res) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.toastService.show({ body: 'Ocorreu um erro ao buscar a unidade de medida. Por favor, tente novamente.', color: 'danger' });
        return
      }
      this.form.controls.unidadeMedidaUltimaCompra.setValue(res);
    }).catch((err) => {
      if (!showApiErrorMessages(this.modalService, err)) this.toastService.show({ body: 'Ocorreu um erro ao buscar a unidade de medida. Por favor, tente novamente.', color: 'danger' });
    });
  }

  getProduto(id: string) {
    this.produtoService.getProdutoById(id).then(res => {
      if (!res) return this.toastService.show({ body: 'Ocorreu um erro ao buscar o produto. Por favor, tente novamente.', color: 'danger' });
      this.controlProduto.setValue(res);
      this.form.controls.tipo.setValue(res.tipoProduto);
      if (this.isEdit) this.confirmSelecaoProduto();
    });
  }

  back() {
    if (this.showFormSection) return this.showFormSection = false;
    this.dismiss();
  }

  confirmSelecaoProduto() {
    if (!this.showFormSection) {
      if (this.controlProduto.value) this.showFormSection = true;
      return;
    }
    this.confirmCompra();
  }

  confirmCompra() {
    const { valorUltimaCompra, dataUltimaCompra, quantidadeUnidadeMedidaUltimaCompra, unidadeMedidaUltimaCompra, marcaUltimaCompra, localUltimaCompra, tagCalculoCusto } = this.form.value;

    let messages: Array<string> = [];

    if (!(valorUltimaCompra > 0)) messages.push('Valor da última compra é obrigatório e deve ser maior que zero');
    if (!dataUltimaCompra || dateDDMMYYYYIsInvalid(dataUltimaCompra)) messages.push('Data da última compra inválida');
    if (!(quantidadeUnidadeMedidaUltimaCompra > 0)) messages.push('Quantidade da última compra é obrigatória e deve ser maior que zero');
    if (!unidadeMedidaUltimaCompra) messages.push('Unidade de medida da última compra é obrigatória');
    if (dataUltimaCompra && parse(dataUltimaCompra, 'dd/MM/yyyy', new Date()) > new Date()) messages.push('Data da última compra não pode ser maior que a data atual');
    // if (!marcaUltimaCompra) messages.push('Marca da última compra é obrigatória');
    // if (!localUltimaCompra) messages.push('Local da última compra é obrigatório');

    if (messages.length) return this.modalService.presentAlert("Atenção", messages.join('\n'));

    const loading = this.modalService.presentLoading('Salvando compra...');

    const compra: Partial<CompraInsert> = {
      valorComprado: valorUltimaCompra,
      quantidadeComprada: quantidadeUnidadeMedidaUltimaCompra,
      marca: marcaUltimaCompra,
      localCompra: localUltimaCompra,
      dataUltimaCompra: dataUltimaCompra ? parse(dataUltimaCompra, 'dd/MM/yyyy', new Date())?.toISOString() : null,
      idProduto: this.controlProduto.value.id,
      idUnidadeMedida: unidadeMedidaUltimaCompra.id,
      tagCalculoCusto,
    };

    (this.isEdit ? this.compraService.updateCompra({ ...compra, id: this.compra.id }) : this.compraService.insertCompra(compra)).then((res) => {
      if (!res.data) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) this.modalService.presentAlert('Erro', 'Ocorreu um erro ao salvar a compra. Por favor, tente novamente.');
        return;
      }

      this.toastService.show({ body: 'Compra salva com sucesso', color: 'success' });
      closeModal<Compra, 'compra'>(this.activeModal, 'saved', res.data, 'compra');
    }).catch(error => {
      if (showApiErrorMessages(this.modalService, error)) return;
      this.toastService.show({ body: 'Ocorreu um erro ao salvar a compra. Por favor, tente novamente.', color: 'danger' });
    }).finally(() => loading.dismiss());

  }

  async excluir() {
    if (this.form.controls.tagCalculoCusto?.value) return this.modalService.presentAlert("Aviso", "Não é possível excluir a compra definida para cálculo dos custos do produto");

    const confirm = this.modalService.presentConfirm("Tem certeza?", "Deseja realmente excluir essa compra?", null, { yesButtonColor: 'danger' });
    const result: ModalClose<boolean> = await confirm.result;
    if (!result.data) return;

    const loading = this.modalService.presentLoading();

    const errorToast = () => this.toastService.show({ body: "Ocorreu um erro ao excluir a compra" });

    try {
      const res = await this.compraService.deleteCompra(this.compra.id);
      if (!res?.success) {
        if (!showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) errorToast();
        return;
      }

      this.toastService.show({ body: "Compra excluída com sucesso", color: "success" });
      closeModal(this.activeModal, "deleted", this.compra);
    } catch (err) {
      if (!showApiErrorMessages(this.modalService, err)) errorToast();
    } finally {
      loading.dismiss();
    }
  }

  dismiss() {
    closeModal(this.activeModal, 'cancel');
  }
}
