import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColorDirective } from '../../directives/color.directive';
import { ColorTipoProdutoPipe } from '../../directives/color-tipo-produto.pipe';
import { PasteImageDirective } from '../../directives/paste-image.directive';
import { FileToImgSrcPipe } from '../../pipes/file-to-img-src.pipe';
import { TipoProdutoEnum } from '../../model/produto.model';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';

@Component({
  selector: 'button-select-imagem-produto',
  standalone: true,
  imports: [
    ColorDirective,
    ColorTipoProdutoPipe,
    PasteImageDirective,
    FileToImgSrcPipe,
    NgMatIconComponent,
  ],
  templateUrl: './button-select-imagem-produto.component.html',
  styleUrl: './button-select-imagem-produto.component.scss'
})
export class ButtonSelectImagemProdutoComponent {

  @Input({ required: true }) tipoProduto: TipoProdutoEnum;
  @Input({ required: true }) imagemControl: FormControl<string>;
  @Input({ required: true }) imagemFileControl: FormControl<File>;

}
