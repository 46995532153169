import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastService } from '../services/toast.service';
import { ToastComponent } from '../custom-components/toast/toast.component';
import { minLengthPassword } from '../globals/globals';
import { StorageService } from '../services/storage.service';
import { Session, UsuarioSession } from '../model/usuario.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { EsqueciSenhaComponent } from './esqueci-senha/esqueci-senha.component';
import { defaultClassModal, showApiErrorMessages } from '../globals/utils';
import { LogoHeaderComponent } from '../custom-components/logo-header/logo-header.component';
import { BaseClass } from '../globals/base-class';
import { CriarContaComponent } from './criar-conta/criar-conta.component';
import { NgMatIconComponent } from '../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ColorDirective } from '../directives/color.directive';
import { ApiResponseError } from '../model/api.model';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ToastComponent,
    LogoHeaderComponent,
    NgMatIconComponent,
    ColorDirective,
    NgTemplateOutlet,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent extends BaseClass() {

  @ViewChild("inputEmail", { read: ElementRef }) inputEmail: ElementRef<HTMLInputElement>;
  @ViewChild("inputPassword", { read: ElementRef }) inputPassword: ElementRef<HTMLInputElement>;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    senha: new FormControl('', [Validators.required, Validators.minLength(minLengthPassword)]),
    manterConectado: new FormControl(false),
  });

  fazendoLogin: boolean = false;

  hidePassword: boolean = true;

  redirectTo: {
    path: string,
    params: { [k: string]: string }
  };

  showLogin: boolean = false;

  constructor(
    private toastService: ToastService,
    private storageService: StorageService,
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,

  ) {
    super();
    const redirectToQP = this.route.snapshot.queryParams?.['redirectTo'];
    if (redirectToQP) {
      const [path, params] = redirectToQP.split('?');
      this.redirectTo = { path, params: {} };
      params?.split('&').forEach((p: string) => {
        const [key, value] = p.split('=');
        this.redirectTo.params[key] = value;
      });
    }
  }

  ngAfterViewInit() {
    if (this.inputEmail && this.inputPassword) {
      this.inputEmail.nativeElement.onkeyup = (ev) => ev.key === 'Enter' ? this.inputPassword.nativeElement.focus() : null;
      this.inputPassword.nativeElement.onkeyup = (ev) => ev.key === 'Enter' ? this.login() : null;
    }
  }

  login() {
    if (this.form.invalid) return this.toastService.show({ body: 'Verifique se os campos estão preenchidos corretamente', color: 'warning' });

    this.fazendoLogin = true;
    this.form.disable();

    const { email, senha } = this.form.value;
    this.authService.login(email, senha).then((res) => {
      if (!res.accessToken) {
        if (showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>)) return;
        return this.toastService.show({ body: 'Ocorreu um erro ao tentar fazer login', color: 'danger' });
      }
      const { nameid, unique_name, email, TenantId } = JSON.parse(atob(res.accessToken.split('.')[1]))

      const usuario: UsuarioSession = {
        id: nameid,
        nome: unique_name,
        email
      };

      const session: Session = {
        accessToken: res.accessToken,
        timestamp: Date.now(),
        keepConnected: this.form.value.manterConectado,
        empresa: TenantId,
        usuario,
      }
      this.storageService.setSession(session);
      this.router.navigate(this.redirectTo ? this.redirectTo.path?.split('/') : ['/home'], { queryParams: this.redirectTo?.params });
    }).catch((err) => {
      console.error(err);
      if (showApiErrorMessages(this.modalService, { validations: err?.['error'] })) return;
      this.toastService.show({ body: 'Ocorreu um erro ao tentar fazer login', color: 'danger' });
    }).finally(() => {
      this.fazendoLogin = false;
      this.form.enable();
    });
  }

  forgotPassword() {
    this.modalService.presentModal(EsqueciSenhaComponent, defaultClassModal(this), null, { windowClass: 'blur-backdrop', });
  }

  createAccount() {
    if (this.showLogin) return this.showLogin = false;
    this.modalService.presentModal(CriarContaComponent, "slide-bottom", null, { backdrop: 'static', keyboard: false, windowClass: 'blur-backdrop modal-fullscreen', });
  }
}
