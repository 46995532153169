import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { BehaviorSubject } from 'rxjs';
import { Categoria, Categorias } from '../../model/categoria.model';
import { defaultClassModal } from '../../globals/utils';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { SmallLabelComponent } from '../../custom-components/small-label/small-label.component';
import { DebounceDirective } from '../../directives/debounce.directive';
import { FormsModule } from '@angular/forms';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { NoResultsComponent } from '../../custom-components/no-results/no-results.component';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { ColorDirective } from '../../directives/color.directive';
import { IconInEllipseComponent } from '../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { CategoriaService } from '../../services/categoria.service';
import { ApiConstraintGetCategorias } from '../../model/api.model';
import { PaginatorComponent, PaginatorOpts } from '../../custom-components/paginator/paginator.component';
import { StorageService } from '../../services/storage.service';
import { NovaCategoriaComponent } from './nova-categoria/nova-categoria.component';
import { ModalClose, ModalCloseReason } from '../../model/custom-types';

@Component({
  selector: 'app-categorias',
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    SmallLabelComponent,
    DebounceDirective,
    FormsModule,
    NgMatIconComponent,
    NoResultsComponent,
    OperationInProgressComponent,
    ColorDirective,
    IconInEllipseComponent,
    PaginatorComponent,
  ],
  templateUrl: './categorias.component.html',
  styleUrl: './categorias.component.scss'
})
export class CategoriasComponent extends BaseClass() {

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  categorias: Categorias;
  paginatorOpts: PaginatorOpts;
  searchTerm: string;

  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.getCategorias();
  }

  async getCategorias() {
    try {
      this.operationInProgress$.next(true);

      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_CATEGORIAS')
      const constraints: ApiConstraintGetCategorias = {
        Page: this.paginatorOpts?.page,
        PageSize,
        Termo: this.searchTerm,
      };
      const res = await this.categoriaService.getCategorias(constraints);
      if (res.errors) return this.toastService.show({ body: 'Erro ao buscar categorias', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data } = res;
      this.categorias = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      this.toastService.show({ body: 'Erro ao buscar categorias', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  addEdit(categoria: Categoria = null) {
    const modal = this.modalService.presentModal(NovaCategoriaComponent, defaultClassModal(this), { categoria });
    modal.closed.subscribe({
      next: (res: ModalClose) => {
        if ((['saved', 'deleted'] as Array<ModalCloseReason>).includes(res.reason)) this.getCategorias();
      }
    });
  }

  inputOnSearch() {
    this.getCategorias();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_CATEGORIAS', size);
    this.getCategorias();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getCategorias();
  }

}
