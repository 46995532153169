import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AppColor } from '../../model/custom-types';
import { BaseClass } from '../../globals/base-class';
import { NgClass } from '@angular/common';

@Component({
  selector: 'segments',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NgClass,
  ],
  templateUrl: './segments.component.html',
  styleUrl: './segments.component.scss'
})
export class SegmentsComponent extends BaseClass() {
  @Input() color: AppColor = 'primary';
  @Input({ required: true }) segments: Array<Segment>;
  @Input() isNavigation: boolean = true;
  @Input() activeSegment: Segment;
  @Input() nowrap: boolean = true;
  @Input() disabled: boolean = false;
  @Input() queryParams: { [key: string]: string | boolean | number } = {};

  @Output() onSegmentChange: EventEmitter<Segment> = new EventEmitter();

  routeChanged(route: RouterLinkActive, segment: Segment) {
    if (route.isActive) this.selectSegment(segment);
  }

  selectSegment(segment: Segment) {
    this.onSegmentChange.emit(segment);
  }
}

export type Segment = {
  title: string;
  path: Array<string>;
}
