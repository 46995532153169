<div id="container" class="d-flex w-100" [ngClass]="{ 'flex-column': !isDesktop }">
  <div class="d-flex flex-column gap-5 w-100">
    <div class="d-flex flex-column align-items-center gap-2">
      <h1 color="dark" class="fw-bolder">Sobre a última compra</h1>
      <h6 [color]="produtoForm.controls.tipo.value | colorTipoProduto" class="fw-bold">{{ produtoForm.controls.nome.value }}</h6>
    </div>

    <div id="content" class="w-100 m-auto">
      <form-compra-produto [form]="produtoForm" />
    </div>
  </div>
</div>
