import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { UnidadeMedida } from '../model/unidade-medida.model';
import { ApiConstraintGetUnidadesMedida } from '../model/api.model';
import { environment } from '../../environments/environment.dev';
import { makeParamsGet } from '../globals/utils';

@Injectable({
  providedIn: 'root'
})
export class UnidadeMedidaService extends BaseHttpService {

  getUnidadesMedida(constraints?: ApiConstraintGetUnidadesMedida) {
    const params = makeParamsGet(constraints);
    return this.get<UnidadeMedida>(`${environment.api.endpoints.unidadesMedida}`, params);
  }

  getUnidadeMedidaById(id: string) {
    return this.getById<UnidadeMedida>(`${environment.api.endpoints.unidadesMedida}`, id);
  }

  insert(unidadeMedida: Partial<UnidadeMedida>) {
    return this.post<UnidadeMedida>(`${environment.api.endpoints.unidadesMedida}`, unidadeMedida);
  }

  update(unidadeMedida: Partial<UnidadeMedida>) {
    return this.put<UnidadeMedida>(`${environment.api.endpoints.unidadesMedida}/${unidadeMedida.id}`, unidadeMedida);
  }

  deleteUnidadeMedida(id: string) {
    return this.delete<UnidadeMedida>(`${environment.api.endpoints.unidadesMedida}`, id);
  }

}
