import { Component, Inject, Input } from '@angular/core';
import { Produto } from '../../model/produto.model';
import { BaseClass } from '../../globals/base-class';
import { ProdutoService } from '../../services/produto.service';
import { ToastService } from '../../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColorDirective } from '../../directives/color.directive';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { CategoriaAutocompleteComponent } from '../autocomplete/categoria-autocomplete/categoria-autocomplete.component';
import { closeModal, showApiErrorMessages } from '../../globals/utils';
import { FormControl } from '@angular/forms';
import { Categoria } from '../../model/categoria.model';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-alterar-categoria-produto',
  standalone: true,
  imports: [
    ColorDirective,
    SmallLabelComponent,
    CategoriaAutocompleteComponent,
  ],
  templateUrl: './alterar-categoria-produto.component.html',
  styleUrl: './alterar-categoria-produto.component.scss'
})
export class AlterarCategoriaProdutoComponent extends BaseClass() {

  produto: Produto;
  categoriaAtual: Categoria;

  control: FormControl<Categoria> = new FormControl(null);

  constructor(
    @Inject('data') private data: { produto: Produto, categoriaAtual: Categoria },
    private produtoService: ProdutoService,
    private toastService: ToastService,
    private ngbActiveModal: NgbActiveModal,
    private modalService: ModalService,
  ) {
    super();
    this.produto = this.data.produto;
    this.categoriaAtual = this.data.categoriaAtual;
  }

  async salvar() {
    const categoria = this.control.value;
    if (!categoria?.id) return this.toastService.show({ body: 'Selecione uma categoria', color: 'danger' });

    const loading = this.modalService.presentLoading("Alterando categoria do produto...", true);

    try {
      const produto: Produto = await this.produtoService.getProdutoById(this.produto.id);
      if (!produto) return this.toastService.show({ body: 'Produto não encontrado', color: 'danger' });

      await this.produtoService.updateProduto({ ...produto, idCategoria: categoria.id });
      this.toastService.show({ body: 'Categoria do produto alterada com sucesso', color: 'success' });
      closeModal(this.ngbActiveModal, "saved", true);
    } catch (error) {
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao alterar categoria do produto', color: 'danger' });
    } finally {
      loading.dismiss();
    }
  }

  dismiss() {
    closeModal(this.ngbActiveModal, "cancel", false);
  }

}
