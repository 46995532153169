<div id="container" class="d-flex w-100 pt-4">
  <div class="d-flex flex-column gap-4 w-100">
    <h2 class="fw-bolder px-1" color="dark">Registro de vendas</h2>

    <small-label class="px-1">Registro da quantidade de vendas por período</small-label>

    <div id="table" class="w-100">
      <div
        id="header"
        class="d-flex list-row align-items-center position-sticky top-0 px-1 rounded-top-4"
        [style.background]="'var(--color-' + (produto?.tipoProduto | colorTipoProduto) + ', var(--bs-' + (produto?.tipoProduto | colorTipoProduto) + '))'"
      >
        <div class="cell text-center fw-bold py-2 flex-grow-1 detalhes">Vendas</div>
      </div>

      <div id="table-content" class="px-1 pb-1 d-flex flex-column gap-2 py-2">
        @if (!vendasHistorico) {
          <operation-in-progress />
        }

        @if (vendasHistorico?.length === 0) {
          <no-results message="Nenhuma venda encontrada" />
        }

        @for (venda of vendasHistorico; track $index) {
          <div class="venda d-flex flex-column gap-3 p-3 rounded-4 position-relative">
            <div class="detalhes flex-grow-1" [ngClass]="{ 'd-flex flex-column': !isDesktop, 'd-grid grid-auto-columns align-items-center': isDesktop }">
              <div class="d-flex gap-2">
                @if (isDesktop) {
                  <small-label color="cinza">Período</small-label>
                }
                <small-label color="dark" class="fw-bold">{{ "Setembro/2024" }}</small-label>
              </div>

              <div class="d-flex gap-2">
                <ng-container *ngTemplateOutlet="quantidadeMedidaBlock; context: { $implicit: venda }" />
              </div>

              <div class="d-flex gap-2">
                <ng-container *ngTemplateOutlet="precoBlock; context: { $implicit: venda }" />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>

<ng-template #quantidadeMedidaBlock let-venda>
  <small-label>
    <div class="d-flex gap-2">
      @if (isDesktop) {
        <small-label color="cinza"> Quantidade </small-label>
      }
      <span class="fw-bold" color="dark"> {{ venda?.["quantidade"] }} </span>
    </div>
  </small-label>
</ng-template>

<ng-template #precoBlock let-venda>
  <small-label>
    <div class="d-flex gap-2">
      @if (isDesktop) {
        <small-label color="cinza"> Preço </small-label>
      }
      <span class="fw-bold" color="dark"> {{ venda?.["preco"] | currency }} </span>
    </div>
  </small-label>
</ng-template>
