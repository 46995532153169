import { Routes } from '@angular/router';
import { ComprasComponent } from './compras-wrapper/compras/compras.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { UnidadesMedidaComponent } from './unidades-medida/unidades-medida.component';
import { PendenciasComponent } from './pendencias/pendencias.component';
import { ComprasWrapperComponent } from './compras-wrapper/compras-wrapper.component';
import { HistoricoComprasComponent } from './compras-wrapper/historico-compras/historico-compras.component';
import { MinhaContaComponent } from './minha-conta/minha-conta.component';
import { MeuNegocioComponent } from './meu-negocio/meu-negocio.component';
import { AjudaComponent } from './ajuda/ajuda.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { VendasWrapperComponent } from './vendas-wrapper/vendas-wrapper.component';
import { ProdutosVendaComponent } from './vendas-wrapper/produtos-venda/produtos-venda.component';
import { HistoricoVendasComponent } from './vendas-wrapper/historico-vendas/historico-vendas.component';
import { DetalhesProdutoRoutes } from './produtos/detalhes-produto/detalhes-produto.routes';
import { DetalhesProdutoComponent } from './produtos/detalhes-produto/detalhes-produto.component';
import { TipoProdutoEnum } from '../model/produto.model';
import { ProdutoWrapperComponent } from './produtos/produtos-wrapper.component';

const ChildrenRouteProduto: Routes = [
  { path: ':id', component: DetalhesProdutoComponent, children: DetalhesProdutoRoutes },
];

export const HomeRoutes: Routes = [
  { path: '', redirectTo: 'produtos', pathMatch: 'full' },
  {
    path: 'produtos',
    component: ProdutoWrapperComponent,
    children: [
      { path: TipoProdutoEnum[TipoProdutoEnum.composicao], component: ProdutosComponent, children: ChildrenRouteProduto, },
      { path: TipoProdutoEnum[TipoProdutoEnum.insumo], component: ProdutosComponent, children: ChildrenRouteProduto, },
      { path: '', redirectTo: TipoProdutoEnum[TipoProdutoEnum.composicao], pathMatch: 'full' },
    ],
  },
  {
    path: 'compras',
    component: ComprasWrapperComponent,
    children: [
      { path: 'list', component: ComprasComponent },
      { path: 'historico', component: HistoricoComprasComponent },
      { path: '', redirectTo: 'list', pathMatch: 'full' },
    ]
  },
  {
    path: 'vendas',
    component: VendasWrapperComponent,
    children: [
      { path: 'produtos', component: ProdutosVendaComponent },
      { path: 'historico', component: HistoricoVendasComponent },
      { path: '', redirectTo: 'produtos', pathMatch: 'full' },
    ],
  },
  { path: 'categorias', component: CategoriasComponent },
  { path: 'unidades-medida', component: UnidadesMedidaComponent },
  { path: 'pendencias', component: PendenciasComponent },
  { path: 'minha-conta', component: MinhaContaComponent },
  { path: 'meu-negocio', component: MeuNegocioComponent },
  { path: 'ajuda', component: AjudaComponent },
];
