import { Component, Input } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import {
  Produto,
  ProdutosComposicao,
  TipoProdutoEnum,
  UnidadeMedidaProdutoEquivalentRendimento,
} from '../../../../model/produto.model';
import { ColorDirective } from '../../../../directives/color.directive';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { AsyncPipe, CurrencyPipe, I18nPluralPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { ProdutoService } from '../../../../services/produto.service';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { OperationInProgressComponent } from "../../../../custom-components/operation-in-progress/operation-in-progress.component";
import { NoResultsComponent } from "../../../../custom-components/no-results/no-results.component";
import { PaginatorComponent, PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../../services/storage.service';
import { ApiConstraintGetProdutosComposicao } from '../../../../model/api.model';
import { ToastService } from '../../../../services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { ModalService } from '../../../../services/modal.service';
import { showApiErrorMessages } from '../../../../globals/utils';

@Component({
  selector: 'section-composicao-produto',
  standalone: true,
  imports: [
    ColorDirective,
    SmallLabelComponent,
    CurrencyPipe,
    I18nPluralPipe,
    NgbAccordionModule,
    NgClass,
    NgMatIconComponent,
    NgTemplateOutlet,
    OperationInProgressComponent,
    NoResultsComponent,
    PaginatorComponent,
    AsyncPipe,
  ],
  templateUrl: './section-composicao-produto.component.html',
  styleUrl: './section-composicao-produto.component.scss'
})
export class SectionComposicaoProdutoComponent extends BaseClass() {
  @Input() produto: Produto;

  produtosComposicao: ProdutosComposicao;

  unidadeRendimento: UnidadeMedidaProdutoEquivalentRendimento<TipoProdutoEnum.composicao>;

  paginatorOpts: PaginatorOpts;
  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private produtoService: ProdutoService,
    private storageService: StorageService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    this.unidadeRendimento = this.produto.unidadesMedidaRendimentos?.at(0);
    this.getProdutosComposicao();
  }

  async getProdutosComposicao() {
    try {
      this.operationInProgress$.next(true);

      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_PRODUTOS_COMPOSICAO')
      const constraints: ApiConstraintGetProdutosComposicao = {
        Page: this.paginatorOpts?.page,
        PageSize,
      };
      const res = await this.produtoService.getProdutosComposicao(this.produto.id, constraints);
      if (res.errors) return this.toastService.show({ body: 'Erro ao buscar produtos da composição', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data } = res;
      this.produtosComposicao = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      if (!showApiErrorMessages(this.modalService, error)) this.toastService.show({ body: 'Erro ao buscar produtos da composição', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PRODUTOS_COMPOSICAO', size);
    this.getProdutosComposicao();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getProdutosComposicao();
  }

}
