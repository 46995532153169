import { Component, Input } from '@angular/core';

@Component({
  selector: 'logo-header',
  standalone: true,
  imports: [],
  templateUrl: './logo-header.component.html',
  styleUrl: './logo-header.component.scss'
})
export class LogoHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() hideLine: boolean = false;
}
