<div id="container">
  <input
    #inputMedidaRef
    #autocompleteTrigger="matAutocompleteTrigger"
    type="text"
    class="medida form-control lh-2"
    id="unidade-medida-input"
    placeholder="Ex: Quilos, Litros, Caixas"
    [formControl]="control"
    [matAutocomplete]="autocompleteUnidade"
    [debounce]="debounce"
    (onChange)="filter($event)"
  />
  <ng-mat-icon class="cursor-pointer" slot="end" name="expand_more" [color]="tipoProduto | colorTipoProduto" (click)="inputMedidaRef.focus()" />
  <mat-autocomplete #autocompleteUnidade="matAutocomplete" [displayWith]="displayWith" requireSelection class="autocomplete-selecionar-produto-composicao">
    @if (!idProduto) {
      <nova-unidade-option [control]="control" [tipoProduto]="tipoProduto" [autocompleteTrigger]="autocompleteTrigger" (unidadeAdicionada)="getUnidadesMedida()" />
    }
    @if (searching) {
      <mat-option disabled>Carregando unidades de medida...</mat-option>
    } @else {
      @if (unidadesMedida?.length === 0) {
        <mat-option disabled>{{ inputMedidaRef.value ? " Nenhuma unidade encontrada" : "Esse produto não tem nenhuma unidade de medida equivalente cadastrada" }}</mat-option>
      }
      @for (unidade of unidadesMedida; track unidade) {
        <mat-option [value]="unidade">
          <div class="d-flex gap-3 align-items-center">
            <span>{{ unidade.nome }}</span>
          </div>
        </mat-option>
      }
    }
  </mat-autocomplete>
</div>
