import { Component, HostListener, Inject } from '@angular/core';
import { OperationInProgressComponent } from '../../../custom-components/operation-in-progress/operation-in-progress.component';
import { Segment, SegmentsComponent } from '../../../custom-components/segments/segments.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { BaseClass } from '../../../globals/base-class';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import {
  ClassificacaoProdutoEnum,
  ComposicaoInsert,
  InsumoInsert,
  Produto,
  ProdutoCommonInsert,
  ProdutosComposicaoForm,
  TipoProdutoEnum,
} from '../../../model/produto.model';
import { closeModal, fileToBase64, showApiErrorMessages, updateUrl } from '../../../globals/utils';
import { ModalClose } from '../../../model/custom-types';
import { ColorDirective } from '../../../directives/color.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SectionDetalhesProdutoComponent } from './section-detalhes-produto/section-detalhes-produto.component';
import { SectionMedidasProdutoComponent } from './section-medidas-produto/section-medidas-produto.component';
import { SectionComposicaoProdutoComponent } from './section-composicao-produto/section-composicao-produto.component';
import { SectionVendasProdutoComponent } from './section-vendas-produto/section-vendas-produto.component';
import { SectionComprasProdutoComponent } from './section-compras-produto/section-compras-produto.component';
import { Location as NgLocation } from '@angular/common';
import { ProdutoService } from '../../../services/produto.service';
import { ToastService } from '../../../services/toast.service';
import { ColorTipoProdutoPipe } from '../../../directives/color-tipo-produto.pipe';
import { Categoria } from '../../../model/categoria.model';
import { ModalService } from '../../../services/modal.service';
import { UnidadeMedida } from '../../../model/unidade-medida.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SectionDetalhesProdutoEditarComponent } from './section-detalhes-produto-editar/section-detalhes-produto-editar.component';
import { SectionMedidasProdutoEditarComponent } from './section-medidas-produto-editar/section-medidas-produto-editar.component';
import { SectionComposicaoProdutoEditarComponent } from './section-composicao-produto-editar/section-composicao-produto-editar.component';
import { ApiResponseError } from '../../../model/api.model';
import { SwipeEventTreshold } from '../../../globals/globals';
import { PaginatorComponent } from '../../../custom-components/paginator/paginator.component';

const CHILDREN = [
  SectionDetalhesProdutoComponent,
  SectionMedidasProdutoComponent,
  SectionComposicaoProdutoComponent,
  SectionVendasProdutoComponent,
  SectionComprasProdutoComponent,

  SectionDetalhesProdutoEditarComponent,
  SectionMedidasProdutoEditarComponent,
  SectionComposicaoProdutoEditarComponent,
];

export type TFormEditProduto = {
  tipo: FormControl<TipoProdutoEnum>;

  ativo: FormControl<boolean>;
  imagem: FormControl<string>;
  imagemFile: FormControl<File>;
  nome: FormControl<string>;
  codigoBarras: FormControl<string>;
  valorVenda: FormControl<number>;
  categoria: FormControl<Categoria>;
  classificacao: FormControl<ClassificacaoProdutoEnum>;

  unidadeMedidaCompra: FormControl<UnidadeMedida>;
}

export type UnidadeMedidaFormEditar = UnidadeMedida & { checked: boolean, quantidade: number };

@Component({
  selector: 'detalhes-produto',
  standalone: true,
  imports: [
    OperationInProgressComponent,
    SegmentsComponent,
    RouterOutlet,
    ColorDirective,
    AsyncPipe,
    NgClass,
    ColorTipoProdutoPipe,
    PaginatorComponent,
    CHILDREN,
  ],
  templateUrl: './detalhes-produto.component.html',
  styleUrls: ['../common/common-styles-modal-full-content-produto.scss', './detalhes-produto.component.scss'],
})
export class DetalhesProdutoComponent extends BaseClass() {

  segments: Array<Segment> = [
    { path: ['detalhes'], title: 'Detalhes' },
    { path: ['medidas'], title: 'Medidas' },
  ];
  activeSegment: Segment;

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  TipoProdutoEnum = TipoProdutoEnum;

  produto: Produto;
  produtosComposicao: ProdutosComposicaoForm;
  unidadesMedida: Array<UnidadeMedidaFormEditar> = null;

  formEdit: FormGroup<TFormEditProduto> = null;

  private produtoMudou: boolean = false

  constructor(
    @Inject('data') private data: { id?: string },
    private router: Router,
    private route: ActivatedRoute,
    private ngbActiveModal: NgbActiveModal,
    private ngLocation: NgLocation,
    private produtoService: ProdutoService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  coords: { x: number, y: number };

  @HostListener('touchstart', ['$event'])
  touchStart(event: TouchEvent) {
    this.coords = { x: event.touches[0].clientX, y: event.touches[0].clientY };
  }

  @HostListener('touchend', ['$event'])
  touchEnd(event: TouchEvent) {
    const target = event.target as HTMLElement;
    const x = event.changedTouches[0].clientX;
    const y = event.changedTouches[0].clientY;

    const swipeLeft = this.coords.x - x > SwipeEventTreshold && Math.abs(this.coords.y - y) < 50;
    const swipeRight = x - this.coords.x > SwipeEventTreshold && Math.abs(this.coords.y - y) < 50;

    if (target.closest('#content')) {
      if (swipeLeft) {
        const next = this.segments[this.segments.findIndex(x => x.path[0] === this.activeSegment.path[0]) + 1];
        if (next) this.segmentChanged(next);
      } else if (swipeRight) {
        const prev = this.segments[this.segments.findIndex(x => x.path[0] === this.activeSegment.path[0]) - 1];
        if (prev) this.segmentChanged(prev);
      }
    }
  }

  ngOnInit() {
    const sub = this.router.events.subscribe({
      next: (ev) => {
        if (ev instanceof NavigationEnd) this.ngbActiveModal.dismiss();
      }
    });
    this.appendSubscription(sub);

    this.initialize();
  }

  private activateSegment() {
    const queryParams = this.route.snapshot.queryParams;
    this.activeSegment = this.segments.find(s => s.path[0] === queryParams?.['segment']) || this.segments[0];
  }

  initialize() {
    this.activateSegment();

    this.getDetalhesProduto(this.data?.id).then(() => {
      if (this.route.snapshot?.queryParams?.['action'] === 'editar') this.editar();
    });
  }

  async getDetalhesProduto(idProduto: string) {
    this.operationInProgress$.next(true);

    try {
      const produtoRes = await this.produtoService.getProdutoById(idProduto);

      if (!produtoRes) {
        this.toastService.show({ body: 'Produto não encontrado', color: 'danger' });
        this.operationInProgress$.next(false);
        return this.close();
      }

      this.setDetalhesProduto(produtoRes);
    } catch (error) {
      console.log(error);
      this.toastService.show({ body: 'Erro ao buscar detalhes do produto', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  private async setDetalhesProduto(produto: Produto) {
    this.produto = produto;
    this.unidadesMedida = this.produto.unidadesMedidaEquivalentes?.map(x => ({
      id: x.idUnidadeMedida,
      nome: x.nomeUnidadeMedida,
      sigla: x.siglaUnidadeMedida,
      checked: true,
      quantidade: x.quantidadeEquivalente ?? 1,
    }));

    this.addSegmentosExtras(produto);
    this.activateSegment();
    updateUrl(this.ngLocation, { id: this.produto.id, segment: this.activeSegment.path[0] });
    this.operationInProgress$.next(false);
  }

  private addSegmentosExtras(produto: Produto) {
    if (produto.tipoProduto === TipoProdutoEnum.composicao) {
      this.addSegmentsArray(
        { path: ['composicao'], title: 'Composição' },
        { path: ['vendas'], title: 'Vendas' },
      );
      return;
    }

    this.addSegmentsArray({ path: ['compras'], title: 'Registro de compras' });
  }

  private addSegmentsArray(...segments: Array<Segment>) {
    segments.forEach(s => {
      if (!this.segments.find(x => x.path[0] === s.path[0])) this.segments.push(s);
    });
  }

  segmentChanged(segment: Segment, editar: boolean = false) {
    this.activeSegment = segment;
    const params = Object.fromEntries(Object.entries({
      id: this.produto.id,
      segment: segment.path[0],
      action: editar ? 'editar' : null,
    }).filter(([_, v]) => v !== null));
    updateUrl(this.ngLocation, params);
  }

  delete() {
    const confirm = this.modalService.presentConfirm("Tem certeza", "Deseja realmente excluir este produto?", null, { yesButtonColor: 'danger' });

    confirm.closed.subscribe({
      next: (res: ModalClose<boolean>) => {
        if (res.data === true) {
          const loading = this.modalService.presentLoading('Excluindo produto...', true);

          this.produtoService.deleteProduto(this.produto.id).then((res) => {
            if (!res.success) {
              const userNotified = !showApiErrorMessages(this.modalService, res as ApiResponseError<'validation'>);
              if (!userNotified) this.toastService.show({ body: 'Erro ao excluir produto', color: 'danger' });
              return;
            }

            this.toastService.show({ body: `Produto excluído com sucesso`, color: 'success' });
            closeModal<Produto, "produto">(this.ngbActiveModal, 'deleted', this.produto, 'produto');
          }).catch((err) => {
            console.log(err);
            const userNotified = !showApiErrorMessages(this.modalService, err as ApiResponseError<'validation'>);
            if (!userNotified) this.toastService.show({ body: 'Erro ao excluir produto', color: 'danger' });
          }).finally(() => {
            loading.dismiss();
          });
        }
      }
    });
  }

  private valueFormDetalhes(): Pick<Produto, 'ativo' | 'nome' | 'codigoBarras' | 'valorVenda' | 'idCategoria' | 'classificacao' | 'idUnidadeMedidaCompra'> {
    const { ativo, imagem, imagemFile, nome, codigoBarras, valorVenda, categoria, classificacao, unidadeMedidaCompra } = this.formEdit.getRawValue();

    const requiredFields = {
      ativo: "Ativo é obrigatório",
      nome: "Nome é obrigatório",
      codigoBarras: "Código de barras é obrigatório",
      categoria: "Categoria é obrigatório",
      classificacao: "Classificação é obrigatório",
    };

    if (this.formEdit.controls.classificacao.value === ClassificacaoProdutoEnum.vendido) requiredFields['valorVenda'] = "Valor de venda é obrigatório e deve ser um número maior que zero";

    const messages: Array<string> = [];
    for (const [key, value] of Object.entries(requiredFields)) {
      if (!this.formEdit.get(key).valid) messages.push(value);
    }

    if (messages.length > 0) {
      this.modalService.presentAlert("Aviso", messages.join("\n"));
      this.segmentChanged(this.segments.find(x => x.path[0] === 'detalhes'));
      return;
    }

    return {
      ativo,
      nome,
      codigoBarras,
      valorVenda,
      idCategoria: categoria.id,
      classificacao,
      idUnidadeMedidaCompra: unidadeMedidaCompra?.id || null,
    };
  }

  private unidadesSelecionadas(): Array<UnidadeMedidaFormEditar> {
    const unidades = this.unidadesMedida?.filter(x => x.checked);

    if (unidades.some(x => !(x.quantidade > 0))) {
      this.modalService.presentAlert("Aviso", "Quantidade das unidades de medida devem ser maiores que zero");
      this.segmentChanged(this.segments.find(x => x.path[0] === 'medidas'));
      return;
    }

    return unidades;
  }

  async save() {
    const formValueDetalhes = this.valueFormDetalhes();
    if (!formValueDetalhes) return;

    const unidades = this.unidadesSelecionadas();
    if (!unidades) return;

    const { categoria, unidadesMedidaEquivalentes, unidadesMedidaRendimentos, ...produto } = this.produto;

    const toUpdateCommon: Partial<ProdutoCommonInsert> = {
      id: this.produto.id,
      quantidadeMediaVendidaMensal: this.produto.quantidadeMediaVendidaMensal,
      tipoProduto: this.produto.tipoProduto,
      valorVenda: this.produto.valorVenda,
      idCompraProduto: this.produto.idCompraProduto,
      ...formValueDetalhes,
    };

    if (this.formEdit.controls.imagemFile.value) {
      const base64Image = await fileToBase64(this.formEdit.controls.imagemFile.value);
      if (!base64Image) return this.toastService.show({ body: 'Erro ao converter imagem', color: 'danger' });
      toUpdateCommon.imageBase64 = base64Image;
    }

    const toUpdateInsumo: Partial<InsumoInsert> = {
      valorComprado: this.produto.valorComprado,
      quantidadeComprada: this.produto.quantidadeComprada,
      unidadesMedidaEquivalentes: unidades.map(x => {
        return {
          idUnidadeMedida: x.id,
          quantidadeEquivalente: x.quantidade,
          idCompraProduto: this.produto.idCompraProduto,
        }
      }),

      dataUltimaCompra: this.produto.dataUltimaCompra,
    }

    const toUpdateComposicao: Partial<ComposicaoInsert> = {
      unidadesMedidaRendimentos: unidades.map(x => {
        return {
          idUnidadeMedida: x.id,
          quantidadeRendimento: x.quantidade,
          idCompraProduto: this.produto.idCompraProduto,
        }
      })
    }

    const toUpdate = {
      ...toUpdateCommon,
      ...(this.produto.tipoProduto === TipoProdutoEnum.insumo ? toUpdateInsumo : toUpdateComposicao),
    };

    const loading = this.modalService.presentLoading('Salvando alterações...', true);

    this.produtoService.updateProduto(toUpdate).then((res) => {
      if (!res.success && !showApiErrorMessages(this.modalService, res.errors)) return this.toastService.show({ body: 'Erro ao salvar alterações', color: 'danger' });

      this.toastService.show({ body: 'Alterações salvas com sucesso', color: 'success' });
      this.formEdit = null;
      this.getDetalhesProduto(this.produto.id);
      this.produtoMudou = true;
    }).catch((err) => {
      console.log(err);
      if (!showApiErrorMessages(this.modalService, err)) this.toastService.show({ body: 'Erro ao salvar alterações', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    });
  }

  editar() {
    if (this.formEdit) return this.save();

    const eInsumo = this.produto.tipoProduto === TipoProdutoEnum.insumo;
    this.unidadesMedida = (eInsumo ? this.produto.unidadesMedidaEquivalentes : this.produto.unidadesMedidaRendimentos).map(x => {
      return {
        checked: true,
        id: x.idUnidadeMedida,
        nome: x.nomeUnidadeMedida,
        quantidade: eInsumo ? x.quantidadeEquivalente : x.quantidadeRendimento,
        sigla: x.siglaUnidadeMedida,
      }
    });

    this.formEdit = new FormGroup<TFormEditProduto>({
      tipo: new FormControl(this.produto.tipoProduto),

      ativo: new FormControl(this.produto.ativo, [Validators.required, Validators.pattern(/(true|false)/)]),
      imagem: new FormControl(this.produto.imagem),
      imagemFile: new FormControl(null),
      nome: new FormControl(this.produto.nome, [Validators.required]),
      codigoBarras: new FormControl(this.produto.codigoBarras),
      valorVenda: new FormControl(this.produto.valorVenda, [Validators.required]),
      categoria: new FormControl({ ...this.produto.categoria, id: this.produto.idCategoria }, [Validators.required]),
      classificacao: new FormControl(this.produto.classificacao, [Validators.required]),

      unidadeMedidaCompra: new FormControl({
        id: this.produto.idUnidadeMedidaCompra,
        nome: this.produto.nomeUnidadeMedidaCompra,
        sigla: this.produto.siglaUnidadeMedidaCompra,
      }),
    });

    this.segments = this.segments.filter(x => {
      if (this.produto.tipoProduto === TipoProdutoEnum.insumo) return x.path[0] != 'compras';
      if (this.produto.tipoProduto === TipoProdutoEnum.composicao) return x.path[0] != 'vendas';
    });

    const _segment = this.segments?.slice(0, 3).find(x => this.activeSegment.path[0] === x.path[0]);
    this.segmentChanged(_segment || this.segments[0], true);
  }

  unidadeMedidaChanged(unidade: UnidadeMedidaFormEditar) {
    this.unidadesMedida = [unidade];
  }

  async close(): Promise<void> {
    if (this.formEdit) {
      if (!this.formEdit.pristine) {
        const confirm = this.modalService.presentConfirm("Tem certeza?", "Deseja cancelar a edição do produto? Todas as alterações serão perdidas.");
        const res: ModalClose<boolean> = await confirm.result;
        if (res.data !== true) return;
      }

      this.addSegmentosExtras(this.produto);
      updateUrl(this.ngLocation, { id: this.produto.id, segment: this.activeSegment.path[0] });
      return this.formEdit = null;
    }

    delete this.route.snapshot?.queryParams;
    closeModal<Produto, 'produto'>(this.ngbActiveModal, this.produtoMudou ? "saved" : "cancel", this.produto, 'produto');
  }
}
